
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, defaultSelectChargement, mvt_delete_api, mvt_info_api, mvt_list_api, typeMvt, typeTiers } from '../../global/GlobalVars';
import Mvt, { AutoCreateFacture } from './Mvt';
import LignesMvt, { cleanGetLignesMvtsListQuery } from './LignesMvt';
import BasicButton from '../../components/BasicButton';
import {
  DateFormat, DateFormatDb, clearToasts, formatBoolToStar, formatDate, formatDateTime,
  formatDecimalNumber, formatIntTostring, formatTodayDate, ClientOrFournisseur,
  showToast, showToastError
} from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetMvtEmptyQuery, cleanGetMvtQuery } from './Mvt';
import BasicLabel from '../../components/BasicLabel';
import BasicDateInput from '../../components/BasicDateInput';
import dayjs from 'dayjs';
import { GetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import BasicSelect from '../../components/BasicSelect';
import BasicInput from '../../components/BasicInput';
import { GetFournisseursListQuery } from '../fournisseur/FournisseursList';
import { GetClientsListQuery } from '../client/ClientsList';
import { GetRepresentantsListQuery } from '../representant/RepresentantsList';
import { GetMode_ReglementsListQuery } from '../mode_reglement/Mode_ReglementsList';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import { GetUtilisateursListQuery } from '../utilisateur/UtilisateursList';
import BasicCheckBox from '../../components/BasicCheckBox';
import { customConsoleLog } from '../../global/Tools';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReactDOM from 'react-dom';
import BasicNumberInput from '../../components/BasicNumberInput';
import { GetParametreQuery, cleanGetParametreQuery, invalidateGetParametreQuery } from '../parametre/Parametre';
import { useLoginStore } from '../../stores/globalStore';
import { v4 as uuidv4 } from 'uuid';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'ref_mvt', headerName: 'Ref Mvt', width: 100 },
  { field: 'date_mvt', headerName: 'date_mvt', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'tiers_code', headerName: 'Code Tiers', width: 100 },
  { field: 'tiers_nom', headerName: 'Nom Tiers', width: 200 },
  { field: 'echeance', headerName: 'echeance', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'valide', headerName: 'V', width: 50, type: 'string', valueFormatter: (params) => formatBoolToStar(params.value) },
  { field: 'etat', headerName: 'Etat', width: 50 },
  { field: 'total_ttc', headerName: 'Montant', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'id_utilisateur', headerName: 'U', width: 30 },
  { field: 'modesreglementcode', headerName: 'Reg', width: 70 },
  { field: 'en_tete', headerName: 'Client Divers', width: 200 },

];
const hideColumns = { id: false }


export const GetMvtsListQuery = (formValues, canFetch = true) => {
  const params = formValues;
  customConsoleLog('params', params);

  return useQuery({
    queryKey: ['MvtList'],
    queryFn: async () => await callAxiosApi(`${mvt_list_api}`, { params }, true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddMvtLine = (queryClient, handleCloseMvt, handleRowSelectionMvtsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${mvt_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['MvtList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addMvtLine onSuccess');
      customConsoleLog(newprod);
      handleCloseMvt();
      handleRowSelectionMvtsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateMvtLine = (queryClient, handleCloseMvt, handleRowSelectionMvtsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${mvt_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateMvtLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateMvtLine called')
      queryClient.setQueryData(['MvtList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetMvtQuery(newprod.id, queryClient);
      handleCloseMvt();
      handleRowSelectionMvtsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteMvtLine = (queryClient, handleCloseMvt) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${mvt_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['MvtList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetMvtQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseMvt();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetMvtsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['MvtList'] });
}


const BasicFitlerModal = (props) => {
  const { openFilter, handleClose, defaultValues, values, setValues, handleRefetch, type_mvt, ...others } = props;

  const date_debut = formatDate(dayjs().startOf('year'), true);
  const date_fin = formatDate(dayjs(), true);
  const date_debut_2001 = formatDate(dayjs('2001-01-01'), true);
  // const date_debut = '22-01-2024';
  // const date_fin = '22-01-2024';
  const montant_debut = '0.00';
  const montant_fin = '99999999.99';

  const [filterFormValues, setFilterFormValues] = useState({
    ...defaultValues,
    date_debut: date_debut, date_fin: date_fin,
    montant_debut: montant_debut, montant_fin: montant_fin,
    echeance_debut: date_debut_2001, echeance_fin: date_fin
  });

  customConsoleLog('filterFormValues', filterFormValues);

  const [canFetch, setCanFetch] = useState(false);

  const isCliOrFr = ClientOrFournisseur(type_mvt);



  const {
    data: data_etablissement = defaultSelectChargement,
    isLoading: isLoading_etablissement, error: error_etablissement, refetch: refetch_etablissement,
    failureCount: failureCount_etablissement } = GetEtablissementsListQuery(true);

  const {
    data: data_tiers = defaultSelectChargement,
    isLoading: isLoading_tiers } = isCliOrFr == typeTiers.client ? GetClientsListQuery(true, 'nom') : GetFournisseursListQuery(true, 'nom');


  const {
    data: data_representant = defaultSelectChargement,
    isLoading: isLoading_representant } = GetRepresentantsListQuery(true, 'code');


  const {
    data: data_mode_reglements = defaultSelectChargement,
    isLoading: isLoading_mode_reglements } = GetMode_ReglementsListQuery(true, 'code');

  const {
    data: data_utilisateur = defaultSelectChargement,
    isLoading: isLoading_utilisateur } = GetUtilisateursListQuery(true, 'id');

  const handleFilterSubmit = (e) => {
    e.preventDefault();

  }

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;

    // if (name.includes('date')) {
    //   //const formatedDate = formatDate(value, false);

    //   setFilterFormValues({ ...filterFormValues, [name]: formatedDate });
    // }
    // else {

    setFilterFormValues({ ...filterFormValues, [name]: type === 'checkbox' ? checked : value });
    //}
  }


  const handleOkFilter = () => {
    setValues(filterFormValues);
    handleRefetch();
    handleFermerFilter();
  }

  const handleTousFilter = () => {
    setValues({ type_mvt: type_mvt });
    handleRefetch();
    handleFermerFilter();
  }

  const handleFermerFilter = () => {
    handleClose();
  }

  return (
    <>
      <BasicModal open={openFilter} isModal={true}>
        <section className="m-2">
          <form onSubmit={handleFilterSubmit}>

            <div className='flex flex-col gap-2 min-h-[250px] mt-8'>
              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Ref Mvt :">
                  <BasicInput className='text-xs' id="ref_mvt" name="ref_mvt" value={filterFormValues.ref_mvt || ''} onChange={handleFilterChange} />
                </BasicLabel>
                <div></div>
              </div>
              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Période du :">
                  <BasicDateInput isbig={true} id="date_debut" name="date_debut"
                    format={DateFormat} value={filterFormValues.date_debut || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs' titre="au :">
                  <BasicDateInput isbig={true} id="date_fin" name="date_fin"
                    format={DateFormat} value={filterFormValues.date_fin || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>

              </div>
              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Tiers :">
                  <BasicSelect id="id_tiers" name="id_tiers" className='text-xs'
                    listItems={data_tiers || []} value={filterFormValues.id_tiers || ''}
                    textKey={'nom'}
                    fieldsConfig={[{ key: 'nom', title: 'Nom' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>
              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Representant :">
                  <BasicSelect id="id_representant" name="id_representant" className='text-xs'
                    listItems={data_representant || []} value={filterFormValues.id_representant || ''}
                    textKey={'nom'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'nom', title: 'Nom' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>
              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Mode Reglement :">
                  <BasicSelect id="id_reglement" name="id_reglement"
                    className='text-xs'
                    listItems={data_mode_reglements || []} value={filterFormValues.id_reglement || ''}
                    textKey={'libelle'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>
              <div className='grid grid-cols-3'>
                <BasicLabel className='text-xs w-[120px]' titre="Valide :">
                  <BasicCheckBox id="valide" name="valide"
                    checked={filterFormValues.valide || ''} onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs w-[200px] col-span-2' titre="Sans les Factures ou BL :">
                  <BasicCheckBox id="sans_fc_bl" name="sans_fc_bl"
                    checked={filterFormValues.sans_fc_bl || ''} onChange={handleFilterChange} />
                </BasicLabel>
              </div>

              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Montant entre :">
                  <BasicDecimalInput id="montant_debut" name="montant_debut" className='text-xs' value={filterFormValues.montant_debut || montant_debut} onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs' titre="Et :">
                  <BasicDecimalInput id="montant_fin" name="montant_fin" className='text-xs' value={filterFormValues.montant_fin || montant_fin} onChange={handleFilterChange} />
                </BasicLabel>
              </div>
              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Echeance Entre :">
                  <BasicDateInput isbig={true} className='text-xs' id="echeance_debut" name="echeance_debut"
                    format={DateFormat} value={filterFormValues.echeance_debut || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs' titre="au :">
                  <BasicDateInput isbig={true} id="echeance_fin" name="echeance_fin"
                    format={DateFormat} value={filterFormValues.echeance_fin || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>

              </div>

              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Utilisateur :">
                  <BasicSelect id="id_utilisateur" name="id_utilisateur"
                    className="text-xs"
                    listItems={data_utilisateur || []} value={filterFormValues.id_utilisateur || ''}
                    textKey={'username'}
                    fieldsConfig={[{ key: 'username', title: 'Utilisateur' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>
              <div className=''>
                <BasicLabel className='text-xs w-[150px]' titre="Depot :">
                  <BasicSelect id="id_etablissement" name="id_etablissement"
                    className="text-xs" listItems={data_etablissement || []}
                    value={filterFormValues.id_etablissement || ''}
                    textKey={'libelle'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>

            </div>

            <div className="grid grid-cols-7 gap-3 mt-2">
              <div className='col-span-4'></div>
              <BasicButton type="button" onClick={handleOkFilter}>Ok</BasicButton>
              <BasicButton type="button" onClick={handleFermerFilter}>Fermer</BasicButton>
              <BasicButton type="button" onClick={handleTousFilter}>Tous</BasicButton>
            </div>
          </form>
        </section>
      </BasicModal>
    </>

  )
}

/*
id
id_article
code_article
nbp
qte
prix
code_tiers
uncde



*/

const columns_lignesmvtList = [
  {
    field: 'id', headerName: 'id', filterable: false, sortable: false,
  },
  { field: 'id_article', headerName: 'Id article', sortable: false },
  {
    field: 'code_article', headerName: 'Code article', width: 200, sortable: false,
  },
  {

    field: 'nbp', headerName: 'NBP', width: 50, sortable: false,
    type: 'number',
  },
  {
    field: 'qte', headerName: 'qte', width: 100, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 5),
  },
  {
    field: 'pu', headerName: 'Prix TTC', width: 200, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value),
  },
  {
    field: 'code_tiers', headerName: 'Code tiers', width: 200, sortable: false,
  },
  {
    field: 'uncde', headerName: 'Uncde', width: 100, sortable: false,
  },

];

const hideColumns_lignesmvtList = {
  id: false,
  id_article: false,
  defaultqte: false,
  qes: false,
  pp: false,
  montant_ht: false,
  pa: false,
  rms: false,
  numstk: false,
  saisie_le: false,
};




const MvtsList = forwardRef((props, ref) => {
  //function MvtsList({ removeWidget, isModal }) {
  const { handleGetid_mvt, isModal, removeWidget, type_mvt, id_etablissement, autoOpen, livraisonType, ...others } = props;
  const queryClient = useQueryClient();
  const [openMvtList, setOpenMvtList] = useState(false);
  const [openMvt, setOpenMvt] = useState(false);
  const [openTransformerEnFacture, setOpenTransformerEnFacture] = useState(false);
  const [openAutoCreateFacture, setOpenAutoCreateFacture] = useState(false);
  const [transformerEnFactureValues, setTransformerEnFactureValues] = useState({
    list_mvt: [],
    max_price_pr_facture: 5000, id_reglement: 2, date_facture: formatDate(dayjs(), true),
    diff_jours: 0, type_mvt: '', data_parametres: []
  });
  const { loginDetails, loginPermission } = useLoginStore();

  const [lignesmvtList, setLignesmvtList] = useState([]);


  const [actionNameMvt, setActionNameMvt] = useState("")
  const [selectMvtsList, setSelectionMvtsList] = useState([]);

  const { data: data_mode_reglement = [],
    isLoading: isLoading_mode_reglement } = GetMode_ReglementsListQuery(true, 'libelle');

  const {
    data: data_parametres = [],
    isLoading: isLoading_parametres, isFetching: isFetching_parametres, refetch: refetch_parametres } = GetParametreQuery(1, true);

  const getSelecteditemByID = (id) => {
    return data_mvtslist.filter(item => item.id == id)[0];
  }


  const handleRowSelectionMvtsList = (e) => {
    cleanGetLignesMvtsListQuery(queryClient);
    if (e != selectMvtsList[0]) {
      setSelectionMvtsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenMvt = () => {
    setOpenMvt(true);

    //BasicModalRef.current.open();
  }
  const handleCloseMvt = () => {
    setOpenMvt(false);
    //BasicModalRef.current.close();
    cleanGetMvtEmptyQuery(queryClient);
    cleanGetMvtQuery(selectMvtsList[0], queryClient);
    cleanGetLignesMvtsListQuery(queryClient);
  }

  customConsoleLog('MvtList props', props);



  const [status_mvt, execute_mvt, resolve_mvt, reject_mvt, reset_mvt] = useAwaitableComponent();
  const [asyncConfirmDialogInfoMvt, setConfirmDialogInfoMvt] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogMvtsList = status_mvt === "awaiting";

  const handleAwaitModalMvtsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoMvt({ titre: mtitre, msg: mmsg });
      await execute_mvt();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_mvt();
    }
  };


  customConsoleLog('MvtsList rendered')
  customConsoleLog('type_mvt', type_mvt)
  customConsoleLog('id_etablissement', id_etablissement)
  customConsoleLog('removeWidget', removeWidget)



  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(true);
  }

  const handleCloseFilter = () => {
    setOpenFilter(false);
  }

  const defaultfilterFormValues = {
    type_mvt: type_mvt,
    id_etablissement: id_etablissement,
    date_debut: formatDate(dayjs(), true),
    date_fin: formatDate(dayjs(), true),
  }


  const [filterFormValues, setFilterFormValues] = useState(defaultfilterFormValues);



  const { data: data_mvtslist = [], error: error_mvtslist,
    refetch: refecth_mvtslist, isLoading: isLoading_mvtslist, failureCount: failureCount_mvtslist } =
    GetMvtsListQuery(filterFormValues);


  const handleRefetchMvtsList = () => {
    customConsoleLog('handleRefetchMvtsList called');
    cleanGetMvtsListQuery(queryClient);
  }





  const handleNouveauMvtsList = () => {

    clearToasts();
    setActionNameMvt(btnActionsName.nouveau);
    setSelectionMvtsList([])
    handleOpenMvt();

  }

  const handleConsulterMvtsList = () => {
    if (selectMvtsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMvt(btnActionsName.consulter);

    handleOpenMvt();
    customConsoleLog(selectMvtsList[0]);
  }

  const handleDupliquerMvtsList = () => {
    if (selectMvtsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMvt(btnActionsName.dupliquer);
    handleOpenMvt();
    customConsoleLog(selectMvtsList[0]);
  }

  const handleModifierMvtsList = () => {
    if (selectMvtsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    customConsoleLog('loginDetails', loginDetails.admin)
    if ((type_mvt == typeMvt.factureClient || type_mvt == typeMvt.factureFournisseur) && !loginDetails.admin) {
      showToastError('Facture ne peut pas etre modifier', 5000, 'error');
      return;
    }
    setActionNameMvt(btnActionsName.modifier);
    handleOpenMvt();
    customConsoleLog(selectMvtsList[0]);
  }


  const handleSupprimerMvtsList = async () => {
    if (selectMvtsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    customConsoleLog('loginDetails', loginDetails.admin)
    if ((type_mvt == typeMvt.factureClient || type_mvt == typeMvt.factureFournisseur) && !loginDetails.admin) {
      showToastError('Facture ne peut pas etre supprimer', 5000, 'error');
      return;
    }

    const item = getSelecteditemByID(selectMvtsList[0])
    if (await handleAwaitModalMvtsList('Supprimer!!', `Supprimer [${item.ref_mvt} - ${item.tiers_nom} ] ?!`)) {

      setActionNameMvt(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteMvtLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenMvtsList = (itemID) => {
    setOpenMvtList(true);


    if (itemID) {
      handleRowSelectionMvtsList([itemID]);
    }
  }


  const handleFermerMvtsList = () => {
    if (selectMvtsList[0]) {
      if (handleGetid_mvt) {

        handleGetid_mvt(selectMvtsList[0]);
      }
    }
    cleanGetMvtsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetMvtsListQuery(queryClient);
      cleanGetMvtEmptyQuery(queryClient);
      cleanGetMvtQuery(selectMvtsList[0], queryClient);
      setOpenMvtList(false);
    }
    else {

      setOpenMvtList(false);
    }
  }







  const addMvtLine = useAddMvtLine(queryClient, handleCloseMvt, handleRowSelectionMvtsList);


  const updateMvtLine = useUpdateMvtLine(queryClient, handleCloseMvt, handleRowSelectionMvtsList);

  const deleteMvtLine = useDeleteMvtLine(queryClient, handleCloseMvt);






  const DataTableMvtsListRef = React.useRef(null);

  const handlePrintMvtsList = () => {
    // if (DataTableMvtsListRef.current) {
    //   DataTableMvtsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    // }

    customConsoleLog('handlePrintMvtsList called');
    customConsoleLog('selectMvtsList', selectMvtsList);
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenMvtsList(itemID);
    },
    handleClose() {
      handleFermerMvtsList();
    }
  }));

  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    //setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const checkMvtTransformConditions = (values, type_mvt_page) => {
    const { list_mvt: list_mvt_tmp, type_mvt: type_mvt_tmp } = values;
    console.log('TransformerEnFacture checkMvtTransformConditions', list_mvt_tmp, type_mvt_tmp);
    if(list_mvt_tmp?.length <= 0){
      return false;
    }

    const firstEtat = getSelecteditemByID(list_mvt_tmp[0]).etat;
    for (let i = 0; i < list_mvt_tmp.length; i++) {
      const item = getSelecteditemByID(list_mvt_tmp[i]);
      if (item.etat !== firstEtat) {
        showToastError(`Les Etats des Movement sont differentes!!`, 5000, 'error');
        return false;
      }

    }

    return true;
  }

  const TransformerEnFactureMenu = () => {
    customConsoleLog('TransformerEnFacture called');
    customConsoleLog('selectMvtsList', selectMvtsList);
    cleanGetParametreQuery(1, queryClient);
    // check if all selected mvts tiers are the same
    // if not show error message
    if (selectMvtsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }

    const tiers = getSelecteditemByID(selectMvtsList[0]).tiers_code;
    for (let i = 1; i < selectMvtsList.length; i++) {
      if (getSelecteditemByID(selectMvtsList[i])?.tiers_code !== tiers) {
        showToastError('Les tiers selectionnés ne sont pas les mêmes', 5000, 'error');
        return;
      }
    }


    setTransformerEnFactureValues(prevState => ({ ...prevState, list_mvt: selectMvtsList }));

    if (!checkMvtTransformConditions(transformerEnFactureValues, type_mvt)) {
      customConsoleLog('TransformerEnFacture !checkMvtTransformConditions');
      return;
    }
    setOpenTransformerEnFacture(true);

    handleClose();
  };

  const ValiderMvtMenu = () => {

    handleClose();
  };

  const handleTransformerEnFactureChange = (e) => {
    const { name, value, type, checked } = e.target;
    customConsoleLog('handleTransformerEnFactureChange', name, value);
    //transformerEnFactureValues[name] = type === 'checkbox' ? checked : value;
    setTransformerEnFactureValues(prevState => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  }


  const handleOkTransformerEnFacture = () => {
    customConsoleLog('handleOkTransformerEnFacture called');
    //setOpenTransformerEnFacture(false);
    customConsoleLog('transformerEnFactureValues', transformerEnFactureValues);
    if (transformerEnFactureValues.list_mvt.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    if (!transformerEnFactureValues.type_mvt) {
      showToastError('Type du Movement invalid', 5000, 'error');
      return;
    }



    setOpenAutoCreateFacture(true);

    //autoCreateFacture(transformerEnFactureValues);

    // ReactDOM.createPortal(
    //   <AutoCreateFacture
    //   open={true}
    //   transformerEnFactureValues={transformerEnFactureValues} />
    //   , document.body);

  }

  const handleAnnulerTransformerEnFacture = () => {
    customConsoleLog('handleAnnulerTransformerEnFacture called');
    setOpenTransformerEnFacture(false);
  }

  const typeMvtList = Object.keys(typeMvt).map((key) => {
    return { id: typeMvt[key], code: typeMvt[key], libelle: key }
  });


  React.useEffect(() => {
    if (!isLoading_parametres && data_parametres) {
      setTransformerEnFactureValues(prevState => ({ ...prevState, data_parametres: data_parametres }));
    }
  }, [isLoading_parametres]);

  // if (isLoading_mvtslist || error_mvtslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerMvtsList}
  //         refetch={refecth_mvtslist}
  //         failureCount={failureCount_mvtslist}
  //         error={error_mvtslist} />

  //     </>
  //   )
  // }



  React.useEffect(() => {
    customConsoleLog('MvtsList useEffect isLoading_mvtslist', isLoading_mvtslist);
    customConsoleLog('MvtsList useEffect autoOpen', autoOpen);
    if (!isLoading_mvtslist && autoOpen) {
      handleNouveauMvtsList();
    }
  }, [isLoading_mvtslist]);




  return (
    <>
      {
        openTransformerEnFacture &&
        <>
          <BasicModal open={openTransformerEnFacture} handleClose={() => setOpenTransformerEnFacture(false)} isModal={true}>
            <div className="m-2 w-[500px]">
              <h2 className="text-lg font-bold mb-4">Transformer Movement</h2>
              <div className="grid grid-rows-3 gap-2">
                <BasicLabel className='w-[250px]' titre="Refs selectionner :">
                  <span>{selectMvtsList.map((id) => {
                    const item = getSelecteditemByID(id);
                    return item.ref_mvt + ' ';
                  })}</span>
                </BasicLabel>
                <input type="hidden" id="loginDetails" name="loginDetails" onChange={handleTransformerEnFactureChange} value={loginDetails || ''} />
                <input type="hidden" id="data_parametres" name="data_parametres" onChange={handleAnnulerTransformerEnFacture} value={transformerEnFactureValues.data_parametres || ''} />
                <BasicLabel className='w-[250px]' titre="Type Movement :">
                  <BasicSelect id="type_mvt" name="type_mvt" className='text-s'
                    listItems={typeMvtList || []} value={transformerEnFactureValues.type_mvt || ''}
                    textKey={'libelle'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                    onChange={handleTransformerEnFactureChange}
                    isNotClearable={false}
                    isDisabled={false}
                    required={true}
                  />
                </BasicLabel>
                <BasicLabel className='w-[250px]' titre="Date Movement :">
                  <BasicDateInput id="date_facture" name="date_facture"
                    isbig={true}
                    format={DateFormat} value={transformerEnFactureValues.date_facture || ''}
                    onChange={handleTransformerEnFactureChange} />
                </BasicLabel>
                <BasicLabel className='w-[250px]' titre="Difference Jours :">
                  <BasicNumberInput id="diff_jours" name="diff_jours"
                    className='text-s'
                    value={transformerEnFactureValues.diff_jours || ''}
                    onChange={handleTransformerEnFactureChange} />
                </BasicLabel>
                <BasicLabel className='w-[250px]' titre="Type Reglement :">
                  <BasicSelect id="id_reglement" name="id_reglement" className='text-s'
                    listItems={data_mode_reglement || []} value={transformerEnFactureValues.id_reglement || ''}
                    textKey={'code'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                    onChange={handleTransformerEnFactureChange}
                    isNotClearable={false}
                    isDisabled={false}
                  />
                </BasicLabel>
                <BasicLabel className='w-[250px]' titre="Max Prix par Movement :">
                  <BasicDecimalInput id="max_price_pr_facture" name="max_price_pr_facture"
                    className='text-s'
                    value={transformerEnFactureValues.max_price_pr_facture || ''}
                    onChange={handleTransformerEnFactureChange} />
                </BasicLabel>
              </div>
              {
                openAutoCreateFacture &&
                <AutoCreateFacture
                  open={openAutoCreateFacture}
                  setOpen={setOpenAutoCreateFacture}
                  setOpenParent={setOpenTransformerEnFacture}
                  loginDetails={loginDetails}
                  transformerEnFactureValues={transformerEnFactureValues} />
              }
              <div className="grid grid-cols-2 gap-2 mt-4">
                <BasicButton onClick={handleOkTransformerEnFacture} id="ok" name="ok">Ok</BasicButton>
                <BasicButton onClick={handleAnnulerTransformerEnFacture} id="annuler" name="annuler">Annuler</BasicButton>
              </div>
            </div>
          </BasicModal>
        </>
      }

      <BasicModal open={openMvtList} handleClose={handleFermerMvtsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_mvtslist || error_mvtslist
            ? <BasicLoading
              handleClose={handleFermerMvtsList}
              refetch={refecth_mvtslist}
              failureCount={failureCount_mvtslist}
              error={error_mvtslist} />
            : <>
              {
                showAsyncConfirmDialogMvtsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogMvtsList}
                  onSubmit={resolve_mvt}
                  onCancel={reject_mvt}
                  info={asyncConfirmDialogInfoMvt}
                />
              }

              {
                openFilter &&
                <>
                  <BasicFitlerModal openFilter={openFilter} handleClose={handleCloseFilter}
                    defaultValues={defaultfilterFormValues}
                    values={filterFormValues} setValues={setFilterFormValues}
                    handleRefetch={handleRefetchMvtsList}
                    type_mvt={type_mvt} />
                </>
              }


              {
                openMvt &&
                <BasicModal open={openMvt} isModal={true}>
                  <Mvt
                    actionName={actionNameMvt}
                    selectedItem={getSelecteditemByID(selectMvtsList[0])}
                    handleClose={handleCloseMvt}
                    addMvtLine={addMvtLine}
                    updateMvtLine={updateMvtLine}
                    type_mvt={type_mvt}
                    id_etablissement={id_etablissement}
                    // uniqueMvtKey={crypto.randomUUID()}
                    uniqueMvtKey={uuidv4()}
                    livraisonType={livraisonType}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Mvts</h2>}

              <RedDataTableBasic
                ref={DataTableMvtsListRef}
                rows={data_mvtslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionMvtsList}
                select={selectMvtsList}
                onRowDoubleClick={handleConsulterMvtsList}
                hideFooter={true}
                height={selectMvtsList.length > 0 && selectMvtsList[0] ? '350px' : null}
                disableMultipleRowSelection={false}
                checkboxSelection={true}
                handleContextMenu={handleContextMenu}
              />
              {
                selectMvtsList.length > 0 && selectMvtsList[0]
                  ? <div className="mt-2">
                    <div className='w-[80%] mx-auto'>
                      <LignesMvt
                        id_mvt={selectMvtsList[0]}
                        lignesmvtList={lignesmvtList}
                        setLignesmvtList={setLignesmvtList}
                        columns={columns_lignesmvtList}
                        columnVisibilityModel={hideColumns_lignesmvtList}
                        isHt={true}
                        canDelete={true}
                        isReadOnly={true}
                        height={'100px'}
                      />
                    </div>
                  </div>
                  : <></>

              }

              <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
                slotProps={{
                  root: {
                    onContextMenu: (event) => {
                      event.preventDefault();
                      handleClose();
                    },
                  },
                }}
              >
                <MenuItem onClick={TransformerEnFactureMenu}>Transformer</MenuItem>
                <MenuItem onClick={ValiderMvtMenu}>Valider</MenuItem>
              </Menu>

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleOpenFilter} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauMvtsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterMvtsList} id="consulter" name="consulter">Consulter</BasicButton>
                {/* <BasicButton onClick={handleDupliquerMvtsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton> */}
                <div></div>
                <div></div>
                <BasicButton onClick={handleModifierMvtsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintMvtsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerMvtsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerMvtsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default MvtsList



const MvtsListwidgetKey = 'Mvtswidget'
export const MvtsListwidget = {
  i: MvtsListwidgetKey, x: 0, y: 0, w: 11, h: 15, component: MvtsList, titre: 'Mvts',
  id_fenetre_reception: 509,
  id_fenetre_livraison: 510,
  id_fenetre_livraisonC: 532,
  id_fenetre_bonClient: 533,
  id_fenetre_stockSF: 534,
  id_fenetre_encoursPF: 535,
  id_fenetre_entreesPF: 536,
  id_fenetre_transfertDepot: 514,
  id_fenetre_factureClt: 516
}

/*
id_fenetre_reception: 509,
id_fenetre_livraison: 510,
id_fenetre_livraisonC: 1003,
id_fenetre_bonClient: 1004,
id_fenetre_stockSF: 1005,
id_fenetre_encoursPF: 1006,
id_fenetre_entreesPF: 1007,
id_fenetre_transfertDepot: 514,
id_fenetre_factureClt: 516

*/
