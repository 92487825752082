import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProblemDeConnectionMSG } from './GlobalText';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { typeMvt, typeTiers, imprimerFormatType, isProgDebug, accesTypes, livraisonsType } from './GlobalVars';
import { Switch } from '@mui/base';
//import customParseFormat from 'dayjs/plugin/customParseFormat'

import { useQueryClient } from '@tanstack/react-query';
import FrenchNumbersToWords from 'french-numbers-to-words';

export const useGetFetchQuery = (name) => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(name);
};

export const getTextFromNumber = (num) => {
  const tmp = new FrenchNumbersToWords(num || 0, 'fr').result;
  if (tmp) {
    return tmp.fullText;
  }
  return '';
}



// devisClient: 'DC',
// commandeClient: 'CC',
// bonLivraisonClient: 'LC',
// retourClient: 'RC',
// avoirClient: 'AC',
// bonClient: 'PC',
// factureClient: 'FC',
// devisFournisseur: 'DF',
// commandeFournisseur: 'CF',
// factureFournisseur: 'FF',
// bonLivraisonFournisseur: 'LF',
// retourFournisseur: 'RF',
// avoirFournisseur: 'AF',
// stockSF: 'SF',
// transfertEntreDepot: 'DD',

export const revisedRandId = () => {
  return Math.random().toString(36).replace(/[^a-z]+/g, '').slice(2, 10);
}

/*
 calcutate QES from qte based on type_mvt

CC = qte * 0 commandeClient
DC = qte * 0 devisClient
DD = qte * -1 transfertEntreDepot
FC = qte * -1 factureClient
PC = qte * -1 bonClient
LC = qte * -1 bonLivraisonClient
LF = qte * 1 bonLivraisonFournisseur
FF = qte * 1 factureFournisseur

autre = qte * 1

*/

export const calculateQES = (type_mvt, qte) => {
  switch (type_mvt) {
    case typeMvt.devisClient:
    case typeMvt.commandeClient:
    case typeMvt.factureClient:
    case typeMvt.devisFournisseur:
    case typeMvt.commandeFournisseur:
    case typeMvt.factureFournisseur:
    case typeMvt.encoursPF:
      return qte * 0;
    case typeMvt.bonLivraisonClient:
    case typeMvt.bonClient:
      return qte * -1;
    case typeMvt.bonLivraisonFournisseur:
    case typeMvt.entreesPF:
      return qte * 1;
    case typeMvt.transfertEntreDepot:
      return qte * 0; //qte * -1;
    default:
      return qte * 1;
  }
}


export const getImprimerFormat = (type_mvt) => {
  switch (type_mvt) {
    case typeMvt.devisClient:
      return imprimerFormatType.dev;
    case typeMvt.commandeClient:
      return imprimerFormatType.cde;
    case typeMvt.bonLivraisonClient:
      return imprimerFormatType.bl;
    case typeMvt.retourClient:
    case typeMvt.avoirClient:
    case typeMvt.bonClient:
      return imprimerFormatType.bon;
    case typeMvt.factureClient:
      return imprimerFormatType.fac;
    case typeMvt.devisFournisseur:
      return imprimerFormatType.dev;
    case typeMvt.commandeFournisseur:
      return imprimerFormatType.cde;
    case typeMvt.factureFournisseur:
      return imprimerFormatType.fac;
    case typeMvt.bonLivraisonFournisseur:
      return imprimerFormatType.bl;
    case typeMvt.retourFournisseur:
    case typeMvt.avoirFournisseur:
      return imprimerFormatType.bon;
    default:
      return imprimerFormatType.bl;
  }
}



export const ClientOrFournisseur = (type_mvt) => {
  switch (type_mvt) {
    case typeMvt.devisClient:
    case typeMvt.commandeClient:
    case typeMvt.bonLivraisonClient:
    case typeMvt.retourClient:
    case typeMvt.avoirClient:
    case typeMvt.bonClient:
    case typeMvt.factureClient:
      return typeTiers.client;
      break;
    case typeMvt.devisFournisseur:
    case typeMvt.commandeFournisseur:
    case typeMvt.factureFournisseur:
    case typeMvt.bonLivraisonFournisseur:
    case typeMvt.retourFournisseur:
    case typeMvt.avoirFournisseur:
    case typeMvt.stockSF:
    case typeMvt.encoursPF:
    case typeMvt.entreesPF:
      return typeTiers.fournisseur;
      break;
    default:
      return typeTiers.client;
      break;
  }
}


export const getPrixFromLivraisonType = (article, isCliOrFr, tmp_livraisonType) => {
  // prix 1 = prix de vente par detail a credit
  // prix 2 = '''           par detail a comptant
  // prix 3 =   par detail fardeau comptant
  // prix 4 =   
  // prix 5 = par fardeau a credit
  if (isCliOrFr == typeTiers.client) {
    switch (tmp_livraisonType) {
      case livraisonsType.livraisons:
        return article.pp;
      case livraisonsType.livraisonsC:
        return article.pp2;
      case livraisonsType.livraisonsCr:
        return article.pp3;
      default:
        return article.pp;
    }
  }
  else {
    return article.pa;
  }

}


export const getPrixArticle = (article, isCliOrFr, cat = 1) => {
  try {
    if (isCliOrFr == typeTiers.client) {
      switch (Number(cat)) {
        case 1:
          return article.pp;
        case 2:
          return article.pp2;
        case 3:
          return article.pp3;
        case 4:
          return article.pp4;
        case 5:
          return article.pp5;
        default:
          return article.pp;
      }
    }
    else {
      return article.pa;
    }

  }
  catch (e) {
    customConsoleLog(e);
    return 0;
  }

}



/*

num_pro_cli = models.IntegerField(db_column='Num pro cli', blank=True, null=True)  
    num_dev_cli = models.IntegerField(db_column='Num dev cli', blank=True, null=True)  
    num_bc_cli = models.IntegerField(db_column='Num BC cli', blank=True, null=True)  
    num_bl_cli = models.IntegerField(db_column='Num BL cli', blank=True, null=True)  
    num_fac_cli = models.IntegerField(db_column='Num fac cli', blank=True, null=True)  
    num_br_cli = models.IntegerField(db_column='Num BR cli', blank=True, null=True)  
    num_be_cli = models.IntegerField(db_column='Num BE cli', blank=True, null=True)  
    num_bc_frn = models.IntegerField(db_column='Num BC frn', blank=True, null=True)  
    num_br_frn = models.IntegerField(db_column='Num BR frn', blank=True, null=True)  
    num_dp_frn = models.IntegerField(db_column='Num DP frn', blank=True, null=True)  
    num_lp_frn = models.IntegerField(db_column='Num LP frn', blank=True, null=True)  
    num_dd = models.IntegerField(db_column='Num DD', blank=True, null=True)  
    num_av_cli = models.IntegerField(db_column='Num av cli', blank=True, null=True)  
    num_av_frn = models.IntegerField(db_column='Num av frn', blank=True, null=True) 

*/

export const getParametreMvtNumbreColumn = (parametre, type_mvt) => {
  if (!parametre) return 0;
  customConsoleLog('getParametreMvtNumbreColumn type_mvt', type_mvt);
  switch (type_mvt) {
    case typeMvt.devisClient:
      return parametre.num_dev_cli;
    case typeMvt.commandeClient:
      return parametre.num_bc_cli;
    case typeMvt.bonLivraisonClient:
      return parametre.num_bl_cli;
    case typeMvt.factureClient:
      return parametre.num_fac_cli;
    case typeMvt.bonClient:
      return parametre.num_br_cli;
    case typeMvt.avoirClient:
      return parametre.num_av_cli;
    // case typeMvt.devisFournisseur:
    //   return parametre.num_dev_frn;
    // case typeMvt.commandeFournisseur:
    //   return parametre.num_bc_frn;
    // case typeMvt.bonLivraisonFournisseur:
    //   return parametre.num_bl_frn;
    // case typeMvt.factureFournisseur:
    //   return parametre.num_fac_frn;
    // case typeMvt.bonFournisseur:
    //   return parametre.num_br_frn;
    // case typeMvt.avoirFournisseur:
    //   return parametre.num_av_frn;
    // case typeMvt.stockSF:
    //return parametre.num_sf;
    case typeMvt.transfertEntreDepot:
      return parametre.num_dd;
    default:
      return 0;
  }



}

export const getTitreMvt = (type_mvt) => {
  switch (type_mvt) {
    case typeMvt.devisClient:
      return 'Devis Client';
    case typeMvt.commandeClient:
      return 'Commande Client';
    case typeMvt.bonLivraisonClient:
      return 'Bon Livraison Client';
    case typeMvt.retourClient:
      return 'Retour Client';
    case typeMvt.avoirClient:
      return 'Avoir Client';
    case typeMvt.bonClient:
      return 'Bon Client';
    case typeMvt.factureClient:
      return 'Facture Client';
    case typeMvt.devisFournisseur:
      return 'Devis Fournisseur';
    case typeMvt.commandeFournisseur:
      return 'Commande Fournisseur';
    case typeMvt.factureFournisseur:
      return 'Facture Fournisseur';
    case typeMvt.bonLivraisonFournisseur:
      return 'Reception Fournisseur';
    case typeMvt.retourFournisseur:
      return 'Retour Fournisseur';
    case typeMvt.avoirFournisseur:
      return 'Avoir Fournisseur';
    case typeMvt.stockSF:
      return 'Stock SF';
    case typeMvt.transfertEntreDepot:
      return 'Transfert Entre Depot';
    case typeMvt.encoursPF:
      return 'Encours P.F.';
    case typeMvt.entreesPF:
      return 'Entrees P.F.';
    default:
      return '';
  }
}


export const calculateReduction = (ppttc, pu) => {
  return Number(((ppttc - pu) / ppttc) * 100) || 0;
}


export const calculateTTC = (ht, tva = 20) => {
  const tva_val = Number(tva);
  const val = formatDecimalNumber((tva_val / 100) + 1);
  const ttc = formatDecimalNumber(ht * val);
  return ttc || 0;
}


export const calculateHT = (ttc, tva = 20) => {
  const tva_val = Number(tva);
  const val = formatDecimalNumber((tva_val / 100) + 1);
  const ht = formatDecimalNumber(ttc / val);

  customConsoleLog(`ttc: ${ttc} tva: ${tva} ht: ${ht}`);

  return ht || 0;
}

// return true if the string is null or empty
export const strIsNullOrEmpty = (str) => {
  return !str || str === '';
}


const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const getOnlyUpdatedValues = (initialData, formValues) => {
  const updatedValues = {};
  for (const key in formValues) {
    if (key === 'id') {
      updatedValues[key] = formValues[key];
    }
    else if (!isEqual(initialData[key], formValues[key])) {
      updatedValues[key] = formValues[key];
    }
  }

  return updatedValues;
};

// export function getOnlyUpdatedArrayValues(initialData, newData) {
//   const tmp = [];
//   newData.forEach((row, index) => {
//     const keys = Object.keys(row);
//     let tmprow = {};
//     keys.forEach(key => {
//       if (initialData[index][key] != row[key]) {
//         tmprow[key] = row[key];
//       }
//     });
//     if(tmprow && Object.keys(tmprow).length > 0){
//       tmprow['id'] = row['id'];
//       customConsoleLog('getOnlyUpdatedArrayValues tmprow', tmprow);
//       tmp.push(tmprow);
//     }
//   });
//   return tmp;
// }

export function getOnlyUpdatedArrayValues(initialData, newData) {
  const tmp = [];

  for (let i = 0; i < newData.length; i++) {
    const keys = Object.keys(newData[i]);
    let tmprow = {};
    keys.forEach(key => {
      if (initialData[i][key] != newData[i][key]) {
        tmprow[key] = newData[i][key];
      }
    });
    if (tmprow && Object.keys(tmprow).length > 0) {
      tmprow['id'] = newData[i]['id'];
      customConsoleLog('getOnlyUpdatedArrayValues tmprow', tmprow);
      tmp.push(tmprow);
    }

  }
  return tmp;
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatRegType = (value) => {
  //normally we should get the value from the backend ModesReglement table
  if (typeof value === 'number' && value !== 0) {
    return '';
  }

  switch (value) {
    case 1:
      return 'CHQ';
    case 2:
      return 'ESP';
    case 3:
      return 'CRE';
    case 4:
      return 'VIR';
    case 5:
      return 'EFF';
    default:
      return '';
  }

}

export const ConvertToInt = (value) => {
  // If the value is null or undefined, return 0
  if (value === null || value === undefined) {
    return 0;
  }

  let strValue = String(value).trim();

  let intValue = parseInt(strValue, 10);

  if (isNaN(intValue)) {
    return 0;
  }

  return intValue;

}

export const formatIntTostring = (value, str) => {
  // check if it's a number and it's not zero
  if (typeof value === 'number' && value !== 0) {
    return str;
  }
  return '';
}

export const formatBoolToStar = (value) => {
  if (value) {
    return '*';
  }
  return '';
}

export const formatDecimalNumber = (value, decimalNumbers = 2) => {
  // Ensure valid number and handle potential edge cases
  if (typeof value !== 'number' || isNaN(value)) {
    const mval = Number('0');
    return mval.toFixed(Math.max(decimalNumbers, 0));
  }
  const val = Number(value);

  return val.toFixed(Math.max(decimalNumbers, 0)); // Handle negative values gracefully
};

// the format i get form the backend is 2021-09-01T00:00:00Z
export const DateFormatDb = 'YYYY-MM-DDT00:00:00+00:00';
export const DateTimeFormatDb = 'YYYY-MM-DDThh:mm:ss+00:00';



// export const DateFormatDb = 'DD-MM-YYYY';
// export const DateTimeFormatDb = 'DD-MM-YYYY hh:mm:ss';

export const DateFormat = 'DD-MM-YYYY';
export const DateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
var customParseFormat = require('dayjs/plugin/customParseFormat')


// export const formatTodayDate = (forDb = false) => {
//   dayjs.extend(customParseFormat);
//   if (!forDb) {
//     return dayjs().format(DateFormat);
//   }
//   else{
//     return dayjs().format(DateFormatDb);
//   }
// }

export const formatDate = (value, forDb = false, showOnly = false) => {
  dayjs.extend(customParseFormat);

  if (value && showOnly) {
    return dayjs(value, DateFormatDb).format(DateFormat).toString();
  }
  else if (value && !forDb) {
    const date = dayjs(value, DateFormatDb);
    return date;
  }
  else if (value && forDb) {
    const date = dayjs(value, DateFormat).format(DateFormatDb).toString();

    return date;
  }

  return '';
}
export const formatDateTime = (value, forDb = false, showOnly = false) => {
  dayjs.extend(customParseFormat);

  if (value && showOnly) {
    return dayjs(value, DateTimeFormatDb).format(DateTimeFormat).toString();
  }
  else if (value && !forDb) {
    const date = dayjs(value, DateTimeFormatDb);
    return date;
  }
  else if (value && forDb) {
    const date = dayjs(value, DateTimeFormat).format(DateTimeFormatDb).toString();

    return date;
  }

  return '';
}


export function checkString(str) {
  const strcheck = str ? str : '';
  return strcheck;
}

export function showToast(msg, timerMs, toastType = '') {
  if (toastType == 'succes') {
    toast.success(msg, { autoClose: timerMs, containerId: 'All' });
  }
  else {
    toast(msg, { autoClose: timerMs, containerId: 'All' });
  }
}

export function errorStr(error) {

  customConsoleLog(error);


  if (error?.detail) {


    // try {
    //   const mymsg = JSON.parse(error.message);
    //   return `${JSON.stringify(mymsg.detail)}`;
    // }
    // catch (e) {

    // }

    try {
      return `${JSON.stringify(error.detail)}`
    }
    catch (e) {

    }
  }
  else {
    return ProblemDeConnectionMSG.localerreur.info[0];

  }



}

export function showToastError(error, timerMs, toastType = 'error') {
  customConsoleLog('showToastError');

  toast.error(error, { autoClose: timerMs, containerId: 'All' });

}


export function MsgFormated({ title, msg }) {
  // split the message by the new line
  const msgArray = msg.split('\n');
  return (
    <>
      <div>{title}</div>
      <div>Raison :</div>
      <div>
        {msgArray.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </>
  );
}


export function showToastErrorStill(error, msg, toastType = 'error') {
  customConsoleLog('showToastError');

  toast.error(<MsgFormated title={error} msg={msg} />, { autoClose: false, containerId: 'All' });

}

export function showToastAccesError(timerMs, toastType = 'error', depot = null) {
  customConsoleLog('showToastAccesError');
  let error = `Acces non autorisé`;

  if (depot) {
    error = `Acces au depot '${depot}' non autorisé`;
  }

  toast.error(error, { autoClose: timerMs, containerId: 'All' });

}

export function clearToasts() {
  try {
    if (toast.length > 0) {

      toast.clearWaitingQueue();
      toast.dismiss({ containerId: "All" });
    }
  } catch (error) {
    customConsoleLog(error);
  }
}

export function validateValues(txt, validValues = [''], maxLength = 0) {
  //const validValues = ['', '0', '1', '2'];
  if (maxLength != 0 && txt.length > maxLength) {
    return false;
  }
  return validValues.includes(txt);
}

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function numToWords(value = 0, devise = "Dirham", cents = "Centime") {
  const units = ["", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
  const teens = ["dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
  const tens = ["", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"];
  const thousands = ["", "mille", "million", "milliard"];

  function intToWords(n) {
    if (n === 0) {
      return "zéro";
    } else if (n < 10) {
      return units[n];
    } else if (n < 20) {
      return teens[n - 10];
    } else if (n < 100) {
      let result = tens[Math.floor(n / 10)];
      if (n % 10 !== 0) {
        result += "-" + units[n % 10];
      }
      return result;
    } else if (n < 1000) {
      let result;
      if (Math.floor(n / 100) === 1) {
        result = "cent";
      } else {
        result = units[Math.floor(n / 100)] + " cent";
      }
      if (n % 100 !== 0) {
        result += " " + intToWords(n % 100);
      }
      return result;
    } else {
      for (let i = 0; i < thousands.length; i++) {
        let unitValue = Math.pow(1000, i);
        if (n < unitValue * 1000) {
          let result = intToWords(Math.floor(n / unitValue)) + " " + thousands[i];
          if (n % unitValue !== 0) {
            result += " " + intToWords(n % unitValue);
          }
          return result;
        }
      }
    }
    return "";
  }

  let integerPart = Math.floor(value);
  let decimalPart = Math.round((value - integerPart) * 100);

  let integerWords = intToWords(integerPart);
  let decimalWords = intToWords(decimalPart);

  let result;
  if (integerPart === 1000) {
    result = "mille " + devise;
  } else if (integerPart === 0) {
    result = "zéro " + devise;
  } else {
    result = integerWords + " " + devise + (integerPart > 1 ? "s" : "");
  }

  if (decimalPart > 0) {
    result += " et " + decimalWords + " " + (decimalPart > 1 ? cents + "s" : cents);
  }

  return result += ".";
}


export function customConsoleLog(...params) {
  // for (let i = 0; i < params.length; i++) {
  //   if (typeof params[i] === 'object') {
  //     console.log(JSON.stringify(params[i]));
  //   }
  //   else {
  //     console.log(params[i]);
  //   }
  // }
  //if (process.env.NODE_ENV !== 'production') {
    if (isProgDebug) {
      console.log(...params);
    }
  //}
}


export function checkPermission(permissions, action, iddepots = 0, fenetre_id = 0) {

  /*** 
        id_utilisateur
        iddepots
        id_fenetre
        xacce = acces
        xajou = add
        xmodi = edit
        xsupp = delete
        xcons = view
        ximpr = print
        xtous = admin
  ***/

  return true;

  if (iddepots && iddepots == 99) {
    let tmp = true;
    const depots = permissions.acces_depot || [];
    if (depots.length > 0) {
      for (var i = 0; i < depots.length; i++) {
        if (depots[i][action] == false && depots[i][accesTypes.tous] == false) {
          tmp = false;
          break;
        }
      }
    }
    return tmp;
  }

  if (iddepots) {
    const depots = permissions.acces_depot || [];

    customConsoleLog('checkPermission depots', depots);
    customConsoleLog('checkPermission iddepots', iddepots);

    if (depots.length > 0) {
      for (var i = 0; i < depots.length; i++) {
        if (depots[i].iddepots == iddepots) {
          customConsoleLog('checkPermission depots[i]', depots[i]);
          if (depots[i][accesTypes.tous] == true) {
            return true;
          }
          return depots[i][action] ? true : false;
        }
      }
    }

  }
  else if (fenetre_id) {
    const fenetres = permissions.acces_fenetre || [];
    if (fenetres.length > 0) {
      for (var i = 0; i < fenetres.length; i++) {
        if (fenetres[i].id_fenetre == fenetre_id) {
          customConsoleLog('checkPermission fenetres[i]', fenetres[i]);
          if (fenetres[i][accesTypes.tous] == true) {
            return true;
          }
          return fenetres[i][action] ? true : false;
        }
      }
    }

  }
  return false;

  if (action && permissions) {
    if (permissions[action]) {
      return permissions[action];
    }
    // for (const key in permissions) {
    //   if (key == action) {
    //     return permissions[key];
    //   }
    // }
  }
}