
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, reglementmvt_delete_api, reglementmvt_info_api, reglementmvt_list_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatBoolToStar, formatDate, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import RedEditDataTableBasic from '../../components/RedEditDataTableBasic';
import { customConsoleLog } from '../../global/Tools';






const columns = [
    { field: 'id', headerName: 'ID', width: 70, filterable: false },
    { field: 'type_mvt', headerName: 'Type', width: 100 },
    { field: 'ref_mvt', headerName: 'Ref Mvt', width: 100 },
    { field: 'date_mvt', headerName: 'date_mvt', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
    { field: 'reste', headerName: 'Reste', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
    { field: 'en_tete', headerName: 'Client', width: 200 },
    { field: 'total', headerName: 'total', type: 'number' },
    //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false, total: false }


export const GetReglementsMvtsListQuery = (canFetch = true, vars = {}) => {
    const params = { ...vars };
    customConsoleLog('GetReglementsMvtsListQuery', params);
    return useQuery({
        queryKey: ['ReglementMvtList'],
        queryFn: async () => await callAxiosApi(`${reglementmvt_list_api}`, { params }, true)
        ,
        refetchOnWindowFocus: false,
        retry: 2,
        cacheTime: 0,
        enabled: canFetch,
    })
}



export const cleanGetReglementsMvtsListQuery = (queryClient) => {
    queryClient.removeQueries({ queryKey: ['ReglementMvtList'] });
}


const ReglementsMvtsList = forwardRef((props, ref) => {
    //function ReglementsMvtsList({ removeWidget, isModal }) {
    const { handleGetid_reglementmvt, isModal, removeWidget, ridParams, pinnedRows, ...others } = props;
    const queryClient = useQueryClient();
    const [openReglementMvtList, setOpenReglementMvtList] = useState(false);

    const [actionNameReglementMvt, setActionNameReglementMvt] = useState("")
    const [selectReglementsMvtsList, setSelectionReglementsMvtsList] = useState([]);

    const handleRowSelectionReglementsMvtsList = (e) => {
        if (e != selectReglementsMvtsList[0]) {
            setSelectionReglementsMvtsList(e);
            customConsoleLog(e);
        }
    };




    const [status_reglementmvt, execute_reglementmvt, resolve_reglementmvt, reject_reglementmvt, reset_reglementmvt] = useAwaitableComponent();
    const [asyncConfirmDialogInfoReglementMvt, setConfirmDialogInfoReglementMvt] = useState({ titre: '', msg: '' });

    const showAsyncConfirmDialogReglementsMvtsList = status_reglementmvt === "awaiting";

    const handleAwaitModalReglementsMvtsList = async (mtitre, mmsg) => {

        try {
            setConfirmDialogInfoReglementMvt({ titre: mtitre, msg: mmsg });
            await execute_reglementmvt();
            return true;
        } catch (err) {
            return false;
        } finally {
            reset_reglementmvt();
        }
    };


    customConsoleLog('ReglementsMvtsList rendered')



    const { data: data_reglementsmvtslist = [], error: error_reglementsmvtslist,
        refetch: refecth_reglementsmvtslist, isLoading: isLoading_reglementsmvtslist, failureCount: failureCount_reglementsmvtslist } =
        GetReglementsMvtsListQuery(openReglementMvtList, ridParams ? ridParams : {});


    const getSelecteditemByID = (id) => {
        return data_reglementsmvtslist.filter(item => item.id == id)[0];
    }



    const handleOpenReglementsMvtsList = (itemID) => {
        setOpenReglementMvtList(true);


        if (itemID) {
            handleRowSelectionReglementsMvtsList([itemID]);
        }
    }






    const handleFermerReglementsMvtsList = () => {

        cleanGetReglementsMvtsListQuery(queryClient);

        if (removeWidget) {

            removeWidget();
            cleanGetReglementsMvtsListQuery(queryClient);
            setOpenReglementMvtList(false);
        }
        else {

            setOpenReglementMvtList(false);
        }
    }


    // const handleSelectMvtsReglementsMvtsList = () => {
    //     if (selectReglementsMvtsList[0]) {
    //         for (let i = 0; i < selectReglementsMvtsList.length; i++) {
    //             customConsoleLog('selectReglementsMvtsList[i]', selectReglementsMvtsList[i]);
    //             customConsoleLog('getSelecteditemByID(selectReglementsMvtsList[i])', getSelecteditemByID(selectReglementsMvtsList[i]));
    //         }
    //     }
    //     customConsoleLog('handleSelectMvtsReglementsMvtsList');
    // }

    const handleFinSelectionReglementsMvtsList = () => {
        customConsoleLog('handleFinSelectionReglementsMvtsList');

        if (selectReglementsMvtsList[0]) {
            if (handleGetid_reglementmvt) {

                const items = selectReglementsMvtsList.map((id) => getSelecteditemByID(id));

                handleGetid_reglementmvt(items);
            }
        }

        handleFermerReglementsMvtsList();


    }

    const handleEffacerReglementsMvtsList = () => {
        setSelectionReglementsMvtsList([]);
        customConsoleLog('handleEffacerReglementsMvtsList');
    }



    const total_reste_selected = selectReglementsMvtsList.reduce((acc, id) => {
        const tmp = getSelecteditemByID(id) ? getSelecteditemByID(id).reste : 0;
        return acc + tmp;
    }, 0);


    //const total_reste_selected = 0;





    const DataTableReglementsMvtsListRef = React.useRef(null);



    useImperativeHandle(ref, () => ({
        handleOpen(itemID) {
            handleOpenReglementsMvtsList(itemID);
        },
        handleClose() {
            handleFermerReglementsMvtsList();
        }
    }));

    // if (isLoading_reglementsmvtslist || error_reglementsmvtslist) {
    //   return (
    //     <>
    //       <BasicLoading
    //         handleClose={handleFermerReglementsMvtsList}
    //         refetch={refecth_reglementsmvtslist}
    //         failureCount={failureCount_reglementsmvtslist}
    //         error={error_reglementsmvtslist} />

    //     </>
    //   )
    // }


    return (
        <>
            <BasicModal open={openReglementMvtList} handleClose={handleFermerReglementsMvtsList} isModal={isModal} >
                {/* Tables */}
                {
                    isLoading_reglementsmvtslist || error_reglementsmvtslist
                        ? <BasicLoading
                            handleClose={handleFermerReglementsMvtsList}
                            refetch={refecth_reglementsmvtslist}
                            failureCount={failureCount_reglementsmvtslist}
                            error={error_reglementsmvtslist} />
                        : <>
                            {
                                showAsyncConfirmDialogReglementsMvtsList &&
                                <BasicAsyncConfirmDialog
                                    visible={showAsyncConfirmDialogReglementsMvtsList}
                                    onSubmit={resolve_reglementmvt}
                                    onCancel={reject_reglementmvt}
                                    info={asyncConfirmDialogInfoReglementMvt}
                                />
                            }

                            {isModal && <h2 className="text-lg font-bold mb-4">ReglementsMvts</h2>}

                            <RedDataTableBasic
                                ref={DataTableReglementsMvtsListRef}
                                rows={data_reglementsmvtslist}
                                columns={columns}
                                columnVisibilityModel={hideColumns}
                                handleRowSelection={handleRowSelectionReglementsMvtsList} 
                                select={selectReglementsMvtsList}
                                checkboxSelection={true}
                            />

                            {/* Buttons */}
                            <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                                {/* <BasicButton onClick={handleSelectMvtsReglementsMvtsList} id="select_mvts" name="select_mvts">Selectionner</BasicButton> */}
                                <BasicButton onClick={handleFinSelectionReglementsMvtsList} id="fin_selection" name="fin_selection">Fin Selection</BasicButton>
                                <div className='col-span-2 flex content-center pl-2'>
                                    <BasicDecimalInput id="total_reste_selected" name="total_reste_selected" className='bg-yellow-300 border-black text-xs' value={total_reste_selected} readOnly={true} />
                                </div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <BasicButton onClick={handleEffacerReglementsMvtsList} id="effacer_selection" name="effacer_selection">Effacer</BasicButton>
                                <BasicButton onClick={handleFermerReglementsMvtsList} id="fermer" name="fermer">Fermer</BasicButton>
                            </div>
                        </>
                }
            </BasicModal>


        </>

    );
    //}
});





export default ReglementsMvtsList



const ReglementsMvtsListwidgetKey = 'ReglementsMvtswidget'
export const ReglementsMvtsListwidget = { i: ReglementsMvtsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: (props) => <ReglementsMvtsList {...props} />, titre: 'A payer' }
