import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicButton from "../../components/BasicButton";
import BasicModal from "../../components/BasicModal";
import { ClientOrFournisseur, checkPermission, clearToasts, formatDecimalNumber, showToast, showToastAccesError, showToastError, strIsNullOrEmpty } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { accesTypes, defaultSelectChargement, fenetreType, typeMvt } from '../../global/GlobalVars';
import MvtsList, { MvtsListwidget } from '../mvt/MvtsList';
import { GetEtablissementsListQuery, cleanGetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import BasicSelect from '../../components/BasicSelect';
import BasicLabel from '../../components/BasicLabel';
import BasicLoading from '../../components/BasicLoading';
import { useLoginStore, useMvtInfoStore } from '../../stores/globalStore';
import { customConsoleLog } from '../../global/Tools';

function ChoisirDepot(props) {
    const { compo, type_mvt, open, handleClose, isModal, titre, addLayout, autoOpen, type_mvt_global, layoutId, livraisonType, ...others } = props;
    const queryClient = useQueryClient();
    //const [openDepot, setOpenDepot] = useState(open);
    const [depot, setDepot] = useState(null);

    const { g_mvtType, g_CliOrFr, g_depot, g_autoOpen, setGMvtType, setGCliOrFr, setGDepot, setGAutoOpen, setGLivraisonType } = useMvtInfoStore();



    // React.useEffect(() => {
    //     customConsoleLog('useEffect choisir_depot open', open)
    //     setOpenDepot(open);
    // }, [open]);



    const {
        data: data_etablissement = defaultSelectChargement,
        isLoading: isLoading_etablissement, error: error_etablissement, refetch: refetch_etablissement,
        failureCount: failureCount_etablissement } = GetEtablissementsListQuery(true);

    const { loginPermission } = useLoginStore();



    const handleGetid_etablissement = (value) => {
        if (value == depot) return;
        customConsoleLog('handleGetid_etablissement', value);
        setDepot(value);
    }

    const handleDepotChange = (e) => {
        customConsoleLog('handleDepotChange', e.target.value);
        handleGetid_etablissement(e.target.value);
    }


    const tmpWidget =
    {
        ...compo,
        titre: titre,
        component: (tmpprops) => <compo.component type_mvt={type_mvt} type_mvt_global={type_mvt} id_etablissement={depot} autoOpen={autoOpen} livraisonType={livraisonType} {...tmpprops} />,
    }


    const mvtList =
    {
        id: layoutId,
        titre: titre,
        link: tmpWidget, //'#',
        typefenetre: fenetreType.widget,
    }



    const handleOkChoisirDepot = () => {

        if (!depot) {
            showToastError('Veuillez choisir un depot');
            return;
        }

        if (!checkPermission(loginPermission, accesTypes.acces, depot || 99, 0)) {
            showToastAccesError(5000, 'error', data_etablissement.find(x => x.id == depot).libelle);
            return;
        }

        customConsoleLog('setGMvtType', type_mvt);
        addLayout(mvtList);
        setGDepot(depot);
        setGMvtType(type_mvt);
        setGCliOrFr(ClientOrFournisseur(type_mvt));
        setGAutoOpen(autoOpen);
        setGLivraisonType(livraisonType);
        handleFermerChoisirDepot();

    }


    const handleFermerChoisirDepot = () => {
        cleanGetEtablissementsListQuery(queryClient);
        setDepot(null);
        //clearToasts();
        handleClose();
    }




    return (
        <BasicModal open={open} handleClose={handleFermerChoisirDepot} isModal={isModal}>

            <div className='min-h-[300px] min-w-[500px]'>

                {
                    isLoading_etablissement || error_etablissement
                        ? <>

                            <BasicLoading
                                handleClose={handleFermerChoisirDepot}
                                refetch={refetch_etablissement}
                                failureCount={failureCount_etablissement}
                                error={error_etablissement} />
                        </>
                        : <>
                            <h1>Depot</h1>
                            <div className='min-h-[250px] p-10'>
                                <BasicSelect id="depot" name="depot" className="text-xs" listItems={data_etablissement || []} value={depot || ''}
                                    textKey={'libelle'}
                                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                                    onChange={handleDepotChange}
                                    isNotClearable={true}
                                    hasError={strIsNullOrEmpty(depot)}>
                                </BasicSelect>
                            </div>
                            <div className='grid grid-cols-5 gap-2'>
                                <div className='col-span-3'></div>
                                <BasicButton onClick={handleOkChoisirDepot} id="ok" name="ok" >ok</BasicButton>
                                <BasicButton onClick={handleFermerChoisirDepot} id="fermer" name="fermer">Fermer</BasicButton>
                            </div>
                        </>
                }
            </div>
        </BasicModal>
    )
}

export default ChoisirDepot;