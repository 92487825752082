
import { useEffect, useState } from "react";
import { login_api, user_api } from "../../global/GlobalVars";
import { ProblemDeConnectionMSG } from "../../global/GlobalText";
import BasicInput from "../../components/BasicInput";
import { errorStr, showToastError } from "../../global/Tools";
import { axiosClient, callAxiosApi } from "../../interceptors/axios";
import { useLoginStore } from "../../stores/globalStore";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { customConsoleLog } from '../../global/Tools';

export const GetUserQuery = () => {
  return useQuery({
    queryKey: ['GetUserQuery'],
    queryFn: async () => await callAxiosApi(`${user_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { logged, loginDetails, setLogin, setLogout} = useLoginStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (logged) {
      setLogout();
    }
  }, [])


  function submitLogin(e) {
    e.preventDefault();

    axiosClient.post(
      login_api,
      {
        username: username,
        password: password
      }
    ).then(function (response) {
      const csrfToken = response.headers['X-CSRFTOKEN'];
      customConsoleLog(csrfToken);

      customConsoleLog(response.data);
      setLogin(true, response.data);

      //navigate('/');
      window.location.href = '/'
      //setCurrentUser(true);
    }).catch((error) => {
      customConsoleLog(error);

      const errorMsg = errorStr(error.response?.data || ProblemDeConnectionMSG.localerreur.info[0]);
      showToastError(errorMsg, 5000, 'error');
      //throw new Error(errorMsg);
    });
  }

  // function submitLogout(e) {
  //   e.preventDefault();

  //   axiosClient.post(
  //     logout_api,
  //     {withCredentials: true}
  //   ).then(function(response) {
  //     setCurrentUser(false);
  //   }).catch((error) => {

  //     const errorMsg = error.response?.data.error || ProblemDeConnectionMSG;
  //     showToast(errorMsg, 5000, 'error');
  //     customConsoleLog(error.response?.data.error);
  //     });
  // }

  // const submit = async e => {
  //   e.preventDefault();

  //   const user = {
  //     username: username,
  //     password: password
  //   };
  //   try{
  //     const { data } = await axios.post(loging_token_api, user, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     }, { withCredentials: true });

  //     customConsoleLog(data)
  //     localStorage.clear();
  //     localStorage.setItem('access_token', data.access);
  //     localStorage.setItem('refresh_token', data.refresh);
  //     axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
  //     //window.location.href = '/'
  //   }
  //   catch(error){
  //     const errorMsg = error.response?.data.detail || ProblemDeConnectionMSG;
  //     showToast(errorMsg, 5000, 'error');
  //     customConsoleLog(errorMsg)
  //   }


  // }

  // if(currentUser){
  //   return (
  //     <>
  //     <form onSubmit={submitLogout}>
  //     <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
  //                   logout
  //       </button>
  //     </form>
  //     </>
  //   )
  // }

  return (
    <>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 px-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <img className="mx-auto h-10 w-auto" src="https://www.svgrepo.com/show/301692/login.svg" alt="Workflow"> */}
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Se connecter
          </h2>
        </div>


        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={submitLogin}>

              <div className="mt-6">
                <BasicInput id="utilisateur" name="utilisateur" titre="Utilisateur" className="text-lg" value={username} onChange={e => setUsername(e.target.value)} required />

              </div>
              <div className="mt-6">
                <BasicInput id="mot_de_passe" name="mot_de_passe" titre="Mot de passe" type="password" value={password} onChange={e => setPassword(e.target.value)} required />

              </div>


              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                    ok
                  </button>
                </span>
              </div>
            </form>

          </div>
        </div>
      </div>
    </>

  )
}
