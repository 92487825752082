let globalVariable = 0;

export const getGlobalVariable = () => globalVariable;
export const setGlobalVariable = (value) => { globalVariable = value; }

export const msgTypes = {
  txtmsg: 'msg',
  mvtValidationmsg: 'mvt',
}

export const accesTypes = {
  acces: 'xacce',
  ajouter: 'xajou',
  modifier: 'xmodi',
  supprimer: 'xsupp',
  consulter: 'xcons',
  imprimer: 'ximpr',
  tous: 'xtous',
}

export const fenetreType = {
  modal: 'Modal',
  widget: 'Widget',
  link: 'Link',
}

export const btnActionsName = {
    nouveau: 'act_nouveau',
    consulter: 'act_consulter',
    dupliquer: 'act_dupliquer',
    modifier: 'act_modifier',
    supprimer: 'act_supprimer',
    imprimer: 'act_imprimer',
}

export const typeMvt = {
    devisClient: 'DC',
    commandeClient: 'CC',
    bonLivraisonClient: 'LC',
    retourClient: 'RC',
    avoirClient: 'AC',
    bonClient: 'PC',
    factureClient: 'FC',
    devisFournisseur: 'DF',
    commandeFournisseur: 'CF',
    factureFournisseur: 'FF',
    bonLivraisonFournisseur: 'LF',
    retourFournisseur: 'RF',
    avoirFournisseur: 'AF',
    stockSF: 'SF',
    transfertEntreDepot: 'DD',
    encoursPF: 'KF',
    entreesPF: 'UF',
  }

export const imprimerFormatType = {
    ent: 'ent',
    dev: 'dev',
    fac: 'fac',
    cde: 'cde',
    bon: 'bon',
    nom: 'nom',
    fav: 'fav',
    bl: 'bl',
}

export const typeTiers = {
    client: 'C',
    fournisseur: 'F',
}

export const livraisonsType = {
  livraisons: "Livraisons",
  livraisonsC: "LivraisonsC",
  livraisonsCr: "LivraisonsCr",
}

export const defaultSelectChargement = [{id:0,libelle:'chargement..'}];

export const isProgDebug = true;

export const serverAddress = "http://ambadmin.redmt.cc"
//export const serverAddress = "http://localhost:8080"

export const ping_api = `${serverAddress}/api/ping/`



export const article_list_api = `${serverAddress}/api/get_articles_list/`
export const article_info_api = `${serverAddress}/api/get_article_info/`
export const article_update_api = `${serverAddress}/api/update_article_info/`
export const article_delete_api = `${serverAddress}/api/delete_article_info/`



export const departement_list_api = `${serverAddress}/api/get_departements_list/`
export const departement_info_api = `${serverAddress}/api/get_departement_info/`
export const departement_update_api = `${serverAddress}/api/update_departement_info/`
export const departement_delete_api = `${serverAddress}/api/delete_departement_info/`



export const famille_list_api = `${serverAddress}/api/get_familles_list/`
export const famille_info_api = `${serverAddress}/api/get_famille_info/`
export const famille_update_api = `${serverAddress}/api/update_famille_info/`
export const famille_delete_api = `${serverAddress}/api/delete_famille_info/`


export const sousfamille_list_api = `${serverAddress}/api/get_sousfamilles_list/`
export const sousfamille_info_api = `${serverAddress}/api/get_sousfamille_info/`
export const sousfamille_update_api = `${serverAddress}/api/update_sousfamille_info/`
export const sousfamille_delete_api = `${serverAddress}/api/delete_sousfamille_info/`

export const tva_list_api = `${serverAddress}/api/get_tvas_list/`
export const tva_info_api = `${serverAddress}/api/get_tva_info/`
export const tva_update_api = `${serverAddress}/api/update_tva_info/`
export const tva_delete_api = `${serverAddress}/api/delete_tva_info/`


export const article_fardeau_list_api = `${serverAddress}/api/get_article_fardeaus_list/`
export const article_fardeau_info_api = `${serverAddress}/api/get_article_fardeau_info/`
export const article_fardeau_update_api = `${serverAddress}/api/update_article_fardeau_info/`
export const article_fardeau_delete_api = `${serverAddress}/api/delete_article_fardeau_info/`


export const client_list_api = `${serverAddress}/api/get_clients_list/`
export const client_info_api = `${serverAddress}/api/get_client_info/`
export const client_update_api = `${serverAddress}/api/update_client_info/`
export const client_delete_api = `${serverAddress}/api/delete_client_info/`


export const fournisseur_list_api = `${serverAddress}/api/get_fournisseurs_list/`
export const fournisseur_info_api = `${serverAddress}/api/get_fournisseur_info/`
export const fournisseur_update_api = `${serverAddress}/api/update_fournisseur_info/`
export const fournisseur_delete_api = `${serverAddress}/api/delete_fournisseur_info/`


export const representant_list_api = `${serverAddress}/api/get_representants_list/`
export const representant_info_api = `${serverAddress}/api/get_representant_info/`
export const representant_update_api = `${serverAddress}/api/update_representant_info/`
export const representant_delete_api = `${serverAddress}/api/delete_representant_info/`


export const stock_depot_list_api = `${serverAddress}/api/get_stock_depots_list/`
export const stock_depot_info_api = `${serverAddress}/api/get_stock_depot_info/`
export const stock_depot_update_api = `${serverAddress}/api/update_stock_depot_info/`
export const stock_depot_delete_api = `${serverAddress}/api/delete_stock_depot_info/`


export const fardeau_detaile_list_api = `${serverAddress}/api/get_fardeau_detailes_list/`
export const fardeau_detaile_info_api = `${serverAddress}/api/get_fardeau_detaile_info/`
export const fardeau_detaile_update_api = `${serverAddress}/api/update_fardeau_detaile_info/`
export const fardeau_detaile_delete_api = `${serverAddress}/api/delete_fardeau_detaile_info/`


export const depense_list_api = `${serverAddress}/api/get_depenses_list/`
export const depense_info_api = `${serverAddress}/api/get_depense_info/`
export const depense_update_api = `${serverAddress}/api/update_depense_info/`
export const depense_delete_api = `${serverAddress}/api/delete_depense_info/`


export const chiffre_affaire_journee_list_api = `${serverAddress}/api/get_chiffre_affaire_journees_list/`
export const chiffre_affaire_journee_info_api = `${serverAddress}/api/get_chiffre_affaire_journee_info/`
export const chiffre_affaire_journee_update_api = `${serverAddress}/api/update_chiffre_affaire_journee_info/`
export const chiffre_affaire_journee_delete_api = `${serverAddress}/api/delete_chiffre_affaire_journee_info/`


export const depot_list_api = `${serverAddress}/api/get_depots_list/`
export const depot_info_api = `${serverAddress}/api/get_depot_info/`
export const depot_update_api = `${serverAddress}/api/update_depot_info/`
export const depot_delete_api = `${serverAddress}/api/delete_depot_info/`


export const pays_list_api = `${serverAddress}/api/get_payss_list/`
export const pays_info_api = `${serverAddress}/api/get_pays_info/`
export const pays_update_api = `${serverAddress}/api/update_pays_info/`
export const pays_delete_api = `${serverAddress}/api/delete_pays_info/`


export const region_list_api = `${serverAddress}/api/get_regions_list/`
export const region_info_api = `${serverAddress}/api/get_region_info/`
export const region_update_api = `${serverAddress}/api/update_region_info/`
export const region_delete_api = `${serverAddress}/api/delete_region_info/`


export const phrasier_list_api = `${serverAddress}/api/get_phrasiers_list/`
export const phrasier_info_api = `${serverAddress}/api/get_phrasier_info/`
export const phrasier_update_api = `${serverAddress}/api/update_phrasier_info/`
export const phrasier_delete_api = `${serverAddress}/api/delete_phrasier_info/`


export const categorie_list_api = `${serverAddress}/api/get_categories_list/`
export const categorie_info_api = `${serverAddress}/api/get_categorie_info/`
export const categorie_update_api = `${serverAddress}/api/update_categorie_info/`
export const categorie_delete_api = `${serverAddress}/api/delete_categorie_info/`


export const mode_reglement_list_api = `${serverAddress}/api/get_mode_reglements_list/`
export const mode_reglement_info_api = `${serverAddress}/api/get_mode_reglement_info/`
export const mode_reglement_update_api = `${serverAddress}/api/update_mode_reglement_info/`
export const mode_reglement_delete_api = `${serverAddress}/api/delete_mode_reglement_info/`


export const type_depense_list_api = `${serverAddress}/api/get_type_depenses_list/`
export const type_depense_info_api = `${serverAddress}/api/get_type_depense_info/`
export const type_depense_update_api = `${serverAddress}/api/update_type_depense_info/`
export const type_depense_delete_api = `${serverAddress}/api/delete_type_depense_info/`


export const utilisateur_list_api = `${serverAddress}/api/get_utilisateurs_list/`
export const utilisateur_info_api = `${serverAddress}/api/get_utilisateur_info/`
export const utilisateur_update_api = `${serverAddress}/api/update_utilisateur_info/`
export const utilisateur_delete_api = `${serverAddress}/api/delete_utilisateur_info/`


export const acces_depot_list_api = `${serverAddress}/api/get_acces_depots_list/`
export const acces_depot_info_api = `${serverAddress}/api/get_acces_depot_info/`
export const acces_depot_update_api = `${serverAddress}/api/update_acces_depot_info/`
export const acces_depot_delete_api = `${serverAddress}/api/delete_acces_depot_info/`


export const acces_cuve_list_api = `${serverAddress}/api/get_acces_cuves_list/`
export const acces_cuve_info_api = `${serverAddress}/api/get_acces_cuve_info/`
export const acces_cuve_update_api = `${serverAddress}/api/update_acces_cuve_info/`
export const acces_cuve_delete_api = `${serverAddress}/api/delete_acces_cuve_info/`


export const acces_vehicule_list_api = `${serverAddress}/api/get_acces_vehicules_list/`
export const acces_vehicule_info_api = `${serverAddress}/api/get_acces_vehicule_info/`
export const acces_vehicule_update_api = `${serverAddress}/api/update_acces_vehicule_info/`
export const acces_vehicule_delete_api = `${serverAddress}/api/delete_acces_vehicule_info/`


export const permission_list_api = `${serverAddress}/api/get_permissions_list/`
export const permission_info_api = `${serverAddress}/api/get_permission_info/`
export const permission_update_api = `${serverAddress}/api/update_permission_info/`
export const permission_delete_api = `${serverAddress}/api/delete_permission_info/`


export const etablissement_list_api = `${serverAddress}/api/get_etablissements_list/`
export const etablissement_info_api = `${serverAddress}/api/get_etablissement_info/`
export const etablissement_update_api = `${serverAddress}/api/update_etablissement_info/`
export const etablissement_delete_api = `${serverAddress}/api/delete_etablissement_info/`


export const cuve_list_api = `${serverAddress}/api/get_cuves_list/`
export const cuve_info_api = `${serverAddress}/api/get_cuve_info/`
export const cuve_update_api = `${serverAddress}/api/update_cuve_info/`
export const cuve_delete_api = `${serverAddress}/api/delete_cuve_info/`


export const marque_list_api = `${serverAddress}/api/get_marques_list/`
export const marque_info_api = `${serverAddress}/api/get_marque_info/`
export const marque_update_api = `${serverAddress}/api/update_marque_info/`
export const marque_delete_api = `${serverAddress}/api/delete_marque_info/`


export const usage_list_api = `${serverAddress}/api/get_usages_list/`
export const usage_info_api = `${serverAddress}/api/get_usage_info/`
export const usage_update_api = `${serverAddress}/api/update_usage_info/`
export const usage_delete_api = `${serverAddress}/api/delete_usage_info/`


export const chauffeur_list_api = `${serverAddress}/api/get_chauffeurs_list/`
export const chauffeur_info_api = `${serverAddress}/api/get_chauffeur_info/`
export const chauffeur_update_api = `${serverAddress}/api/update_chauffeur_info/`
export const chauffeur_delete_api = `${serverAddress}/api/delete_chauffeur_info/`


export const vehicule_list_api = `${serverAddress}/api/get_vehicules_list/`
export const vehicule_info_api = `${serverAddress}/api/get_vehicule_info/`
export const vehicule_update_api = `${serverAddress}/api/update_vehicule_info/`
export const vehicule_delete_api = `${serverAddress}/api/delete_vehicule_info/`


export const entretien_list_api = `${serverAddress}/api/get_entretiens_list/`
export const entretien_info_api = `${serverAddress}/api/get_entretien_info/`
export const entretien_update_api = `${serverAddress}/api/update_entretien_info/`
export const entretien_delete_api = `${serverAddress}/api/delete_entretien_info/`


export const reservation_list_api = `${serverAddress}/api/get_reservations_list/`
export const reservation_info_api = `${serverAddress}/api/get_reservation_info/`
export const reservation_update_api = `${serverAddress}/api/update_reservation_info/`
export const reservation_delete_api = `${serverAddress}/api/delete_reservation_info/`


export const parametre_list_api = `${serverAddress}/api/get_parametres_list/`
export const parametre_info_api = `${serverAddress}/api/get_parametre_info/`
export const parametre_update_api = `${serverAddress}/api/update_parametre_info/`
export const parametre_delete_api = `${serverAddress}/api/delete_parametre_info/`

export const mvt_list_api = `${serverAddress}/api/get_mvts_list/`
export const mvt_info_api = `${serverAddress}/api/get_mvt_info/`
export const mvt_info_with_lignes_api = `${serverAddress}/api/get_mvt_info_with_lignes/`
export const mvt_update_api = `${serverAddress}/api/update_mvt_info/`
export const mvt_delete_api = `${serverAddress}/api/delete_mvt_info/`


export const lignesmvt_list_api = `${serverAddress}/api/get_lignesmvts_list/`
export const lignesmvt_info_api = `${serverAddress}/api/get_lignesmvt_info/`
export const lignesmvt_update_api = `${serverAddress}/api/update_lignesmvt_info/`
export const lignesmvt_delete_api = `${serverAddress}/api/delete_lignesmvt_info/`


export const reglement_list_api = `${serverAddress}/api/get_reglements_list/`
export const reglement_info_api = `${serverAddress}/api/get_reglement_info/`
export const reglement_update_api = `${serverAddress}/api/update_reglement_info/`
export const reglement_delete_api = `${serverAddress}/api/delete_reglement_info/`


export const lignesreglement_list_api = `${serverAddress}/api/get_lignesreglements_list/`
export const lignesreglement_info_api = `${serverAddress}/api/get_lignesreglement_info/`
export const lignesreglement_update_api = `${serverAddress}/api/update_lignesreglement_info/`
export const lignesreglement_delete_api = `${serverAddress}/api/delete_lignesreglement_info/`


export const reglementmvt_list_api = `${serverAddress}/api/get_reglementmvts_list/`
export const reglementmvt_info_api = `${serverAddress}/api/get_reglementmvt_info/`
export const reglementmvt_update_api = `${serverAddress}/api/update_reglementmvt_info/`
export const reglementmvt_delete_api = `${serverAddress}/api/delete_reglementmvt_info/`


export const permissions_list_api = `${serverAddress}/api/get_permissions_list/`
export const permissionsinfo_api = `${serverAddress}/api/get_permission_info/`
export const permissions_update_api = `${serverAddress}/api/update_permission_info/`
export const permissions_delete_api = `${serverAddress}/api/delete_permission_info/`


export const rooms_list_api = `${serverAddress}/api/get_rooms_list/`
export const room_info_api = `${serverAddress}/api/get_room_info/`
export const room_update_api = `${serverAddress}/api/update_room_info/`
export const room_delete_api = `${serverAddress}/api/delete_room_info/`


export const messages_list_api = `${serverAddress}/api/get_messages_list/`
export const message_info_api = `${serverAddress}/api/get_message_info/`
export const message_update_api = `${serverAddress}/api/update_message_info/`
export const message_delete_api = `${serverAddress}/api/delete_message_info/`


export const example_list_api = `${serverAddress}/api/get_examples_list/`
export const example_info_api = `${serverAddress}/api/get_example_info/`
export const example_update_api = `${serverAddress}/api/update_example_info/`
export const example_delete_api = `${serverAddress}/api/delete_example_info/`


export const upload_article_img_api = `${serverAddress}/api/upload_produit_img/`
export const get_articles_img_api = `${serverAddress}/api/get_produits_img/`


export const user_api =`${serverAddress}/api/user/`
export const login_api =`${serverAddress}/api/login/`
export const logout_api =`${serverAddress}/api/logout/`

export const articles_image_dir = `${serverAddress}/media/produits/`
//export const article_fardeaus_image_dir = `${serverAddress}/media/produits/`
// export const loging_token_api = `${serverAddress}/api/token/`
// export const loging_refresh_token_api =`${serverAddress}/api/token/refresh/` 