import React from 'react'
import { checkString, cn  } from '../global/Tools';
import { customConsoleLog } from '../global/Tools';

const spanclassName = "whitespace-nowrap mr-1 w-[100px] text-right"
const labelClassName = 'flex items-center mb-1'

function BasicLabel(props) {
    const { children, className, titre, labelClass} = props;
    const customClassLabel = cn(`${labelClassName} ${checkString(labelClass)}`);
    const customClass = cn(`${spanclassName} ${checkString(className)}`);
    return (
        <div className={customClassLabel}>
            <span className={customClass}>{titre}</span>
            {children}
        </div>
    )
}

export default BasicLabel