import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, ping_api, vehicule_info_api, vehicule_update_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import BasicContainer from '../../components/BasicContainer';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import BasicSelect from '../../components/BasicSelect';
import MarquesList, { GetMarquesListQuery } from '../marque/MarquesList';
import UsagesList, { GetUsagesListQuery } from '../usage/UsagesList';
import ChauffeursList, { GetChauffeursListQuery } from '../chauffeur/ChauffeursList';
import BasicRadio from '../../components/BasicRadio';
import BasicNumberInput from '../../components/BasicNumberInput';
import BasicCheckBox from '../../components/BasicCheckBox';
import BasicDateInput from '../../components/BasicDateInput';
import BasicTabs, { BasicTab, BasicTabPanel, BasicTabsList } from '../../components/BasicTabs';
import { customConsoleLog } from '../../global/Tools';





const GetVehiculeEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetVehiculeEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetVehiculeQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetVehiculeQuery', itemID],
    queryFn: async () => await callAxiosApi(`${vehicule_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddVehiculeQuery = (handleClose, addVehiculeLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${vehicule_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddVehiculeQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Vehicule ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addVehiculeLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateVehiculeQuery = (itemID, updateVehiculeLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${vehicule_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateVehiculeQuery', itemID],
    onSuccess: () => {
      const msg = `Vehicule mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateVehiculeQuery called');

        updateVehiculeLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetVehiculeEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetVehiculeEmptyQuery'], exact: true });
}

export const cleanGetVehiculeQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanVehiculeGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetVehiculeQuery', itemID], exact: true });
}




function Vehicule({ actionName, selectedItem, handleClose, addVehiculeLine, updateVehiculeLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Vehicule compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetVehiculeEmptyQuery() : GetVehiculeQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addVehicule = useAddVehiculeQuery(handleClose, addVehiculeLine)
  const updateVehicule = useUpdateVehiculeQuery(itemID, updateVehiculeLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('useEffect called');
    }
  }, [data]);





  const { data: data_marque, isLoading: isLoading_marque } = GetMarquesListQuery(canFetchOthers);


  const MarqueListRef = React.useRef();

  const handleMarqueClick = () => {
    const marqueid = data_marque?.find(x => x.code == formValues.marque)?.id;

    MarqueListRef.current?.handleOpen(marqueid);
  };

  const handleGetmarque = (value) => {
    const marquelibelle = data_marque?.find(x => x.id == value)?.libelle;
    const marquecode = data_marque?.find(x => x.id == value)?.code;

    if (formValues.marquelibelle == marquelibelle) return;
    setFormValues(prevState => ({
      ...prevState,
      'marque': marquecode,
    }));
  }


  React.useEffect(() => {
    const marquelibelle = data_marque?.find(x => x.code == formValues.marque)?.libelle;
    if (formValues.marquelibelle == marquelibelle) return;
    setFormValues(prevState => ({
      ...prevState,
      'marquelibelle': marquelibelle,
    }));
  }, [formValues.marque, data_marque]);



  const { data: data_usage, isLoading: isLoading_usage } = GetUsagesListQuery(canFetchOthers);


  const UsageListRef = React.useRef();

  const handleUsageClick = () => {
    const usageid = data_usage?.find(x => x.code == formValues.usage)?.id;

    UsageListRef.current?.handleOpen(usageid);
  };

  const handleGetusage = (value) => {
    const usagelibelle = data_usage?.find(x => x.id == value)?.libelle;
    const usagecode = data_usage?.find(x => x.id == value)?.code;

    if (formValues.usagelibelle == usagelibelle) return;
    setFormValues(prevState => ({
      ...prevState,
      'usage': usagecode,
    }));
  }


  React.useEffect(() => {
    const usagelibelle = data_usage?.find(x => x.code == formValues.usage)?.libelle;
    if (formValues.usagelibelle == usagelibelle) return;
    setFormValues(prevState => ({
      ...prevState,
      'usagelibelle': usagelibelle,
    }));
  }, [formValues.usage, data_usage]);


  const { data: data_chauffeur, isLoading: isLoading_chauffeur } = GetChauffeursListQuery(canFetchOthers);


  const ChauffeurListRef = React.useRef();

  const handleChauffeurClick = () => {
    const chauffeurid = data_chauffeur?.find(x => x.code == formValues.genre)?.id;

    ChauffeurListRef.current?.handleOpen(chauffeurid);
  };

  const handleGetchauffeur = (value) => {
    const chauffeurlibelle = data_chauffeur?.find(x => x.id == value)?.libelle;
    const chauffeurcode = data_chauffeur?.find(x => x.id == value)?.code;

    if (formValues.chauffeurlibelle == chauffeurlibelle) return;
    setFormValues(prevState => ({
      ...prevState,
      'genre': chauffeurcode,
    }));
  }


  React.useEffect(() => {
    const chauffeurlibelle = data_chauffeur?.find(x => x.code == formValues.genre)?.libelle;
    if (formValues.chauffeurlibelle == chauffeurlibelle) return;
    setFormValues(prevState => ({
      ...prevState,
      'genrelibelle': chauffeurlibelle,
    }));
  }, [formValues.genre, data_chauffeur]);


  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkVehicule = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        addVehicule.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addVehicule.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateVehicule.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseVehicule = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }


  const tabContentClass = "border rounded-sm border-gray-400 border-t-0 p-1  bg-slate-100";



  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      <div className="m-2 flex flex-row">
        <UsagesList ref={UsageListRef} handleGetid_usage={handleGetusage} isModal={true} />
        <MarquesList ref={MarqueListRef} handleGetid_marque={handleGetmarque} isModal={true} />
        <ChauffeursList ref={ChauffeurListRef} handleGetid_chauffeur={handleGetchauffeur} isModal={true} />
        <section className='w-[700px]'>
          <h2 className="text-lg font-bold mb-4">Vehicule</h2>
          <form onSubmit={handleSubmit}>
            <BasicContainer className=''>



              <BasicTabs defaultValue={1}>
                <BasicTabsList className='grid grid-cols-2'>
                  <BasicTab value={1} className='pt-1'>Immatriculation</BasicTab>
                  <BasicTab value={2}>Information Complementaires</BasicTab>
                </BasicTabsList>
                <BasicTabPanel value={1} className={tabContentClass}>
                  <div className='flex flex-col gap-1'>
                    <div>
                      <BasicLabel className='text-xs w-[200px]' titre="Immatriculation :">
                        <BasicInput id="code" name="code" className='text-xs' value={formValues.code || ''} onChange={handleChange} readOnly={isReadOnly} required />
                      </BasicLabel>
                    </div>

                    <div className='grid grid-cols-2'>
                      <BasicLabel className='text-xs w-[200px]' titre="Usage :">
                        <BasicInput id="usage" name="usage" className='text-xs bg-blue-800 text-white' value={formValues.usage || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>
                      <div className='text-xs flex items-center mb-1 ml-2'>
                        <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_usage} onClick={handleUsageClick}>...</BasicButtonSmall>
                        <BasicInput id="usagelibelle" name="usagelibelle" className='text-xs bg-blue-800 text-white' value={formValues.usagelibelle || ''} readOnly={true} />
                      </div>
                    </div>

                    <div className='grid grid-cols-2'>
                      <BasicLabel className='text-xs w-[200px]' titre="Marque :">
                        <BasicInput id="marque" name="marque" className='text-xs bg-blue-800 text-white' value={formValues.marque || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>
                      <div className='text-xs flex items-center mb-1 ml-2'>
                        <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_marque} onClick={handleMarqueClick}>...</BasicButtonSmall>
                        <BasicInput id="marquelibelle" name="marquelibelle" className='text-xs bg-blue-800 text-white' value={formValues.marquelibelle || ''} readOnly={true} />
                      </div>
                    </div>


                    <div className='grid grid-cols-2'>
                      <BasicLabel className='text-xs w-[200px]' titre="Type :">
                        <BasicInput id="type" name="type" className='text-xs bg-blue-800 text-white' value={formValues.type || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>
                      <div className='text-xs flex items-center mb-1 ml-2'>

                      </div>
                    </div>
                  </div>


                  <div className='grid grid-cols-2'>
                    <BasicLabel className='text-xs w-[200px]' titre="Chauffeur :">
                      <BasicInput id="genre" name="genre" className='text-xs bg-blue-800 text-white' value={formValues.genre || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </BasicLabel>
                    <div className='text-xs flex items-center mb-1 ml-2'>
                      <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_chauffeur} onClick={handleChauffeurClick}>...</BasicButtonSmall>
                      <BasicInput id="genrelibelle" name="genrelibelle" className='text-xs bg-blue-800 text-white' value={formValues.genrelibelle || ''} readOnly={true} />
                    </div>
                  </div>

                  <div className='grid grid-cols-2 my-3'>
                    <BasicLabel className='text-xs w-full' titre="Carburant :">

                    </BasicLabel>
                    <BasicContainer className='text-xs flex items-center mb-1 ml-2'>
                      <div className='grid grid-cols-3'>
                        <BasicRadio type="radio" id="gasoil" name="carburant" value="D" titre="Gasoil" selectedValue={formValues.carburant} onChange={handleChange} className="text-xs" />
                        <BasicRadio type="radio" id="essence" name="carburant" value="E" titre="Essence" selectedValue={formValues.carburant} onChange={handleChange} className="text-xs" />
                        <BasicRadio type="radio" id="autres" name="carburant" value="X" titre="Autres" selectedValue={formValues.carburant} onChange={handleChange} className="text-xs" />
                      </div>
                    </BasicContainer>
                  </div>




                  <div className='grid grid-cols-2'>
                    <BasicLabel className='text-xs w-[200px]' titre="Numéro de chassis :">
                      <BasicInput id="n_chassis" name="n_chassis" className='text-xs bg-blue-800 text-white' value={formValues.n_chassis || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </BasicLabel>
                    <BasicLabel className='text-xs w-[200px]' titre="Compteur :">
                      <BasicInput id="compteuract" name="compteuract" className='text-xs bg-blue-800 text-white' value={formValues.compteuract || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </BasicLabel>
                  </div>

                </BasicTabPanel>
                <BasicTabPanel value={2} className={tabContentClass}>
                <BasicLabel className='text-xs' titre="Avec Remorque :">
                <BasicCheckBox id="remorque" name="remorque" className="text-xs" checked={formValues.remorque} onChange={handleChange} disabled={isReadOnly} />
                </BasicLabel>
                  
                  <div className='grid grid-cols-2 mt-2 gap-1'>
                    <div className='flex flex-col gap-2'>

                      <BasicLabel className='text-xs w-[230px]' titre="Puissance :">
                        <BasicNumberInput id="puissance" name="puissance" className='text-xs bg-blue-800 text-white' value={formValues.puissance || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[230px]' titre="Cylindres :">
                        <BasicNumberInput id="cylindres" name="cylindres" className='text-xs bg-blue-800 text-white' value={formValues.cylindres || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[230px]' titre="Nbre de Places :">
                        <BasicNumberInput id="places" name="places" className='text-xs bg-blue-800 text-white' value={formValues.places || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[230px]' titre="Poids Total :">
                        <BasicNumberInput id="poids_total" name="poids_total" className='text-xs bg-blue-800 text-white' value={formValues.poids_total || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[230px]' titre="Volume Reservoire1 : ">
                        <BasicNumberInput id="volumeres1" name="volumeres1" className='text-xs bg-blue-800 text-white' value={formValues.volumeres1 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[230px]' titre="Volume Reservoire2 : ">
                        <BasicNumberInput id="volumeres2" name="volumeres2" className='text-xs bg-blue-800 text-white' value={formValues.volumeres2 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                    </div>
                    <div className='flex flex-col gap-2'>

                      <BasicLabel className='text-xs w-[320px]' titre="Date mise en circulation :">
                        <BasicDateInput id="mise_en_circulation" name="mise_en_circulation" className='text-xs' isbig={true} value={formValues.mise_en_circulation || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[320px]' titre="Date derniere mutation :">
                        <BasicDateInput id="date_de_mutation" name="date_de_mutation" className='text-xs' isbig={true} value={formValues.date_de_mutation || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[320px]' titre="Date Vente :">
                        <BasicDateInput id="datevente" name="datevente" className='text-xs' isbig={true} value={formValues.datevente || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[320px]' titre="Nombre de sinistres : ">
                        <BasicNumberInput id="nbsinistre" name="nbsinistre" className='text-xs bg-blue-800 text-white' value={formValues.nbsinistre || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                      <BasicLabel className='text-xs w-[320px]' titre="Dernier le :">
                        <BasicDateInput id="derniersin" name="derniersin" className='text-xs' isbig={true} value={formValues.derniersin || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>

                    </div>
                  </div>

                </BasicTabPanel>
              </BasicTabs>

            </BasicContainer>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkVehicule}
                  loading={addVehicule.isPending || updateVehicule.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseVehicule}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Vehicule