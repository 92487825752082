import React, { useEffect, useRef } from 'react'
import { checkString, cn } from '../global/Tools';

import Select, { components, createFilter } from 'react-select';
import BasicCheckBox from './BasicCheckBox';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { customConsoleLog } from '../global/Tools';

const spanLabelClass = "whitespace-nowrap inline bg-gray-100 py-1 px-1 text-gray-600"
const inputClass = "bg-white border-transparent w-full py-1  px-1  text-gray-600 focus:outline-none"
const inputReadOnlyClass = "bg-gray-200 border-transparent w-full py-1  px-1  text-gray-600 focus:outline-none"

const Menu = (props) => {
  // we should get the element by id props.selectProps.id and get the ref
  // const id = props.selectProps.id;
  // const element = document.getElementById(id);
  // const ref = useRef(null);
  // ref.current = element;

  return (
    <>
      <components.Menu {...props}>

        {props.children}
      </components.Menu>
    </>
  );
}


const MenuPortal = (props) => {

  return (
    <components.MenuPortal {...props}>
      <div className='bg-yellow-400'>

        {props.children}
      </div>
    </components.MenuPortal>
  );
}



const MenuList = (props) => {


  return (
    <components.MenuList {...props}>
      <table className="w-full">
        <thead className='bg-gray-300 sticky top-0 '>
          <tr>
            {
              props.children[0]?.props?.data?.fields.map((field, index) => (
                <th key={index} className='p-1 border border-collapse border-gray-50'>{field.title}</th>
              ))

            }
          </tr>
        </thead>
        <tbody className='overflow-auto'>
          {props.children}
        </tbody>
      </table>
    </components.MenuList>
  );
};

const Option = (props) => {
  const { data, className, innerProps, innerRef, ...others } = props;
  const isSelected = others.isSelected;
  const isFocused = others.isFocused;

  const fields = data.fields;


  const optionStyles = {
    base: cn("hover:cursor-pointer px-3 py-1 min-w-[100px]", className),
    focus: cn("bg-blue-100 active:bg-gray-200", className),
    selected: cn("bg-blue-600 text-white ", className),
  };

  const customClass = cn(
    optionStyles.base,
    isFocused && optionStyles.focus,
    isSelected && optionStyles.selected,
  );

  return (
    // <components.Option {...others}>
    <tr ref={innerRef} {...innerProps} className={customClass}>

      {fields.map((field, index) => (
        <td key={index} className="p-1 border border-collapse border-gray-400  text-nowrap">
          <span>{field.value}</span>
        </td>
      ))}
      {/* {Object.entries(data).map(([key, value], index) => (
        <td key={index} className="p-1 border-b border-gray-400  border-r text-nowrap">
          <span>{value}</span>
        </td>
      ))} */}
    </tr>
    // </components.Option>
  );
};





function BasicSelect(props) {
  const {
    children, titre, listItems, className, inputClassName,
    readOnly, textKey, valueKey, onChange,
    value, isClearable, isNotClearable, fieldsConfig, focus, id, portalClassName, onKeyUp, hasError, ...others } = props;

  const inputRef = React.useRef(null);
  const [customClearable, setCustomClearable] = React.useState(false);

  const customPortal = document.getElementById('selectCustomPortal');

  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);


  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: ('any'),
    stringify: option => `${option.data.fields.map(field => field.value).join(' ')}`,
  };


  const options = listItems.map((item, index) => {
    return {
      value: valueKey ? item[valueKey] : item.id,
      label: item[textKey],
      fields: fieldsConfig?.map((field) => {
        return { key: field.key, title: field.title, value: item[field.key] }
      }
      )
    }
  }
  );

  // randomId should be a string with 'select' and a random number
  const randomId = 'select' + Math.floor(Math.random() * 1000).toString();
  const selectId = id ? id : randomId;


  const getOptionByid = (id) => {
    if (!id) return { value: '', label: '' };
    if (options.length == 0) return { value: '', label: '' };

    return options.filter(option => option.value == id)[0];
  }

  useEffect(() => {
    if (focus) {
      inputRef?.current.focus();
    }
  }, [focus]);



  const [localValue, setLocalValue] = React.useState(getOptionByid(value));


  useEffect(() => {
    customConsoleLog('useEffect value', value);
    setLocalValue(getOptionByid(value));
  }, [value, listItems]);


  const errorClass = hasError ? 'border-red-500' : 'border-gray-300';


  const handleOnChange = (selectedOption, actionMeta) => {
    // customConsoleLog('handleOnChange selectedOption actionMeta', selectedOption, actionMeta);
    //const { name, value, type, checked } = e.target;
    if (!selectedOption) {
      onChange({ target: { name: actionMeta.name, value: '' } });
      return;
    }
    const e = { target: { name: actionMeta.name, value: selectedOption.value } };
    onChange(e);
    //setLocalValue(getOptionByid(selectedOption.value));
  }


  const controlStyles = {
    base: cn("border rounded bg-white hover:cursor-pointer", className),
    focus: cn("border-primary-600 ring-1 ring-primary-500", className),
    nonFocus: cn("border-gray-300 hover:border-gray-400", className),
    disabled: cn("bg-gray-300 border-gray-300", className),
  };
  const placeholderStyles = cn("text-gray-500 pl-1", className, inputClassName);
  const selectInputStyles = cn("p-1", className, inputClassName);
  const valueContainerStyles = cn("p-1", className, inputClassName);
  const singleValueStyles = cn("leading-none", className, inputClassName);

  const clearIndicatorStyles = cn("text-gray-400 hover:text-red-600");
  const dropdownIndicatorStyles = {
    base: cn("text-gray-600 hover:text-gray-800"),
    disabled: cn("text-transparent hover:text-transparent"),
  };

  const menuPortalStyles = cn("zIndex9999", className);

  const menuStyles = cn(" p-1 my-2 border border-gray-500 rounded-lg bg-white", className, portalClassName);
  const optionStyles = {
    base: cn("hover:cursor-pointer px-3 py-1 rounded", className),
    focus: cn("bg-blue-100 active:bg-gray-200", className),
    selected: cn("bg-blue-600 text-white ", className),
  };

  const noOptionsMessageStyles = cn("text-red-500 bg-red-50 py-1 border border-dashed border-red-200 rounded-sm", className);

  const handleMenuClose = (event) => {
    //customConsoleLog('handleMenuClose event', event);
    //event.preventDefault();
  };

  // const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  // const toggleMenuIsOpen = () => {
  //   setMenuIsOpen((value) => !value);
  //   const selectEl = inputRef.current;
  //   if (!selectEl) return;
  //   if (menuIsOpen) selectEl.blur();
  //   else selectEl.focus();
  // };

  const handleOnKeyUp = (e) => {
    customConsoleLog('handleOnKeyUp e', e);
    onKeyUp(e);
  }



  const handleKeyDown = (event) => {
    customConsoleLog('handleKeyDown event', event);
    if (event.key === 'F4' && focus) {
      event.preventDefault();
      // Your custom logic to handle F4 press when the select is focused
      customConsoleLog('F4 key pressed on select!');
    }
  };



  // isClearable should be called only if isClearable or isNotClearable is false
  useEffect(() => {

    if (!isNotClearable) {
      setCustomClearable(true);
    }
    else if (isClearable) {
      setCustomClearable(true);
    }
    else {
      setCustomClearable(false);
    }
  }, [isClearable, isNotClearable]);

  return (
    <div className={`inline-flex rounded border-[1px] text-xxs w-full ${errorClass}`} >
      {titre && <span className={spanLabelClass}>{titre}</span>}
      <Select
        className={"w-full"}
        //classNamePrefix="select"
        ref={inputRef}
        defaultValue={localValue}
        isDisabled={readOnly}
        // isLoading={isLoading}
        {...(customClearable && { isClearable: true })}
        // isRtl={isRtl}
        isSearchable={true}
        name={others.name}
        options={options}
        onChange={handleOnChange}
        //menuIsOpen={menuIsOpen}
        menuPlacement="auto"
        menuPortalTarget={customPortal}
        value={localValue}
        onMenuClose={handleMenuClose}
        // styles={colorStyles}
        //getOptionLabel={(mval) => <MyCustomSingleValue data={[mval.value, mval.label, mval.label, mval.label, mval.label, mval.label]}/>}


        components={{ Menu, MenuList, Option, }}
        unstyled
        noOptionsMessage={() => "Aucun resultat trouvé"}
        styles={{
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
              minHeight: "0px",
            },
          }),
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: "normal",
            overflow: "visible",
          }),
          control: (base) => ({
            ...base,
            transition: "none",
            minHeight: "0px",
          }),
        }}
        //components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
        classNames={{
          control: ({ isFocused, isDisabled }) =>
            cn(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base,
              isDisabled && controlStyles.disabled,
            ),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          clearIndicator: () => clearIndicatorStyles,
          // indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: ({isDisabled}) =>
            cn(
              !isDisabled ? dropdownIndicatorStyles.base : dropdownIndicatorStyles.disabled,
              ),
          menuPortal: () => menuPortalStyles,
          menu: () => menuStyles,
          // groupHeading: () => groupHeadingStyles,
          // option: ({ isFocused, isSelected }) =>
          //   cn(
          //     isFocused && optionStyles.focus,
          //     isSelected && optionStyles.selected,
          //     optionStyles.base,
          //   ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        {...others}
        //onKeyDown={handleKeyDown}
        id={selectId}
        filterOption={createFilter(filterConfig)}


      />
      {/* <BasicCheckBox
          titre="menuIsOpen"
          checked={menuIsOpen}
          onChange={toggleMenuIsOpen}
          id="cypress-single__clearable-checkbox"
        /> */}
      {children}
    </div>
  )
}

export default BasicSelect