import React, { useRef } from 'react'

import { InputNumber } from 'primereact/inputnumber';
import { checkString, cn } from '../global/Tools';

const spanLabelClass = "whitespace-nowrap inline bg-gray-100 py-1 px-1 text-gray-600"
const inputClass = " border-transparent w-full py-1  px-1  text-gray-600 focus:outline-blue-200"
const inputReadOnlyClass = "bg-gray-200 border-transparent w-full py-1  px-1  text-gray-600"


function BasicNumberInput(props) {
  const { titre, value, onChange, className, readOnly, focus, ...others } = props;
  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);

  const inputRef = useRef(null);

  const handleFocus = () => {
    inputRef.current.select();
  };

  React.useEffect(() => {
    if(focus){
      if(inputRef.current){
        inputRef.current.focus();
      }
    }
  }, [focus])

  return (
    <>
      <div className="inline-flex bg-white rounded border-[1px] border-gray-300 text-xxs w-full">
        {titre && <span className={spanLabelClass}>{titre}</span>}
        <InputNumber {...others}
          useGrouping={false}
          value={value ? value : '0'}
          onChange={(e) => onChange(e.originalEvent)}
          readOnly={readOnly}
          pt={{
            root: 'w-full inline-flex ',
            input: {
              root: ({ props }) => ({
                className: `${customClass}`
              })
            },

          }}
          onFocus={handleFocus} // Call handleFocus when the input receives focus
          inputRef={inputRef} // Assign the ref to the input field
        />
      </div>
    </>
  )
}

export default BasicNumberInput