import React from 'react'
import { checkString, cn  } from '../global/Tools';

const spanLabelClass = "whitespace-nowrap inline bg-gray-100 py-1 px-1 text-gray-600"
const inputClass = "bg-transparent border-transparent w-full py-1  px-1  text-gray-600 focus:outline-none"
const inputReadOnlyClass = "bg-transparent border-transparent w-full py-1  px-1  text-gray-600 focus:outline-none"

function BasicSelectList(props) {
  const { children, titre, listItems, className, readOnly, textKey, ...others } = props;
  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);
  return (
    <div className="flex flex-col bg-white rounded border-[1px] border-gray-300 text-xxs w-full">
    {titre && <span className={spanLabelClass}>{titre}</span>}
    <select className={`${customClass}`} disabled={readOnly} {...others} size={15}>
      {listItems?.map((item, index) => {
        return <option key={index} value={item.id}>{item[textKey]}</option>
      })}
      </select>
    {children}
    </div>
  )
}

export default BasicSelectList