import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './custom.css';
import App from './App';

import { ThemeProvider as ThemeProviderTailwind } from "@material-tailwind/react"
import { createTheme, ThemeProvider as ThemeProviderMUI } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import { frFR as frFRDG } from '@mui/x-data-grid';
import { frFR as pickersfrFR } from '@mui/x-date-pickers/locales';
import { PrimeReactProvider } from "primereact/api";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from 'react-toastify';

export const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  frFR,
  frFRDG,
  pickersfrFR,
);


root.render(
  <>
  <React.StrictMode>
    {/* <LoginContextProvider> */}

    < ThemeProviderTailwind >
      <ThemeProviderMUI theme={theme}>
        <PrimeReactProvider value={{ unstyled: true, pt: {} }}>
          <QueryClientProvider client={queryClient}>

            <ToastContainer containerId="All" newestOnTop={true} />
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PrimeReactProvider>
      </ThemeProviderMUI>
    </ ThemeProviderTailwind>
    {/* </LoginContextProvider> */}
  </React.StrictMode>
            <div id='selectCustomPortal' className='text-xxs'></div>
  </>
);

