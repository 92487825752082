
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, entretien_delete_api, entretien_info_api, entretien_list_api } from '../../global/GlobalVars';
import Entretien from './Entretien';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDate, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetEntretienEmptyQuery, cleanGetEntretienQuery } from './Entretien';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'date', headerName: 'Date', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'libelle', headerName: 'Chauffeur', width: 150 },
  { field: 'dtepro', headerName: 'DTEPRO', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'mtdep', headerName: 'Ctre/mois', width: 100, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'typedep', headerName: 'TypeDep', width: 100 },
  { field: 'pr', headerName: 'PR', width: 50 },
  { field: 'pla_immatr', headerName: 'PLA_IMMATR', width: 100 },
  { field: 'idcompte', headerName: 'IDVEHICULE', width: 100 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetEntretiensListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['EntretienList'],
    queryFn: async () => await callAxiosApi(`${entretien_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddEntretienLine = (queryClient, handleCloseEntretien, handleRowSelectionEntretiensList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${entretien_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['EntretienList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addEntretienLine onSuccess');
      customConsoleLog(newprod);
      handleCloseEntretien();
      handleRowSelectionEntretiensList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateEntretienLine = (queryClient, handleCloseEntretien, handleRowSelectionEntretiensList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${entretien_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateEntretienLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateEntretienLine called')
      queryClient.setQueryData(['EntretienList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetEntretienQuery(newprod.id, queryClient);
      handleCloseEntretien();
      handleRowSelectionEntretiensList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteEntretienLine = (queryClient, handleCloseEntretien) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${entretien_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['EntretienList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetEntretienQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseEntretien();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetEntretiensListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['EntretienList'] });
}


const EntretiensList = forwardRef((props, ref) => {
  //function EntretiensList({ removeWidget, isModal }) {
  const { handleGetid_entretien, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openEntretienList, setOpenEntretienList] = useState(false);
  const [openEntretien, setOpenEntretien] = useState(false);

  const [actionNameEntretien, setActionNameEntretien] = useState("")
  const [selectEntretiensList, setSelectionEntretiensList] = useState([]);

  const handleRowSelectionEntretiensList = (e) => {
    if (e != selectEntretiensList[0]) {
      setSelectionEntretiensList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenEntretien = () => {
    setOpenEntretien(true);

    //BasicModalRef.current.open();
  }
  const handleCloseEntretien = () => {
    setOpenEntretien(false);
    //BasicModalRef.current.close();
    cleanGetEntretienEmptyQuery(queryClient);
    cleanGetEntretienQuery(selectEntretiensList[0], queryClient);
  }



  const [status_entretien, execute_entretien, resolve_entretien, reject_entretien, reset_entretien] = useAwaitableComponent();
  const [asyncConfirmDialogInfoEntretien, setConfirmDialogInfoEntretien] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogEntretiensList = status_entretien === "awaiting";

  const handleAwaitModalEntretiensList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoEntretien({ titre: mtitre, msg: mmsg });
      await execute_entretien();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_entretien();
    }
  };


  customConsoleLog('EntretiensList rendered')



  const { data: data_entretienslist = [], error: error_entretienslist,
    refetch: refecth_entretienslist, isLoading: isLoading_entretienslist, failureCount: failureCount_entretienslist } =
    GetEntretiensListQuery();






  const handleFiltrerEntretiensList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauEntretiensList = () => {

    clearToasts();
    setActionNameEntretien(btnActionsName.nouveau);
    setSelectionEntretiensList([])
    handleOpenEntretien();

  }

  const handleConsulterEntretiensList = () => {
    if (selectEntretiensList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameEntretien(btnActionsName.consulter);

    handleOpenEntretien();
    customConsoleLog(selectEntretiensList[0]);
  }

  const handleDupliquerEntretiensList = () => {
    if (selectEntretiensList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameEntretien(btnActionsName.dupliquer);
    handleOpenEntretien();
    customConsoleLog(selectEntretiensList[0]);
  }

  const handleModifierEntretiensList = () => {
    if (selectEntretiensList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameEntretien(btnActionsName.modifier);
    handleOpenEntretien();
    customConsoleLog(selectEntretiensList[0]);
  }


  const handleSupprimerEntretiensList = async () => {
    if (selectEntretiensList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectEntretiensList[0])
    if (await handleAwaitModalEntretiensList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameEntretien(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteEntretienLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenEntretiensList = (itemID) => {
    setOpenEntretienList(true);


    if(itemID) {
      handleRowSelectionEntretiensList([itemID]);
    }
  }


  const handleFermerEntretiensList = () => {
    if (selectEntretiensList[0]) {
      if (handleGetid_entretien) {

        handleGetid_entretien(selectEntretiensList[0]);
      }
    }
    cleanGetEntretiensListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetEntretiensListQuery(queryClient);
      cleanGetEntretienEmptyQuery(queryClient);
      cleanGetEntretienQuery(selectEntretiensList[0], queryClient);
      setOpenEntretienList(false);
    }
    else{

      setOpenEntretienList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_entretienslist.filter(item => item.id == id)[0];
  }



  const addEntretienLine = useAddEntretienLine(queryClient, handleCloseEntretien, handleRowSelectionEntretiensList);


  const updateEntretienLine = useUpdateEntretienLine(queryClient, handleCloseEntretien, handleRowSelectionEntretiensList);

  const deleteEntretienLine = useDeleteEntretienLine(queryClient, handleCloseEntretien);






  const DataTableEntretiensListRef = React.useRef(null);

  const handlePrintEntretiensList = () => {
    if (DataTableEntretiensListRef.current) {
      DataTableEntretiensListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenEntretiensList(itemID);
    },
    handleClose() {
      handleFermerEntretiensList();
    }
  }));

  // if (isLoading_entretienslist || error_entretienslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerEntretiensList}
  //         refetch={refecth_entretienslist}
  //         failureCount={failureCount_entretienslist}
  //         error={error_entretienslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openEntretienList} handleClose={handleFermerEntretiensList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_entretienslist || error_entretienslist
            ? <BasicLoading
              handleClose={handleFermerEntretiensList}
              refetch={refecth_entretienslist}
              failureCount={failureCount_entretienslist}
              error={error_entretienslist} />
            : <>
              {
                showAsyncConfirmDialogEntretiensList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogEntretiensList}
                  onSubmit={resolve_entretien}
                  onCancel={reject_entretien}
                  info={asyncConfirmDialogInfoEntretien}
                />
              }


              {
                openEntretien &&
                <BasicModal open={openEntretien} isModal={true}>
                  <Entretien
                    actionName={actionNameEntretien}
                    selectedItem={getSelecteditemByID(selectEntretiensList[0])}
                    handleClose={handleCloseEntretien}
                    addEntretienLine={addEntretienLine}
                    updateEntretienLine={updateEntretienLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Entretiens</h2>}

              <RedDataTableBasic
                ref={DataTableEntretiensListRef}
                rows={data_entretienslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionEntretiensList} 
                select={selectEntretiensList}
                onRowDoubleClick={handleConsulterEntretiensList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerEntretiensList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauEntretiensList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterEntretiensList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerEntretiensList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierEntretiensList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintEntretiensList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerEntretiensList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerEntretiensList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default EntretiensList



const EntretiensListwidgetKey = 'Entretienswidget'
export const EntretiensListwidget = { i: EntretiensListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: EntretiensList, titre: 'Entretiens', id_fenetre: 806 }
