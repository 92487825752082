
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, sousfamille_delete_api, sousfamille_info_api, sousfamille_list_api } from '../../global/GlobalVars';
import SousFamille from './SousFamille';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetSousFamilleEmptyQuery, cleanGetSousFamilleQuery } from './SousFamille';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 100 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
];
const hideColumns = { id: false }


export const GetSousFamillesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['SousFamilleList'],
    queryFn: async () => await callAxiosApi(`${sousfamille_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddSousFamilleLine = (queryClient, handleCloseSousFamille, handleRowSelectionSousFamillesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${sousfamille_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['SousFamilleList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addSousFamilleLine onSuccess');
      customConsoleLog(newprod);
      handleCloseSousFamille();
      handleRowSelectionSousFamillesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateSousFamilleLine = (queryClient, handleCloseSousFamille, handleRowSelectionSousFamillesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${sousfamille_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateSousFamilleLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateSousFamilleLine called')
      queryClient.setQueryData(['SousFamilleList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetSousFamilleQuery(newprod.id, queryClient);
      handleCloseSousFamille();
      handleRowSelectionSousFamillesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteSousFamilleLine = (queryClient, handleCloseSousFamille) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${sousfamille_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['SousFamilleList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetSousFamilleQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseSousFamille();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetSousFamillesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['SousFamilleList'] });
}


const SousFamillesList = forwardRef((props, ref) => {
  //function SousFamillesList({ removeWidget, isModal }) {
  const { handleGetid_sousfamille, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openSousFamilleList, setOpenSousFamilleList] = useState(false);
  const [openSousFamille, setOpenSousFamille] = useState(false);

  const [actionNameSousFamille, setActionNameSousFamille] = useState("")
  const [selectSousFamillesList, setSelectionSousFamillesList] = useState([]);

  const handleRowSelectionSousFamillesList = (e) => {
    if (e != selectSousFamillesList[0]) {
      setSelectionSousFamillesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenSousFamille = () => {
    setOpenSousFamille(true);

    //BasicModalRef.current.open();
  }
  const handleCloseSousFamille = () => {
    setOpenSousFamille(false);
    //BasicModalRef.current.close();
    cleanGetSousFamilleEmptyQuery(queryClient);
    cleanGetSousFamilleQuery(selectSousFamillesList[0], queryClient);
  }



  const [status_sousfamille, execute_sousfamille, resolve_sousfamille, reject_sousfamille, reset_sousfamille] = useAwaitableComponent();
  const [asyncConfirmDialogInfoSousFamille, setConfirmDialogInfoSousFamille] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogSousFamillesList = status_sousfamille === "awaiting";

  const handleAwaitModalSousFamillesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoSousFamille({ titre: mtitre, msg: mmsg });
      await execute_sousfamille();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_sousfamille();
    }
  };


  customConsoleLog('SousFamillesList rendered')



  const { data: data_sousfamilleslist = [], error: error_sousfamilleslist,
    refetch: refecth_sousfamilleslist, isLoading: isLoading_sousfamilleslist, failureCount: failureCount_sousfamilleslist } =
    GetSousFamillesListQuery();






  const handleFiltrerSousFamillesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauSousFamillesList = () => {

    clearToasts();
    setActionNameSousFamille(btnActionsName.nouveau);
    setSelectionSousFamillesList([])
    handleOpenSousFamille();

  }

  const handleConsulterSousFamillesList = () => {
    if (selectSousFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameSousFamille(btnActionsName.consulter);

    handleOpenSousFamille();
    customConsoleLog(selectSousFamillesList[0]);
  }

  const handleDupliquerSousFamillesList = () => {
    if (selectSousFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameSousFamille(btnActionsName.dupliquer);
    handleOpenSousFamille();
    customConsoleLog(selectSousFamillesList[0]);
  }

  const handleModifierSousFamillesList = () => {
    if (selectSousFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameSousFamille(btnActionsName.modifier);
    handleOpenSousFamille();
    customConsoleLog(selectSousFamillesList[0]);
  }


  const handleSupprimerSousFamillesList = async () => {
    if (selectSousFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectSousFamillesList[0])
    if (await handleAwaitModalSousFamillesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameSousFamille(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteSousFamilleLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenSousFamillesList = (itemID) => {
    setOpenSousFamilleList(true);


    if(itemID) {
      handleRowSelectionSousFamillesList([itemID]);
    }
  }


  const handleFermerSousFamillesList = () => {
    if (selectSousFamillesList[0]) {
      if (handleGetid_sousfamille) {

        handleGetid_sousfamille(selectSousFamillesList[0]);
      }
    }
    cleanGetSousFamillesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetSousFamillesListQuery(queryClient);
      cleanGetSousFamilleEmptyQuery(queryClient);
      cleanGetSousFamilleQuery(selectSousFamillesList[0], queryClient);
      setOpenSousFamilleList(false);
      
    }
    else {
      setOpenSousFamilleList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_sousfamilleslist.filter(item => item.id == id)[0];
  }



  const addSousFamilleLine = useAddSousFamilleLine(queryClient, handleCloseSousFamille, handleRowSelectionSousFamillesList);


  const updateSousFamilleLine = useUpdateSousFamilleLine(queryClient, handleCloseSousFamille, handleRowSelectionSousFamillesList);

  const deleteSousFamilleLine = useDeleteSousFamilleLine(queryClient, handleCloseSousFamille);






  const DataTableSousFamillesListRef = React.useRef(null);

  const handlePrintSousFamillesList = () => {
    if (DataTableSousFamillesListRef.current) {
      DataTableSousFamillesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenSousFamillesList(itemID);
    },
    handleClose() {
      handleFermerSousFamillesList();
    }
  }));

  // if (isLoading_sousfamilleslist || error_sousfamilleslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerSousFamillesList}
  //         refetch={refecth_sousfamilleslist}
  //         failureCount={failureCount_sousfamilleslist}
  //         error={error_sousfamilleslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openSousFamilleList} handleClose={handleFermerSousFamillesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_sousfamilleslist || error_sousfamilleslist
            ? <BasicLoading
              handleClose={handleFermerSousFamillesList}
              refetch={refecth_sousfamilleslist}
              failureCount={failureCount_sousfamilleslist}
              error={error_sousfamilleslist} />
            : <>
              {
                showAsyncConfirmDialogSousFamillesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogSousFamillesList}
                  onSubmit={resolve_sousfamille}
                  onCancel={reject_sousfamille}
                  info={asyncConfirmDialogInfoSousFamille}
                />
              }


              {
                openSousFamille &&
                <BasicModal open={openSousFamille} isModal={true}>
                  <SousFamille
                    actionName={actionNameSousFamille}
                    selectedItem={getSelecteditemByID(selectSousFamillesList[0])}
                    handleClose={handleCloseSousFamille}
                    addSousFamilleLine={addSousFamilleLine}
                    updateSousFamilleLine={updateSousFamilleLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">SousFamilles</h2>}

              <RedDataTableBasic
                ref={DataTableSousFamillesListRef}
                rows={data_sousfamilleslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionSousFamillesList} 
                select={selectSousFamillesList}
                onRowDoubleClick={handleConsulterSousFamillesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerSousFamillesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauSousFamillesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterSousFamillesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerSousFamillesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierSousFamillesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintSousFamillesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerSousFamillesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerSousFamillesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }

      </BasicModal>


    </>

  );
  //}
});





export default SousFamillesList



const SousFamillesListwidgetKey = 'SousFamilleswidget'
export const SousFamillesListwidget = { i: SousFamillesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: SousFamillesList, titre: 'SousFamilles', id_fenetre: 731 }
