
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, marque_delete_api, marque_info_api, marque_list_api } from '../../global/GlobalVars';
import Marque from './Marque';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDateTime, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetMarqueEmptyQuery, cleanGetMarqueQuery } from './Marque';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 200 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
  { field: 'saisie_le', headerName: 'SAISIE LE', width: 200, type: 'date', valueFormatter: (params) => formatDateTime(params.value, false, true) },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetMarquesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['MarqueList'],
    queryFn: async () => await callAxiosApi(`${marque_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddMarqueLine = (queryClient, handleCloseMarque, handleRowSelectionMarquesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${marque_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['MarqueList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addMarqueLine onSuccess');
      customConsoleLog(newprod);
      handleCloseMarque();
      handleRowSelectionMarquesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateMarqueLine = (queryClient, handleCloseMarque, handleRowSelectionMarquesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${marque_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateMarqueLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateMarqueLine called')
      queryClient.setQueryData(['MarqueList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetMarqueQuery(newprod.id, queryClient);
      handleCloseMarque();
      handleRowSelectionMarquesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteMarqueLine = (queryClient, handleCloseMarque) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${marque_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['MarqueList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetMarqueQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseMarque();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetMarquesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['MarqueList'] });
}


const MarquesList = forwardRef((props, ref) => {
  //function MarquesList({ removeWidget, isModal }) {
  const { handleGetid_marque, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openMarqueList, setOpenMarqueList] = useState(false);
  const [openMarque, setOpenMarque] = useState(false);

  const [actionNameMarque, setActionNameMarque] = useState("")
  const [selectMarquesList, setSelectionMarquesList] = useState([]);

  const handleRowSelectionMarquesList = (e) => {
    if (e != selectMarquesList[0]) {
      setSelectionMarquesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenMarque = () => {
    setOpenMarque(true);

    //BasicModalRef.current.open();
  }
  const handleCloseMarque = () => {
    setOpenMarque(false);
    //BasicModalRef.current.close();
    cleanGetMarqueEmptyQuery(queryClient);
    cleanGetMarqueQuery(selectMarquesList[0], queryClient);
  }



  const [status_marque, execute_marque, resolve_marque, reject_marque, reset_marque] = useAwaitableComponent();
  const [asyncConfirmDialogInfoMarque, setConfirmDialogInfoMarque] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogMarquesList = status_marque === "awaiting";

  const handleAwaitModalMarquesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoMarque({ titre: mtitre, msg: mmsg });
      await execute_marque();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_marque();
    }
  };


  customConsoleLog('MarquesList rendered')



  const { data: data_marqueslist = [], error: error_marqueslist,
    refetch: refecth_marqueslist, isLoading: isLoading_marqueslist, failureCount: failureCount_marqueslist } =
    GetMarquesListQuery();






  const handleFiltrerMarquesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauMarquesList = () => {

    clearToasts();
    setActionNameMarque(btnActionsName.nouveau);
    setSelectionMarquesList([])
    handleOpenMarque();

  }

  const handleConsulterMarquesList = () => {
    if (selectMarquesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMarque(btnActionsName.consulter);

    handleOpenMarque();
    customConsoleLog(selectMarquesList[0]);
  }

  const handleDupliquerMarquesList = () => {
    if (selectMarquesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMarque(btnActionsName.dupliquer);
    handleOpenMarque();
    customConsoleLog(selectMarquesList[0]);
  }

  const handleModifierMarquesList = () => {
    if (selectMarquesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMarque(btnActionsName.modifier);
    handleOpenMarque();
    customConsoleLog(selectMarquesList[0]);
  }


  const handleSupprimerMarquesList = async () => {
    if (selectMarquesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectMarquesList[0])
    if (await handleAwaitModalMarquesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameMarque(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteMarqueLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenMarquesList = (itemID) => {
    setOpenMarqueList(true);


    if(itemID) {
      handleRowSelectionMarquesList([itemID]);
    }
  }


  const handleFermerMarquesList = () => {
    if (selectMarquesList[0]) {
      if (handleGetid_marque) {

        handleGetid_marque(selectMarquesList[0]);
      }
    }
    cleanGetMarquesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetMarquesListQuery(queryClient);
      cleanGetMarqueEmptyQuery(queryClient);
      cleanGetMarqueQuery(selectMarquesList[0], queryClient);
      setOpenMarqueList(false);
    }
    else{

      setOpenMarqueList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_marqueslist.filter(item => item.id == id)[0];
  }



  const addMarqueLine = useAddMarqueLine(queryClient, handleCloseMarque, handleRowSelectionMarquesList);


  const updateMarqueLine = useUpdateMarqueLine(queryClient, handleCloseMarque, handleRowSelectionMarquesList);

  const deleteMarqueLine = useDeleteMarqueLine(queryClient, handleCloseMarque);






  const DataTableMarquesListRef = React.useRef(null);

  const handlePrintMarquesList = () => {
    if (DataTableMarquesListRef.current) {
      DataTableMarquesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenMarquesList(itemID);
    },
    handleClose() {
      handleFermerMarquesList();
    }
  }));

  // if (isLoading_marqueslist || error_marqueslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerMarquesList}
  //         refetch={refecth_marqueslist}
  //         failureCount={failureCount_marqueslist}
  //         error={error_marqueslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openMarqueList} handleClose={handleFermerMarquesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_marqueslist || error_marqueslist
            ? <BasicLoading
              handleClose={handleFermerMarquesList}
              refetch={refecth_marqueslist}
              failureCount={failureCount_marqueslist}
              error={error_marqueslist} />
            : <>
              {
                showAsyncConfirmDialogMarquesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogMarquesList}
                  onSubmit={resolve_marque}
                  onCancel={reject_marque}
                  info={asyncConfirmDialogInfoMarque}
                />
              }


              {
                openMarque &&
                <BasicModal open={openMarque} isModal={true}>
                  <Marque
                    actionName={actionNameMarque}
                    selectedItem={getSelecteditemByID(selectMarquesList[0])}
                    handleClose={handleCloseMarque}
                    addMarqueLine={addMarqueLine}
                    updateMarqueLine={updateMarqueLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Marques</h2>}

              <RedDataTableBasic
                ref={DataTableMarquesListRef}
                rows={data_marqueslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionMarquesList} 
                select={selectMarquesList}
                onRowDoubleClick={handleConsulterMarquesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerMarquesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauMarquesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterMarquesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerMarquesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierMarquesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintMarquesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerMarquesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerMarquesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default MarquesList



const MarquesListwidgetKey = 'Marqueswidget'
export const MarquesListwidget = { i: MarquesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: MarquesList, titre: 'Marques', id_fenetre: 802 }
