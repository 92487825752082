import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, ping_api, tva_info_api, tva_update_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import BasicNumberInput from '../../components/BasicNumberInput';
import { customConsoleLog } from '../../global/Tools';





const GetTvaEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetTvaEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetTvaQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetTvaQuery', itemID],
    queryFn: async () => await callAxiosApi(`${tva_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddTvaQuery = (handleClose, addTvaLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${tva_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddTvaQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Tva ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addTvaLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateTvaQuery = (itemID, updateTvaLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${tva_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateTvaQuery', itemID],
    onSuccess: () => {
      const msg = `Tva mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateTvaQuery called');

        updateTvaLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetTvaEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetTvaEmptyQuery'], exact: true });
}

export const cleanGetTvaQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanTvaGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetTvaQuery', itemID], exact: true });
}




function Tva({ actionName, selectedItem, handleClose, addTvaLine, updateTvaLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Tva compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetTvaEmptyQuery() : GetTvaQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addTva = useAddTvaQuery(handleClose, addTvaLine)
  const updateTva = useUpdateTvaQuery(itemID, updateTvaLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('useEffect called');
    }
  }, [data]);




  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkTva = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        addTva.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addTva.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateTva.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseTva = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }



  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      <div className="m-2 flex flex-row">
        <section className='w-[700px]'>
          <h2 className="text-lg font-bold mb-4">Tva</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-5 min-h-40 mt-8'>

            <BasicLabel className='text-xs' titre="Code :">
                <BasicInput id="code" name="code" className='text-xs' value={formValues.code || ''} onChange={handleChange} readOnly={isReadOnly} required />
              </BasicLabel>
              
              <BasicLabel className='text-xs' titre="Libelle :">
                <BasicInput id="libelle" name="libelle" className='text-xs' value={formValues.libelle || ''} onChange={handleChange} readOnly={isReadOnly} required />
              </BasicLabel>

              <BasicLabel className='text-xs' titre="TAUX :">
              <BasicNumberInput id="taux" name="taux" className='text-xs' value={formValues.taux || ''} onChange={handleChange} readOnly={isReadOnly} required />
              </BasicLabel>
            </div>
            
            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkTva}
                  loading={addTva.isPending || updateTva.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseTva}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Tva