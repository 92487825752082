import React from 'react'
import { checkString, cn  } from '../global/Tools';


const spanLabelClass = "whitespace-nowrap inline py-1 px-1 text-gray-600"
const inputClass = "bg-transparent border-transparent py-1 px-1 m-1 text-gray-600 focus:outline-none"
const inputReadOnlyClass = "bg-gray-100 border-transparent py-1 px-1 m-1 text-gray-600"


function BasicRadio(props) {
  const { titre, className, readOnly, value, selectedValue, labelClass, ...others } = props;

  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);
  const customSpanClass = cn(spanLabelClass, labelClass ? labelClass : '');
  
  
    return (
      <>
      <div className="inline-flex w-fit text-xs">
      <input type="radio" value={value} {...others} disabled={readOnly} className={`${customClass}`}  checked={selectedValue == value && true}/>
      {titre && <span className={customSpanClass}>{titre}</span>}
      </div>
      </>
    )
}

export default BasicRadio