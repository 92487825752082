
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, region_delete_api, region_info_api, region_list_api } from '../../global/GlobalVars';
import Region from './Region';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetRegionEmptyQuery, cleanGetRegionQuery } from './Region';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID Region', width: 120},
  { field: 'region', headerName: 'Region', width: 200 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { }


export const GetRegionsListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['RegionList'],
    queryFn: async () => await callAxiosApi(`${region_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddRegionLine = (queryClient, handleCloseRegion, handleRowSelectionRegionsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${region_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['RegionList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addRegionLine onSuccess');
      customConsoleLog(newprod);
      handleCloseRegion();
      handleRowSelectionRegionsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateRegionLine = (queryClient, handleCloseRegion, handleRowSelectionRegionsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${region_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateRegionLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateRegionLine called')
      queryClient.setQueryData(['RegionList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetRegionQuery(newprod.id, queryClient);
      handleCloseRegion();
      handleRowSelectionRegionsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteRegionLine = (queryClient, handleCloseRegion) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${region_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['RegionList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetRegionQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseRegion();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetRegionsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['RegionList'] });
}


const RegionsList = forwardRef((props, ref) => {
  //function RegionsList({ removeWidget, isModal }) {
  const { handleGetid_region, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openRegionList, setOpenRegionList] = useState(false);
  const [openRegion, setOpenRegion] = useState(false);

  const [actionNameRegion, setActionNameRegion] = useState("")
  const [selectRegionsList, setSelectionRegionsList] = useState([]);

  const handleRowSelectionRegionsList = (e) => {
    if (e != selectRegionsList[0]) {
      setSelectionRegionsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenRegion = () => {
    setOpenRegion(true);

    //BasicModalRef.current.open();
  }
  const handleCloseRegion = () => {
    setOpenRegion(false);
    //BasicModalRef.current.close();
    cleanGetRegionEmptyQuery(queryClient);
    cleanGetRegionQuery(selectRegionsList[0], queryClient);
  }



  const [status_region, execute_region, resolve_region, reject_region, reset_region] = useAwaitableComponent();
  const [asyncConfirmDialogInfoRegion, setConfirmDialogInfoRegion] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogRegionsList = status_region === "awaiting";

  const handleAwaitModalRegionsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoRegion({ titre: mtitre, msg: mmsg });
      await execute_region();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_region();
    }
  };


  customConsoleLog('RegionsList rendered')



  const { data: data_regionslist = [], error: error_regionslist,
    refetch: refecth_regionslist, isLoading: isLoading_regionslist, failureCount: failureCount_regionslist } =
    GetRegionsListQuery();






  const handleFiltrerRegionsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauRegionsList = () => {

    clearToasts();
    setActionNameRegion(btnActionsName.nouveau);
    setSelectionRegionsList([])
    handleOpenRegion();

  }

  const handleConsulterRegionsList = () => {
    if (selectRegionsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameRegion(btnActionsName.consulter);

    handleOpenRegion();
    customConsoleLog(selectRegionsList[0]);
  }

  const handleDupliquerRegionsList = () => {
    if (selectRegionsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameRegion(btnActionsName.dupliquer);
    handleOpenRegion();
    customConsoleLog(selectRegionsList[0]);
  }

  const handleModifierRegionsList = () => {
    if (selectRegionsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameRegion(btnActionsName.modifier);
    handleOpenRegion();
    customConsoleLog(selectRegionsList[0]);
  }


  const handleSupprimerRegionsList = async () => {
    if (selectRegionsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectRegionsList[0])
    if (await handleAwaitModalRegionsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameRegion(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteRegionLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenRegionsList = (itemID) => {
    setOpenRegionList(true);


    if(itemID) {
      handleRowSelectionRegionsList([itemID]);
    }
  }


  const handleFermerRegionsList = () => {
    if (selectRegionsList[0]) {
      if (handleGetid_region) {

        handleGetid_region(selectRegionsList[0]);
      }
    }
    cleanGetRegionsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetRegionsListQuery(queryClient);
      cleanGetRegionEmptyQuery(queryClient);
      cleanGetRegionQuery(selectRegionsList[0], queryClient);
      setOpenRegionList(false);
    }
    else{

      setOpenRegionList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_regionslist.filter(item => item.id == id)[0];
  }



  const addRegionLine = useAddRegionLine(queryClient, handleCloseRegion, handleRowSelectionRegionsList);


  const updateRegionLine = useUpdateRegionLine(queryClient, handleCloseRegion, handleRowSelectionRegionsList);

  const deleteRegionLine = useDeleteRegionLine(queryClient, handleCloseRegion);






  const DataTableRegionsListRef = React.useRef(null);

  const handlePrintRegionsList = () => {
    if (DataTableRegionsListRef.current) {
      DataTableRegionsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenRegionsList(itemID);
    },
    handleClose() {
      handleFermerRegionsList();
    }
  }));

  // if (isLoading_regionslist || error_regionslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerRegionsList}
  //         refetch={refecth_regionslist}
  //         failureCount={failureCount_regionslist}
  //         error={error_regionslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openRegionList} handleClose={handleFermerRegionsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_regionslist || error_regionslist
            ? <BasicLoading
              handleClose={handleFermerRegionsList}
              refetch={refecth_regionslist}
              failureCount={failureCount_regionslist}
              error={error_regionslist} />
            : <>
              {
                showAsyncConfirmDialogRegionsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogRegionsList}
                  onSubmit={resolve_region}
                  onCancel={reject_region}
                  info={asyncConfirmDialogInfoRegion}
                />
              }


              {
                openRegion &&
                <BasicModal open={openRegion} isModal={true}>
                  <Region
                    actionName={actionNameRegion}
                    selectedItem={getSelecteditemByID(selectRegionsList[0])}
                    handleClose={handleCloseRegion}
                    addRegionLine={addRegionLine}
                    updateRegionLine={updateRegionLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Regions</h2>}

              <RedDataTableBasic
                ref={DataTableRegionsListRef}
                rows={data_regionslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionRegionsList} 
                select={selectRegionsList}
                onRowDoubleClick={handleConsulterRegionsList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerRegionsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauRegionsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterRegionsList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerRegionsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierRegionsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintRegionsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerRegionsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerRegionsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default RegionsList



const RegionsListwidgetKey = 'Regionswidget'
export const RegionsListwidget = { i: RegionsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: RegionsList, titre: 'Regions', id_fenetre: 702 }
