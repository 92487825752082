import { logout_api } from "../../global/GlobalVars";
import { errorStr, showToastError } from "../../global/Tools";
import { ProblemDeConnectionMSG } from "../../global/GlobalText";
import { axiosClient, callAxiosApi } from "../../interceptors/axios";
import { useQuery } from "@tanstack/react-query";
import BasicLoading from "../../components/BasicLoading";

const logoutFunc = async () => {
  try {
    await axiosClient.post(
      logout_api,
      { withCredentials: true }
    );
    return {};
  } catch (error) {

    const errorMsg = errorStr(error.response?.data || ProblemDeConnectionMSG.localerreur.info[0]);
    showToastError(errorMsg, 5000, 'error');
    throw new Error(errorMsg);
  }
}

const LogoutQuery = () => {
  return useQuery({
    queryKey: ['LogoutQuery'],
    queryFn:  async () => await callAxiosApi(`${logout_api}`, '', true, 'post'),  //await logoutFunc(), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

export const Logout = () => {

    const {isLoading, error, refetch, failureCount} = LogoutQuery();

  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={refetch}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }

  
    window.location.href = '/login';
  

  // useEffect(() => {
    // (async () => {
    //     try {
    //         const { data } = await axios.post(`${logout_api}`, {
    //             refresh_token: localStorage.getItem('refresh_token')
    //         }, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         }, { withCredentials: true });

    //         customConsoleLog('logout', data)
    //         localStorage.clear();
    //         axios.defaults.headers.common['Authorization'] = null;
    //         window.location.href = '/login'
    //     } catch (error) {
    //         const errorMsg = error.response?.data.detail || ProblemDeConnectionMSG;
    //         showToast(errorMsg, 5000, 'error');
    //         customConsoleLog(errorMsg);
    //     }
    // })();

    
    // axiosClient.post(
    //   logout_api,
    //   { withCredentials: true }
    // ).then(function (response) {
    //   window.location.href = '/login'
    // }).catch((error) => {

    //   const errorMsg = errorStr(error.response?.data || ProblemDeConnectionMSG.localerreur.info[0]);
    //   showToastError(errorMsg, 5000, 'error');
    //   throw new Error(errorMsg);
    // });
    //logoutFunc();

    //return () => logoutFunc();
  // }, []);




  // customConsoleLog(data)
  // localStorage.clear();
  // localStorage.setItem('token', data.access);
  // localStorage.setItem('refresh_token', data.refresh);
  // axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
  // window.location.href = '/'


  // return (
  //   <div></div>
  // )
}