
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, fournisseur_delete_api, fournisseur_info_api, fournisseur_list_api } from '../../global/GlobalVars';
import Fournisseur from './Fournisseur';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetFournisseurEmptyQuery, cleanGetFournisseurQuery } from './Fournisseur';
import { cleanGetRegionsListQuery } from '../region/RegionsList';
import { cleanGetCategoriesListQuery } from '../categorie/CategoriesList';
import { cleanGetPayssListQuery } from '../pays/PayssList';
import { cleanGetRepresentantsListQuery } from '../representant/RepresentantsList';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 100 },
  { field: 'nom', headerName: 'Nom', width: 200 },
  { field: 'ice', headerName: 'ICE', width: 100 },
  { field: 'telephone', headerName: 'Telephone', width: 100 },
  { field: 'fax', headerName: 'FAX', width: 100 },
];
const hideColumns = { id: false }


export const GetFournisseursListQuery = (canFetch = true, order_by='code', id_etablissement=null) => {
  return useQuery({
    queryKey: ['FournisseurList'],
    queryFn: async () => {
      const params = {order_by: order_by, id_etablissement};
      return await callAxiosApi(`${fournisseur_list_api}`, {params}, true);
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddFournisseurLine = (queryClient, handleCloseFournisseur, handleRowSelectionFournisseursList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${fournisseur_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['FournisseurList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addFournisseurLine onSuccess');
      customConsoleLog(newprod);
      handleCloseFournisseur();
      handleRowSelectionFournisseursList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateFournisseurLine = (queryClient, handleCloseFournisseur, handleRowSelectionFournisseursList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${fournisseur_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateFournisseurLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateFournisseurLine called')
      queryClient.setQueryData(['FournisseurList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetFournisseurQuery(newprod.id, queryClient);
      handleCloseFournisseur();
      handleRowSelectionFournisseursList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteFournisseurLine = (queryClient, handleCloseFournisseur) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${fournisseur_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['FournisseurList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetFournisseurQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseFournisseur();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetFournisseursListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['FournisseurList'] });
}


const FournisseursList = forwardRef((props, ref) => {
  //function FournisseursList({ removeWidget, isModal }) {
  const { handleGetid_fournisseur, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openFournisseurList, setOpenFournisseurList] = useState(false);
  const [openFournisseur, setOpenFournisseur] = useState(false);

  const [actionNameFournisseur, setActionNameFournisseur] = useState("")
  const [selectFournisseursList, setSelectionFournisseursList] = useState([]);

  const handleRowSelectionFournisseursList = (e) => {
    if (e != selectFournisseursList[0]) {
      setSelectionFournisseursList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenFournisseur = () => {
    setOpenFournisseur(true);

    //BasicModalRef.current.open();
  }
  const handleCloseFournisseur = () => {
    setOpenFournisseur(false);
    //BasicModalRef.current.close();
    cleanGetFournisseurEmptyQuery(queryClient);
    cleanGetFournisseurQuery(selectFournisseursList[0], queryClient);
  }



  const [status_fournisseur, execute_fournisseur, resolve_fournisseur, reject_fournisseur, reset_fournisseur] = useAwaitableComponent();
  const [asyncConfirmDialogInfoFournisseur, setConfirmDialogInfoFournisseur] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogFournisseursList = status_fournisseur === "awaiting";

  const handleAwaitModalFournisseursList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoFournisseur({ titre: mtitre, msg: mmsg });
      await execute_fournisseur();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_fournisseur();
    }
  };


  customConsoleLog('FournisseursList rendered')



  const { data: data_fournisseurslist = [], error: error_fournisseurslist,
    refetch: refecth_fournisseurslist, isLoading: isLoading_fournisseurslist, failureCount: failureCount_fournisseurslist } =
    GetFournisseursListQuery();






  const handleFiltrerFournisseursList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauFournisseursList = () => {

    clearToasts();
    setActionNameFournisseur(btnActionsName.nouveau);
    setSelectionFournisseursList([])
    handleOpenFournisseur();

  }

  const handleConsulterFournisseursList = () => {
    if (selectFournisseursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameFournisseur(btnActionsName.consulter);

    handleOpenFournisseur();
    customConsoleLog(selectFournisseursList[0]);
  }

  const handleDupliquerFournisseursList = () => {
    if (selectFournisseursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameFournisseur(btnActionsName.dupliquer);
    handleOpenFournisseur();
    customConsoleLog(selectFournisseursList[0]);
  }

  const handleModifierFournisseursList = () => {
    if (selectFournisseursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameFournisseur(btnActionsName.modifier);
    handleOpenFournisseur();
    customConsoleLog(selectFournisseursList[0]);
  }


  const handleSupprimerFournisseursList = async () => {
    if (selectFournisseursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectFournisseursList[0])
    if (await handleAwaitModalFournisseursList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameFournisseur(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteFournisseurLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenFournisseursList = (itemID) => {
    setOpenFournisseurList(true);
    if(itemID) {
      handleRowSelectionFournisseursList([itemID]);
    }
  }


  const handleFermerFournisseursList = () => {
    if (selectFournisseursList[0]) {
      if (handleGetid_fournisseur) {

        handleGetid_fournisseur(selectFournisseursList[0]);
      }
    }
    cleanGetFournisseursListQuery(queryClient);
    cleanGetRegionsListQuery(queryClient);
    cleanGetCategoriesListQuery(queryClient);
    cleanGetPayssListQuery(queryClient);
    cleanGetRepresentantsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetFournisseursListQuery(queryClient);
      cleanGetFournisseurEmptyQuery(queryClient);
      cleanGetFournisseurQuery(selectFournisseursList[0], queryClient);
      setOpenFournisseurList(false);
    }
    else{
    setOpenFournisseurList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_fournisseurslist.filter(item => item.id == id)[0];
  }



  const addFournisseurLine = useAddFournisseurLine(queryClient, handleCloseFournisseur, handleRowSelectionFournisseursList);


  const updateFournisseurLine = useUpdateFournisseurLine(queryClient, handleCloseFournisseur, handleRowSelectionFournisseursList);

  const deleteFournisseurLine = useDeleteFournisseurLine(queryClient, handleCloseFournisseur);






  const DataTableFournisseursListRef = React.useRef(null);

  const handlePrintFournisseursList = () => {
    if (DataTableFournisseursListRef.current) {
      DataTableFournisseursListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenFournisseursList(itemID);
    },
    handleClose() {
      handleFermerFournisseursList();
    }
  }));

  // if (isLoading_fournisseurslist || error_fournisseurslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerFournisseursList}
  //         refetch={refecth_fournisseurslist}
  //         failureCount={failureCount_fournisseurslist}
  //         error={error_fournisseurslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openFournisseurList} handleClose={handleFermerFournisseursList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_fournisseurslist || error_fournisseurslist
            ? <BasicLoading
              handleClose={handleFermerFournisseursList}
              refetch={refecth_fournisseurslist}
              failureCount={failureCount_fournisseurslist}
              error={error_fournisseurslist} />
            : <>
              {
                showAsyncConfirmDialogFournisseursList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogFournisseursList}
                  onSubmit={resolve_fournisseur}
                  onCancel={reject_fournisseur}
                  info={asyncConfirmDialogInfoFournisseur}
                />
              }


              {
                openFournisseur &&
                <BasicModal open={openFournisseur} isModal={true}>
                  <Fournisseur
                    actionName={actionNameFournisseur}
                    selectedItem={getSelecteditemByID(selectFournisseursList[0])}
                    handleClose={handleCloseFournisseur}
                    addFournisseurLine={addFournisseurLine}
                    updateFournisseurLine={updateFournisseurLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Fournisseurs</h2>}

              <RedDataTableBasic
                ref={DataTableFournisseursListRef}
                rows={data_fournisseurslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionFournisseursList} 
                select={selectFournisseursList}
                onRowDoubleClick={handleConsulterFournisseursList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerFournisseursList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauFournisseursList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterFournisseursList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerFournisseursList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierFournisseursList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintFournisseursList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerFournisseursList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerFournisseursList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }

      </BasicModal>


    </>

  );
  //}
});





export default FournisseursList



const FournisseursListwidgetKey = 'Fournisseurswidget'
export const FournisseursListwidget = { i: FournisseursListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: FournisseursList, titre: 'Fournisseurs', id_fenetre: 507 }
