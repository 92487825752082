
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, acces_cuve_delete_api, acces_cuve_info_api, acces_cuve_list_api } from '../../global/GlobalVars';
import Acces_Cuve from './Acces_Cuve';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetAcces_CuveEmptyQuery, cleanGetAcces_CuveQuery } from './Acces_Cuve';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'id_utilisateur_nom', headerName: 'Utilisateur', width: 200 },
  { field: 'iddepot_code', headerName: 'CUVES', width: 100 },
  { field: 'iddepot_libelle', headerName: 'NOM CUVES', width: 250 },
  { field: 'id_utilisateur', headerName: 'ID Utilisateur', width: 100 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetAcces_CuvesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['Acces_CuveList'],
    queryFn: async () => await callAxiosApi(`${acces_cuve_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddAcces_CuveLine = (queryClient, handleCloseAcces_Cuve, handleRowSelectionAcces_CuvesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${acces_cuve_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['Acces_CuveList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addAcces_CuveLine onSuccess');
      customConsoleLog(newprod);
      handleCloseAcces_Cuve();
      handleRowSelectionAcces_CuvesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateAcces_CuveLine = (queryClient, handleCloseAcces_Cuve, handleRowSelectionAcces_CuvesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${acces_cuve_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateAcces_CuveLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateAcces_CuveLine called')
      queryClient.setQueryData(['Acces_CuveList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetAcces_CuveQuery(newprod.id, queryClient);
      handleCloseAcces_Cuve();
      handleRowSelectionAcces_CuvesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteAcces_CuveLine = (queryClient, handleCloseAcces_Cuve) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${acces_cuve_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['Acces_CuveList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetAcces_CuveQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseAcces_Cuve();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetAcces_CuvesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['Acces_CuveList'] });
}


const Acces_CuvesList = forwardRef((props, ref) => {
  //function Acces_CuvesList({ removeWidget, isModal }) {
  const { handleGetid_acces_cuve, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openAcces_CuveList, setOpenAcces_CuveList] = useState(false);
  const [openAcces_Cuve, setOpenAcces_Cuve] = useState(false);

  const [actionNameAcces_Cuve, setActionNameAcces_Cuve] = useState("")
  const [selectAcces_CuvesList, setSelectionAcces_CuvesList] = useState([]);

  const handleRowSelectionAcces_CuvesList = (e) => {
    if (e != selectAcces_CuvesList[0]) {
      setSelectionAcces_CuvesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenAcces_Cuve = () => {
    setOpenAcces_Cuve(true);

    //BasicModalRef.current.open();
  }
  const handleCloseAcces_Cuve = () => {
    setOpenAcces_Cuve(false);
    //BasicModalRef.current.close();
    cleanGetAcces_CuveEmptyQuery(queryClient);
    cleanGetAcces_CuveQuery(selectAcces_CuvesList[0], queryClient);
  }



  const [status_acces_cuve, execute_acces_cuve, resolve_acces_cuve, reject_acces_cuve, reset_acces_cuve] = useAwaitableComponent();
  const [asyncConfirmDialogInfoAcces_Cuve, setConfirmDialogInfoAcces_Cuve] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogAcces_CuvesList = status_acces_cuve === "awaiting";

  const handleAwaitModalAcces_CuvesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoAcces_Cuve({ titre: mtitre, msg: mmsg });
      await execute_acces_cuve();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_acces_cuve();
    }
  };


  customConsoleLog('Acces_CuvesList rendered')



  const { data: data_acces_cuveslist = [], error: error_acces_cuveslist,
    refetch: refecth_acces_cuveslist, isLoading: isLoading_acces_cuveslist, failureCount: failureCount_acces_cuveslist } =
    GetAcces_CuvesListQuery();






  const handleFiltrerAcces_CuvesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauAcces_CuvesList = () => {

    clearToasts();
    setActionNameAcces_Cuve(btnActionsName.nouveau);
    setSelectionAcces_CuvesList([])
    handleOpenAcces_Cuve();

  }

  const handleConsulterAcces_CuvesList = () => {
    if (selectAcces_CuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Cuve(btnActionsName.consulter);

    handleOpenAcces_Cuve();
    customConsoleLog(selectAcces_CuvesList[0]);
  }

  const handleDupliquerAcces_CuvesList = () => {
    if (selectAcces_CuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Cuve(btnActionsName.dupliquer);
    handleOpenAcces_Cuve();
    customConsoleLog(selectAcces_CuvesList[0]);
  }

  const handleModifierAcces_CuvesList = () => {
    if (selectAcces_CuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Cuve(btnActionsName.modifier);
    handleOpenAcces_Cuve();
    customConsoleLog(selectAcces_CuvesList[0]);
  }


  const handleSupprimerAcces_CuvesList = async () => {
    if (selectAcces_CuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectAcces_CuvesList[0])
    if (await handleAwaitModalAcces_CuvesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameAcces_Cuve(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteAcces_CuveLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenAcces_CuvesList = (itemID) => {
    setOpenAcces_CuveList(true);


    if(itemID) {
      handleRowSelectionAcces_CuvesList([itemID]);
    }
  }


  const handleFermerAcces_CuvesList = () => {
    if (selectAcces_CuvesList[0]) {
      if (handleGetid_acces_cuve) {

        handleGetid_acces_cuve(selectAcces_CuvesList[0]);
      }
    }
    cleanGetAcces_CuvesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetAcces_CuvesListQuery(queryClient);
      cleanGetAcces_CuveEmptyQuery(queryClient);
      cleanGetAcces_CuveQuery(selectAcces_CuvesList[0], queryClient);
      setOpenAcces_CuveList(false);
    }
    else{

      setOpenAcces_CuveList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_acces_cuveslist.filter(item => item.id == id)[0];
  }



  const addAcces_CuveLine = useAddAcces_CuveLine(queryClient, handleCloseAcces_Cuve, handleRowSelectionAcces_CuvesList);


  const updateAcces_CuveLine = useUpdateAcces_CuveLine(queryClient, handleCloseAcces_Cuve, handleRowSelectionAcces_CuvesList);

  const deleteAcces_CuveLine = useDeleteAcces_CuveLine(queryClient, handleCloseAcces_Cuve);






  const DataTableAcces_CuvesListRef = React.useRef(null);

  const handlePrintAcces_CuvesList = () => {
    if (DataTableAcces_CuvesListRef.current) {
      DataTableAcces_CuvesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenAcces_CuvesList(itemID);
    },
    handleClose() {
      handleFermerAcces_CuvesList();
    }
  }));

  // if (isLoading_acces_cuveslist || error_acces_cuveslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerAcces_CuvesList}
  //         refetch={refecth_acces_cuveslist}
  //         failureCount={failureCount_acces_cuveslist}
  //         error={error_acces_cuveslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openAcces_CuveList} handleClose={handleFermerAcces_CuvesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_acces_cuveslist || error_acces_cuveslist
            ? <BasicLoading
              handleClose={handleFermerAcces_CuvesList}
              refetch={refecth_acces_cuveslist}
              failureCount={failureCount_acces_cuveslist}
              error={error_acces_cuveslist} />
            : <>
              {
                showAsyncConfirmDialogAcces_CuvesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogAcces_CuvesList}
                  onSubmit={resolve_acces_cuve}
                  onCancel={reject_acces_cuve}
                  info={asyncConfirmDialogInfoAcces_Cuve}
                />
              }


              {
                openAcces_Cuve &&
                <BasicModal open={openAcces_Cuve} isModal={true}>
                  <Acces_Cuve
                    actionName={actionNameAcces_Cuve}
                    selectedItem={getSelecteditemByID(selectAcces_CuvesList[0])}
                    handleClose={handleCloseAcces_Cuve}
                    addAcces_CuveLine={addAcces_CuveLine}
                    updateAcces_CuveLine={updateAcces_CuveLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Acces_Cuves</h2>}

              <RedDataTableBasic
                ref={DataTableAcces_CuvesListRef}
                rows={data_acces_cuveslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionAcces_CuvesList} 
                select={selectAcces_CuvesList}
                onRowDoubleClick={handleConsulterAcces_CuvesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerAcces_CuvesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauAcces_CuvesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterAcces_CuvesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerAcces_CuvesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierAcces_CuvesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintAcces_CuvesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerAcces_CuvesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerAcces_CuvesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default Acces_CuvesList



const Acces_CuvesListwidgetKey = 'Acces_Cuveswidget'
export const Acces_CuvesListwidget = { i: Acces_CuvesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: Acces_CuvesList, titre: 'Acces_Cuves', id_fenetre: 902 }
