import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, ping_api, mvt_info_api, mvt_update_api, defaultSelectChargement, typeTiers, typeMvt, mvt_info_with_lignes_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ClientOrFournisseur, ConvertToInt, calculateHT, calculateQES, clearToasts, formatDate, formatDateTime, formatDecimalNumber, getImprimerFormat, getOnlyUpdatedValues, getParametreMvtNumbreColumn, getPrixArticle, getTextFromNumber, getTitreMvt, numToWords, showToast, showToastError, strIsNullOrEmpty, useGetFetchQuery } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog, { BasicAsyncConfirmDialogMVTprice } from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import BasicSelect from '../../components/BasicSelect';
import ArticlesList, { GetArticlesListQuery, invalidateGetArticlesListQuery } from '../article/ArticlesList';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import BasicNumberInput from '../../components/BasicNumberInput';
import BasicCheckBox from '../../components/BasicCheckBox';
import { GetClientsListQuery } from '../client/ClientsList';
import { GetFournisseursListQuery } from '../fournisseur/FournisseursList';
import { useLoginStore, useMvtInfoStore } from '../../stores/globalStore';
import { GetParametreQuery, invalidateGetParametreQuery } from '../parametre/Parametre';
import dayjs from 'dayjs';
import LignesMvt, { cleanGetLignesMvtsListQuery } from './LignesMvt';
import BasicDateInput from '../../components/BasicDateInput';
import { GetRepresentantsListQuery } from '../representant/RepresentantsList';
import BasicTextarea from '../../components/BasicTextarea';
import BasicContainer from '../../components/BasicContainer';
import BasicRadio from '../../components/BasicRadio';
import { GetMode_ReglementsListQuery } from '../mode_reglement/Mode_ReglementsList';
import { GetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import { customConsoleLog } from '../../global/Tools';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import BasicModal from '../../components/BasicModal';
import { GetArticle_FardeausListQuery } from '../article_fardeau/Article_FardeausList';
import { v4 as uuidv4 } from 'uuid';




const GetMvtEmptyQuery = (type_mvt, id_etablissement) => {
  return useQuery({
    queryKey: ['GetMvtEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetMvtQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetMvtQuery', itemID],
    queryFn: async () => await callAxiosApi(`${mvt_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetMvtWithLignesQuery = (params) => {
  return useQuery({
    queryKey: ['GetMvtWithLinesQuery', 0],
    queryFn: async () => await callAxiosApi(`${mvt_info_with_lignes_api}0`, JSON.stringify(params), true, 'post')
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}



const useAddMvtQuery = (handleClose, addMvtLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      vals.lignesmvtListValues.forEach((ligne) => {
        if (ligne?.id) {
          delete ligne.id;
        }
      });


      return await callAxiosApi(`${mvt_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddMvtQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Mvt ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addMvtLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {
      customConsoleLog('error', error)

    },

  })

}

const useUpdateMvtQuery = (itemID, updateMvtLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      customConsoleLog('useUpdateMvtQuery', vals);

      vals.lignesmvtListValues.forEach((ligne) => {
        if (ligne?.id) {
          delete ligne.id;
        }
      });

      return await callAxiosApi(`${mvt_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateMvtQuery', itemID],
    onSuccess: () => {
      const msg = `Mvt mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateMvtQuery called');

        updateMvtLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {

      customConsoleLog('error', error)
    },

  })
}

export const cleanGetMvtEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetMvtEmptyQuery'], exact: true });
}

export const cleanGetMvtQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanMvtGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetMvtQuery', itemID], exact: true });
}

export const cleanGetMvtWithLignesQuery = async (queryClient) => {
  customConsoleLog('cleanMvtGetQuery');
  queryClient.removeQueries({ queryKey: ['GetMvtWithLinesQuery'] });
}

export const getFinalsplitedMvts = (list_mvt, transformerEnFactureValues, loginDetails) => {
  const { max_price_pr_facture, id_reglement, date_facture, diff_jours, type_mvt, data_parametres } = transformerEnFactureValues;
  
  customConsoleLog('getFinalsplitedMvts', list_mvt);

  customConsoleLog('getFinalsplitedMvts', transformerEnFactureValues);



  if (!list_mvt) {
    return [];
  }

  // if max_price_pr_facture is 0 convert the mvt to the desired type without splitting it
  // combine all mvts lignes in one mvt
  

  let secureLoopCounter = 0;

  const tmpmvts = JSON.parse(JSON.stringify(list_mvt));

  // conbine all mvts lignes in one mvt
  let mvts = [
    { lignesmvtListValues: [],
    id_tiers: tmpmvts[0].id_tiers,
    tiers_code: tmpmvts[0].tiers_code,
    tiers_nom: tmpmvts[0].tiers_nom,}
  ];
  for (let i = 0; i < tmpmvts.length; i++) {
    for (let j = 0; j < tmpmvts[i].mvtLignes.length; j++) {
      mvts[0].lignesmvtListValues.push(tmpmvts[i].mvtLignes[j]);
    }
  }

  const newMvts = [];
  let mvtCounter = 1; // To prevent infinite loops
  let dateDebut = dayjs(date_facture);
  let actDays = 0;
  let refMvtStart = getParametreMvtNumbreColumn(data_parametres, type_mvt);
  let mvtTmpId = refMvtStart;

  const addDays = (date, days) => {
    return date.add(days, 'day');
  }

  mvts.forEach(mvt => {
    mvtTmpId++;
    let currentMvt = {
      date_mvt: formatDate(addDays(dateDebut, actDays * diff_jours), true),
      echeance: formatDate(addDays(dateDebut, actDays * diff_jours), true),
      en_tete: tmpmvts[0].en_tete,
      etat: '',
      ice: tmpmvts[0].ice,
      id_etablissement_de: tmpmvts[0].id_etablissement_de,
      id_etablissement_e: tmpmvts[0].id_etablissement_e,
      id_reglement: id_reglement,
      id_representant: tmpmvts[0].id_representant,
      id_tiers: tmpmvts[0].id_tiers,
      id_utilisateur: loginDetails.id,
      marque: false,
      lignesmvtListValues: [], // mvtLignes
      piece: '',
      pied: '',
      port: 0,
      ref_mvt: `${mvtTmpId}`,
      remise: 0,
      saisie_le: formatDateTime(dayjs(), true),
      total_ht: 0,
      total_ttc: 0,
      txtrem: 0,
      type_mvt: type_mvt,
      tiers_code: tmpmvts[0].tiers_code,
      tiers_nom: tmpmvts[0].tiers_nom,
      verifie: false,
      valide: false,
    };

    let currentTotal = 0;

    mvt.lignesmvtListValues.forEach(ligne => {
      const { qte, pu, nbp } = ligne;
      let ligneTotal = qte * pu;
      // remove id from ligne
      delete ligne.id;
      const oneqtePrice = ligneTotal / qte;
      const onenbpPrice = ligneTotal / nbp;
      let totalNbpConsumed = 0;
      let totalQteConsumed = 0;

      while (ligneTotal > 0 && mvtCounter <= 50) {
        const remainingCapacity = max_price_pr_facture - currentTotal;

        if (ligneTotal <= remainingCapacity || max_price_pr_facture == 0) {
          // Add the whole ligne to the current mvt
          const qteToAdd = qte - totalQteConsumed;
          const nbpToAdd = nbp - totalNbpConsumed;

          ligne.qte = qteToAdd;
          ligne.qes = calculateQES(type_mvt, qteToAdd);
          ligne.nbp = nbpToAdd;
          currentMvt.lignesmvtListValues.push({ ...ligne });
          currentTotal += ligneTotal;
          currentMvt.total_ht = formatDecimalNumber(calculateHT(currentTotal, 20), 2);
          currentMvt.total_ttc = formatDecimalNumber(currentTotal, 2);
          ligneTotal = 0; // Fully added
        } else {
          // Partially add the ligne
          const qteToAdd = Math.floor(remainingCapacity / oneqtePrice);
          const nbpToAdd = Math.floor(remainingCapacity / onenbpPrice);

          const partialLigne = {
            ...ligne,
            qte: qteToAdd,
            qes: calculateQES(type_mvt, qteToAdd),
            nbp: nbpToAdd,
          };
          currentMvt.lignesmvtListValues.push(partialLigne);
          totalNbpConsumed += nbpToAdd;
          totalQteConsumed += qteToAdd;

          currentTotal += qteToAdd * pu;
          ligneTotal -= qteToAdd * pu;
          ligne.qte -= qteToAdd;

          currentMvt.total_ttc = formatDecimalNumber(currentTotal, 2);

          // Start a new mvt
          newMvts.push(currentMvt);

          currentTotal = 0;
          mvtTmpId++;
          currentMvt = {

            date_mvt: formatDate(addDays(dateDebut, actDays * diff_jours), true),
            echeance: formatDate(addDays(dateDebut, actDays * diff_jours), true),
            en_tete: tmpmvts[0].en_tete,
            etat: '',
            ice: tmpmvts[0].ice,
            id_etablissement_de: tmpmvts[0].id_etablissement_de,
            id_etablissement_e: tmpmvts[0].id_etablissement_e,
            id_reglement: id_reglement,
            id_representant: tmpmvts[0].id_representant,
            id_tiers: tmpmvts[0].id_tiers,
            id_utilisateur: loginDetails.id,
            marque: false,
            lignesmvtListValues: [], // mvtLignes
            piece: '',
            pied: '',
            port: 0,
            ref_mvt: `${mvtTmpId}`,
            remise: 0,
            saisie_le: formatDateTime(dayjs(), true),
            total_ht: 0,
            total_ttc: 0,
            txtrem: 0,
            type_mvt: type_mvt,
            tiers_code: tmpmvts[0].tiers_code,
            tiers_nom: tmpmvts[0].tiers_nom,
            verifie: false,
            valide: false,

          };
        }

        mvtCounter++;
        actDays++;

      }
    });

    if (currentMvt.lignesmvtListValues.length > 0) {
      newMvts.push(currentMvt);
    }
  });

  // Validation
  // const isValid = newMvts.every(mvt => {
  //     const mvtTotal = mvt.mvtLignes.reduce((sum, ligne) => sum + ligne.ppttc, 0);
  //     return mvtTotal <= max_price_pr_facture;
  // });

  // if (!isValid) {
  //   customConsoleLog("getFinalsplitedMvts : The resulting mvts array is invalid.");
  // }

  customConsoleLog('getFinalsplitedMvts newMvts', newMvts);
  return newMvts;



}

export function AutoCreateFacture({ open, setOpen, setOpenParent, transformerEnFactureValues, loginDetails }) {
  const queryClient = useQueryClient();
  const [finalsplitedMvts, setFinalsplitedMvts] = React.useState([]);

  //const { list_mvt, max_price_pr_facture, id_reglement, date_facture, diff_jours } = transformerEnFactureValues;
  //const type_mvt = typeMvt.factureClient;
  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = GetMvtWithLignesQuery(transformerEnFactureValues);

  if (!isLoading && !isError) {
    customConsoleLog('AutoCreateFacture', data);
  }

  const handleClose = () => {
    setOpen(false);
    cleanGetMvtWithLignesQuery(queryClient);
  }


  const addMvt = useAddMvtQuery(handleClose, null);

  const handleCloseParent = () => {
    setOpenParent(false);
  }


  React.useEffect(() => {

    if (data && !isLoading) {
      setFinalsplitedMvts(getFinalsplitedMvts(data, transformerEnFactureValues, loginDetails));
    }
  }, [isLoading]);


  const handleSave = () => {
    customConsoleLog('handleSave');
    for (let i = 0; i < finalsplitedMvts.length; i++) {
      const lignesmvtListValues = finalsplitedMvts[i].lignesmvtListValues;
      // remove lignesmvtListValues from the mvt
      const tmpMvt = { ...finalsplitedMvts[i] };
      delete tmpMvt.lignesmvtListValues;
      const combinedValues = { ...tmpMvt, lignesmvtListValues };
      customConsoleLog('getFinalsplitedMvts handleSave', combinedValues);
      // normally we should wait for the mutation to finish before adding another mvt
      const res = addMvt.mutate(combinedValues);
      customConsoleLog('getFinalsplitedMvts handleSave res', res);
      
    }

  }

  //const finalsplitedMvts = getFinalsplitedMvts(data, transformerEnFactureValues);
  customConsoleLog('AutoCreateFacture finalsplitedMvts', finalsplitedMvts);


  customConsoleLog('AutoCreateFacture', open);

  const total = formatDecimalNumber(finalsplitedMvts?.reduce((sum, mvt) => sum + parseFloat(mvt.total_ttc), 0), 2);
  const tiers = finalsplitedMvts[0]?.tiers_code + ' - ' + finalsplitedMvts[0]?.tiers_nom;

  return (
    <>
      <BasicModal open={open} handleClose={() => handleClose} isModal={true} title='Transformer en Facture'>
        {
          finalsplitedMvts && finalsplitedMvts.length > 0 ?
            <div className='overflow-auto max-h-[400px]'>
              <div className='text-red-500'>Veuillez verifier les montants avant de continuer</div>
              <div>Nombre de Movement : { finalsplitedMvts.length }</div>
              <div>Total : {  total }</div>
              <div>Tiers : {  tiers }</div>
              <table className='w-full border border-gray-400'>
                <thead className='text-red-600'>
                  <tr>
                    <th className='p-1 text-xs border border-gray-400'>Ref</th>
                    <th className='p-1 text-xs border border-gray-400'>Date</th>
                    <th className='p-1 text-xs border border-gray-400'>Total TTC</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    finalsplitedMvts.map((mvt, index) => {
                      return (
                        <tr key={index}>
                          <td className='p-1 text-xs border border-gray-400 text-center'>{mvt.ref_mvt}</td>
                          <td className='p-1 text-xs border border-gray-400 text-center'>{formatDate(mvt.date_mvt, false, true)}</td>
                          <td className='p-1 text-xs border border-gray-400 text-right'>{mvt.total_ttc}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              
            </div>
            :
            <BasicLoading />
        }
        <div className='grid grid-cols-4 gap-1 mt-2'>
          <div className='col-span-2'> </div>
          <div >
            {finalsplitedMvts && finalsplitedMvts.length > 0 ?<BasicButton onClick={handleSave}>Enregistrer</BasicButton> : <div></div>}
          </div>
          <div>

            <BasicButton onClick={handleClose}>Fermer</BasicButton>
          </div>
        </div>
      </BasicModal>
    </>
  )
}


export function ArticlesLignesRecap({ mvtTmpInfo, isMsg, sendTextToParent, isTextOpen }) {
  // change mvtTmpInfo string to object
  const { jsonArticlesChanged, jsonArticlesNotChanged, mvtTotalTTC, mvtTiersInfo } = typeof mvtTmpInfo == 'string' ? JSON.parse(mvtTmpInfo) : mvtTmpInfo;

  const h2Style = 'text-blue-500 text-lg font-bold mt-4 p-1 border border-gray-400';
  const tableStyle = 'w-full border border-gray-400';

  const theadStyle = 'text-red-600';
  const trTheadStyle = '';
  const thStyle = 'p-1 text-xs border border-gray-400';

  const tbodyStyle = '';
  const trTbodyStyle = '';
  const tdStyle = 'p-1 text-xs border border-gray-400';



  const h22Style = 'text-lg font-bold mt-4 p-1 border border-gray-400';
  const thead2Style = 'text-green-600';

  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };

  const handleGetArticleInfo = async (e, itemID) => {

    e.preventDefault();
    customConsoleLog('handleGetArticleInfo', itemID);
    await handleAwaitModal('Article Info', 'Article Info');

  }

  const HandleSendTextToParent = (e, str) => {
    e.preventDefault();
    sendTextToParent(str);
  }


  return (
    <>
      <div className='text-xs'>{mvtTiersInfo?.code} : {mvtTiersInfo?.nom} , <strong>Total : {mvtTotalTTC}</strong></div>
      <h2 className={h2Style}>Prix modifier</h2>
      <table className={tableStyle}>
        <thead className={theadStyle}>
          <tr className={trTheadStyle}>
            {isMsg && <th className={thStyle}></th>}
            <th className={thStyle}>Code</th>
            <th className={thStyle}>Libelle</th>
            <th className={thStyle}>Unite</th>
            <th className={thStyle}>Nbp</th>
            <th className={thStyle}>Qte</th>
            <th className={thStyle}>Prix(anc.)</th>
            <th className={thStyle}>Total(anc.)</th>
          </tr>
        </thead>
        <tbody className={tbodyStyle}>
          {
            jsonArticlesChanged?.map((ligne, index) => {
              return (
                <tr className={trTbodyStyle} key={index}>
                  {isMsg && <td className={tdStyle}>
                    <a href='#' onClick={(e) => handleGetArticleInfo(e, ligne.id)}>
                      <MagnifyingGlassIcon className="h-4 w-4 text-blue-400 m-2" /></a>
                  </td>}
                  <td className={tdStyle}>
                    {
                      isTextOpen ?
                        <a href='#'
                          onDoubleClick={(e) => HandleSendTextToParent(e, String(ligne.code + " - " + ligne.libelle + " : "))}>
                          {ligne.code}
                        </a>
                        :
                        ligne.code
                    }
                  </td>
                  <td className={tdStyle}>{ligne.libelle}</td>
                  <td className={tdStyle}>{ligne.unite}</td>
                  <td className={tdStyle}>{ligne.qte}</td>
                  <td className={tdStyle}>{ligne.nbp}</td>
                  <td className={tdStyle}>{ligne.prix} ({ligne.prixOld})</td>
                  <td className={tdStyle}>{ligne.total} ({ligne.totalOld})</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {
        jsonArticlesNotChanged?.length > 0 &&
        <>
          <h2 className={h22Style}>Prix Non modifier</h2>
          <table className={tableStyle}>
            <thead className={thead2Style}>
              <tr className={trTheadStyle}>
                <th className={thStyle}>Code</th>
                <th className={thStyle}>Libelle</th>
                <th className={thStyle}>Unite</th>
                <th className={thStyle}>Nbp</th>
                <th className={thStyle}>Qte</th>
                <th className={thStyle}>Prix</th>
                <th className={thStyle}>Total</th>
              </tr>
            </thead>
            <tbody className={tbodyStyle}>
              {
                jsonArticlesNotChanged?.map((ligne, index) => {
                  return (
                    <tr className={trTbodyStyle} key={index}>
                      <td className={tdStyle}>{ligne.code}</td>
                      <td className={tdStyle}>{ligne.libelle}</td>
                      <td className={tdStyle}>{ligne.unite}</td>
                      <td className={tdStyle}>{ligne.qte}</td>
                      <td className={tdStyle}>{ligne.nbp}</td>
                      <td className={tdStyle}>{ligne.prix}</td>
                      <td className={tdStyle}>{ligne.total}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </>
      }
    </>
  )
}


function Mvt({ actionName, selectedItem, handleClose, addMvtLine, updateMvtLine, type_mvt, id_etablissement, uniqueMvtKey }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});

  const [lignesmvtList, setLignesmvtList] = React.useState([]);
  const [isHt, setIsHt] = React.useState(false);

  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  customConsoleLog('itemID', itemID);
  const isNew = itemID ? false : true;
  customConsoleLog('isNew', isNew);
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Mvt compo rendered');
  const isCliOrFr = ClientOrFournisseur(type_mvt);
  const tiersTitre = isCliOrFr == typeTiers.client ? 'Client' : 'Fournisseur';
  const representantTitre = 'Camion'; //isCliOrFr == typeTiers.client ? 'Camion' : '';

  const [tiersInfo, setTiersInfo] = React.useState({ nom: '', ville: '', ice: '', depotac: 0 });
  const [representantNom, setRepresentantNom] = React.useState('');




  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetMvtEmptyQuery(type_mvt, id_etablissement) : GetMvtQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers);


  // just to cache the data
  GetArticlesListQuery(canFetchOthers);

  GetArticle_FardeausListQuery(canFetchOthers);


  const {
    data: data_tiers = defaultSelectChargement,
    isLoading: isLoading_tiers } = isCliOrFr == typeTiers.client ? GetClientsListQuery(canFetchOthers, 'nom', id_etablissement) : GetFournisseursListQuery(canFetchOthers, 'nom', id_etablissement);

  const {
    data: data_representant = defaultSelectChargement,
    isLoading: isLoading_representant } = GetRepresentantsListQuery(canFetchOthers, 'nom');

  const { data: data_parametres = [],
    isLoading: isLoading_parametres, isFetching: isFetching_parametres, refetch: refetch_parametres } = GetParametreQuery(1, canFetchOthers);

  const { data: data_mode_reglement = [],
    isLoading: isLoading_mode_reglement } = GetMode_ReglementsListQuery(canFetchOthers, 'libelle');

  const {
    data: data_etablissement = defaultSelectChargement,
    isLoading: isLoading_etablissement, error: error_etablissement, refetch: refetch_etablissement,
    failureCount: failureCount_etablissement } = GetEtablissementsListQuery(canFetchOthers);

  const addMvt = useAddMvtQuery(handleClose, addMvtLine);
  const updateMvt = useUpdateMvtQuery(itemID, updateMvtLine, handleClose, data?.libelle);

  const modeReglementLibelle = formValues.id_reglement ? data_mode_reglement?.find((mode) => mode.id == formValues.id_reglement)?.libelle : '';



  const { g_mvtType, g_depot, g_Cat, setGCat, g_livraisonType } = useMvtInfoStore();


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const [status_confirm_mvtprice, execute_confirm_mvtprice, resolve_confirm_mvtprice, reject_confirm_mvtprice, reset_confirm_mvtprice] = useAwaitableComponent();
  const [asyncConfirmDialogInfo_mvtprice, setConfirmDialogInfo_mvtprice] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";
  const showAsyncConfirmDialog_mvtprice = status_confirm_mvtprice === "awaiting";

  const [isOutOfStock, setIsOutOfStock] = React.useState(false);

  const [blockSiDepacementQte, setBlockSiDepacementQte] = React.useState(false);

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };

  const handleAwaitModalMvtPrice = async (mtitre, mmsg, uniqueMvtKey, mvtKey, id_etablissement, mvtTmpInfo) => {

    try {
      setConfirmDialogInfo_mvtprice({ titre: mtitre, msg: mmsg, mvtKey: mvtKey, id_etablissement: id_etablissement, uniqueMvtKey: uniqueMvtKey, mvtTmpInfo: mvtTmpInfo });
      await execute_confirm_mvtprice();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm_mvtprice();
    }
  };



  React.useEffect(() => {
    if (data && !isNew) {
      setInitialData(data);
      setFormValues({
        ...data,
        imprimerFormat: getImprimerFormat(g_mvtType)
      });
      customConsoleLog('useEffect called');
    }
  }, [data]);


  React.useEffect(() => {
    invalidateGetArticlesListQuery(queryClient);
  }, []);




  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value);


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };


  const data_articleslist = useGetFetchQuery(['ArticleList']);

  const handleOkMvt = async (e) => {



    clearToasts();

    if (formValues.id_tiers == null || formValues.id_tiers == '') {
      showToastError(`Veuillez choisir un ${tiersTitre}`, 5000, 'error');
      return;
    }

    if (formValues.ref_mvt == null || formValues.ref_mvt == '') {
      showToastError(`Veuillez saisir une reference`, 5000, 'error');
      return;
    }

    const lignesmvtListValues = lignesmvtList.filter((ligne) => ligne.id_article);

    if (lignesmvtList.length == 0 || lignesmvtListValues.length == 0) {
      showToastError(`Veuillez ajouter des lignes`, 5000, 'error');
      return;
    }

    customConsoleLog('lignesmvtListValues', lignesmvtListValues);

    if(isOutOfStock && blockSiDepacementQte){
      showToastError(`Stock insuffisant!!`, 5000, 'error');
      return;
    }

    let str = '';
    let prixChanged = false;
    let jsonArticlesChanged = [];
    let jsonArticlesNotChanged = [];


    for (let i = 0; i < lignesmvtListValues.length; i++) {
      const ligne = lignesmvtListValues[i];
      const article = data_articleslist?.find((article) => article.id == ligne.id_article);
      if (ligne.ppttc != ligne.pu) {
        str += `Ligne ${i + 1} : "${article.libelle} : ${article.unite} - ${ligne.nbp} x ${ligne.qte} - ${ligne.pu} DH"\n`;
        prixChanged = true;
        jsonArticlesChanged.push({
          id: ligne.id_article, prix: ligne.pu, prixOld: ligne.ppttc, qte: ligne.qte, nbp: ligne.nbp,
          total: ligne.qte * ligne.pu, totalOld: ligne.qte * ligne.ppttc, code: article.code,
          libelle: article.libelle, unite: article.unite
        });
      }
      else {
        str += `Ligne non modifier ${i + 1} : "${article.libelle} : ${article.unite} - ${ligne.nbp} x ${ligne.qte} - ${ligne.pu} DH"\n`;
        jsonArticlesNotChanged.push({
          id: ligne.id_article, prix: ligne.pu, prixOld: ligne.ppttc, qte: ligne.qte, nbp: ligne.nbp,
          total: ligne.qte * ligne.pu, totalOld: ligne.qte * ligne.ppttc, code: article.code,
          libelle: article.libelle, unite: article.unite
        });
      }
    }

    if (prixChanged) {
      //const randomKey = Math.random().toString(36).substring(2,21);
      //const randomKey = crypto.randomUUID();
      const randomKey = uuidv4();
      const mvtTmpInfo = {
        jsonArticlesChanged, jsonArticlesNotChanged, mvtTotalTTC: formValues.total_ttc, mvtTiersInfo: {
          nom: tiersInfo.nom, id: formValues.id_tiers, code: formValues.id_tiers
        }
      };
      if (await handleAwaitModal('Enregistrer, Voulez vous continuer?', 'Prix modifier : \n' + str)) {
        if (await handleAwaitModalMvtPrice('Veuillez patienter', str, uniqueMvtKey, randomKey, g_depot, mvtTmpInfo)) {
          customConsoleLog('prix ok');
          if (actionName == btnActionsName.nouveau) {
            const updatedValues = { ...formValues, saisie_le: formatDateTime(dayjs(), true) };

            const combinedValues = { ...updatedValues, lignesmvtListValues };
            addMvt.mutate(combinedValues);

          }
          else {
            const updatedValues = getOnlyUpdatedValues(initialData, { ...formValues, saisie_le: formatDateTime(dayjs(), true) });

            const combinedValues = { ...updatedValues, lignesmvtListValues };

            updateMvt.mutate(combinedValues);
          }

        }
        else {
          customConsoleLog('prix annuler');
          return;

        }
      }
      else {
        customConsoleLog('prix annuler');

        return;
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer, Voulez vous continuer?', 'Enregistrer?')) {
        if (actionName == btnActionsName.nouveau) {
          const updatedValues = { ...formValues, saisie_le: formatDateTime(dayjs(), true) };

          const combinedValues = { ...updatedValues, lignesmvtListValues };
          addMvt.mutate(combinedValues);

        }
        else {
          const updatedValues = getOnlyUpdatedValues(initialData, { ...formValues, saisie_le: formatDateTime(dayjs(), true) });

          const combinedValues = { ...updatedValues, lignesmvtListValues };

          updateMvt.mutate(combinedValues);
        }
      }
      else {
        customConsoleLog('annuler');
        return;
      }
    }


    return;






  }

  const handleCloseMvt = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
      cleanGetLignesMvtsListQuery(queryClient);
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
        cleanGetLignesMvtsListQuery(queryClient);
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }

  const { loginDetails } = useLoginStore();


  React.useEffect(() => {
    if (actionName == btnActionsName.nouveau) {
      customConsoleLog('loginDetails', loginDetails.id_utilisateur);
      customConsoleLog('useEffect isNew g_mvtType', g_mvtType);
      setFormValues(prevState => ({
        ...prevState,
        type_mvt: g_mvtType,
        id_etablissement_de: g_depot,
        verifie: false,
        valide: false,
        marque: false,
        ref_mvt: '',
        etat: ' ',
        piece: '',
        id_utilisateur: loginDetails.id_utilisateur,
        id_representant: 1,
        date_mvt: formatDate(dayjs(), true),
        echeance: formatDate(dayjs(), true),
        txrem: 0,
        ice: '',
        en_tete: '',
        pied: '',
        remise: 0,
        port: 0,
        imprimerFormat: getImprimerFormat(g_mvtType),

      }));
    }
  }, []);



  React.useEffect(() => {
    customConsoleLog('lignesmvtList', lignesmvtList);
    // we should ignore empty rows
    const total_ttc = lignesmvtList.reduce((acc, item) => {
      if (item.id_article) {
        return acc + (item.pu * item.qte);
      }
      else {
        return acc;
      }
    }, 0);
    const total_ht = lignesmvtList.reduce((acc, item) => {
      if (item.id_article) {
        return acc + (item.puht * item.qte);
      }
      else {
        return acc;
      }
    }, 0);

    customConsoleLog('total_ttc', total_ttc);
    customConsoleLog('total_ht', total_ht);


    setFormValues(prevState => ({
      ...prevState,
      total_ttc: formatDecimalNumber(total_ttc),
      total_ht: formatDecimalNumber(total_ht),
    }));

  }, [lignesmvtList]);

  const total_tva = formatDecimalNumber(formValues.total_ttc - formValues.total_ht);







  React.useEffect(() => {

    const tiersTmp = data_tiers?.find((tiers) => tiers.id == formValues.id_tiers);

    if (isCliOrFr == typeTiers.client) {
      const tiersCat = tiersTmp?.id_cat;
      customConsoleLog('tiersCat', tiersCat);
      setGCat(tiersCat);
    }
    if (isCliOrFr == typeTiers.fournisseur) {
      setGCat(1);
    }

    if (formValues.id_tiers) {
      setTiersInfo({ nom: tiersTmp?.nom || '', ville: tiersTmp?.ville || '', ice: tiersTmp?.ville || '', depotac: tiersTmp?.depotac || 0 });
      if (tiersTmp?.ville) {
        setFormValues(prevState => ({
          ...prevState,
          ice: tiersTmp?.ville || '',
        }));
      }
      else {
        setFormValues(prevState => ({
          ...prevState,
          ice: '',
        }));
      }
    }

  }, [isCliOrFr, formValues.id_tiers, data_tiers]);

  React.useEffect(() => {
    const representantTmp = data_representant?.find((representant) => representant.id == formValues.id_representant);

    if (formValues.id_representant) {
      setRepresentantNom(representantTmp?.nom || '');
    }
  }, [formValues.id_representant]);


  React.useEffect(() => {
    if (actionName == btnActionsName.nouveau && data_parametres) {
      const ref_mvt = ConvertToInt(getParametreMvtNumbreColumn(data_parametres, g_mvtType)) + 1;
      setBlockSiDepacementQte(data_parametres.qtemaxi);
      setFormValues(prevState => ({
        ...prevState,
        ref_mvt: `${ref_mvt}`,
      }));
    }
  }, [isFetching_parametres, data_parametres]);


  React.useEffect(() => {
    const ttc = formValues.total_ttc || 0;
    const txrem = formValues.txrem || 0;
    const remise = ttc * txrem / 100;
    const port = Number(formValues.port || 0);
    const total_net = ttc - remise + port;


    setFormValues(prevState => ({
      ...prevState,
      total_net: total_net,
      remise: remise,
      port: port,
    }));

  }, [formValues.txrem, formValues.port, formValues.total_ttc]);

  // React.useEffect(() =>{
  //   customConsoleLog('isOutOfStock', isOutOfStock);

  // }, [isOutOfStock]);



  const handleOnRemiseChange = (e) => {
    const { name, value } = e.target;
    const ttc = formValues.total_ttc || 0;
    // Remise = the discount in decimal not the percentage
    const remise = value || 0;
    const port = Number(formValues.port || 0);
    const total_net = ttc - remise + port;
    const txrem = remise / ttc * 100;

    setFormValues(prevState => ({
      ...prevState,
      total_net: total_net,
      remise: remise,
      txrem: txrem,
    }));
  }


  const getNouveauRefMvt = () => {
    customConsoleLog('getNouveauRefMvt');
    refetch_parametres();
  }


  const handleIsHt = (e) => {
    setIsHt(e.target.checked);
  }

  const lignesmvtReadonly = isReadOnly;// || strIsNullOrEmpty(formValues.id_tiers);

  // React.useEffect(() => {
  //   customConsoleLog('imprimerFormat', getImprimerFormat(g_mvtType));

  // }, [g_mvtType, itemID]);

  const handleTotalNetClick = (e) => {
    const total_net = formValues.total_net || 0;
    //const total_net_words = getTextFromNumber(total_net);
    const total_net_words = 'Arrêtée la présente facture à la somme de ' + numToWords(total_net);
    customConsoleLog('total_net', total_net);
    customConsoleLog('total_net_words', total_net_words);
    customConsoleLog('isReadOnly', isReadOnly);
    customConsoleLog('type_mvt', type_mvt);
    if (isReadOnly || type_mvt != typeMvt.factureClient) return;

    setFormValues(prevState => ({
      ...prevState,
      pied: total_net_words,
    }));
  }




  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }




  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }
      {
        showAsyncConfirmDialog_mvtprice &&
        <BasicAsyncConfirmDialogMVTprice
          visible={showAsyncConfirmDialog_mvtprice}
          onSubmit={resolve_confirm_mvtprice}
          onCancel={reject_confirm_mvtprice}
          info={asyncConfirmDialogInfo_mvtprice}
        />
      }

      <div className="m-2 flex flex-row">
        <section className='w-[900px]'>
          <h2 className="text-lg font-bold mb-4">{getTitreMvt(g_mvtType)}</h2>
          <form onSubmit={handleSubmit}>
            {/* <div className='grid grid-cols-10'>
              <BasicButtonSmall className='text-xxs' onClick={() => { customConsoleLog('formValues', formValues) }}>Show formvalues</BasicButtonSmall>
              <BasicButtonSmall className='text-xxs' onClick={() => { customConsoleLog('parametre', data_parametres) }}>Show Parametre</BasicButtonSmall>
              <BasicButtonSmall className='text-xxs' onClick={() => getNouveauRefMvt()}>Nouveau Parametre
              </BasicButtonSmall>
              <div></div>
            </div> */}
            <div className='flex flex-col mt-8'>
              <input type="hidden" id="type_mvt" name="type_mvt" value={formValues.type_mvt || ''} onChange={handleChange} />

              <div className='grid grid-cols-5'>
                <BasicLabel className='text-xxs w-[150px]' titre="Ref :">
                  <BasicInput id="ref_mvt" name="ref_mvt" className='text-xxs' value={formValues.ref_mvt || ''} onChange={handleChange} readOnly={isReadOnly} required />
                </BasicLabel>

                <div></div>
                <BasicLabel className='text-xxs w-[150px]' titre='Date : '>
                  <BasicDateInput id="date_mvt" name="date_mvt" value={formValues.date_mvt || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>

                {
                  type_mvt == typeMvt.stockSF
                    ?
                    <>
                    </>
                    :
                    <>
                      <BasicLabel className='text-xxs w-[150px]' titre='Piece : '>
                        <BasicInput id="piece" name="piece" className='text-xxs' value={formValues.piece || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>
                    </>
                }

              </div>
              <div className='grid grid-cols-5 mt-1'>


                {
                  type_mvt == typeMvt.encoursPF || type_mvt == typeMvt.entreesPF
                    ?
                    <>
                      <div className='col-span-2'>
                        <BasicLabel className='text-xxs w-[100px]' titre={`${tiersTitre} :`}>
                          <BasicSelect id="id_tiers" name="id_tiers" className={`text-xxs`}
                            listItems={data_tiers || []} value={formValues.id_tiers || ''}
                            textKey={'code'}
                            fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'nom', title: 'Nom' }]}
                            onChange={handleChange}
                            isNotClearable={true}
                            isDisabled={isReadOnly}
                            hasError={strIsNullOrEmpty(formValues.id_tiers)} >
                          </BasicSelect>
                        </BasicLabel>
                      </div>
                      <BasicLabel className='text-xs italic text-blue-600' titre={`${tiersInfo.nom}`}></BasicLabel>
                    </>
                    :
                    <>


                      <div className='col-span-2'>
                        <BasicLabel className='text-xxs w-[100px]' titre={`${tiersTitre} :`}>
                          <BasicSelect id="id_tiers" name="id_tiers" className={`text-xxs`}
                            listItems={data_tiers || []} value={formValues.id_tiers || ''}
                            textKey={'code'}
                            fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'nom', title: 'Nom' }]}
                            onChange={handleChange}
                            isNotClearable={true}
                            isDisabled={isReadOnly}
                            hasError={strIsNullOrEmpty(formValues.id_tiers)} >
                          </BasicSelect>
                        </BasicLabel>
                      </div>
                      <BasicLabel className='text-xs italic text-blue-600' titre={`${tiersInfo.nom}`}></BasicLabel>
                    </>
                }
                <div></div>
                <div></div>
              </div>

              <div className='grid grid-cols-5 mt-1'>

                <BasicLabel className='text-xxs w-[150px]' titre={`${representantTitre} :`}>
                  <BasicSelect id="id_representant" name="id_representant" className={`text-xxs`}
                    listItems={data_representant || []} value={formValues.id_representant || ''}
                    textKey={'code'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'nom', title: 'Nom' }]}
                    onChange={handleChange}
                    isNotClearable={true}
                    isDisabled={isReadOnly}
                    hasError={strIsNullOrEmpty(formValues.id_representant)} >
                  </BasicSelect>
                </BasicLabel>
                <BasicLabel className='text-xs italic text-blue-600' titre={`${representantNom}`}></BasicLabel>
                <BasicLabel className='text-xxs [150px]' titre='Depot :'>
                  <BasicInput id="id_etablissement_de" name="id_etablissement_de" className='text-xxs' value={formValues.id_etablissement_de || ''} onChange={handleChange} readOnly={true} />
                </BasicLabel>
                {
                  type_mvt == typeMvt.transfertEntreDepot
                    ?
                    <>

                      <BasicLabel className='text-xxs [150px]' titre='Au Depot :'>
                        <BasicSelect id="id_etablissement_e" name="id_etablissement_e" className={`text-xxs`}
                          listItems={data_etablissement || []} value={formValues.id_etablissement_e || ''}
                          textKey={'code'}
                          fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                          onChange={handleChange}
                          isNotClearable={true}
                          isDisabled={isReadOnly}
                          hasError={strIsNullOrEmpty(formValues.id_etablissement_e)} >
                        </BasicSelect>
                      </BasicLabel>
                    </>
                    :
                    <>
                    </>
                }
              </div>

              <div className='grid grid-cols-5 mt-1'>
                {
                  type_mvt == typeMvt.stockSF || type_mvt == typeMvt.encoursPF || type_mvt == typeMvt.entreesPF || type_mvt == typeMvt.transfertEntreDepot
                    ?
                    <>
                      <div></div>
                    </>
                    :
                    <>
                      <BasicLabel className='text-xxs w-[150px]' titre='Reglement : '>
                        <BasicSelect id="id_reglement" name="id_reglement" className={`text-xxs`}
                          listItems={data_mode_reglement || []} value={formValues.id_reglement || ''}
                          textKey={'code'}
                          fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                          onChange={handleChange}
                          isNotClearable={false}
                          isDisabled={isReadOnly}
                        //hasError={strIsNullOrEmpty(formValues.id_reglement)} 
                        >
                        </BasicSelect>
                      </BasicLabel>
                      <BasicLabel className='text-xs italic text-blue-600' titre={`${modeReglementLibelle}`}></BasicLabel>
                    </>
                }
              </div>

              <div className='grid grid-cols-5 mt-1 gap-1'>
                {
                  type_mvt == typeMvt.stockSF || type_mvt == typeMvt.encoursPF || type_mvt == typeMvt.entreesPF || type_mvt == typeMvt.transfertEntreDepot
                    ?
                    <>
                      <div></div>
                    </>
                    :
                    <>
                      <BasicLabel className='text-xxs w-[150px]' titre='Echeance : '>
                        <BasicDateInput id="echeance" name="echeance" value={formValues.echeance || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </BasicLabel>
                    </>
                }

                <BasicInput id="en_tete" name="en_tete" className='text-xxs' value={formValues.en_tete || ''} onChange={handleChange} readOnly={isReadOnly} />
                <BasicInput id="ice" name="ice" className='text-xxs' value={formValues.ice || ''} onChange={handleChange} readOnly={isReadOnly} />
                <div className=''>
                  <div className='max-w-5 mx-auto'>
                    <BasicInput id="etat" name="etat" className='text-xxs bg-yellow-400' value={formValues.etat || ' '} onChange={handleChange} readOnly={isReadOnly} />
                  </div>
                </div>

                <div className='text-right'>
                  <BasicCheckBox titre={'HT'} checked={isHt} onChange={handleIsHt} />
                </div>
              </div>

            </div>
            <section className='mt-4'>
              {!strIsNullOrEmpty(formValues.id_tiers)
                ?
                <LignesMvt 
                  type_mvt={type_mvt} 
                  id_mvt={itemID}
                  lignesmvtList={lignesmvtList} 
                  setLignesmvtList={setLignesmvtList}
                  isHt={isHt} 
                  setIsOutOfStock={setIsOutOfStock}
                  isReadOnly={lignesmvtReadonly}
                  livraisonType={g_livraisonType} />
                :
                <BasicContainer className='text-red-600 text-xxs min-h-[140px]'>Veuillez choisir un {tiersTitre}</BasicContainer>}
            </section>
            <section className='mt-4'>
              <div className='grid grid-cols-5'>
                <div className='col-span-2'>
                  <div className='flex flex-row gap-2'>
                    <label className='text-xxs'>Escompte %
                      <BasicDecimalInput id="txrem" name="txrem" className='text-xxs' value={formValues.txrem || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </label>
                    <label className='text-xxs'>Mt Escompte
                      <BasicDecimalInput id="remise" name="remise" className='text-xxs' value={formValues.remise || ''} onChange={handleOnRemiseChange} readOnly={isReadOnly} />
                    </label>
                    <label className='text-xxs'>Transport
                      <BasicDecimalInput id="port" name="port" className='text-xxs' value={formValues.port || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </label>
                  </div>
                  <BasicTextarea id="pied" name="pied" rows="2" className='text-xs resize-none' value={formValues.pied || ''} onChange={handleChange} readOnly={isReadOnly} />

                  <BasicContainer className="mt-2 p-1 grid grid-cols-8 gap-1">
                    <BasicRadio type="radio" id="ent" name="imprimerFormat" value="ent" titre="Ent"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                    <BasicRadio type="radio" id="dev" name="imprimerFormat" value="dev" titre="Dev"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                    <BasicRadio type="radio" id="fac" name="imprimerFormat" value="fac" titre="Fac"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                    <BasicRadio type="radio" id="cde" name="imprimerFormat" value="cde" titre="Cde"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                    <BasicRadio type="radio" id="bon" name="imprimerFormat" value="bon" titre="Bon"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                    <BasicRadio type="radio" id="nom" name="imprimerFormat" value="nom" titre="Nom"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                    <BasicRadio type="radio" id="fav" name="imprimerFormat" value="fav" titre="FaV"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                    <BasicRadio type="radio" id="bl" name="imprimerFormat" value="bl" titre="BL"
                      selectedValue={formValues.imprimerFormat || ''} onChange={handleChange}
                      className="text-xxs m-0 p-0" labelClass="text-xxs" />
                  </BasicContainer>
                  <div className='grid grid-cols-5 gap-1 mt-1'>

                    <BasicButtonSmall className='text-xxs' onClick={() => {
                      customConsoleLog('imprimerFormat', formValues.imprimerFormat);
                    }}>Imprimer</BasicButtonSmall>
                    <BasicButtonSmall className='text-xxs col-span-2' onClick={() => { }}>Consulter Afficher</BasicButtonSmall>
                    <BasicButtonSmall className='text-xxs col-span-2' onClick={() => { }}>ExporterPDF</BasicButtonSmall>
                  </div>

                </div>
                <div className='col-span-2'>
                  <div className='mx-2 h-full'>
                    <div className='h-[100px]'>
                      <BasicButton type="button" onClick={() => { }}>Ok + imprimer <br />Direct 1 copies</BasicButton>

                    </div>
                    <div className='grid grid-cols-2 gap-2'>

                      {actionName != btnActionsName.consulter
                        ? <BasicButton type="button" onClick={handleOkMvt}
                          loading={addMvt.isPending || updateMvt.isPending}>{getButtonName(actionName)}</BasicButton>
                        : <div></div>
                      }
                      <BasicButton type="button" onClick={handleCloseMvt}>Fermer</BasicButton>
                    </div>
                  </div>

                </div>
                <div className='col-span-1'>
                  <div className='flex flex-col'>
                    <BasicLabel className='text-xs' titre="Total HT :">
                      <BasicDecimalInput id="total_ht" name="total_ht" className='text-xs bg-green-400' value={formValues.total_ht || ''} onChange={handleChange} readOnly={true} />
                    </BasicLabel>
                    <BasicLabel className='text-xs' titre="Total Tva :">
                      <BasicDecimalInput name="total_tva" className='text-xs bg-green-400' value={total_tva || ''} readOnly={true} />
                    </BasicLabel>
                    <BasicLabel className='text-xs' titre="Total TTC :">
                      <BasicDecimalInput id="total_ttc" name="total_ttc" className='text-xs bg-green-400' value={formValues.total_ttc || ''} onChange={handleChange} readOnly={true} />
                    </BasicLabel>
                    <BasicLabel className='text-xs' titre="Net a Payer :">
                      <BasicDecimalInput name="total_net" className='text-xs bg-yellow-400' value={formValues.total_net || ''} readOnly={true} onClick={handleTotalNetClick} />
                    </BasicLabel>
                  </div>
                </div>

              </div>
            </section>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Mvt


