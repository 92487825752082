import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, ping_api, sousfamille_info_api, sousfamille_update_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import { customConsoleLog } from '../../global/Tools';





const GetSousFamilleEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetSousFamilleEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetSousFamilleQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetSousFamilleQuery', itemID],
    queryFn: async () => await callAxiosApi(`${sousfamille_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddSousFamilleQuery = (handleClose, addSousFamilleLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${sousfamille_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddSousFamilleQuery', 0],
    onSuccess: (newprod) => {
      const msg = `SousFamille ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addSousFamilleLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateSousFamilleQuery = (itemID, updateSousFamilleLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${sousfamille_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateSousFamilleQuery', itemID],
    onSuccess: () => {
      const msg = `SousFamille mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateSousFamilleQuery called');

        updateSousFamilleLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetSousFamilleEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetSousFamilleEmptyQuery'], exact: true });
}

export const cleanGetSousFamilleQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanSousFamilleGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetSousFamilleQuery', itemID], exact: true });
}




function SousFamille({ actionName, selectedItem, handleClose, addSousFamilleLine, updateSousFamilleLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('SousFamille compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetSousFamilleEmptyQuery() : GetSousFamilleQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addSousFamille = useAddSousFamilleQuery(handleClose, addSousFamilleLine)
  const updateSousFamille = useUpdateSousFamilleQuery(itemID, updateSousFamilleLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('useEffect called');
    }
  }, [data]);




  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkSousFamille = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        addSousFamille.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addSousFamille.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateSousFamille.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseSousFamille = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }



  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      <div className="m-2 flex flex-row">
        <section className='w-[700px]'>
          <h2 className="text-lg font-bold mb-4">SousFamille</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-5 min-h-24 mt-8'>

              <BasicLabel className='text-xs' titre="Code :">
                <BasicInput id="code" name="code" className='text-xs' value={formValues.code || ''} onChange={handleChange} readOnly={isReadOnly} required />
              </BasicLabel>

              <BasicLabel className='text-xs' titre="Libelle :">
                <BasicInput id="libelle" name="libelle" className='text-xs' value={formValues.libelle || ''} onChange={handleChange} readOnly={isReadOnly} required />
              </BasicLabel>
            </div>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkSousFamille}
                  loading={addSousFamille.isPending || updateSousFamille.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseSousFamille}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default SousFamille