import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, getOnlyUpdatedValues, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import BasicLabel from '../../components/BasicLabel';
import BasicInput from '../../components/BasicInput';
import { btnActionsName, parametre_info_api, parametre_update_api, ping_api } from '../../global/GlobalVars';
import BasicTabs, { BasicTab, BasicTabPanel, BasicTabsList } from '../../components/BasicTabs';
import BasicCheckBox from '../../components/BasicCheckBox';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import BasicTextarea from '../../components/BasicTextarea';
import BasicContainer from '../../components/BasicContainer';
import BasicNumberInput from '../../components/BasicNumberInput';
import BasicRadio from '../../components/BasicRadio';
import { customConsoleLog } from '../../global/Tools';

// lets make a fake query and return a fake data without using useQuery
export const GetParametreAutoIncrementEmptyQuery = () => {
    return useQuery({
        queryKey: ['GetParametreAutoIncrementEmptyQuery'],
        queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
        refetchOnWindowFocus: false,
        retry: 2,
        cacheTime: 0,
    })
}

export const GetParametreAutoIncrementQuery = (itemID, canFetch = true) => {
    return useQuery({
        queryKey: ['GetParametreAutoIncrementQuery', itemID],
        queryFn: async () => await callAxiosApi(`${parametre_info_api}${itemID}`, '', true)
        ,
        refetchOnWindowFocus: false,
        retry: 2,
        cacheTime: 0,
        enabled: canFetch
    })
}

export const useUpdateParametreAutoIncrementQuery = (itemID, handleClose, itemName) => {
    return useMutation({
        mutationFn: async (vals) => {
            return await callAxiosApi(`${parametre_update_api}${itemID}`, JSON.stringify(vals), true, 'post');
        }
        ,
        mutationKey: ['UpdateParametreAutoIncrementQuery', itemID],
        onSuccess: () => {
            const msg = `ParametreAutoIncrement mise a jours avec succes`
            showToast(msg, 5000, 'succes');
            try {
                customConsoleLog('UpdateParametreAutoIncrementQuery called');

            }
            catch (e) {
                customConsoleLog(e)
            }
            handleClose(null, true);
        },
        onError: (error, variables, context) => {


        },

    })
}

export const cleanGetParametreAutoIncrementEmptyQuery = (queryClient) => {
    queryClient.removeQueries({ queryKey: ['GetParametreAutoIncrementEmptyQuery'], exact: true });
}

export const cleanGetParametreAutoIncrementQuery = async (itemID, queryClient) => {
    customConsoleLog('cleanParametreAutoIncrementGetQuery', itemID);
    queryClient.removeQueries({ queryKey: ['GetParametreAutoIncrementQuery', itemID], exact: true });
}

export const invalidateGetParametreAutoIncrementQuery = async (queryClient) => {
    customConsoleLog('invalidateGetParametreAutoIncrementQuery');
    queryClient.invalidateQueries({ queryKey: ['GetParametreAutoIncrementQuery'] });
}



const ParametreAutoIncrement = forwardRef((props, ref) => {
    const { handleGetid_parametre, isModal, removeWidget, isReadOnly, actionName, ...others } = props;
    const isReadOnlyLocal = isReadOnly || false;
    const queryClient = useQueryClient();
    const [openParametreAutoIncrement, setOpenParametreAutoIncrement] = useState(false);
    const [formValues, setFormValues] = React.useState({});
    const [initialData, setInitialData] = React.useState({});
    const itemID = 1;

    const {
        data,
        error,
        isError,
        isLoading,
        failureCount,
        refetch } = GetParametreAutoIncrementQuery(itemID);


    const canFetchOthers = !isLoading && !isError;
    customConsoleLog('canFetchOthers', canFetchOthers)



    React.useEffect(() => {
        if (data) {
            setInitialData(data);
            setFormValues(data);
            customConsoleLog('useEffect called');
        }
    }, [data]);


    const [status_parametreautoincrement, execute_parametreautoincrement, resolve_parametreautoincrement, reject_parametreautoincrement, reset_parametreautoincrement] = useAwaitableComponent();
    const [asyncConfirmDialogInfoParametreAutoIncrement, setConfirmDialogInfoParametreAutoIncrement] = useState({ titre: '', msg: '' });

    const showAsyncConfirmDialogParametreAutoIncrement = status_parametreautoincrement === "awaiting";

    const handleAwaitModalParametreAutoIncrement = async (mtitre, mmsg) => {

        try {
            setConfirmDialogInfoParametreAutoIncrement({ titre: mtitre, msg: mmsg });
            await execute_parametreautoincrement();
            return true;
        } catch (err) {
            return false;
        } finally {
            reset_parametreautoincrement();
        }
    };

    const handleFermerParametreAutoIncrement = async (event, closeAuto = false) => {
        customConsoleLog('handleFermerParametreAutoIncrement', closeAuto);
        if (!closeAuto) {
            if (await handleAwaitModalParametreAutoIncrement('Fermer!!', 'Etes vous sur?!')) {
                customConsoleLog('fermer');

            }
            else {
                customConsoleLog('annuler');
                return;
            }
        }


        cleanGetParametreAutoIncrementEmptyQuery(queryClient);
        cleanGetParametreAutoIncrementQuery(itemID, queryClient);

        if (removeWidget) {

            removeWidget();
            setOpenParametreAutoIncrement(false);
        }
        else {

            setOpenParametreAutoIncrement(false);
        }
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        customConsoleLog('handleChange ', value)


        setFormValues(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));

    };

    const handleAutoIncrement = (e) => {
        const { name, value, type, checked } = e.target;
        const cleanName = name.replace('_auto_increment', '');

        if (isNaN(value) || value === '') {
            setFormValues(prevState => ({
                ...prevState,
                [cleanName]: initialData[cleanName]
            }));
            customConsoleLog('Valeur non numerique');
            return;
        }
        customConsoleLog('handleAutoIncrement ', value)

        const newValue = parseInt(initialData[cleanName]) + parseInt(value);

        setFormValues(prevState => ({
            ...prevState,
            [cleanName]: newValue
        }));

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        customConsoleLog('SubmitEvent')
    };


    const handleOkParametreAutoIncrement = async (e) => {



        clearToasts();


        if (await handleAwaitModalParametreAutoIncrement('Enregistrer!!', 'Etes vous sur?!')) {
            customConsoleLog('modifier');
            updateParametreAutoIncrement.mutate(getOnlyUpdatedValues(initialData, formValues))
            //updateParametreAutoIncrement.mutate(formValues)

        }
        else {
            customConsoleLog('modifier annuler');
        }

    }

    const getButtonName = () => {
        switch (actionName) {
            case btnActionsName.consulter:
                return "";

            default:
                return "ok";
        }


    }

    const tabContentClass = "min-h-[290px] border rounded-sm border-gray-400 border-t-0 p-1  bg-slate-100";


    const updateParametreAutoIncrement = useUpdateParametreAutoIncrementQuery(itemID, handleFermerParametreAutoIncrement, data?.libelle)


    return (
        <>
            <BasicModal open={openParametreAutoIncrement} handleClose={handleFermerParametreAutoIncrement} isModal={isModal}>
                {
                    isLoading || isError
                        ?
                        <>
                            <BasicLoading
                                handleClose={handleFermerParametreAutoIncrement}
                                refetch={refetch}
                                failureCount={failureCount}
                                error={error} />
                        </>
                        :
                        <>
                            {
                                showAsyncConfirmDialogParametreAutoIncrement &&
                                <BasicAsyncConfirmDialog
                                    visible={showAsyncConfirmDialogParametreAutoIncrement}
                                    onSubmit={resolve_parametreautoincrement}
                                    onCancel={reject_parametreautoincrement}
                                    info={asyncConfirmDialogInfoParametreAutoIncrement}
                                />
                            }
                            {
                                <div className="m-2 flex flex-row">
                                    <section className=' w-full mr-4'>
                                        {isModal && <h2 className="text-lg font-bold mb-4">ParametreAutoIncrement</h2>}
                                        <form onSubmit={handleSubmit}>
                                            <div className='flex flex-col min-h-40 mt-2'>
                                                <div>
                                                    <div>Commandes :</div>
                                                    <div className='grid grid-cols-5 gap-2 max-w-[600px] mb-4'>
                                                        <div className='col-span-4'>
                                                        <BasicNumberInput id="num_bc_cli" name="num_bc_cli" className='text-xs' value={formValues.num_bc_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </div>
                                                        <div className='col-span-1'>
                                                        <BasicNumberInput id="num_bc_cli_auto_increment" name="num_bc_cli_auto_increment" className='text-xs' value={formValues.num_bc_cli_auto_increment || ''} onChange={handleAutoIncrement} readOnly={isReadOnlyLocal} />
                                                        </div>
                                                    </div>

                                                    <div>Bon de Livraison :</div>
                                                    <div className='grid grid-cols-5 gap-2 max-w-[600px] mb-4'>
                                                        <div className='col-span-4'>
                                                            <BasicNumberInput id="num_bl_cli" name="num_bl_cli" className='text-xs' value={formValues.num_bl_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </div>
                                                        <div className='col-span-1'>
                                                            <BasicNumberInput id="num_bl_cli_auto_increment" name="num_bl_cli_auto_increment" className='text-xs' value={formValues.num_bl_cli_auto_increment || ''} onChange={handleAutoIncrement} readOnly={isReadOnlyLocal} />
                                                        </div>
                                                    </div>

                                                    <div>Facture :</div>
                                                    <div className='grid grid-cols-5 gap-2 max-w-[600px] mb-4'>
                                                        <div className='col-span-4'>
                                                            <BasicNumberInput id="num_fac_cli" name="num_fac_cli" className='text-xs' value={formValues.num_fac_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </div>
                                                        <div className='col-span-1'>
                                                            <BasicNumberInput id="num_fac_cli_auto_increment" name="num_fac_cli_auto_increment" className='text-xs' value={formValues.num_fac_cli_auto_increment || ''} onChange={handleAutoIncrement} readOnly={isReadOnlyLocal} />
                                                        </div>
                                                    </div>
                                                        
                                                </div>

                                            </div>

                                            <div className="grid grid-cols-7 gap-2 mt-2">
                                                <div className='col-span-5'></div>
                                                {actionName != btnActionsName.consulter
                                                    ? <BasicButton type="button" onClick={handleOkParametreAutoIncrement}
                                                        loading={updateParametreAutoIncrement.isPending}>{getButtonName(actionName)}</BasicButton>
                                                    : <div></div>
                                                }
                                                <BasicButton type="button" onClick={handleFermerParametreAutoIncrement}>Fermer</BasicButton>
                                            </div>

                                        </form>

                                    </section>
                                </div>
                            }
                        </>
                }
            </BasicModal>
        </>
    )

});

export default ParametreAutoIncrement;


const ParametreAutoIncrementwidgetKey = 'ParametreAutoIncrementwidget'
export const ParametreAutoIncrementwidget = { i: ParametreAutoIncrementwidgetKey, x: 0, y: 0, w: 6, h: 10, component: (props) => <ParametreAutoIncrement {...props} />, titre: 'Parametre Auto', id_fenetre: 9999 }