import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { messages_list_api, msgTypes, rooms_list_api, message_update_api } from '../../global/GlobalVars';
import { callAxiosApi } from '../../interceptors/axios';
import BasicButton from '../../components/BasicButton';
import { customConsoleLog, formatDateTime, showToast } from '../../global/Tools';
import { useLoginStore, useMessageStore } from '../../stores/globalStore';
import BasicSelectList from '../../components/BasicSelectList';
import BasicInput from '../../components/BasicInput';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import { ArticlesLignesRecap } from '../mvt/Mvt';
import BasicTextarea from '../../components/BasicTextarea';





function BasicRoomsItems(props) {
    const { rooms_list, handleSelectRoom, selectRoomID, ...others } = props;


    return (
        <>
            {
                rooms_list.map((item, index) => {
                    return (
                        <div key={index} className={`cursor-pointer ${selectRoomID == item.id ? 'bg-blue-200' : 'bg-slate-50'} 
                    p-1 pl-3 hover:bg-blue-600`}
                            onClick={() => handleSelectRoom(item.id)}>
                            <div className="">
                                {item.room_title}
                                {selectRoomID == item.id ? ' ->' : ''}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )

}

export const GetMessageslatestUpdatedAtQuery = (canFetch = true, lastFetchedTimes = {}) => {
    return useQuery({
        queryKey: ['MessageslatestUpdatedAt'],
        queryFn: async () => {
            // const combinedData = Object.assign({}, ...data);
            // const params = { ...combinedData };
            return await callAxiosApi(`${messages_list_api}?latest_updated_at`, JSON.stringify(lastFetchedTimes), false, 'post')
        }
        ,
        refetchInterval: 10000,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 0,
        enabled: canFetch,
        
    })
}



export const GetRoomsListQuery = (canFetch = true, order_by = 'id', ...data) => {
    return useQuery({
        queryKey: ['RoomsList'],
        queryFn: async () => {
            const combinedData = Object.assign({}, ...data);
            const params = { order_by, ...combinedData };
            return await callAxiosApi(`${rooms_list_api}`, { params }, true)
        }
        ,
        refetchOnWindowFocus: false,
        retry: 2,
        cacheTime: 0,
        enabled: canFetch,
    })
}

export const GetMessagesListQuery = (canFetch = true, order_by = 'id', ...data) => {
    const combinedData = Object.assign({}, ...data);
    return useQuery({
        queryKey: ['MessagesList', `${combinedData.id_room}`],
        queryFn: async () => {
            const params = { order_by, ...combinedData };
            return await callAxiosApi(`${messages_list_api}`, { params }, false)
        }
        ,
        refetchOnWindowFocus: false,
        retry: 2,
        cacheTime: 0,
        enabled: canFetch,
        
    })
}

export const UpdateMessageQuery = (itemID, refecth_messageslist, handleClose, itemName) => {
    return useMutation({
        mutationFn: async (vals) => {
            return await callAxiosApi(`${message_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
        }
        ,
        mutationKey: ['UpdateMessageQuery', itemID],
        onSuccess: () => {
            const msg = `Message mise a jours avec succes`
            showToast(msg, 5000, 'succes');
            try {
                customConsoleLog('UpdateMessageQuery called');

                refecth_messageslist();
            }
            catch (e) {
                customConsoleLog(e)
            }

        },
        onError: (error, variables, context) => {


        },

    })
}

export const cleanGetRoomsListQuery = (queryClient) => {
    queryClient.removeQueries({ queryKey: ['RoomsList'] });
}


export const cleanGetMessagesListQuery = (queryClient) => {
    queryClient.removeQueries({ queryKey: ['MessagesList'] });
}



function MessagesList(props) {
    const queryClient = useQueryClient();
    const { handleGetid_messages, isModal, removeWidget, ...others } = props;
    const [openMessagesList, setOpenMessagesList] = useState(false);
    const [selectMessagesList, setSelectionMessagesList] = useState([]);
    const [selectRoomID, setSelectRoomID] = useState(0);
    const [msgTXT, setMsgTXT] = useState('');
    const [ resfuserTxtBool, setRefuserTxtBool] = useState(false);
    const [ refuserTxt, setRefuserTxt] = useState('');
    const [ selectedMessageId, setSelectedMessageId] = useState(0);
    const { lastFetchedTimes, setLastFetchedTimes } = useMessageStore();

    const { loginDetails } = useLoginStore();

    const { data: data_latest_updated_at = {}, error: error_latest_updated_at,
        refetch: refecth_latest_updated_at, isLoading: isLoading_latest_updated_at, isFetching: isFetching_latest_updated_at,
        failureCount: failureCount_latest_updated_at } =
        GetMessageslatestUpdatedAtQuery(true, { id_user: loginDetails.id, ...lastFetchedTimes });



    const { data: data_roomslist = [], error: error_roomslist,
        refetch: refecth_roomslist, isLoading: isLoading_roomslist, failureCount: failureCount_roomslist } =
        GetRoomsListQuery(true, 'room_title', { allowed_users: loginDetails.id });


    const { data: data_messageslist = [], error: error_messageslist, isSuccess: isSuccess_messageslist,
        refetch: refecth_messageslist, isLoading: isLoading_messageslist, failureCount: failureCount_messageslist } =
        GetMessagesListQuery(selectRoomID ? true : false, '-id', { id_room: selectRoomID });

    React.useEffect(() => {
        if(data_roomslist){
           for(let i=0; i<data_roomslist.length; i++){
               customConsoleLog('refecth_messageslist data_roomslist', data_roomslist[i]);
           }
        }

    }, [data_roomslist]);


    React.useEffect(() => {

    
        if (!isLoading_latest_updated_at) {
            const latest_updated_at = data_latest_updated_at.latest_updated_at;
            customConsoleLog('refecth_messageslist latest_updated_at', latest_updated_at);
            customConsoleLog('refecth_messageslist lastUpdatedAt', lastFetchedTimes);
            customConsoleLog('refecth_messageslist selectRoomID', selectRoomID);

            if(lastFetchedTimes.length > 0){
                let tester = false;
                for(let i=0; i<lastFetchedTimes.length; i++){
                    if(latest_updated_at[i].latest_updated_at != lastFetchedTimes[i].latest_updated_at){
                        tester = true;
                    }
                }
                if(tester){
                    if (selectRoomID) {
                        // lets update lastFetchedTimes.has_changes = false
                        refecth_messageslist();
                        setLastFetchedTimes(latest_updated_at);
                    }
                }
            }
            else{
                if (selectRoomID) {
                refecth_messageslist();
                setLastFetchedTimes(latest_updated_at);
                }
            }

            // for(let i=0; i<latest_updated_at.length; i++){
            //     customConsoleLog('refecth_messageslist latest_updated_at', latest_updated_at[i]);
            //     if(lastFetchedTimes.length > 0 && latest_updated_at[i].latest_updated_at != lastFetchedTimes[i].latest_updated_at){
            //         tester = true;
            //     }
            // }


            // if (latest_updated_at != lastFetchedTimes) {
            //     if (selectRoomID) {
            //         customConsoleLog('refecth_messageslist', '');
            //         refecth_messageslist();
            //         setLastFetchedTimes(latest_updated_at);
            //     }
            // }
        }
    }, [data_latest_updated_at]);

    // if(isSuccess_messageslist){
    //     const latest_updated_at = data_latest_updated_at.latest_updated_at;
    //     setLastUpdatedAt(latest_updated_at);
    // }


    const handleFermerMessagesList = () => {
        // if (selectMessagesList[0]) {
        //   if (handleGetid_mode_reglement) {

        //     handleGetid_mode_reglement(selectMessagesList[0]);
        //   }
        // }
        // cleanGetMode_ReglementsListQuery(queryClient);

        cleanGetMessagesListQuery(queryClient);
        cleanGetRoomsListQuery(queryClient);

        if (removeWidget) {

            removeWidget();
            //   cleanGetMode_ReglementsListQuery(queryClient);
            //   cleanGetMode_ReglementEmptyQuery(queryClient);
            //   cleanGetMode_ReglementQuery(selectMode_ReglementsList[0], queryClient);
            setOpenMessagesList(false);
        }
        else {

            setOpenMessagesList(false);
        }
    }

    const updateMessageLine = UpdateMessageQuery(selectRoomID, refecth_messageslist, handleFermerMessagesList, 'MessagesList');

    const handleEnvoyerMSG = () => {
        customConsoleLog('EnvoyerMSG', 'EnvoyerMSG');
        const vals = { id: 0, id_room: selectRoomID, id_user: loginDetails.id, msg_content: msgTXT, msg_type: msgTypes.txtmsg };
        updateMessageLine.mutate(vals);
    }

    const handleRefuserMSG = (refuBool, itemID) => {
        setRefuserTxt('');
        setRefuserTxtBool(refuBool);
        setSelectedMessageId(itemID);
    }

    const handleUpdateMessage = (id, state) => {
        customConsoleLog('handleUpdateMessage', id, state);
        const vals = { id, mvt_tmp_state: state, mvt_tmp_user: loginDetails.id, mvt_refuser_msg: refuserTxt };
        updateMessageLine.mutate(vals);
        handleRefuserMSG(false, 0);


    }

    const handleSelectRoom = (id) => {
        setMsgTXT('');
        setSelectRoomID(id);
        cleanGetMessagesListQuery(queryClient);
    }

    const handleTextChange = (e) => {
        setMsgTXT(e.target.value);
    }


    const handleOpenMode_MessagesList = () => {
        setOpenMessagesList(!openMessagesList);
    }

    const handleGetTextFromChild = (text) => {
        // check if refuserTxt is empty no need to add \n
        const str = refuserTxt ? refuserTxt + '\n' + text : text;
        setRefuserTxt(str);
    }

    customConsoleLog('RoomsList', data_roomslist);
    customConsoleLog('MessagesList', data_messageslist);
    return (
        <>
            <div className='grid grid-cols-12'>
                <div className='col-span-3 overflow-y-auto'>
                    <BasicRoomsItems rooms_list={data_roomslist}
                        handleSelectRoom={handleSelectRoom}
                        selectRoomID={selectRoomID} />
                </div>
                {
                    selectRoomID ?
                        <>
                            <div className='col-span-9 bg-slate-600'>
                                <div className='h-[420px] overflow-y-auto'>
                                    <div className='m-1'>
                                        {
                                            data_messageslist.map((item, index) => {
                                                const isMsgFromUser = item.id_user == loginDetails.id;
                                                const msgType = item.msg_type;
                                                customConsoleLog('isMsgFromUser', isMsgFromUser);
                                                if (msgType == msgTypes.txtmsg) {

                                                    if (isMsgFromUser) {
                                                        return (
                                                            <div className='ml-12 mb-6 rounded-lg '>
                                                                <div className='bg-green-300 rounded-t-lg p-1'>{item.id_user_name}
                                                                    <div className='float-right'>{formatDateTime(item.created_at, false, true)}</div>
                                                                </div>
                                                                <div className='bg-green-100 rounded-b-lg p-2'>{item.msg_content}</div>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div className='mr-12 mb-6 rounded-lg '>
                                                                <div className='bg-sky-300 rounded-t-lg p-1'>{item.id_user_name}
                                                                    <div className='float-right'>{formatDateTime(item.created_at, false, true)}</div></div>
                                                                <div className='bg-sky-100 rounded-b-lg p-2'>{item.msg_content}</div>
                                                            </div>
                                                        )

                                                    }
                                                }
                                                else if (msgType == msgTypes.mvtValidationmsg) {
                                                    return (
                                                        <div className='mr-12 mb-6 rounded-lg '>
                                                            <div className='bg-sky-300 rounded-t-lg p-1'>{item.id_user_name}
                                                                <div className='float-right'>{formatDateTime(item.created_at, false, true)}</div></div>
                                                            <div className='bg-sky-100 rounded-b-lg p-2'>
                                                                {/* {item.mvt_tmp_json} */}
                                                                <ArticlesLignesRecap 
                                                                mvtTmpInfo={item.mvt_tmp_json}
                                                                isMsg={true}
                                                                sendTextToParent={handleGetTextFromChild}
                                                                isTextOpen={resfuserTxtBool && selectedMessageId == item.id ? true : false}
                                                                />
                                                                {
                                                                    resfuserTxtBool && selectedMessageId == item.id &&
                                                                    <>
                                                                    <BasicTextarea className='w-full text-s my-1' value={refuserTxt || ''} onChange={(e) => setRefuserTxt(e.target.value)} />
                                                                    <BasicButtonSmall onClick={() => handleUpdateMessage(item.id, 1)}>Refuser</BasicButtonSmall>
                                                                    <BasicButtonSmall onClick={() => handleRefuserMSG(false, 0)}>Annuler</BasicButtonSmall>
                                                                    </>
                                                                }
                                                                {
                                                                    item.mvt_tmp_state == 0 && !resfuserTxtBool &&
                                                                    <p>
                                                                        <BasicButtonSmall onClick={() => handleRefuserMSG(true, item.id)}>Refuser</BasicButtonSmall>
                                                                        <BasicButtonSmall onClick={() => handleUpdateMessage(item.id, 2)}>Valider</BasicButtonSmall>
                                                                    </p>

                                                                }
                                                                {
                                                                    item.mvt_tmp_state == 1 &&
                                                                    <><p>Refusé par : {item.mvt_tmp_user_name}</p>
                                                                    <p>Raison : </p>
                                                                    <p>{item.mvt_refuser_msg}</p>
                                                                    </>
                                                                }
                                                                {
                                                                    item.mvt_tmp_state == 2 &&
                                                                    <p>Validé par : {item.mvt_tmp_user_name}</p>
                                                                }
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='grid grid-cols-12 gap-1'>
                                    <div className='col-span-10'>
                                        <BasicInput type="text" className='w-full text-s my-1' value={msgTXT} onChange={handleTextChange} />
                                    </div>
                                    <BasicButton id="envoyer" name="envoyer" className='col-span-2'
                                        onClick={handleEnvoyerMSG}>Envoyer</BasicButton>
                                </div>
                            </div>
                        </>
                        :
                        <div className='col-span-9'></div>
                }


            </div>
            <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFermerMessagesList} id="fermer" name="fermer">Fermer</BasicButton>
            </div>
        </>
    )
}

export default MessagesList

const MessagesListwidgetKey = 'Messageswidget'
export const MessagesListwidget = { i: MessagesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: MessagesList, titre: 'Messages', id_fenetre: 709 }