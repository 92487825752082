import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, permission_info_api, permission_update_api, ping_api, utilisateur_info_api, utilisateur_update_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedArrayValues, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import { customConsoleLog } from '../../global/Tools';
import { CheckBox } from '@mui/icons-material';
import RedEditDataTableBasic from '../../components/RedEditDataTableBasic';
import { act } from 'react';
import BasicSelect from '../../components/BasicSelect';
import BasicCheckBox from '../../components/BasicCheckBox';





const GetUtilisateurEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetUtilisateurEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetUtilisateurQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetUtilisateurQuery', itemID],
    queryFn: async () => await callAxiosApi(`${utilisateur_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddUtilisateurQuery = (handleClose, addUtilisateurLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${permission_update_api}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddUtilisateurQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Utilisateur ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addUtilisateurLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateUtilisateurQuery = (itemID, updateUtilisateurLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      //return await callAxiosApi(`${utilisateur_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
      return await callAxiosApi(`${permission_update_api}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateUtilisateurQuery', itemID],
    onSuccess: () => {
      const msg = `Utilisateur mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateUtilisateurQuery called');

        updateUtilisateurLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}


export const titletmpUtilisateurPermission = 'tmpUtilisateurPermission';

export const GetUtilisateurPermissionQuery = (canFetch = true, id_utilisateur, iddepots, id_fenetre, act, mKey = 'UtilisateurPermission') => {
  return useQuery({
    queryKey: [`${mKey}`, id_utilisateur],
    queryFn: async () => {
      // const params = {id:id_utilisateur, iddepots:iddepots, id_fenetre:id_fenetre, act:act}
      const params = { id_utilisateur: id_utilisateur };

      return await callAxiosApi(`${permission_info_api}`, params, true, 'post');
    },
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

export const cleanGetUtilisateurPermissionQuery = (queryClient, mKey = 'UtilisateurPermission') => {
  customConsoleLog('cleanGetUtilisateurPermissionQuery', `${mKey}`);
  queryClient.removeQueries({ queryKey: [`${mKey}`] });
}

export const cleanGetUtilisateurEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetUtilisateurEmptyQuery'], exact: true });
}

export const cleanGetUtilisateurQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanUtilisateurGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetUtilisateurQuery', itemID], exact: true });
}


function CustomCheckBoxComponent(props) {
  const { id, value, field, hasFocus, defaultkey } = props;

  return (
    <CheckBox id={id} name={id} value={value} />
  );
}


const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'xacce', headerName: 'Acce..', width: 60, type: 'boolean', editable: true, sortable: false, resizable: false, filterable: false, disableColumnMenu: true },
  { field: 'xajou', headerName: 'Ajou..', width: 60, type: 'boolean', editable: true, sortable: false, resizable: false, filterable: false, disableColumnMenu: true },
  { field: 'xcons', headerName: 'Cons..', width: 60, type: 'boolean', editable: true, sortable: false, resizable: false, filterable: false, disableColumnMenu: true },
  { field: 'ximpr', headerName: 'Impri..', width: 60, type: 'boolean', editable: true, sortable: false, resizable: false, filterable: false, disableColumnMenu: true },
  { field: 'xmodi', headerName: 'Modif..', width: 60, type: 'boolean', editable: true, sortable: false, resizable: false, filterable: false, disableColumnMenu: true },
  { field: 'xsupp', headerName: 'Supp..', width: 60, type: 'boolean', editable: true, sortable: false, resizable: false, filterable: false, disableColumnMenu: true },
  { field: 'xtous', headerName: 'Tous', width: 60, type: 'boolean', editable: true, sortable: false, resizable: false, filterable: false, disableColumnMenu: true },
  { field: 'id_fenetre', headerName: 'Ordre', width: 60, type: 'number', editable: false, sortable: true, resizable: false, filterable: true, disableColumnMenu: false },
  { field: 'id_fenetre_libelle', headerName: 'Option', width: 200, type: 'string', editable: false, sortable: true, resizable: true, filterable: true, disableColumnMenu: false },
];

const hideColumns = { id: false }



function Utilisateur({ actionName, selectedItem, handleClose, addUtilisateurLine, updateUtilisateurLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const [initialUtilisateurPermission, setInitialUtilisateurPermission] = React.useState([]);
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  const [selectedUtilisateurPermission, setSelectedUtilisateurPermission] = React.useState([]);
  const [utilisateurpermission, setUtilisateurPermission] = React.useState([]);

  const [columnDoubleClickTracker, setColumnDoubleClickTracker] = React.useState([
    { colfield: 'xacce', value: false },
    { colfield: 'xajou', value: false },
    { colfield: 'xcons', value: false },
    { colfield: 'ximpr', value: false },
    { colfield: 'xmodi', value: false },
    { colfield: 'xsupp', value: false },
    { colfield: 'xtous', value: false },
  ]);

  customConsoleLog('Utilisateur compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetUtilisateurEmptyQuery() : GetUtilisateurQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('Utilisateur canFetchOthers', canFetchOthers)

  const addUtilisateur = useAddUtilisateurQuery(handleClose, addUtilisateurLine);
  const updateUtilisateur = useUpdateUtilisateurQuery(itemID, updateUtilisateurLine, handleClose, data?.libelle);

  const tmpCanFetchOthers = canFetchOthers && !isNew;
  customConsoleLog('Utilisateur isNew', isNew);
  customConsoleLog('Utilisateur tmpCanFetchOthers', tmpCanFetchOthers);


  const { data: data_utilisateurpermission = [],
    isLoading: isLoading_UtilisateurPermission } = GetUtilisateurPermissionQuery(tmpCanFetchOthers, itemID, 0, 0, 'none', titletmpUtilisateurPermission);




  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('Debug useEffect called', data);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (data_utilisateurpermission['acces_fenetre'] && data_utilisateurpermission['acces_fenetre'].length > 0 && actionName != btnActionsName.nouveau) {
      setInitialUtilisateurPermission(data_utilisateurpermission['acces_fenetre']);
      setUtilisateurPermission(data_utilisateurpermission['acces_fenetre']);
      customConsoleLog('Debug data_utilisateurpermission[acces_fenetre]', data_utilisateurpermission['acces_fenetre']);
    }
  }, [data_utilisateurpermission['acces_fenetre']]);

  React.useEffect(() => {
    if (utilisateurpermission) {
      customConsoleLog('Debug utilisateurpermission', utilisateurpermission);
    }
  }, [utilisateurpermission]);


  const DataTableUtilisateurPermissionLignesRef = React.useRef(null);

  const handleRowSelectionUtilisateurPermission = (e) => {
    if (e != selectedUtilisateurPermission[0]) {
      setSelectedUtilisateurPermission(e);
      customConsoleLog(e);
    }
  }

  const onColumnHeaderDoubleClick = (e) => {
    customConsoleLog('Debug onColumnHeaderDoubleClick', e);
    const col = e.colDef.field;
    customConsoleLog('Debug col', col);
    // toggle rows values of this column
    //let newrows = [...utilisateurpermission];
    let newColumnDoubleClickTracker = [...columnDoubleClickTracker];

    const colIndex = newColumnDoubleClickTracker.findIndex(x => x.colfield === col);
    const newvalue = !newColumnDoubleClickTracker[colIndex].value;

    customConsoleLog('Debug newvalue', newvalue);
    // newrows.forEach(row => {
    //   row[col] = newvalue;
    // });
    newColumnDoubleClickTracker[colIndex].value = newvalue;
    setColumnDoubleClickTracker(newColumnDoubleClickTracker);
    setUtilisateurPermission((prev) => prev.map((row) => {
      return {
        ...row,
        [col]: newvalue
      }
    }));
    //customConsoleLog('Debug newrows', newrows);

  }


  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange name', name)
    customConsoleLog('handleChange value', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkUtilisateur = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');

        const tmpformvals = { ...formValues };
        const tmpPermissionvals = utilisateurpermission.map(permission => {
          const { id, ...rest } = permission;
          return rest;
        });

        if (tmpformvals?.id) {
          tmpformvals.id = 0;
        }

        const finalvals = {
          'formvals': tmpformvals,
          'permissionvals': tmpPermissionvals
        }

        addUtilisateur.mutate(finalvals);

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');

        const tmpformvals = { ...formValues };
        const tmpPermissionvals = utilisateurpermission.map(permission => {
          const { id, date_joined, ...rest } = permission;
          return rest;
        });

        if (tmpformvals?.id) {
          tmpformvals.id = 0;
        }

        const finalvals = {
          'formvals': tmpformvals,
          'permissionvals': tmpPermissionvals
        }
        addUtilisateur.mutate(finalvals)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        const tmpformvals = getOnlyUpdatedValues(initialData, formValues);
        const tmpPermissionvals = getOnlyUpdatedArrayValues(initialUtilisateurPermission, utilisateurpermission);
        const finalvals = {
          'formvals': tmpformvals,
          'permissionvals': tmpPermissionvals
        }
        customConsoleLog('utilisateurpermission utilisateurpermission', utilisateurpermission);
        customConsoleLog('handleAwaitModal finalvals', finalvals);
        updateUtilisateur.mutate(finalvals);

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseUtilisateur = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }



  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      <div className="m-2 flex flex-row">
        <section className='w-[700px]'>
          <h2 className="text-lg font-bold mb-4">Utilisateur</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-1 min-h-40 mt-8'>

              <BasicLabel className='text-xs' titre="Utilisateur :">
                <BasicInput id="username" name="username"
                  className='text-xs' value={formValues.username || ''}
                  onChange={handleChange} readOnly={isReadOnly} autoComplete="new-password" required />
              </BasicLabel>

              <BasicLabel className='text-xs' titre="Mots de passe :">
                <BasicInput id="password" name="password" type='password'
                  className='text-xs' value={formValues.password || ''} onChange={handleChange}
                  readOnly={isReadOnly} autoComplete="new-password" required />
              </BasicLabel>

              <BasicCheckBox
                  id="compta"
                  name="compta"
                  titre="Compta"
                  checked={formValues.compta || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  hidden={true}
                />
                <BasicCheckBox
                  id="immob"
                  name="immob"
                  titre="Immobilisation"
                  checked={formValues.immob || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  hidden={true}
                />
                <BasicCheckBox
                  id="paie"
                  name="paie"
                  titre="Paie"
                  checked={formValues.paie || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  hidden={true}
                />
              <div className='grid grid-cols-6'>
                <BasicCheckBox
                  id="tresor"
                  name="tresor"
                  titre="Tresorerie"
                  checked={formValues.tresor || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  required
                  labelClass='text-s'
                />
                <BasicCheckBox
                  id="gestion"
                  name="gestion"
                  titre="Gestion"
                  checked={formValues.gestion || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  required
                  labelClass='text-s'
                />
                <BasicCheckBox
                  id="tva"
                  name="tva"
                  titre="Tva"
                  checked={formValues.tva || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  required
                  labelClass='text-s'
                />
                <BasicCheckBox
                  id="outils"
                  name="outils"
                  titre="Outils"
                  checked={formValues.outils || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  required
                  labelClass='text-s'
                />
                <BasicCheckBox
                  id="admin"
                  name="admin"
                  titre="Administrateur"
                  checked={formValues.admin || ''}
                  onChange={handleChange}
                  readOnly={isReadOnly}
                  required
                  labelClass='text-s'
                />
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mt-8 mb-4">Permissions</h2>
              <div className='flex flex-col gap-1 min-h-40 mt-8'>
                {
                  actionName == btnActionsName.nouveau
                    ?
                    <>
                      <RedEditDataTableBasic
                        nofilter={true}
                        ref={DataTableUtilisateurPermissionLignesRef}
                        rows={[]}
                        setRows={setUtilisateurPermission}
                        columns={columns}
                        columnVisibilityModel={hideColumns}
                        handleRowSelection={handleRowSelectionUtilisateurPermission}
                        select={selectedUtilisateurPermission}
                        isReadOnly={isReadOnly}
                        canDelete={false}
                        height={'200px'}
                        onColumnHeaderDoubleClick={onColumnHeaderDoubleClick}
                        noNewRow={true}
                        disableColumnMenu={1}
                      />
                    </>
                    :
                    <>
                      <RedEditDataTableBasic
                        nofilter={true}
                        ref={DataTableUtilisateurPermissionLignesRef}
                        rows={utilisateurpermission}
                        setRows={setUtilisateurPermission}
                        columns={columns}
                        columnVisibilityModel={hideColumns}
                        handleRowSelection={handleRowSelectionUtilisateurPermission}
                        select={selectedUtilisateurPermission}
                        isReadOnly={isReadOnly}
                        canDelete={false}
                        height={'200px'}
                        onColumnHeaderDoubleClick={onColumnHeaderDoubleClick}
                        noNewRow={true}
                        disableColumnMenu={1}
                      />
                    </>
                }

              </div>
            </div>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkUtilisateur}
                  loading={addUtilisateur.isPending || updateUtilisateur.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseUtilisateur}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Utilisateur