import { create } from 'zustand'
import { createContext, useContext } from 'react'
import { typeTiers } from '../global/GlobalVars'


export const useLoginStore = create((set) => ({
    logged: false,
    loginDetails: {},
    loginPermission: [],
    setLogged: (isLogged) => set((state) => ({ logged: isLogged })),
    setLoginDetails: (loginInfo) => set((state) => ({ loginDetails: loginInfo })),

    setLogin: (isLogged, loginInfo, loginperm) => set((state) => ({ logged: isLogged, loginDetails: loginInfo, loginPermission: loginperm})),
    setLogout: () => set((state) => ({ logged: false, loginDetails: {}, loginPermission: [] })),
}))


export const useMvtInfoStore = create((set) => ({
    g_mvtType: '',
    g_CliOrFr: typeTiers.client,
    g_depot: 1,
    g_Cat: 1,
    g_autoOpen: false,
    g_livraisonType: '',
    setGMvtType: (type) => set((state) => ({ g_mvtType: type })),
    setGCliOrFr: (type) => set((state) => ({ g_CliOrFr: type })),
    setGDepot: (depot) => set((state) => ({ g_depot: depot })),
    setGCat: (cat) => set((state) => ({ g_Cat: cat })),
    setGAutoOpen: (autoOpen) => set((state) => ({ g_autoOpen: autoOpen })),
    setGLivraisonType: (livraisonType) => set((state) => ({g_livraisonType: livraisonType}))
}))

export const useMessageStore = create((set) => ({
    //lastUpdatedAt: 0.0,
    lastFetchedTimes: [],
    //setLastUpdatedAt: (time) => set((state) => ({ lastUpdatedAt: time })),
    setLastFetchedTimes: (times) => set((state) => ({ lastFetchedTimes: times })),
}))

// const { data: data_utilisateurpermission = [] } = GetUtilisateurPermissionQuery(selectUtilisateursList[0] ? true : false, 
//     selectUtilisateursList[0], 0, UtilisateursListFenetreInfo.id_fenetre, 'Filtrer');




// const LoginContext = createContext();

// export const useLoginContext = () => useContext(LoginContext);

// export const LoginContextProvider = ({ children }) => {
//     return (
//         <LoginContext.Provider value={useLoginStore}>
//             {children}
//         </LoginContext.Provider>
//     );
// };