import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { get_articles_img_api, articles_image_dir, upload_article_img_api } from '../global/GlobalVars';
import { callAxiosApi } from '../interceptors/axios';
import BasicButton from '../components/BasicButton';
import { showToast } from '../global/Tools';
import BasicSelectList from '../components/BasicSelectList';
import BasicInput from '../components/BasicInput';
import BasicLoading from '../components/BasicLoading';
import { customConsoleLog } from '../global/Tools';

const ImageUploadQuery = (refetch_imgs) => {
    return useMutation({
        mutationKey: ['ImageUploadQuery'],
        mutationFn: async (form_data) => await callAxiosApi(`${upload_article_img_api}`,form_data, true, 'post', {headers: {'content-type': 'multipart/form-data'}})
        // {
        //     try {
        //         const response = await axiosClient.post(
        //             `${upload_article_img_api}`, form_data,
        //             {
                        // withCredentials: true, headers: {'content-type': 'multipart/form-data'}
        //             }
        //         );
        //         return response.data;
        //     } catch (error) {

        //         const errorMsg = errorStr(error.response?.data || ProblemDeConnectionMSG.localerreur.info[0]);
        //         showToastError(errorMsg, 5000, 'error');
        //         throw new Error(errorMsg);
        //     }
        // }
        ,
        onSuccess: () => {
            const msg = `Image envoyer avec succes`
            showToast(msg, 5000, 'succes');
            refetch_imgs();
        },
        onError: () => {
            //setIsUpLoading(false);
        }


        // (form_data) => {
        //     setIsUpLoading(true)
        //     return axiosClient.post(upload_article_img_api, form_data, {
        //         headers: {
        //             'content-type': 'multipart/form-data'
        //         }
        //     })
        //         .then(res => {
        //             customConsoleLog(res.data);
        //             refetch_imgs();
        //         })
        //         .catch(err => customConsoleLog(err)).finally(() => setIsUpLoading(false));
        // }
    })
}

function ImageUploader({ handleDialogClose, handleGetImg_Lien }) {

    const [image, setImage] = useState(null);
    //const [isuploading, setIsUpLoading] = useState(false);
    const [imgSelected, setImgSelected] = useState("");
    const { data: data_imgs = [],
        error: error_imgs,
        isLoading: isLoading_imgs,
        failureCount: failureCount_imgs,
        refetch: refetch_imgs } = useQuery({
            queryKey: ['ArticleImagesList'],
            queryFn: async () => await callAxiosApi(`${get_articles_img_api}`,'', true)
            // {
            //     try {
            //         const response = await axiosClient.get(`${get_articles_img_api}`, {
            //             withCredentials: true,
            //         });
            //         return response.data;
            //     } catch (error) {

            //         const errorMsg = errorStr(error.response?.data || ProblemDeConnectionMSG.localerreur.info[0]);
            //         showToastError(errorMsg, 5000, 'error');
            //         throw new Error(errorMsg);
            //     }
            // }


            // () =>
            //     axiosClient.get(
            //         get_articles_img_api,
            //         { withCredentials: true }
            //     ).then(function (response) {
            //         customConsoleLog('then')
            //         return response.data;
            //     }).catch((error) => {
            //         customConsoleLog('error')

            //         //const errorMsg = error.response?.data.detail || error.response?.data.error || ProblemDeConnectionMSG;
            //         showToastError(error, 5000, 'error');
            //         return [];
            //     })
            ,
            refetchOnWindowFocus: false,
            retry: 2,
            cacheTime: 0,
        })

    const uploadImage = ImageUploadQuery(refetch_imgs);

    const handleSelectChange = (e) => {
        setImgSelected(e.target.value);
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        customConsoleLog(image);
        let form_data = new FormData();
        form_data.append('image', image);
        uploadImage.mutate(form_data);

    };

    return (
        <>
            <div className='min-h-[600px] min-w-[600px]'>
                {isLoading_imgs || error_imgs ?

                    <BasicLoading
                        handleClose={handleDialogClose}
                        refetch={refetch_imgs}
                        failureCount={failureCount_imgs}
                        error={error_imgs} />
                    :
                    <>
                        <div className="p-2">
                            <form onSubmit={handleSubmit}>
                                <div className='flex flex-row'>
                                    <BasicInput type="file"
                                        id="image"
                                        accept="image/png, image/jpeg"
                                        onChange={handleImageChange}
                                        required
                                    />
                                    <BasicButton
                                        type="submit"
                                        disabled={uploadImage.isPending}
                                    >{uploadImage.isPending ? 'Envoi en cours...' : 'Envoyer'}</BasicButton>
                                </div>
                            </form>
                        </div>
                        <div className='grid grid-cols-2 gap-1'>
                            <div className=''>


                                <BasicSelectList listItems={data_imgs} titre={'Images'} value={imgSelected} textKey={'libelle'} onChange={handleSelectChange} />

                            </div>
                            <div>
                                <span className='text-xxs'>{imgSelected}</span>
                                <img alt={`${imgSelected}`} src={`${articles_image_dir}${imgSelected}`} className='max-h-80 max-w-[300px]' />
                            </div>
                            {/* {data && data.map((img,index) => {
                    return <p key={index}>{img}</p>
                })} */}
                        </div>
                        <div className='absolute bottom-0 right-0 m-2'>
                            <BasicButton className="mr-2" onClick={() => handleGetImg_Lien(imgSelected)}>ok</BasicButton>
                            <BasicButton onClick={() => handleDialogClose()}>Fermer</BasicButton>
                        </div>

                    </>
                }


            </div>
        </>
    );
}

export default ImageUploader