import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import BasicButton from './BasicButton';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { customConsoleLog, showToastError, showToastErrorStill } from '../global/Tools';

import ReactDOM from 'react-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message_info_api, message_update_api, messages_list_api, msgTypes } from '../global/GlobalVars';
import { callAxiosApi } from '../interceptors/axios';
import { useLoginStore } from '../stores/globalStore';
import { ArticlesLignesRecap } from '../pages/mvt/Mvt';
import BasicLoadingAnim from './BasicLoadingAnim';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BasicAsyncConfirmDialog(props) {
    //React.useImperativeHandle
    //React.forwardRef
    // for child html create a local ref
    // https://www.youtube.com/watch?v=ndVIEMasBl8
    // https://github.com/cosdensolutions/code/tree/master/videos/long/learn-react-hooks-useImperativeHandle

    const { visible, onSubmit, onCancel, info } = props
    const { titre, msg } = info;


    // const display = visible ? "block" : "none";


    const handleSubmit = () => {
        onSubmit(true);
    };

    const handleCancel = () => {
        onCancel(false);
    };

    const splitMsg = msg.split('\n');
    const finalMsg = splitMsg.map((item, index) => {
        return <p className='p-1' key={index}>
            {item}
            </p>
    });

    return (
        ReactDOM.createPortal(
            <React.Fragment>
                <Dialog
                    open={visible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCancel}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        zIndex: 2000,
                    }}
                >
                    <DialogTitle >
                        <div className='flex flex-row items-center'>
                            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400 m-2" />
                            <span className='text-lg font-black'>{titre}</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {finalMsg ? finalMsg : msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <BasicButton className="" onClick={handleSubmit}>Oui</BasicButton>
                        <BasicButton className="" onClick={handleCancel}>Non</BasicButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>,
            document.body)
    );
}


export const GetMessageInfoQuery = (canFetch = true, order_by = 'id', ...data) => {
    const combinedData = Object.assign({}, ...data);
    return useQuery({
        queryKey: ['MessageInfo', `${combinedData.mvt_tmp_key}`],
        queryFn: async () => {
            const params = { order_by, ...combinedData };
            return await callAxiosApi(`${message_info_api}0`, { params }, false)
        }
        ,
        refetchInterval: 10000,
        refetchOnWindowFocus: true,
        retry: 2,
        cacheTime: 0,
        enabled: canFetch,
        
    })
}

export const UpdateMessageInfoQuery = (itemID, refecth_messageInfo) => {
    return useMutation({
        mutationFn: async (vals) => {
            return await callAxiosApi(`${message_update_api}${0}`, JSON.stringify(vals), true, 'post');
        }
        ,
        mutationKey: ['UpdateMessageInfo', itemID],
        onSuccess: () => {
            refecth_messageInfo();
        },
        onError: (error, variables, context) => {
        },
    })
}


export function BasicAsyncConfirmDialogMVTprice(props) {
    //React.useImperativeHandle
    //React.forwardRef
    // for child html create a local ref
    // https://www.youtube.com/watch?v=ndVIEMasBl8
    // https://github.com/cosdensolutions/code/tree/master/videos/long/learn-react-hooks-useImperativeHandle

    const { visible, onSubmit, onCancel, info } = props
    const { titre, msg, mvtKey, uniqueMvtKey, id_etablissement, mvtTmpInfo } = info;
    const queryClient = useQueryClient();
    const { loginDetails } = useLoginStore();
    const { mvtValider, setMvtValider } = useState(false);
    const { btnText, setBtnText } = useState('');
    const { btnShow, setBtnShow } = useState(false);

    const { data: data_messageInfo = [], error: error_messageInfo, isSuccess: isSuccess_messageInfo, isFetching: isFetching_messageInfo, 
        refetch: refecth_messageInfo, isLoading: isLoading_messageInfo, failureCount: failureCount_messageInfo } =
        GetMessageInfoQuery(true, '-id', {mvt_tmp_key:mvtKey});

    const updateMessageLine = UpdateMessageInfoQuery( mvtKey, refecth_messageInfo);
    
    // const display = visible ? "block" : "none";

    React.useEffect(() => {
        if(mvtKey === '') return;
        const vals = { is_MvtPrice:1 , id_etablissement:id_etablissement, id_user: loginDetails.id, msg_content: msg, 
            msg_title: mvtKey, msg_type: msgTypes.mvtValidationmsg, mvt_tmp_key: mvtKey, mvt_tmp_state:0, mvt_tmp_unique_key: uniqueMvtKey, is_NewRequest:1, mvt_tmp_json: JSON.stringify(mvtTmpInfo) };
        updateMessageLine.mutate(vals);
    }, []);

    useEffect(() => {
        customConsoleLog('BasicAsyncConfirmDialogMVTprice', data_messageInfo);
        if (!isFetching_messageInfo) {

            // if (data) {
                // if(data.mvt_tmp_state > 1){
                //     setBtnText((prev) => 'Valider!');
                //     setMvtValider((prev) =>true);
                //     setBtnShow((prev) =>true);
                // }
                // else if(data.mvt_tmp_state == 1){
                //     setBtnText((prev) =>'Refuser!');
                //     setMvtValider((prev) =>false);
                //     setBtnShow((prev) =>true);
                // }
                // else{
                //     setBtnText((prev) =>'');
                //     setBtnShow((prev) =>false);
                // }
                if(data_messageInfo.mvt_tmp_state > 1){
                onSubmit(true);
                }
                else if(data_messageInfo.mvt_tmp_state == 1){
                    const str = data_messageInfo.mvt_refuser_msg;

                    showToastErrorStill('Modification de prix annuler', str, 'error');
                    onCancel(false);
                }
                customConsoleLog('BasicAsyncConfirmDialogMVTprice', data_messageInfo.mvt_tmp_state);



            // }
        }
    }
    , [isFetching_messageInfo]);

    const handleSubmit = () => {
        if(mvtValider) {
        onSubmit(true);
        }
        else{
            oncancel(false);
        }
    };

    const handleCancel = () => {
        // annuler la validation
        const vals = { is_MvtPrice: 0, mvt_tmp_key: mvtKey, mvt_tmp_state:0, mvt_tmp_unique_key: uniqueMvtKey, is_NewRequest:0, delete_invalide_request:1 };
        updateMessageLine.mutate(vals);
        onCancel(false);
    };

    const splitMsg = msg.split('\n');
    const finalMsg = splitMsg.map((item, index) => {
        return <p className='p-1' key={index}>
            {item}
            </p>
    });

    return (
        ReactDOM.createPortal(
            <React.Fragment>
                <Dialog
                    open={visible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCancel}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        zIndex: 2000,
                    }}
                    fullWidth={true}
                    
                >
                    <DialogTitle >
                        <div className='flex flex-row items-center'>
                            {/* <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400 m-2" /> */}
                            <BasicLoadingAnim width={16} height={16}/>
                            <span className='ml-2 text-lg font-black'>{'Veuillez patienter, le prix est en cours de validation...'}</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description"
                        className='overflow-auto'>
                            {/* {finalMsg ? finalMsg : msg} */}
                            <ArticlesLignesRecap 
                            mvtTmpInfo={mvtTmpInfo}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {
                            btnShow && <BasicButton className="" onClick={handleSubmit}>{btnText}</BasicButton>
                        }
                        <BasicButton className="" onClick={handleCancel}>Annuler</BasicButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>,
            document.body)
    );
}


// export default function BasicConfirmDialog(props) {
//     const okButton = props.okButton;

//     const titre = okButton ? 'Enregistrer?' : 'Annuler?';
//     const msg = okButton ? 'Enregistrer les modification?' : 'Annuler les modification?';

//     const handleOUI = () => {
//         props.handleOui();
//     }

//     const handleClose = () => {
//         props.handleNON();
//     };

//     return (
//         <React.Fragment>
//             <Dialog
//                 open={props.OpenConfirm}
//                 TransitionComponent={Transition}
//                 keepMounted
//                 onClose={handleClose}
//                 aria-describedby="alert-dialog-slide-description"
//             >
//                 <DialogTitle>
//                     <div className='flex flex-row items-center'>
//                     <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400 m-2"/>
//                     <span className='text-lg'>{titre}</span>
//                     </div>
//                 </DialogTitle>
//                 <DialogContent>
//                     <DialogContentText id="alert-dialog-slide-description">
//                         {msg}
//                     </DialogContentText>
//                 </DialogContent>
//                 <DialogActions>
//                     <BasicButton className="bg-green-400 border-green-900" onClick={handleOUI}>Oui</BasicButton>
//                     <BasicButton className="bg-red-400 border-red-900" onClick={handleClose}>Non</BasicButton>
//                 </DialogActions>
//             </Dialog>
//         </React.Fragment>
//     );
// }