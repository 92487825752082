import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, defaultSelectChargement, ping_api, reservation_info_api, reservation_update_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import BasicDateInput from '../../components/BasicDateInput';
import BasicContainer from '../../components/BasicContainer';
import BasicRadio from '../../components/BasicRadio';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import VehiculesList, { GetVehiculesListQuery } from '../vehicule/VehiculesList';
import BasicSelect from '../../components/BasicSelect';
import EtablissementsList, { GetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import RegionsList, { GetRegionsListQuery } from '../region/RegionsList';
import BasicTextarea from '../../components/BasicTextarea';
import BasicNumberInput from '../../components/BasicNumberInput';
import { customConsoleLog } from '../../global/Tools';





const GetReservationEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetReservationEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetReservationQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetReservationQuery', itemID],
    queryFn: async () => await callAxiosApi(`${reservation_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddReservationQuery = (handleClose, addReservationLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${reservation_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddReservationQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Reservation ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addReservationLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateReservationQuery = (itemID, updateReservationLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${reservation_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateReservationQuery', itemID],
    onSuccess: () => {
      const msg = `Reservation mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateReservationQuery called');

        updateReservationLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetReservationEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetReservationEmptyQuery'], exact: true });
}

export const cleanGetReservationQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanReservationGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetReservationQuery', itemID], exact: true });
}




function Reservation({ actionName, selectedItem, handleClose, addReservationLine, updateReservationLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Reservation compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetReservationEmptyQuery() : GetReservationQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addReservation = useAddReservationQuery(handleClose, addReservationLine)
  const updateReservation = useUpdateReservationQuery(itemID, updateReservationLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('useEffect called');
    }
  }, [data]);


  const {
    data: data_vehicule = defaultSelectChargement,
    isLoading: isLoading_vehicule } = GetVehiculesListQuery(canFetchOthers);

  const VehiculeListRef = React.useRef();

  const handleVehiculeClick = () => {

    VehiculeListRef.current?.handleOpen(formValues.idcompte);
  };

  const handleGetid_vehicule = (value) => {
    if (value == formValues.idcompte) return;
    customConsoleLog('handleGetid_vehicule', value);
    setFormValues({
      ...formValues,
      'idcompte': value
    });
  }

  const {
    data: data_etablissement = defaultSelectChargement,
    isLoading: isLoading_etablissement } = GetEtablissementsListQuery(canFetchOthers);

  const EtablissementListRef = React.useRef();

  const handleEtablissementClick = () => {

    EtablissementListRef.current?.handleOpen(formValues.iddepot);
  };

  const handleGetid_etablissement = (value) => {
    if (value == formValues.iddepot) return;
    customConsoleLog('handleGetid_etablissement', value);
    setFormValues({
      ...formValues,
      'iddepot': value
    });
  }

  React.useEffect(() => {

    setFormValues(prevState => ({
      ...prevState,
      'depotlibelle': data_etablissement.find(x => x.id == formValues.iddepot)?.libelle || ''
    }));

  }, [formValues.iddepot, data_etablissement]);


  const {
    data: data_region = defaultSelectChargement,
    isLoading: isLoading_region } = GetRegionsListQuery(canFetchOthers);

  const RegionListRef = React.useRef();

  const handleRegionClick = () => {

    RegionListRef.current?.handleOpen(formValues.iddestination);
  };

  const handleGetid_region = (value) => {
    if (value == formValues.iddestination) return;
    customConsoleLog('handleGetid_region', value);
    setFormValues({
      ...formValues,
      'iddestination': value
    });
  }



  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkReservation = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        addReservation.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addReservation.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateReservation.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseReservation = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }



  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      <VehiculesList ref={VehiculeListRef} handleGetid_vehicule={handleGetid_vehicule} isModal={true} />
      <EtablissementsList ref={EtablissementListRef} handleGetid_etablissement={handleGetid_etablissement} isModal={true} />
      <RegionsList ref={RegionListRef} handleGetid_region={handleGetid_region} isModal={true} />

      <div className="m-2 flex flex-row">
        <section className='w-[700px]'>
          <h2 className="text-lg font-bold mb-4">Reservation</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-2 min-h-40 mt-8'>
              <div className='grid grid-cols-3'>

                <BasicLabel className='text-xs w-[150px]' titre="Date saisie :">
                  <BasicDateInput id="date" name="date" className='text-xs' isbig={true} value={formValues.date || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>

                <div className='col-span-2 inline-flex items-center'>
                  <div className='text-blue-600 italic w-[100px] text-right mr-2'>Type :</div>
                  <BasicContainer className="p-2">
                    <div className='grid grid-cols-3'>
                      <BasicRadio type="radio" id="reserve" name="typedep" value="R" titre="Reserve" selectedValue={formValues.typedep} onChange={handleChange} className="text-xs" />
                      <BasicRadio type="radio" id="en_panne" name="typedep" value="P" titre="En Panne" selectedValue={formValues.typedep} onChange={handleChange} className="text-xs" />
                      <BasicRadio type="radio" id="bloquer" name="typedep" value="B" titre="Bloquer" selectedValue={formValues.typedep} onChange={handleChange} className="text-xs" />
                    </div>
                  </BasicContainer>
                </div>
              </div>


              <BasicLabel className='text-xs w-[300px]' titre="Vehicules :">
                <BasicSelect id="idcompte" name="idcompte" className="text-xs" listItems={data_vehicule || []} 
                value={formValues.idcompte || ''} textKey={'code'}
                fieldsConfig={[{key:'code', title: 'Immatriculation'}, {key:'marque', title: 'Marque'}]}
                 onChange={handleChange} readOnly={isReadOnly}>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_vehicule} onClick={handleVehiculeClick}>...</BasicButtonSmall>
                </BasicSelect>
              </BasicLabel>

              <BasicLabel className='text-xs w-[300px]' titre="Depots :">
                <BasicSelect id="iddepot" name="iddepot" className="text-xs" listItems={data_etablissement || []} value={formValues.iddepot || ''} 
                textKey={'libelle'}
                fieldsConfig={[{key:'code', title: 'Code'}, {key:'libelle', title: 'Libelle'}]}
                 onChange={handleChange} readOnly={isReadOnly}>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_etablissement} onClick={handleEtablissementClick}>...</BasicButtonSmall>
                </BasicSelect>
              </BasicLabel>

              <BasicLabel className='text-xs w-[300px]' titre="">
                <BasicInput id="depotlibelle" name="depotlibelle" className='text-xs bg-yellow-300' value={formValues.depotlibelle || ''} onChange={handleChange} readOnly={true} />
              </BasicLabel>

              <BasicLabel className='text-xs w-[300px]' titre="Destination :">
                <BasicSelect id="iddestination" name="iddestination" className="text-xs" listItems={data_region || []} value={formValues.iddestination || ''} 
                textKey={'region'}
                fieldsConfig={[{key:'region', title: 'Region'}]} onChange={handleChange} readOnly={isReadOnly}>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_region} onClick={handleRegionClick}>...</BasicButtonSmall>
                </BasicSelect>
              </BasicLabel>

              <BasicLabel className='text-xs' titre="">
                <BasicTextarea id="libelle" name="libelle" className='text-xs' value={formValues.libelle || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <div className='w-[200px]'>

                <BasicLabel className='text-xs' titre="Nbre de Clients :">
                  <BasicNumberInput id="mtdep" name="mtdep" className='text-xs bg-yellow-300' value={formValues.mtdep || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>
              </div>

              <div className='w-[400px]'>
                <BasicLabel className='text-xs w-[200px]' titre="Date Debut :">
                  <BasicDateInput id="dtedeb" name="dtedeb" className='text-xs' isbig={true} value={formValues.dtedeb || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>
              </div>
              <div className='w-[400px]'>
                <BasicLabel className='text-xs w-[200px]' titre="Date fin :">
                  <BasicDateInput id="dtefin" name="dtefin" className='text-xs' isbig={true} value={formValues.dtefin || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>
              </div>
            </div>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkReservation}
                  loading={addReservation.isPending || updateReservation.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseReservation}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Reservation