
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, article_delete_api, article_info_api, article_list_api } from '../../global/GlobalVars';
import Article from './Article';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetArticleEmptyQuery, cleanGetArticleQuery } from './Article';
import { cleanGetDepartementsListQuery } from '../departement/DepartementsList';
import { cleanGetFamillesListQuery } from '../famille/FamillesList';
import { cleanGetSousFamillesListQuery } from '../sousfamille/SousFamillesList';
import { cleanGetDepartementEmptyQuery, cleanGetDepartementQuery } from '../departement/Departement';
import { cleanGetFamilleEmptyQuery, cleanGetFamilleQuery } from '../famille/Famille';
import { cleanGetSousFamilleEmptyQuery, cleanGetSousFamilleQuery } from '../sousfamille/SousFamille';
import { cleanGetTvasListQuery } from '../tva/TvasList';
import { cleanGetTvaEmptyQuery, cleanGetTvaQuery } from '../tva/Tva';
import { cleanGetFournisseursListQuery } from '../fournisseur/FournisseursList';
import { cleanGetFournisseurEmptyQuery } from '../fournisseur/Fournisseur';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'libelle', headerName: 'Libelle', width: 300 },
  { field: 'unite', headerName: 'Unite', width: 100 },
  { field: 'qti', headerName: 'PIECE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'xsect', headerName: 'SECTION', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value, 4) },
  { field: 'code', headerName: 'Code article', width: 200 },
  { field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }

// should be cached unless GetArticlesListQuery gets called

export const GetArticlesListQuery = (canFetch = true, cacheMinutes = 0, staleMinutes = 0) => {
  return useQuery({
    queryKey: ['ArticleList'],
    queryFn: async () => 
    {
      customConsoleLog('GetArticlesListQuery called');
      return await callAxiosApi(`${article_list_api}`, '', true)
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
    enabled: canFetch,
  })
}

const useAddArticleLine = (queryClient, handleCloseArticle, handleRowSelectionArticlesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${article_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['ArticleList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addArticleLine onSuccess');
      customConsoleLog(newprod);
      handleCloseArticle();
      handleRowSelectionArticlesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateArticleLine = (queryClient, handleCloseArticle, handleRowSelectionArticlesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${article_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateArticleLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateArticleLine called')
      queryClient.setQueryData(['ArticleList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetArticleQuery(newprod.id, queryClient);
      handleCloseArticle();
      handleRowSelectionArticlesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteArticleLine = (queryClient, handleCloseArticle) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${article_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['ArticleList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetArticleQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseArticle();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetArticlesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['ArticleList'] });
}

export const invalidateGetArticlesListQuery = (queryClient) => {
  queryClient.invalidateQueries({ queryKey: ['ArticleList'] });
}
 
const ArticlesList = forwardRef((props, ref) => {
  //function ArticlesList({ removeWidget, isModal }) {
  const { handleGetid_article, isModal, removeWidget, cleanData, ...others } = props;
  const queryClient = useQueryClient();
  const [openArticleList, setOpenArticleList] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);

  const [actionNameArticle, setActionNameArticle] = useState("")
  const [selectArticlesList, setSelectionArticlesList] = useState([]);
  

  
  const handleCleanData = () => {
    invalidateGetArticlesListQuery(queryClient);
  }

  const handleRowSelectionArticlesList = (e) => {
    if (e != selectArticlesList[0]) {
      setSelectionArticlesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenArticle = () => {
    setOpenArticle(true);

    //BasicModalRef.current.open();
  }
  const handleCloseArticle = () => {
    setOpenArticle(false);
    //BasicModalRef.current.close();
    cleanGetArticleEmptyQuery(queryClient);
    cleanGetArticleQuery(selectArticlesList[0], queryClient);
    cleanGetDepartementsListQuery(queryClient);
    cleanGetDepartementEmptyQuery(queryClient);
    //cleanGetDepartementQuery(queryClient);
    cleanGetFamillesListQuery(queryClient);
    cleanGetFamilleEmptyQuery(queryClient);
    //cleanGetFamilleQuery(queryClient);
    cleanGetSousFamillesListQuery(queryClient);
    cleanGetSousFamilleEmptyQuery(queryClient);
    //cleanGetSousFamilleQuery(queryClient);
    cleanGetTvasListQuery(queryClient);
    cleanGetTvaEmptyQuery(queryClient);
    //cleanGetTvaQuery(queryClient);
    cleanGetFournisseursListQuery(queryClient);
    cleanGetFournisseurEmptyQuery(queryClient);
  }



  const [status_article, execute_article, resolve_article, reject_article, reset_article] = useAwaitableComponent();
  const [asyncConfirmDialogInfoArticle, setConfirmDialogInfoArticle] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogArticlesList = status_article === "awaiting";

  const handleAwaitModalArticlesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoArticle({ titre: mtitre, msg: mmsg });
      await execute_article();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_article();
    }
  };


  customConsoleLog('ArticlesList rendered')



  const { data: data_articleslist = [], error: error_articleslist,
    refetch: refecth_articleslist, isLoading: isLoading_articleslist, failureCount: failureCount_articleslist } =
    GetArticlesListQuery();






  const handleFiltrerArticlesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauArticlesList = () => {

    clearToasts();
    setActionNameArticle(btnActionsName.nouveau);
    setSelectionArticlesList([])
    handleOpenArticle();

  }

  const handleConsulterArticlesList = () => {
    if (selectArticlesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameArticle(btnActionsName.consulter);

    handleOpenArticle();
    customConsoleLog(selectArticlesList[0]);
  }

  const handleDupliquerArticlesList = () => {
    if (selectArticlesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameArticle(btnActionsName.dupliquer);
    handleOpenArticle();
    customConsoleLog(selectArticlesList[0]);
  }

  const handleModifierArticlesList = () => {
    if (selectArticlesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameArticle(btnActionsName.modifier);
    handleOpenArticle();
    customConsoleLog(selectArticlesList[0]);
  }


  const handleSupprimerArticlesList = async () => {
    if (selectArticlesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectArticlesList[0])
    if (await handleAwaitModalArticlesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameArticle(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteArticleLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }

  const handleOpenArticlesList = (itemID) => {
    setOpenArticleList(true);

    if(itemID) {
      handleRowSelectionArticlesList([itemID]);
    }
  }


  const handleFermerArticlesList = () => {
    if (selectArticlesList[0]) {
      if (handleGetid_article) {

        handleGetid_article(selectArticlesList[0]);
      }
    }

    if (removeWidget) {

      removeWidget();
      cleanGetArticlesListQuery(queryClient);
      cleanGetArticleEmptyQuery(queryClient);
      cleanGetArticleQuery(selectArticlesList[0], queryClient);
      setOpenArticleList(false);
    }
    else{
    setOpenArticleList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_articleslist.filter(item => item.id == id)[0];
  }



  const addArticleLine = useAddArticleLine(queryClient, handleCloseArticle, handleRowSelectionArticlesList);


  const updateArticleLine = useUpdateArticleLine(queryClient, handleCloseArticle, handleRowSelectionArticlesList);

  const deleteArticleLine = useDeleteArticleLine(queryClient, handleCloseArticle);






  const DataTableArticlesListRef = React.useRef(null);

  const handlePrintArticlesList = () => {
    if (DataTableArticlesListRef.current) {
      DataTableArticlesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenArticlesList(itemID);
    },
    handleClose() {
      handleFermerArticlesList();
    },
    handleRefresh() {
      handleCleanData();
    },
  }));



  return (
    <>
      <BasicModal open={openArticleList} handleClose={handleFermerArticlesList} isModal={isModal}>
        {/* Tables */}
        {isLoading_articleslist || error_articleslist
          ? <BasicLoading
            handleClose={handleFermerArticlesList}
            refetch={refecth_articleslist}
            failureCount={failureCount_articleslist}
            error={error_articleslist} />
          : <>
            {
              showAsyncConfirmDialogArticlesList &&
              <BasicAsyncConfirmDialog
                visible={showAsyncConfirmDialogArticlesList}
                onSubmit={resolve_article}
                onCancel={reject_article}
                info={asyncConfirmDialogInfoArticle}
              />
            }


            {
              openArticle &&
              <BasicModal open={openArticle} isModal={true}>
                <Article
                  actionName={actionNameArticle}
                  selectedItem={getSelecteditemByID(selectArticlesList[0])}
                  handleClose={handleCloseArticle}
                  addArticleLine={addArticleLine}
                  updateArticleLine={updateArticleLine}
                />
              </BasicModal>
            }
            {isModal && <h2 className="text-lg font-bold mb-4">Articles</h2>}

            <RedDataTableBasic
              ref={DataTableArticlesListRef}
              rows={data_articleslist}
              columns={columns}
              columnVisibilityModel={hideColumns}
              handleRowSelection={handleRowSelectionArticlesList} 
              select={selectArticlesList}
              onRowDoubleClick={handleConsulterArticlesList}
            />

            {/* Buttons */}
            <div className="mt-2 grid grid-cols-10 gap-2 p-2">
              <BasicButton onClick={handleFiltrerArticlesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
              <div></div>
              <BasicButton onClick={handleNouveauArticlesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
              <BasicButton onClick={handleConsulterArticlesList} id="consulter" name="consulter">Consulter</BasicButton>
              <BasicButton onClick={handleDupliquerArticlesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
              <div></div>
              <BasicButton onClick={handleModifierArticlesList} id="modifier" name="modifier">Modifier</BasicButton>
              <BasicButton onClick={handlePrintArticlesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
              <BasicButton onClick={handleSupprimerArticlesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
              <BasicButton onClick={handleFermerArticlesList} id="fermer" name="fermer">Fermer</BasicButton>
            </div>
          </>

        }


      </BasicModal>


    </>

  );
  //}
});





export default ArticlesList



const ArticlesListwidgetKey = 'Articleswidget'
export const ArticlesListwidget = { i: ArticlesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: (props) => <ArticlesList {...props}/>, titre: 'Articles', id_fenetre: 504 }
