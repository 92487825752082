import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, getOnlyUpdatedValues, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import BasicLabel from '../../components/BasicLabel';
import BasicInput from '../../components/BasicInput';
import { btnActionsName, parametre_info_api, parametre_update_api, ping_api } from '../../global/GlobalVars';
import BasicTabs, { BasicTab, BasicTabPanel, BasicTabsList } from '../../components/BasicTabs';
import BasicCheckBox from '../../components/BasicCheckBox';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import BasicTextarea from '../../components/BasicTextarea';
import BasicContainer from '../../components/BasicContainer';
import BasicNumberInput from '../../components/BasicNumberInput';
import BasicRadio from '../../components/BasicRadio';
import { customConsoleLog } from '../../global/Tools';

// lets make a fake query and return a fake data without using useQuery
export const GetParametreEmptyQuery = () => {
    return useQuery({
        queryKey: ['GetParametreEmptyQuery'],
        queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
        refetchOnWindowFocus: false,
        retry: 2,
        cacheTime: 0,
    })
}

export const GetParametreQuery = (itemID, canFetch = true) => {
    return useQuery({
        queryKey: ['GetParametreQuery', itemID],
        queryFn: async () => await callAxiosApi(`${parametre_info_api}${itemID}`, '', true)
        ,
        refetchOnWindowFocus: false,
        retry: 2,
        cacheTime: 0,
        enabled: canFetch
    })
}

export const useUpdateParametreQuery = (itemID, handleClose, itemName) => {
    return useMutation({
        mutationFn: async (vals) => {
            return await callAxiosApi(`${parametre_update_api}${itemID}`, JSON.stringify(vals), true, 'post');
        }
        ,
        mutationKey: ['UpdateParametreQuery', itemID],
        onSuccess: () => {
            const msg = `Parametre mise a jours avec succes`
            showToast(msg, 5000, 'succes');
            try {
                customConsoleLog('UpdateParametreQuery called');

            }
            catch (e) {
                customConsoleLog(e)
            }
            handleClose(null, true);
        },
        onError: (error, variables, context) => {


        },

    })
}

export const cleanGetParametreEmptyQuery = (queryClient) => {
    queryClient.removeQueries({ queryKey: ['GetParametreEmptyQuery'], exact: true });
}

export const cleanGetParametreQuery = async (itemID, queryClient) => {
    customConsoleLog('cleanParametreGetQuery', itemID);
    queryClient.removeQueries({ queryKey: ['GetParametreQuery', itemID], exact: true });
}

export const invalidateGetParametreQuery = async (queryClient) => {
    customConsoleLog('invalidateGetParametreQuery');
    queryClient.invalidateQueries({queryKey: ['GetParametreQuery']});
}



const Parametre = forwardRef((props, ref) => {
    const { handleGetid_parametre, isModal, removeWidget, isReadOnly, actionName, ...others } = props;
    const isReadOnlyLocal = isReadOnly || false;
    const queryClient = useQueryClient();
    const [openParametre, setOpenParametre] = useState(false);
    const [formValues, setFormValues] = React.useState({});
    const [initialData, setInitialData] = React.useState({});
    const itemID = 1;

    const {
        data,
        error,
        isError,
        isLoading,
        failureCount,
        refetch } = GetParametreQuery(itemID);


    const canFetchOthers = !isLoading && !isError;
    customConsoleLog('canFetchOthers', canFetchOthers)



    React.useEffect(() => {
        if (data) {
            setInitialData(data);
            setFormValues(data);
            customConsoleLog('useEffect called');
        }
    }, [data]);


    const [status_parametre, execute_parametre, resolve_parametre, reject_parametre, reset_parametre] = useAwaitableComponent();
    const [asyncConfirmDialogInfoParametre, setConfirmDialogInfoParametre] = useState({ titre: '', msg: '' });

    const showAsyncConfirmDialogParametre = status_parametre === "awaiting";

    const handleAwaitModalParametre = async (mtitre, mmsg) => {

        try {
            setConfirmDialogInfoParametre({ titre: mtitre, msg: mmsg });
            await execute_parametre();
            return true;
        } catch (err) {
            return false;
        } finally {
            reset_parametre();
        }
    };

    const handleFermerParametre = async (event, closeAuto = false) => {
        customConsoleLog('handleFermerParametre', closeAuto);
        if(!closeAuto){
            if (await handleAwaitModalParametre('Fermer!!', 'Etes vous sur?!')) {
                customConsoleLog('fermer');
                
              }
              else {
                customConsoleLog('annuler');
                return;
              }
        }
        

        cleanGetParametreEmptyQuery(queryClient);
        cleanGetParametreQuery(itemID, queryClient);

        if (removeWidget) {

            removeWidget();
            setOpenParametre(false);
        }
        else {

            setOpenParametre(false);
        }
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        customConsoleLog('handleChange ', value)


        setFormValues(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        customConsoleLog('SubmitEvent')
    };


    const handleOkParametre = async (e) => {



        clearToasts();


        if (await handleAwaitModalParametre('Enregistrer!!', 'Etes vous sur?!')) {
            customConsoleLog('modifier');
            updateParametre.mutate(getOnlyUpdatedValues(initialData, formValues))
            //updateParametre.mutate(formValues)

        }
        else {
            customConsoleLog('modifier annuler');
        }

    }

    const getButtonName = () => {
        switch (actionName) {
            case btnActionsName.consulter:
                return "";

            default:
                return "ok";
        }


    }

    const tabContentClass = "min-h-[290px] border rounded-sm border-gray-400 border-t-0 p-1  bg-slate-100";


    const updateParametre = useUpdateParametreQuery(itemID, handleFermerParametre, data?.libelle)


    return (
        <>
            <BasicModal open={openParametre} handleClose={handleFermerParametre} isModal={isModal}>
                {
                    isLoading || isError
                        ?
                        <>
                            <BasicLoading
                                handleClose={handleFermerParametre}
                                refetch={refetch}
                                failureCount={failureCount}
                                error={error} />
                        </>
                        :
                        <>
                            {
                                showAsyncConfirmDialogParametre &&
                                <BasicAsyncConfirmDialog
                                    visible={showAsyncConfirmDialogParametre}
                                    onSubmit={resolve_parametre}
                                    onCancel={reject_parametre}
                                    info={asyncConfirmDialogInfoParametre}
                                />
                            }
                            {
                                <div className="m-2 flex flex-row">
                                    <section className=' w-full mr-4'>
                                        {isModal && <h2 className="text-lg font-bold mb-4">Parametre</h2>}
                                        <form onSubmit={handleSubmit}>
                                            <div className='flex flex-col min-h-40 mt-2'>

                                                <BasicLabel className='text-xs w-[200px]' titre="Raison social :">
                                                    <BasicInput id="nomste" name="nomste" className='text-xs' value={formValues.nomste || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                </BasicLabel>

                                                <BasicLabel className='text-xs w-[200px]' titre="Adresse :">
                                                    <BasicInput id="adresse" name="adresse" className='text-xs' value={formValues.adresse || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                </BasicLabel>

                                                <BasicLabel className='text-xs w-[200px]' titre="Ville :">
                                                    <BasicInput id="ville" name="ville" className='text-xs' value={formValues.ville || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                </BasicLabel>
                                                <div className='grid grid-cols-2'>
                                                    <div className='flex flex-col'>

                                                        <BasicLabel className='text-xs w-[250px]' titre="Registre du commerce :">
                                                            <BasicInput id="r_c" name="r_c" className='text-xs' value={formValues.r_c || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </BasicLabel>

                                                        <BasicLabel className='text-xs w-[250px]' titre="Identification Fiscale :">
                                                            <BasicInput id="i_f" name="i_f" className='text-xs' value={formValues.i_f || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </BasicLabel>

                                                        <BasicLabel className='text-xs w-[250px]' titre="N° de CNSS :">
                                                            <BasicInput id="cnss" name="cnss" className='text-xs' value={formValues.cnss || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </BasicLabel>

                                                    </div>
                                                    <div className='flex flex-col'>

                                                        <BasicLabel className='text-xs w-[200px]' titre="Forme Juridique :">
                                                            <BasicInput id="forme_juridique" name="forme_juridique" className='text-xs' value={formValues.forme_juridique || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </BasicLabel>

                                                        <BasicLabel className='text-xs w-[200px]' titre="Patente :">
                                                            <BasicInput id="patente" name="patente" className='text-xs' value={formValues.patente || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </BasicLabel>

                                                        <BasicLabel className='text-xs w-[200px]' titre="Régime :">
                                                            <BasicInput id="regime" name="regime" className='text-xs' value={formValues.regime || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </BasicLabel>

                                                    </div>

                                                </div>
                                                <BasicTabs defaultValue={1}>
                                                    <BasicTabsList className='grid grid-cols-3'>
                                                        <BasicTab value={1} className='pt-1' >Prix</BasicTab>
                                                        <BasicTab value={2} >Numéro</BasicTab>
                                                        <BasicTab value={3} >Editions</BasicTab>
                                                    </BasicTabsList>
                                                    <BasicTabPanel value={1} className={tabContentClass}>
                                                        <div className='grid grid-cols-2 gap-2'>

                                                            <div className='flex flex-col gap-2'>
                                                                <BasicCheckBox id="achats_ht" name="achats_ht" titre="Achats H.T." labelClass="text-xs" checked={formValues.achats_ht || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                <BasicCheckBox id="ventes_ht" name="ventes_ht" titre="Ventes H.T." labelClass="text-xs" checked={formValues.ventes_ht || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />

                                                                <BasicLabel className='text-xs w-[250px]' titre="Coefficient Prix 1 (RVD) :">
                                                                    <BasicDecimalInput id="cofprv" name="cofprv" className='text-xs bg-yellow-300' value={formValues.cofprv || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Coefficient Prix 2 (CL) :">
                                                                    <BasicDecimalInput id="cofpcl" name="cofpcl" className='text-xs bg-yellow-300' value={formValues.cofpcl || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Coefficient Prix 3 (PH) :">
                                                                    <BasicDecimalInput id="cofpph" name="cofpph" className='text-xs bg-yellow-300' value={formValues.cofpph || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Coefficient Prix 4 (DI) :">
                                                                    <BasicDecimalInput id="cofpdi" name="cofpdi" className='text-xs bg-yellow-300' value={formValues.cofpdi || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Coefficient Prix 5 (AU) :">
                                                                    <BasicDecimalInput id="cofpd5" name="cofpd5" className='text-xs bg-yellow-300' value={formValues.cofpd5 || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                            </div>

                                                            <div className='flex flex-col gap-2'>
                                                                <BasicLabel className='text-xs' titre="Commentaire FIN BL :"></BasicLabel>
                                                                <BasicTextarea id="pied_bl_cli" name="pied_bl_cli" value={formValues.pied_bl_cli || ''} onChange={handleChange} readOnly={isReadOnly} />
                                                            </div>
                                                        </div>

                                                    </BasicTabPanel>
                                                    <BasicTabPanel value={2} className={tabContentClass}>
                                                        <BasicLabel className='text-xs' titre="Dernier N° de piéces :"></BasicLabel>
                                                        <BasicContainer className='grid grid-cols-2 gap-2'>
                                                            <div className='flex flex-col'>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Proforma :">
                                                                    <BasicNumberInput id="num_pro_cli" name="num_pro_cli" className='text-xs' value={formValues.num_pro_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Devis :">
                                                                    <BasicNumberInput id="num_dev_cli" name="num_dev_cli" className='text-xs' value={formValues.num_dev_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Commandes :">
                                                                    <BasicNumberInput id="num_bc_cli" name="num_bc_cli" className='text-xs' value={formValues.num_bc_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Bon de Livraison :">
                                                                    <BasicNumberInput id="num_bl_cli" name="num_bl_cli" className='text-xs' value={formValues.num_bl_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Facture :">
                                                                    <BasicNumberInput id="num_fac_cli" name="num_fac_cli" className='text-xs' value={formValues.num_fac_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Retour :">
                                                                    <BasicNumberInput id="num_br_cli" name="num_br_cli" className='text-xs' value={formValues.num_br_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Echange :">
                                                                    <BasicNumberInput id="num_be_cli" name="num_be_cli" className='text-xs' value={formValues.num_be_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Avoir :">
                                                                    <BasicNumberInput id="num_av_cli" name="num_av_cli" className='text-xs' value={formValues.num_av_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                            </div>
                                                            <div className='flex flex-col'>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Transfert Depots :">
                                                                    <BasicNumberInput id="num_dd" name="num_dd" className='text-xs' value={formValues.num_dd || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Commande Frs :">
                                                                    <BasicNumberInput id="num_bc_frn" name="num_bc_frn" className='text-xs' value={formValues.num_bc_frn || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Réglement Cli :">
                                                                    <BasicNumberInput id="num_rc_cli" name="num_rc_cli" className='text-xs' value={formValues.num_rc_cli || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                                <BasicLabel className='text-xs w-[250px]' titre="Réglement Frs :">
                                                                    <BasicNumberInput id="num_rf_frn" name="num_rf_frn" className='text-xs' value={formValues.num_rf_frn || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                                </BasicLabel>
                                                            </div>
                                                        </BasicContainer>

                                                    </BasicTabPanel>
                                                    <BasicTabPanel value={3} className={tabContentClass}>
                                                        <div className='flex flex-col gap-1 pl-28'>
                                                            {/* typeimp */}
                                                                <BasicRadio type="radio" id="typeimp1" name="typeimp" value="1" 
                                                                titre="Edition standard" selectedValue={formValues.typeimp} onChange={handleChange} className="text-xs" />
                                                                <BasicRadio type="radio" id="typeimp2" name="typeimp" value="2" 
                                                                titre="Edition sur préimprimé" selectedValue={formValues.typeimp} onChange={handleChange} className="text-xs" />
                                                                <BasicRadio type="radio" id="typeimp3" name="typeimp" value="3" 
                                                                titre="Edition sans les Quantités" selectedValue={formValues.typeimp} onChange={handleChange} className="text-xs" />
                                                                <BasicRadio type="radio" id="typeimp14" name="typeimp" value="4" 
                                                                titre="Edition sans les prix" selectedValue={formValues.typeimp} onChange={handleChange} className="text-xs" />
                                                            <BasicCheckBox id="qtemaxi" name="qtemaxi" titre="Bloquer si dépacemment Qté Livrée"
                                                                labelClass="text-xs" checked={formValues.qtemaxi || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                            <BasicCheckBox id="mtmaxi" name="mtmaxi" titre="Bloquer si dépacemment Plafond des Clients"
                                                                labelClass="text-xs" checked={formValues.mtmaxi || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                            <BasicCheckBox id="impsimaxi" name="impsimaxi" titre="Imprimer BL ou Facture Bloquer si dépacemment Plafond"
                                                                labelClass="text-xs" checked={formValues.impsimaxi || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                            <BasicCheckBox id="afficher_col_perso" name="afficher_col_perso" titre="Afficher Note Dans la grille de saisie"
                                                                labelClass="text-xs" checked={formValues.afficher_col_perso || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                            <BasicCheckBox id="afficher_col_remise" name="afficher_col_remise" titre="Afficher Remise Dans la grille de saisie"
                                                                labelClass="text-xs" checked={formValues.afficher_col_remise || ''} onChange={handleChange} readOnly={isReadOnlyLocal} />
                                                        </div>

                                                    </BasicTabPanel>
                                                </BasicTabs>
                                            </div>

                                            <div className="grid grid-cols-7 gap-2 mt-2">
                                                <div className='col-span-5'></div>
                                                {actionName != btnActionsName.consulter
                                                    ? <BasicButton type="button" onClick={handleOkParametre}
                                                        loading={updateParametre.isPending}>{getButtonName(actionName)}</BasicButton>
                                                    : <div></div>
                                                }
                                                <BasicButton type="button" onClick={handleFermerParametre}>Fermer</BasicButton>
                                            </div>

                                        </form>

                                    </section>
                                </div>
                            }
                        </>
                }
            </BasicModal>
        </>
    )

});

export default Parametre;


const ParametrewidgetKey = 'Parametrewidget'
export const Parametrewidget = { i: ParametrewidgetKey, x: 0, y: 0, w: 10, h: 16, component: (props) => <Parametre {...props}/>, titre: 'Parametre', id_fenetre: 721 }