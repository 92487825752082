import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route, } from 'react-router-dom';
import Layout from './Layout';
import { Logout } from './pages/logout/Logout';
import { Login } from './pages/login/Login';
// import Main from './Main';











// function Stats({ user }) {

//   if(!user) {
//     return (
//       <Navigate to="/login" replace/>
//     );
//   }

//   return (
//     <div style={{ padding: 20 }}>
//       <h2>Stats View</h2>
//       <p>Lorem ipsum dolor sit amet, consectetur adip.</p>
//     </div>
//   );
// }

// function Login({ onLogin }) {
//   const [creds, setCreds] = useState({});
//   const navigate = useNavigate();

//   function handleLogin() {
//     // For demonstration purposes only. Never use these checks in production!
//     // Use a proper authentication implementation
//     if(creds.username === 'admin' && creds.password === '123') {
//       onLogin && onLogin({username: creds.username});
//       navigate('/');
//     }
//   }
//   return (
//     <>

//   <div className="w-full min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
//   <div className="w-full sm:max-w-md p-5 mx-auto">
    
//       <div className="mb-4">
//         <label className="block mb-1" >Utilisateur</label>
//         <input id="email" type="text" name="email" 
//         onChange={(e) => setCreds({...creds, username: e.target.value})}
//         className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
//       </div>
//       <div className="mb-4">
//         <label className="block mb-1" >Mot de passe</label>
//         <input id="password" type="password" name="password" 
//         onChange={(e) => setCreds({...creds, password: e.target.value})}
//         className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full" />
//       </div>
//       <div className="mt-6">
//         <button 
//         onClick={handleLogin}
//         className="w-full inline-flex items-center justify-center px-4 py-2 bg-red-600 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-red-700 active:bg-red-700 focus:outline-none focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition">Login</button>
//       </div>
    
//   </div>
// </div>
//     </>
//   );
// }



// function AppLayout() {
//   const [user, setUser] = useState();
//   const navigate = useNavigate();

//   function logOut() {
//     setUser(null);
//     navigate("/");
//   }

//   // const [layouts, setLayouts] = useState([]); // Initialize layouts state with an empty array

//   // const addLayout = (layoutToAdd) => {
//   //   setLayouts(prevLayouts => [...prevLayouts, layoutToAdd]); // Add the new layout to the layouts state
//   // };



//   return (
//     <>
      
//       <Layout/>
      
      
//       {/* <Routes>

//         <Route path="/" element={} /> */}

//         {/* <Route path="/parametres" element={<Parametres/>} /> */}
//         {/* <Route path="/" element={<ProtectedRoute user={user}><Home/></ProtectedRoute>} /> */}
//         {/* <Route path="/posts" element={<Posts />}>
//           <Route index element={<PostLists />} />
//           <Route path=":slug" element={<Post />} />
//         </Route>
//         <Route path="/about" element={<About />} /> */}
//         {/* <Route path="/login" element={<Login onLogin={setUser}/>} />
//         <Route path="*" element={<NoMatch />} />
//       </Routes> */}
//     </>
//   );
// }



// function ProtectedRoute({ user, children }) {

//   if(!user) {
//     return (
//       <Navigate to="/login" replace/>
//     );
//   }

//   return children;
// }

export default function App() {

  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Layout/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/logout" element={<Logout/>}/>
      </Routes>
        
    </Router>
    </>
  );
}
