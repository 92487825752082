import React, { useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
//import { DataGrid, gridClasses, gridExpandedSortedRowIdsSelector, gridVisibleColumnDefinitionsSelector } from '@mui/x-data-grid';
// import { DataGridPro, 
//     gridClasses, 
//     gridExpandedSortedRowIdsSelector, 
//     gridVisibleColumnDefinitionsSelector } from '@mui/x-data-grid-pro';
import {
    DataGridPro,
    gridClasses,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarExport,
    useGridApiRef,
    GridCellModes,
    GridDeleteIcon,
    GridActionsCellItem,
    GridClearIcon,
    useGridApiContext,
    GridColumnMenu,
} from '@mui/x-data-grid-pro';
import BasicInput from '../components/BasicInput';
import BasicButton from './BasicButton';
import { cn, revisedRandId } from '../global/Tools';
import { customConsoleLog } from '../global/Tools';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';



const ODD_OPACITY = 0.2;

// const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
//     [`& .${gridClasses.row}`]: { // Target all rows (even and odd)
//       '&:hover, &.Mui-hovered': {  // Apply hover styles
//         backgroundColor: alpha(theme.palette.primary.main, 0.2), // Adjust opacity for hover effect
//       },
//     },
//     [`& .${gridClasses.row}.even`]: {  // Maintain even row styling (optional)
//       backgroundColor: theme.palette.grey[100],
//     },
//     [`& .${gridClasses.row}.odd`]: {  // Add styling for odd rows (optional)
//         backgroundColor: theme.palette.grey[1],
//     },
//     '&.Mui-selected': {  // Maintain selected row styles
//         backgroundColor: alpha(theme.palette.primary.main, 0.1),  // Adjust opacity for selected effect
//     },
//   }));

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.row}.custom-row-bg`]: {
        backgroundColor: theme.palette.error.light, // Example dynamic color
        '&:hover, &.Mui-hovered': {
          backgroundColor: theme.palette.error.dark, // Different hover color
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.error.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.error.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.error.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
      },

}));


function getRowId(row) {
    return row.id;
}

// function CustomFilterItem(props) {
//     const { onClick, colDef } = props;
//     const apiRef = useGridApiContext();
//     const handleClick = React.useCallback(
//       (event) => {
//         apiRef.current.showFilterPanel(colDef.field);
//         onClick(event);
//       },
//       [apiRef, colDef.field, onClick],
//     );
//     return (
//       <MenuItem onClick={handleClick}>
//         <ListItemIcon>
//           {/* <IconFilter fontSize="small" /> */}
//         </ListItemIcon>
//         <ListItemText>Show Filters</ListItemText>
//       </MenuItem>
//     );
//   }

//   function CustomColumnMenu(props) {
//     return (
//       <GridColumnMenu
//         {...props}
//         slots={{
//           // Override slot for `columnMenuFilterItem`
//           GridColumnMenuSortItem: null,
//             GridColumnMenuHideItem: null,
//             GridColumnMenuUnsortItem: null,
//           columnMenuFilterItem: CustomFilterItem,
//         }}
//       />
//     );
//   }


function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                // Hide `columnMenuColumnsItem`
                columnMenuColumnsItem: null,
                columnMenuSortItem: null,
                columnMenuPinningItem: null,
                columnMenuColumnsItem: null,
            
            }}
        />
    );
}




const RedEditDataTableBasic = React.forwardRef((props, ref) => {

    const { rows, setRows, columns, handleRowSelection, columnVisibilityModel,
        select, nofilter, isReadOnly, height, canDelete, pinnedRows, noNewRow, onColumnHeaderDoubleClick, disableColumnMenu, ...others } = props;

    const [customColumns, setCustomColumns] = React.useState(columns);
    const [customPinnedRowsColumns, setCustomPinnedRowsColumns] = React.useState(columns);

    const [searchText, setSearchText] = React.useState('');
    const apiRef = useGridApiRef();
    const [rowColors, setRowColors] = React.useState({});



    const handleDeleteClick = (id) => () => {
        setRows(prev => {
            customConsoleLog('delete id : ', id);
            customConsoleLog('prev : ', prev);
            customConsoleLog('prev.filter : ', prev.filter((row) => row.id !== id));
            return prev.filter((row) => row.id !== id)
        });
    };

    React.useEffect(() => {
        if (!canDelete || isReadOnly) return;
        const deleteColumn = {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 30,
            cellClassName: 'actions',
            isCellEditable: () => false,
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={<GridClearIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(row.id)}
                        color="inherit"
                    />,
                ];
            },
        };

        setCustomColumns([deleteColumn, ...columns]);

        const deleteColumn2 = {
            field: 'empty',
            type: 'text',
            headerName: '',
            width: 30,
            cellClassName: '',
            isCellEditable: () => false,
            getActions: ({ row }) => {
                return [
                    <></>
                    ,
                ];
            },
        };
        setCustomPinnedRowsColumns([deleteColumn2, ...columns]);
    }, []);




    React.useEffect(() => {
        customConsoleLog('selectedRowIds changed')
        handleRowSelectionChange(select)
    }, [select])

    const handlePrint = (options) => {
        apiRef.current.exportDataAsPrint(options);
    };

    const handleLigneColor = (index, color) => {
        customConsoleLog('lignesmvtList handleLigneColor called', color)
        setRowColors((prevColors) => {
            if (color === 'default' || !color) {
              // If color is default or empty, remove the custom color
              const { [index]: _, ...newColors } = prevColors; // Remove the key by destructuring
              return newColors;
            }
        
            // Otherwise, set the new color for the given index
            return {
              ...prevColors,
              [index]: color,
            };
        });
      };

    React.useImperativeHandle(ref, () => ({
        handlePrint: handlePrint,
        handleLigneColor: handleLigneColor,
    }));

    const handleRowSelectionChange = (newSelection) => {
        handleRowSelection(newSelection);

        customConsoleLog('newSelection : ', newSelection)
        const selectedRowIndex = rows.findIndex((row) => row.id === newSelection[0]);
        try {
            setTimeout(() => {
                apiRef.current.scrollToIndexes({ rowIndex: selectedRowIndex, colIndex: 0 });

            }, 200);

        }
        catch (e) {
            customConsoleLog(e)
        }
    };

    const isEmptyRow = (row) => {
        const res = (row.id_article === null || row.id_article === '' || row.id_article === undefined);
        customConsoleLog('row.id_article', row.id_article);
        customConsoleLog('isEmptyRow', res);
        return res;
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleAddNewRow = () => {
        // to avoid having empty rows between filled rows we should clean the rows which are empty and at the end of the list
        //const cleanedRows = rows.filter((row) => !isEmptyRow(row));

        if (rows.length === 0 || !isEmptyRow(rows[rows.length - 1])) {
            const newRowId = revisedRandId();
            setRows((prev) => [...prev, { id: newRowId, pu: 0, puht: 0, pp: 0, ppttc: 0, qte: 0 }]);
        }
    };

    const HandleonColumnHeaderDoubleClick = (params) => {
        if (onColumnHeaderDoubleClick) {
            onColumnHeaderDoubleClick(params);
        };
    };

    // const HandleOnCellClick = (params, event) => {
    //     customConsoleLog("HandleOnCellClick ", event);
    // }

    useEffect(() => {
        if (isReadOnly || noNewRow) return;
        handleAddNewRow();
    }
        , [rows]);






    //if isReadOnly is true we should disable the edit mode


    return (
        <>
            <div className='bg-white' style={{ minHeight: height, height: height, width: '100%' }}>

                <StripedDataGrid
                    getRowId={getRowId}
                    apiRef={apiRef}
                    rows={rows}
                    columns={customColumns}
                    editMode={isReadOnly ? 'none' : 'row'}
                    disableColumnSorting={true}
                    rowHeight={25}
                    columnHeaderHeight={25}
                    disableMultipleRowSelection={true}
                    getRowClassName={(params) =>
                        //params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        {
                            const baseClass = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
                            const customClass = rowColors[params.indexRelativeToCurrentPage] ? 'custom-row-bg' : '';
                            return cn(`${baseClass}`, `${customClass}`); // Combine both classes
                          }
                    }
                    getRowProps={(params) => {
                        const rowColor = rowColors[params.id];
                        return { style: { backgroundColor: rowColor } };
                      }}
                    initialState={{
                        pagination: {
                        }
                    }}
                    pageSizeOptions={[]}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    rowSelectionModel={select}
                    columnVisibilityModel={columnVisibilityModel}
                    ignoreDiacritics={true}
                    hideFooter={nofilter}
                    showColumnVerticalBorder={true}
                    showCellVerticalBorder={true}
                    processRowUpdate={processRowUpdate}
                    onColumnHeaderDoubleClick={(params) => {
                        HandleonColumnHeaderDoubleClick(params);
                    }}
                    slots={{
                        columnMenu: CustomColumnMenu

                    }}
                    disableColumnMenu={disableColumnMenu ? false : true}
                    //onCellClick={HandleOnCellClick}

                />


            </div>
            {
                pinnedRows && pinnedRows.length > 0
                    ?
                    <div className='bg-white' style={{ width: '100%', overflow: 'auto' }}>
                        <DataGridPro
                            className={`max-h-[${height}] min-h-[${height}]`}
                            rows={pinnedRows}
                            columns={customPinnedRowsColumns}
                            editMode={'none'}
                            disableColumnMenu={true}
                            disableColumnSorting={true}
                            rowHeight={25}
                            columnHeaderHeight={0}
                            disableMultipleRowSelection={true}
                            getRowClassName={(params) =>
                                //params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                {
                                    const baseClass = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
                                    const customClass = rowColors[params.indexRelativeToCurrentPage] ? 'custom-row-bg' : '';
                                    return cn(`${baseClass}`, `${customClass}`); // Combine both classes
                                  }
                            }
                            getRowProps={(params) => {
                                const rowColor = rowColors[params.id];
                                return { style: { backgroundColor: rowColor } };
                              }}
                            initialState={{
                                pagination: {
                                }
                            }}
                            pageSizeOptions={[]}
                            //onRowSelectionModelChange={handleRowSelectionChange}
                            //rowSelectionModel={select}
                            columnVisibilityModel={columnVisibilityModel}
                            ignoreDiacritics={true}
                            hideFooter={nofilter}
                            showColumnVerticalBorder={true}
                            showCellVerticalBorder={true}
                            disableRowSelectionOnClick={true}

                        //processRowUpdate={processRowUpdate}
                        />
                    </div>
                    :
                    <></>
            }


        </>
    )
    //}
});

export default RedEditDataTableBasic