
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, acces_vehicule_delete_api, acces_vehicule_info_api, acces_vehicule_list_api } from '../../global/GlobalVars';
import Acces_Vehicule from './Acces_Vehicule';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetAcces_VehiculeEmptyQuery, cleanGetAcces_VehiculeQuery } from './Acces_Vehicule';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'id_utilisateur_nom', headerName: 'Utilisateur', width: 200 },
  { field: 'iddepot_code', headerName: 'VEHICULES', width: 250 },
  { field: 'id_utilisateur', headerName: 'ID Utilisateur', width: 100 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetAcces_VehiculesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['Acces_VehiculeList'],
    queryFn: async () => await callAxiosApi(`${acces_vehicule_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddAcces_VehiculeLine = (queryClient, handleCloseAcces_Vehicule, handleRowSelectionAcces_VehiculesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${acces_vehicule_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['Acces_VehiculeList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addAcces_VehiculeLine onSuccess');
      customConsoleLog(newprod);
      handleCloseAcces_Vehicule();
      handleRowSelectionAcces_VehiculesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateAcces_VehiculeLine = (queryClient, handleCloseAcces_Vehicule, handleRowSelectionAcces_VehiculesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${acces_vehicule_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateAcces_VehiculeLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateAcces_VehiculeLine called')
      queryClient.setQueryData(['Acces_VehiculeList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetAcces_VehiculeQuery(newprod.id, queryClient);
      handleCloseAcces_Vehicule();
      handleRowSelectionAcces_VehiculesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteAcces_VehiculeLine = (queryClient, handleCloseAcces_Vehicule) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${acces_vehicule_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['Acces_VehiculeList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetAcces_VehiculeQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseAcces_Vehicule();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetAcces_VehiculesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['Acces_VehiculeList'] });
}


const Acces_VehiculesList = forwardRef((props, ref) => {
  //function Acces_VehiculesList({ removeWidget, isModal }) {
  const { handleGetid_acces_vehicule, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openAcces_VehiculeList, setOpenAcces_VehiculeList] = useState(false);
  const [openAcces_Vehicule, setOpenAcces_Vehicule] = useState(false);

  const [actionNameAcces_Vehicule, setActionNameAcces_Vehicule] = useState("")
  const [selectAcces_VehiculesList, setSelectionAcces_VehiculesList] = useState([]);

  const handleRowSelectionAcces_VehiculesList = (e) => {
    if (e != selectAcces_VehiculesList[0]) {
      setSelectionAcces_VehiculesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenAcces_Vehicule = () => {
    setOpenAcces_Vehicule(true);

    //BasicModalRef.current.open();
  }
  const handleCloseAcces_Vehicule = () => {
    setOpenAcces_Vehicule(false);
    //BasicModalRef.current.close();
    cleanGetAcces_VehiculeEmptyQuery(queryClient);
    cleanGetAcces_VehiculeQuery(selectAcces_VehiculesList[0], queryClient);
  }



  const [status_acces_vehicule, execute_acces_vehicule, resolve_acces_vehicule, reject_acces_vehicule, reset_acces_vehicule] = useAwaitableComponent();
  const [asyncConfirmDialogInfoAcces_Vehicule, setConfirmDialogInfoAcces_Vehicule] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogAcces_VehiculesList = status_acces_vehicule === "awaiting";

  const handleAwaitModalAcces_VehiculesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoAcces_Vehicule({ titre: mtitre, msg: mmsg });
      await execute_acces_vehicule();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_acces_vehicule();
    }
  };


  customConsoleLog('Acces_VehiculesList rendered')



  const { data: data_acces_vehiculeslist = [], error: error_acces_vehiculeslist,
    refetch: refecth_acces_vehiculeslist, isLoading: isLoading_acces_vehiculeslist, failureCount: failureCount_acces_vehiculeslist } =
    GetAcces_VehiculesListQuery();






  const handleFiltrerAcces_VehiculesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauAcces_VehiculesList = () => {

    clearToasts();
    setActionNameAcces_Vehicule(btnActionsName.nouveau);
    setSelectionAcces_VehiculesList([])
    handleOpenAcces_Vehicule();

  }

  const handleConsulterAcces_VehiculesList = () => {
    if (selectAcces_VehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Vehicule(btnActionsName.consulter);

    handleOpenAcces_Vehicule();
    customConsoleLog(selectAcces_VehiculesList[0]);
  }

  const handleDupliquerAcces_VehiculesList = () => {
    if (selectAcces_VehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Vehicule(btnActionsName.dupliquer);
    handleOpenAcces_Vehicule();
    customConsoleLog(selectAcces_VehiculesList[0]);
  }

  const handleModifierAcces_VehiculesList = () => {
    if (selectAcces_VehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Vehicule(btnActionsName.modifier);
    handleOpenAcces_Vehicule();
    customConsoleLog(selectAcces_VehiculesList[0]);
  }


  const handleSupprimerAcces_VehiculesList = async () => {
    if (selectAcces_VehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectAcces_VehiculesList[0])
    if (await handleAwaitModalAcces_VehiculesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameAcces_Vehicule(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteAcces_VehiculeLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenAcces_VehiculesList = (itemID) => {
    setOpenAcces_VehiculeList(true);


    if(itemID) {
      handleRowSelectionAcces_VehiculesList([itemID]);
    }
  }


  const handleFermerAcces_VehiculesList = () => {
    if (selectAcces_VehiculesList[0]) {
      if (handleGetid_acces_vehicule) {

        handleGetid_acces_vehicule(selectAcces_VehiculesList[0]);
      }
    }
    cleanGetAcces_VehiculesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetAcces_VehiculesListQuery(queryClient);
      cleanGetAcces_VehiculeEmptyQuery(queryClient);
      cleanGetAcces_VehiculeQuery(selectAcces_VehiculesList[0], queryClient);
      setOpenAcces_VehiculeList(false);
    }
    else{

      setOpenAcces_VehiculeList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_acces_vehiculeslist.filter(item => item.id == id)[0];
  }



  const addAcces_VehiculeLine = useAddAcces_VehiculeLine(queryClient, handleCloseAcces_Vehicule, handleRowSelectionAcces_VehiculesList);


  const updateAcces_VehiculeLine = useUpdateAcces_VehiculeLine(queryClient, handleCloseAcces_Vehicule, handleRowSelectionAcces_VehiculesList);

  const deleteAcces_VehiculeLine = useDeleteAcces_VehiculeLine(queryClient, handleCloseAcces_Vehicule);






  const DataTableAcces_VehiculesListRef = React.useRef(null);

  const handlePrintAcces_VehiculesList = () => {
    if (DataTableAcces_VehiculesListRef.current) {
      DataTableAcces_VehiculesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenAcces_VehiculesList(itemID);
    },
    handleClose() {
      handleFermerAcces_VehiculesList();
    }
  }));

  // if (isLoading_acces_vehiculeslist || error_acces_vehiculeslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerAcces_VehiculesList}
  //         refetch={refecth_acces_vehiculeslist}
  //         failureCount={failureCount_acces_vehiculeslist}
  //         error={error_acces_vehiculeslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openAcces_VehiculeList} handleClose={handleFermerAcces_VehiculesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_acces_vehiculeslist || error_acces_vehiculeslist
            ? <BasicLoading
              handleClose={handleFermerAcces_VehiculesList}
              refetch={refecth_acces_vehiculeslist}
              failureCount={failureCount_acces_vehiculeslist}
              error={error_acces_vehiculeslist} />
            : <>
              {
                showAsyncConfirmDialogAcces_VehiculesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogAcces_VehiculesList}
                  onSubmit={resolve_acces_vehicule}
                  onCancel={reject_acces_vehicule}
                  info={asyncConfirmDialogInfoAcces_Vehicule}
                />
              }


              {
                openAcces_Vehicule &&
                <BasicModal open={openAcces_Vehicule} isModal={true}>
                  <Acces_Vehicule
                    actionName={actionNameAcces_Vehicule}
                    selectedItem={getSelecteditemByID(selectAcces_VehiculesList[0])}
                    handleClose={handleCloseAcces_Vehicule}
                    addAcces_VehiculeLine={addAcces_VehiculeLine}
                    updateAcces_VehiculeLine={updateAcces_VehiculeLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Acces_Vehicules</h2>}

              <RedDataTableBasic
                ref={DataTableAcces_VehiculesListRef}
                rows={data_acces_vehiculeslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionAcces_VehiculesList} 
                select={selectAcces_VehiculesList}
                onRowDoubleClick={handleConsulterAcces_VehiculesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerAcces_VehiculesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauAcces_VehiculesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterAcces_VehiculesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerAcces_VehiculesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierAcces_VehiculesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintAcces_VehiculesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerAcces_VehiculesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerAcces_VehiculesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default Acces_VehiculesList



const Acces_VehiculesListwidgetKey = 'Acces_Vehiculeswidget'
export const Acces_VehiculesListwidget = { i: Acces_VehiculesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: Acces_VehiculesList, titre: 'Acces_Vehicules', id_fenetre:903 }
