import React from 'react'
import { checkString, cn  } from '../global/Tools';
import { customConsoleLog } from '../global/Tools';


const spanLabelClass = "whitespace-nowrap inline py-1 px-1 text-gray-600"
const inputClass = "bg-transparent border-transparent py-1 px-1 m-1 text-gray-600 focus:outline-none"
const inputReadOnlyClass = "bg-gray-100 border-transparent py-1 px-1 m-1 text-gray-600"

const divClass = "inline-flex bg-white rounded border border-gray-200 w-fit text-xxs"


function BasicCheckBox(props) {
  const { titre, className, readOnly, labelClass, divClassName, customCheckedValues, onChange, ...others } = props;

  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)} ${others.hidden ? 'hidden' : ''}`);
  const customLabelClass = cn(readOnly ? `${spanLabelClass} ${checkString(labelClass)}` : `${spanLabelClass} ${checkString(labelClass)} ${others.hidden ? 'hidden' : ''}`);

  const customDivClass = cn(`${divClass} ${checkString(divClassName)}`);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if(!customCheckedValues) {
      return onChange(event);
    }
    const newValue = isChecked ? customCheckedValues[0] : customCheckedValues[1];
    onChange({ target: { name: event.target.name , value: newValue, type: 'input' } });
  };


  
  
    return (
      <>
      <div className={customDivClass}>
        <input type="checkbox" {...others} onChange={handleCheckboxChange}  disabled={readOnly} className={`${customClass}`}  />
        {
          // customCheckedValues
          // ?
          // <input type="checkbox" name={name} {...others} disabled={readOnly} className={`${customClass}`}  />
          // :
        }
      
      {titre && <span className={customLabelClass}>{titre}</span>}
      </div>
      </>
    )
}

export default BasicCheckBox