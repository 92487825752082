
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import ReactDOM from 'react-dom';


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };


// const BasicModal = forwardRef((props, ref) => {
//   const [open, setOpen] = useState(false);
//   const {children, ...others} = props;

//   const handleOpen = () => {

//     setOpen(true);
//   }

//   const handleClose = () => {

//     setOpen(false);
//   }

//   useImperativeHandle(ref, () => ({
//     close: handleClose,
//     open: handleOpen
//   }));
  
//   return (
//     <div>
//       <Modal
//         open={open}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         ref={ref}
//       >
//         <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "background.paper", boxShadow: 24, p: 1 }}>
          
//           <Box id="modal-modal-description" >
//             {children}
//           </Box>
//         </Box>
//       </Modal>
//     </div>
//   );

// });

// export default BasicModal;



export default function BasicModal ({children, open, isModal }) {



  
  if(isModal){
    return (
      ReactDOM.createPortal(
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ position: "absolute", top: "30%", left: "30%", transform: "translate(-30%, -30%)", bgcolor: "background.paper", boxShadow: 24}}>
            
            <Box id="modal-modal-description"  className=' bg-slate-100 p-3 max-w-[1024px]'
          >
              {children}
              {/* <Button onClick={handleClose} id="close" name="close">Close</Button> */}
            </Box>
          </Box>
        </Modal>
      </div>
      , document.body)
    );
  }
  else
  {
    return <div>
      {children}
    </div>
  }
  

  
}