import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, ping_api, acces_vehicule_info_api, acces_vehicule_update_api, defaultSelectChargement } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import UtilisateursList, { GetUtilisateursListQuery } from '../utilisateur/UtilisateursList';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import BasicSelect from '../../components/BasicSelect';
import EtablissementsList, { GetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import BasicCheckBox from '../../components/BasicCheckBox';
import VehiculesList, { GetVehiculesListQuery } from '../vehicule/VehiculesList';
import { customConsoleLog } from '../../global/Tools';





const GetAcces_VehiculeEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetAcces_VehiculeEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetAcces_VehiculeQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetAcces_VehiculeQuery', itemID],
    queryFn: async () => await callAxiosApi(`${acces_vehicule_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddAcces_VehiculeQuery = (handleClose, addAcces_VehiculeLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${acces_vehicule_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddAcces_VehiculeQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Acces_Vehicule ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addAcces_VehiculeLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateAcces_VehiculeQuery = (itemID, updateAcces_VehiculeLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${acces_vehicule_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateAcces_VehiculeQuery', itemID],
    onSuccess: () => {
      const msg = `Acces_Vehicule mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateAcces_VehiculeQuery called');

        updateAcces_VehiculeLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetAcces_VehiculeEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetAcces_VehiculeEmptyQuery'], exact: true });
}

export const cleanGetAcces_VehiculeQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanAcces_VehiculeGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetAcces_VehiculeQuery', itemID], exact: true });
}




function Acces_Vehicule({ actionName, selectedItem, handleClose, addAcces_VehiculeLine, updateAcces_VehiculeLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Acces_Vehicule compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetAcces_VehiculeEmptyQuery() : GetAcces_VehiculeQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addAcces_Vehicule = useAddAcces_VehiculeQuery(handleClose, addAcces_VehiculeLine)
  const updateAcces_Vehicule = useUpdateAcces_VehiculeQuery(itemID, updateAcces_VehiculeLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('useEffect called');
    }
  }, [data]);




  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const UtilisateursListRef = React.useRef();

  const handleUtilisateursClick = () => {
    UtilisateursListRef.current.handleOpen(formValues.id_utilisateur);
  };

  const {
    data: data_utilisateur = defaultSelectChargement,
    isLoading: isLoading_utilisateur } = GetUtilisateursListQuery(canFetchOthers);





  const handleGetid_utilisateur = (value) => {
    customConsoleLog('handleGetid_utilisateur', value);
    setFormValues({
      ...formValues,
      'id_utilisateur': value,
    });
  }

  const VehiculesListRef = React.useRef();

  const handleVehiculesClick = () => {
    VehiculesListRef.current.handleOpen(formValues.id_vehicule);
  };

  const {
    data: data_vehicule = defaultSelectChargement,
    isLoading: isLoading_vehicule } = GetVehiculesListQuery(canFetchOthers);


  const handleGetid_vehicule = (value) => {
    customConsoleLog('handleGetid_vehicule', value);
    setFormValues({
      ...formValues,
      'iddepots': value,
    });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkAcces_Vehicule = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        addAcces_Vehicule.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addAcces_Vehicule.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateAcces_Vehicule.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseAcces_Vehicule = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }



  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      <UtilisateursList ref={UtilisateursListRef} handleGetid_utilisateur={handleGetid_utilisateur} isModal={true} />
      <VehiculesList ref={VehiculesListRef} handleGetid_vehicule={handleGetid_vehicule} isModal={true} />

      <div className="m-2 flex flex-row">
        <section className='w-[700px]'>
          <h2 className="text-lg font-bold mb-4">Acces_Vehicule</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-3 min-h-40 mt-8'>

              <BasicLabel className='text-xs' titre="Utilisateur :">
                <BasicSelect id="id_utilisateur" name="id_utilisateur" className="text-xs" listItems={data_utilisateur || []} value={formValues.id_utilisateur || ''}
                  textKey={'utilisateur'} valueKey={'id'}
                  fieldsConfig={[{ key: 'utilisateur', title: 'Utilisateur' }]}

                  onChange={handleChange} readOnly={isReadOnly}>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_utilisateur} onClick={handleUtilisateursClick}>...</BasicButtonSmall>
                </BasicSelect>
              </BasicLabel>

              <BasicLabel className='text-xs' titre="Vehicules :">
                <BasicSelect id="iddepots" name="iddepots" className="text-xs" listItems={data_vehicule || []} value={formValues.iddepots || ''}
                  textKey={'code'} valueKey={'id'}
                  fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'usage', title: 'Usage' }, { key: 'marque', title: 'Marque' }]}

                  onChange={handleChange} readOnly={isReadOnly}>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_vehicule} onClick={handleVehiculesClick}>...</BasicButtonSmall>
                </BasicSelect>
              </BasicLabel>


              <BasicLabel className='text-xs text-red-800' titre="Administateur :">
                <BasicCheckBox id="xtous" name="xtous" checked={formValues.xtous || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <BasicLabel className='text-xs ' titre="Acces :">
                <BasicCheckBox id="xacce" name="xacce" checked={formValues.xacce || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <BasicLabel className='text-xs ' titre="Ajouter :">
                <BasicCheckBox id="xajou" name="xajou" checked={formValues.xajou || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <BasicLabel className='text-xs ' titre="Modifier :">
                <BasicCheckBox id="xmodi" name="xmodi" checked={formValues.xmodi || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <BasicLabel className='text-xs ' titre="Supprimer :">
                <BasicCheckBox id="xsupp" name="xsupp" checked={formValues.xsupp || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <BasicLabel className='text-xs ' titre="Consulter :">
                <BasicCheckBox id="xcons" name="xcons" checked={formValues.xcons || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <BasicLabel className='text-xs ' titre="Imprimer :">
                <BasicCheckBox id="ximpr" name="ximpr" checked={formValues.ximpr || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>
            </div>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkAcces_Vehicule}
                  loading={addAcces_Vehicule.isPending || updateAcces_Vehicule.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseAcces_Vehicule}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Acces_Vehicule