import React from 'react'
import BasicButton from './BasicButton'
import BasicLoadingAnim from './BasicLoadingAnim';



function BasicLoading(props) {
  const { children, refetch, handleClose, error, failureCount } = props;


  const handleCloseLoading = () => {
    handleClose();
  }

  const handleRefetechLoading = () => {
    refetch();
  }

  if (error) {
    
    return (
      <>
        <div className='flex flex-row p-2'>

          <h1 className='ml-3 text-red-600'>{`${error}`}</h1>
        </div>
          <div>
            {children}

            <BasicButton type="button" onClick={() => handleRefetechLoading()}>Ressayer</BasicButton>
            {handleClose && <BasicButton type="button" onClick={() => handleCloseLoading()}>Fermer</BasicButton>}
          </div>
      </>
    )
  }


  return (
    <>
      <div className='flex flex-row p-2'>
        <BasicLoadingAnim width={24} height={24}/>
        <h1 className='ml-3'>{failureCount ? `${failureCount} : ` : ''}Chargement...</h1>

      </div>
      <div>
        {children}

        {/* <BasicButton type="button" onClick={() => handleRefetechLoading()}>Ressayer</BasicButton> */}
        <BasicButton type="button" onClick={() => handleCloseLoading()}>Fermer</BasicButton>
      </div>
    </>
  )
}

export default BasicLoading