import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import GridTemplate from './components/GridTemplate';
import { ArticlesListwidget } from './pages/article/ArticlesList';
import useUnsavedChangesWarning from './hooks/useUnsavedChangesWarning';
import BasicLeftMenu from './components/BasicLeftMenu';
import { accesTypes, fenetreType, typeMvt, user_api } from './global/GlobalVars';
import ReactDOM from 'react-dom';
import ChoisirDepot from './pages/choisir_depot/choisir_depot';
import { useLoginStore, useUtilisateurPermissionStore } from './stores/globalStore';
import { useNavigate } from 'react-router-dom';
import { callAxiosApi } from './interceptors/axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from './components/BasicLoading';
import { GetUserQuery } from './pages/login/Login';
import { checkPermission, customConsoleLog, showToastAccesError, showToastError } from './global/Tools';
import { GetUtilisateurPermissionQuery } from './pages/utilisateur/Utilisateur';
import { MessagesListwidget } from './pages/message/MessagesList';

const menu = [
  {
    id: 'fichier',
    titre: 'Fichier',
    link: '#',
    typefenetre: fenetreType.link,
    submenu: [
      {
        id: 'articles',
        titre: 'Articles',
        link: ArticlesListwidget,
        typefenetre: fenetreType.widget,
      },
      {
        id: 'fich_fermer',
        titre: 'Fermer',
        link: '/logout',
        typefenetre: fenetreType.link,
      },
    ],
  },
  {
    id: 'achats',
    titre: 'Achats',
    link: '#',
    typefenetre: fenetreType.link,
    submenu: [
      {
        id: 'ach_demande_dachats',
        titre: 'Demande D`Achats',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'ach_commandes',
        titre: 'Commandes',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'ach_creation_fardeaux',
        titre: 'Creation Fardeaux',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'ach_retours_qte',
        titre: 'Retours/qte',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'ach_avoirs_factures',
        titre: 'Avoirs/Factures',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'ach_factures',
        titre: 'Factures',
        link: '#',
        typefenetre: fenetreType.link,
      },
    ],
  },
  {
    id: 'ventes',
    titre: 'Ventes',
    link: '#',
    typefenetre: fenetreType.link,
    submenu: [
      {
        id: 'vte_devis',
        titre: 'Devis',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'vte_commandes',
        titre: 'Commandes',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'vte_livraisonunecde',
        titre: 'LivraisonUneCde',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'vte_retours_qte',
        titre: 'Retours/qte',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'vte_avoirs_factures',
        titre: 'Avoirs/Factures',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'vte_factururation_en_details',
        titre: 'Facturation en Details',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'vte_mouvements_supprimer',
        titre: 'Mouvements Supprimer',
        link: '#',
        typefenetre: fenetreType.link,
      },
    ],
  },
  {
    id: 'etats',
    titre: 'Etats',
    link: '#',
    typefenetre: fenetreType.link,
    submenu: [
      {
        id: 'etat_inventaires',
        titre: 'Inventaires',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'etat_inventaires_par_depot',
        titre: 'Inventaires par Depot',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'etat_inventaires_initial_fardeau',
        titre: 'Inventaires Initial Fardeau',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'etat_inventaires_initial_ste',
        titre: 'Inventaires Initial Ste',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'etat_inventaires_initial_depots',
        titre: 'Inventaires Initial Depot',
        link: '#',
        typefenetre: fenetreType.link,
      },
      {
        id: 'etat_mouvements',
        titre: 'Mouvements',
        link: '#',
        subsubmenu: [
          {
            id: 'etat_mouvements_des_achats',
            titre: 'Des Achats',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_mouvements_des_ventes',
            titre: 'Des Ventes',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_mouvements_articles_en_rupture',
            titre: 'Articles en Rupture',
            link: '#',
            typefenetre: fenetreType.link,
          },
        ],
      },
      {
        id: 'etat_situations',
        titre: 'Situations',
        link: '#',
        typefenetre: fenetreType.link,
        subsubmenu: [
          {
            id: 'etat_situations_de_commandes',
            titre: 'Situations de Commandes',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_client_detaillees',
            titre: 'Clients Détaillées',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_cli_det_recap',
            titre: '--- Recap',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_cli_det_recap_depots',
            titre: '--- Recap Depots',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_cli_det_recap_depots_impaye',
            titre: '--- Recap Depots Impaye',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_fournisseurs_detaillees',
            titre: 'Fournisseurs Détaillées',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_four_det_recap',
            titre: '--- Recap',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_four_det_recap_depots',
            titre: '--- Recap Depots',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_situations_balance_agee',
            titre: 'Balance Agée',
            link: '#',
            typefenetre: fenetreType.link,
          },
        ],
      },
      {
        id: 'etat_chiffre_daffaire',
        titre: 'Chiffre D`Affaire',
        link: '#',
        typefenetre: fenetreType.link,
        subsubmenu: [
          {
            id: 'etat_chiffre_daffaire_par_journee',
            titre: 'Par journée',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_famille',
            titre: 'Famille',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_articles',
            titre: 'Articles',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_Regions',
            titre: 'Regions',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_categories',
            titre: 'Categories',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_clients',
            titre: 'Clients',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_clients_marges',
            titre: 'Clients Marges',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_fournisseurs',
            titre: 'Fournisseurs',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_representants',
            titre: 'Representants',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_chiffre_daffaire_total_par_com',
            titre: 'Total Par/Commerciaux',
            link: '#',
            typefenetre: fenetreType.link,
          },
        ],
      },
      {
        id: 'etat_production',
        titre: 'Production',
        link: '#',
        typefenetre: fenetreType.link,
        subsubmenu: [
          {
            id: 'etat_production_productions',
            titre: 'Productions',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_production_encours_semi_fini',
            titre: 'Encours/Semi-finis',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_production_consommations',
            titre: 'Consommations',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_production_entree_sorties',
            titre: 'Entrée/Sorties',
            link: '#',
            typefenetre: fenetreType.link,
          },
          {
            id: 'etat_production_echanges',
            titre: 'Echanges',
            link: '#',
            typefenetre: fenetreType.link,
          },
        ],
      },
    ],
  },
  {
    id: 'messagerie',
    titre: 'Messagerie',
    link: '#',
    typefenetre: fenetreType.link,
    submenu: [
      {
        id: 'messages',
        titre: 'Messages',
        link: MessagesListwidget,
        typefenetre: fenetreType.widget,
      },
    ],
  },
]






const mynavclassName = "relative text-xs text-black z-50 bg-gray-300 border border-gray-500  shadow-lg"
const menu_Div = "group inline-block z-50 whitespace-nowrap ";
const menu_Btn = "outline-none  focus:outline-none  hover:bg-gray-400 px-3 py-1 flex items-center min-w-32";

const menu_SubMenu_Ul = "bg-gray-300 shadow-lg transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32";
const menu_SubMenu_Li = "relative px-3 py-1 border border-gray-400 hover:bg-gray-400";
const menu_SubMenu_Btn = "w-full text-left flex items-center outline-none focus:outline-none";

const menu_SubSubMenu_Ul = "bg-gray-300 shadow-lg absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32";
const menu_SubSubMenu_Li = "px-3 py-1 border border-gray-400 hover:bg-gray-400";
const menu_SubSubMenu_Btn = "w-full text-left flex items-center outline-none focus:outline-none";

function Layout(props) {

  useUnsavedChangesWarning(true);

  const [layouts, setLayouts] = useState([]);
  const defaultModalProps = { compo: '', type_mvt: '', titre: '', open: false, isModal: true, autoOpen: false, livraisonType:'' };
  const [modalprops, setModalProps] = useState(defaultModalProps);
  const [hasLayouts, setHasLayouts] = useState(layouts.length);
  customConsoleLog('hasLayouts', hasLayouts);


  const { logged, loginDetails, loginPermission, setLogin, setLogout } = useLoginStore();

  const queryClient = useQueryClient();
  const { data: data_user, isLoading: isLoading_user, error: error_user,
    refetch: refecth_user, failureCount: failureCount_user } = GetUserQuery();


  const { data: data_utilisateurpermission = [], 
    isLoading: isLoading_UtilisateurPermission } = GetUtilisateurPermissionQuery(logged, loginDetails.id, 0, 0, 'none');







  useEffect(() => {
    if (data_user) {
      setLogin(true, data_user, data_utilisateurpermission);
    }
    else {
      setLogin(false, {});
    }
  }, [isLoading_user, isLoading_UtilisateurPermission]);






  useEffect(() => {
    setHasLayouts(layouts.length);
  }, [layouts]);


  const navigate = useNavigate();



  const addLayout = (item) => {
    const layoutToAdd = item.link;
    const typefenetre = item.typefenetre;
    const layoutId = item.id;


    if (typefenetre === fenetreType.link && layoutToAdd === '/logout') {
      //to refresh the useStore of login
      window.location.href = `${layoutToAdd}`
    }
    else if (typefenetre === fenetreType.link) {
      if (layoutToAdd === '#') {
        customConsoleLog('is string #');
        return;
      }
      navigate(layoutToAdd);
      // window.location.href = `${layoutToAdd}`
      return;
    }
    else if (typefenetre === fenetreType.modal) {
      customConsoleLog('is modal');
      const { compo, type_mvt, titre, open, isModal, autoOpen, livraisonType } = layoutToAdd.props;

      setModalProps({ compo, type_mvt, titre, open, isModal, autoOpen, layoutId, livraisonType });

      //setModalProps({ ...layoutToAdd.props, layoutId });
    }
    else if (typefenetre === fenetreType.widget) {
      customConsoleLog('is widget');
      customConsoleLog('layoutToAdd', layoutToAdd);
      customConsoleLog('layoutId', layoutId);

      if(!layoutId){
        showToastError('Erreur de configuration de la fenetre', 5000, 'error');
        customConsoleLog('layoutId error', layoutId);
        return;
      }


    if(!checkPermission(loginPermission, accesTypes.acces, 0, layoutId)){
      showToastAccesError(5000, 'error');
      return;
    }

      if (layouts.some(item => item.i === layoutToAdd.i)) {
        return;
      }
      else {

        setLayouts(prevLayouts => [...prevLayouts, { ...layoutToAdd, x: 0, y: 0 }]);
        return;
      }
    }





    // if (layoutToAdd === '#') {
    //   customConsoleLog('is string #');
    //   return;
    // }
    // else if (typeof layoutToAdd === 'string' || layoutToAdd instanceof String) {
    //   customConsoleLog('is string');
    //   window.location.href = `${layoutToAdd}`
    //   return;
    // }
    // else if (layouts.some(item => item.i === layoutToAdd.i)) {
    //   return;
    // }
    // else {

    //   setLayouts(prevLayouts => [...prevLayouts, { ...layoutToAdd, x: 0, y: 0 }]);
    // }
  };



  // if (!logged) {
  //   window.location.href = '/login';
  // }

  const handleLayout = () => {
    window.location.href = '/logout';
  }

  if (!logged) {
    return (
      <>

        {
          isLoading_user || error_user
            ? <BasicLoading
              handleClose={handleLayout}
              refetch={refecth_user}
              failureCount={failureCount_user}
              error={error_user} />
            : <>
            </>
        }
      </>

    )
  }

  customConsoleLog('loginDetails', loginDetails);
  customConsoleLog('loginPermission', loginPermission);


  return (

    <>



      <nav className={mynavclassName}>
        {menu.map((item) => (
          <div key={item.id} className={menu_Div}>
            <button onClick={() => addLayout(item)} className={menu_Btn}>
              {item.titre}
            </button>
            <ul className={menu_SubMenu_Ul}>
              {item.submenu.map((childs) => (
                <li key={childs.id} className={menu_SubMenu_Li}>
                  <button onClick={() => addLayout(childs)} className={menu_SubMenu_Btn} >
                    {childs.titre}
                    {childs.subsubmenu && childs.subsubmenu.length > 0 && (
                      <span className="mr-auto">
                        <svg
                          className="fill-current h-4 w-4
                                  transition duration-150 ease-in-out"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                  {childs.subsubmenu && childs.subsubmenu.length > 0 && (
                    <>
                      <ul className={menu_SubSubMenu_Ul}>
                        {childs.subsubmenu.map((subsubitem) => (
                          <li key={subsubitem.id} className={menu_SubSubMenu_Li}>
                            <button onClick={() => addLayout(subsubitem)} className={menu_SubSubMenu_Btn}>
                              {subsubitem.titre}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))
        }
      </nav>

      <div className='flex flex-row min-h-screen'>
        {/* {hasLayouts < 1 &&
          <>
          </>
        } */}

        <div className='w-[200px]'>
          <BasicLeftMenu addLayout={addLayout} />
        </div>
        <div className=''>
          <GridTemplate layout={layouts} setLayouts={setLayouts} />
          {/* <ToolboxLayout/> */}
        </div>
      </div>
      <ChoisirDepot {...modalprops}
        handleClose={() => setModalProps(defaultModalProps)}
        addLayout={addLayout} />
    </>
  )
}

export default Layout