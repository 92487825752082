import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import BasicNumberInput from '../../components/BasicNumberInput'
import BasicDecimalInput from '../../components/BasicDecimalInput'
import { btnActionsName, ping_api, client_info_api, client_update_api, defaultSelectChargement } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import BasicSelect from '../../components/BasicSelect';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import BasicTextarea from '../../components/BasicTextarea';
import BasicContainer from '../../components/BasicContainer';
import CategoriesList, { GetCategoriesListQuery } from '../categorie/CategoriesList';
// import { GetPaysListQuery } from '../pays/PaysList';
// import { GetRegionsListQuery } from '../region/RegionsList';
import RepresentantsList, { GetRepresentantsListQuery } from '../representant/RepresentantsList';
import PayssList ,{ GetPayssListQuery } from '../pays/PayssList';
import RegionsList, { GetRegionsListQuery } from '../region/RegionsList';
import { customConsoleLog } from '../../global/Tools';





const GetClientEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetClientEmptyQuery'],
    queryFn: async () => 
    {
      await callAxiosApi(`${ping_api}`)
      return { 'type_tiers': 'C'}
    }, // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetClientQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetClientQuery', itemID],
    queryFn: async () => await callAxiosApi(`${client_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddClientQuery = (handleClose, addClientLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${client_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddClientQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Client ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addClientLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateClientQuery = (itemID, updateClientLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${client_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateClientQuery', itemID],
    onSuccess: () => {
      const msg = `Client mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateClientQuery called');

        updateClientLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetClientEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetClientEmptyQuery'], exact: true });
}

export const cleanGetClientQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanClientGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetClientQuery', itemID], exact: true });
}




function Client({ actionName, selectedItem, handleClose, addClientLine, updateClientLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Client compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetClientEmptyQuery() : GetClientQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addClient = useAddClientQuery(handleClose, addClientLine)
  const updateClient = useUpdateClientQuery(itemID, updateClientLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('useEffect called');
    }
  }, [data]);




  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkClient = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        addClient.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addClient.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateClient.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseClient = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }

  const RegionsListRef = React.useRef();

  const handleRegionsClick = () => {
    RegionsListRef.current.handleOpen(formValues.id_region);
  };

  const PaysListRef = React.useRef();

  const handlePaysClick = () => {
    PaysListRef.current.handleOpen(formValues.id_pays);
  };

  const RepresentantsListRef = React.useRef();

  const handleRepresentantsClick = () => {
    RepresentantsListRef.current.handleOpen(formValues.id_representant);
  };

  const CategoriesListRef = React.useRef();

  const handleCategoriesClick = () => {
    CategoriesListRef.current.handleOpen(formValues.id_categorie);
  };


  const {
    data: data_region = defaultSelectChargement,
    isLoading: isLoading_region } = GetRegionsListQuery(canFetchOthers);

  const {
    data: data_pays = defaultSelectChargement,
    isLoading: isLoading_pays } = GetPayssListQuery(canFetchOthers);


  const {
    data: data_representant = defaultSelectChargement,
    isLoading: isLoading_representant } = GetRepresentantsListQuery(canFetchOthers);

  const {
    data: data_categorie = defaultSelectChargement,
    isLoading: isLoading_categorie } = GetCategoriesListQuery(canFetchOthers);


  const handleGetid_categorie = (value) => {
    customConsoleLog('handleGetid_categorie', value);
    setFormValues({
      ...formValues,
      'id_categorie': value,
    });
  }

  const handleGetid_representant = (value) => {
    customConsoleLog('handleGetid_representant', value);
    setFormValues({
      ...formValues,
      'id_representant': value,
    });
  }

  const handleGetid_pays = (value) => {
    customConsoleLog('handleGetid_pays', value);
    setFormValues({
      ...formValues,
      'id_pays': value,
    });
  }

  const handleGetid_region = (value) => {
    customConsoleLog('handleGetid_region', value);
    setFormValues({
      ...formValues,
      'id_region': value,
    });
  }

  React.useEffect(() => {
    if(formValues.id_categorie && data_categorie){
      setFormValues({
        ...formValues,
        'tarif': data_categorie?.find(x => x.id == formValues.id_categorie).tarif,
      });
    }
  }, [data_categorie, formValues.id_categorie]);




  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }
      <div className="m-2 flex flex-row">
        <section className='w-[700px]'>
          <CategoriesList ref={CategoriesListRef} handleGetid_categorie={handleGetid_categorie} isModal={true} />
          <RepresentantsList ref={RepresentantsListRef} handleGetid_representant={handleGetid_representant} isModal={true} />
          <PayssList ref={PaysListRef} handleGetid_pays={handleGetid_pays} isModal={true} />
          <RegionsList ref={RegionsListRef} handleGetid_region={handleGetid_region} isModal={true} />
          <h2 className="text-lg font-bold mb-4">Client</h2>
          <form onSubmit={handleSubmit}>
            <BasicContainer>
              <div className='grid grid-cols-5'>
                <div className='col-span-3 grid grid-rows-2'>
                  <BasicLabel className='text-xxs' titre="Code :">
                    <BasicInput id="code" name="code" className='text-xxs' value={formValues.code || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>

                  <BasicLabel className='text-xxs' titre="Nom :">
                    <BasicInput id="nom" name="nom" className='text-xxs' value={formValues.nom || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                </div>
                <div className='col-span-2 grid grid-rows-2'>
                  <div></div>
                  <BasicLabel className='text-xxs' titre="">
                    <BasicNumberInput id="depotac" name="depotac" className='text-xxs' value={formValues.depotac || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                </div>
              </div>
            </BasicContainer>

            <BasicContainer className='mt-2'>
              <div className='grid grid-row-4'>
                <BasicLabel className='text-xxs' titre="Adresse :">
                  <BasicInput id="adresse" name="adresse" className='text-xxs' value={formValues.adresse || ''} onChange={handleChange} readOnly={isReadOnly} required />
                </BasicLabel>
                <BasicLabel className='text-xxs' titre="Address liv :">
                  <BasicInput id="adressel" name="adressel" className='text-xxs' value={formValues.adressel || ''} onChange={handleChange} readOnly={isReadOnly} required />
                </BasicLabel>
                <div className='grid grid-cols-2'>
                  <BasicLabel className='text-xxs' titre="ICE :">
                    <BasicInput id="ville" name="ville" className='text-xxs' value={formValues.ville || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                  <BasicLabel className='text-xxs' titre="Region :">
                    <BasicSelect id="id_region" name="id_region" listItems={data_region || []} value={formValues.id_region || ''} 
                    textKey={'region'}
                    fieldsConfig={[{key:'region', title: 'Region'}]}
                    onChange={handleChange} readOnly={isReadOnly}>
                      <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_region} onClick={handleRegionsClick}>...</BasicButtonSmall>
                    </BasicSelect>
                  </BasicLabel>
                </div>
                <div className='grid grid-cols-2'>
                  <BasicLabel className='text-xxs' titre="Pays :">
                    <BasicSelect id="id_pays" name="id_pays" listItems={data_pays || []} value={formValues.id_pays || ''} 
                    textKey={'pays'}
                    fieldsConfig={[{key:'pays', title: 'Pays'}]}
                     onChange={handleChange} readOnly={isReadOnly}>
                      <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_pays} onClick={handlePaysClick}>...</BasicButtonSmall>
                    </BasicSelect>
                  </BasicLabel>
                  <BasicLabel className='text-xxs' titre="Representant :">
                    <BasicSelect id="id_representant" name="id_representant" listItems={data_representant || []} value={formValues.id_representant || ''} 
                    textKey={'nom'}
                    fieldsConfig={[{key:'code', title: 'Code'}, {key:'nom', title: 'Nom'}]}
                     onChange={handleChange} readOnly={isReadOnly}>
                      <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_representant} onClick={handleRepresentantsClick}>...</BasicButtonSmall>
                    </BasicSelect>
                  </BasicLabel>
                </div>
              </div>
            </BasicContainer>

            <BasicContainer className='mt-2'>
              <div className='grid grid-cols-5 gap-2'>
                <div className='col-span-3'>
                  <BasicLabel className='text-xxs' titre="contact :">
                    <BasicInput id="contact" name="contact" className='text-xxs' value={formValues.contact || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                  <div className='grid grid-cols-2 gap-1'>
                    <div className='grid grid-rows-4'>
                      <BasicLabel className='text-xxs' titre="tel1 :">
                        <BasicInput id="tel_1" name="tel_1" className='text-xxs' value={formValues.tel_1 || ''} onChange={handleChange} readOnly={isReadOnly} required />
                      </BasicLabel>
                      <BasicLabel className='text-xxs' titre="tel2 :">
                        <BasicInput id="tel_2" name="tel_2" className='text-xxs' value={formValues.tel_2 || ''} onChange={handleChange} readOnly={isReadOnly} required />
                      </BasicLabel>
                      <BasicLabel className='text-xxs' titre="if :">
                        <BasicInput id="nif" name="nif" className='text-xxs' value={formValues.nif || ''} onChange={handleChange} readOnly={isReadOnly} required />
                      </BasicLabel>
                      <BasicLabel className='text-xxs' titre="rc :">
                        <BasicInput id="rc" name="rc" className='text-xxs' value={formValues.rc || ''} onChange={handleChange} readOnly={isReadOnly} required />
                      </BasicLabel>
                    </div>
                    <div className='grid grid-rows-4'>
                      <BasicLabel className='text-xxs' titre="fax :">
                        <BasicInput id="fax" name="fax" className='text-xxs' value={formValues.fax || ''} onChange={handleChange} readOnly={isReadOnly} required />
                      </BasicLabel>
                      <div className='row-span-3'>
                        <BasicLabel className='text-xxs' titre="GSM :" labelClass='items-start h-full'>
                          <BasicTextarea id="gsm" name="gsm" value={formValues.gsm || ''} onChange={handleChange} readOnly={isReadOnly} />
                        </BasicLabel>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-span-2 grid grid-rows-4'>
                  <BasicLabel className='text-xxs w-[150px]' titre="Compte :">
                    <BasicInput id="numcompte" name="numcompte" className='text-xxs' value={formValues.numcompte || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                  <BasicLabel className='text-xxs w-[150px]' titre="montant plafond :">
                    <BasicNumberInput id="plafond" name="plafond" className='text-xxs bg-yellow-300' value={formValues.plafond || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                  <BasicLabel className='text-xxs w-[150px]' titre="Remise auto :">
                    <BasicDecimalInput id="txrem" name="txrem" className='text-xxs bg-red-600 text-white' value={formValues.txrem || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                  <BasicLabel className='text-xxs w-[150px]' titre="Commercial :">
                    <BasicNumberInput id="commercial" name="commercial" className='text-xxs' value={formValues.commercial || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                </div>

              </div>
            </BasicContainer>

            <BasicContainer className='mt-2'>
              <div className='grid grid-cols-2'>
                <div className='grid grid-rows-3'>
                  <BasicLabel className='text-xxs' titre="Categorie :">
                    <BasicSelect id="id_categorie" name="id_categorie" listItems={data_categorie || []} value={formValues.id_categorie || ''} 
                    textKey={'libelle'}
                    fieldsConfig={[{key:'id', title: 'ID CAT'}, {key:'libelle', title: 'Libelle'}]}
                     onChange={handleChange} readOnly={isReadOnly}>
                      <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_categorie} onClick={handleCategoriesClick}>...</BasicButtonSmall>
                    </BasicSelect>
                  </BasicLabel>
                  <BasicLabel className='text-xxs w-[85px]' titre="Tarif :">
                    <BasicInput id="tarif" name="tarif" className='text-xxs' value={formValues.tarif || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                  <BasicLabel className='text-xxs w-[150px]' titre="Solde :">
                    <BasicDecimalInput id="solde" name="solde" className='text-xxs bg-yellow-300' value={formValues.solde || ''} onChange={handleChange} readOnly={isReadOnly} required />
                    <div className='text-xxs whitespace-nowrap pl-2'>-:Débiteur, +:Créditeur</div>
                  </BasicLabel>
                </div>
                <div className='grid grid-rows-3'>
                  <div className='row-span-3'>
                    <BasicLabel className='text-xxs' titre="Note :" labelClass='items-start h-full'>
                      <BasicTextarea id="notes" name="notes" value={formValues.notes || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </BasicLabel>
                  </div>
                </div>
              </div>

            </BasicContainer>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkClient}
                  loading={addClient.isPending || updateClient.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseClient}>Fermer</BasicButton>
            </div>

          </form>

        </section>
        {
          isReadOnly && <>
          <section className='w-[120px] m-2'>
            <div className='pt-[200px] px-2'>
  
              <BasicButtonSmall className='w-full text-mxs' type="button" onClick={() => { }}>Historiques</BasicButtonSmall>
              <BasicButtonSmall className='w-full text-mxs mt-3' type="button" onClick={() => { }}>Reglements</BasicButtonSmall>
              <BasicButtonSmall className='w-full text-mxs mt-3' type="button" onClick={() => { }}>Non Payes</BasicButtonSmall>
              <BasicButtonSmall className='w-full text-mxs mt-3' type="button" onClick={() => { }}>Releve + recap</BasicButtonSmall>
            </div>
          </section>
          </>
        }
      </div>
    </>
  )
}

export default Client