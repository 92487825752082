
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, vehicule_delete_api, vehicule_info_api, vehicule_list_api } from '../../global/GlobalVars';
import Vehicule from './Vehicule';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDateTime, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetVehiculeEmptyQuery, cleanGetVehiculeQuery } from './Vehicule';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Immatriculation', width: 200 },
  { field: 'usage', headerName: 'Usage', width: 100 },
  { field: 'marque', headerName: 'Marque', width: 100 },
  { field: 'type', headerName: 'Type', width: 100 },
  { field: 'genre', headerName: 'Genre', width: 100 },
  { field: 'carburant', headerName: 'Carburant', width: 50 },
  { field: 'n_chassis', headerName: 'N chassis', width: 100 },
  { field: 'puissance', headerName: 'Puissance', width: 100, type: 'number' },
  { field: 'cylindres', headerName: 'Cylindres', width: 100, type: 'number' },
  { field: 'poids_total', headerName: 'Poids Total', width: 100, type: 'number' },
  { field: 'remorque', headerName: 'Remorque', width: 100, type: 'boolean' },
  { field: 'mise_en_circulation', headerName: 'Date Mise en circ.', width: 100 },
  { field: 'date_de_mutation', headerName: 'Date de Mutation', width: 100 },
  { field: 'saisie_le', headerName: 'Saisie le', width: 200, type: 'date', valueFormatter: (params) => formatDateTime(params.value, false, true) },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetVehiculesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['VehiculeList'],
    queryFn: async () => await callAxiosApi(`${vehicule_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddVehiculeLine = (queryClient, handleCloseVehicule, handleRowSelectionVehiculesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${vehicule_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['VehiculeList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addVehiculeLine onSuccess');
      customConsoleLog(newprod);
      handleCloseVehicule();
      handleRowSelectionVehiculesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateVehiculeLine = (queryClient, handleCloseVehicule, handleRowSelectionVehiculesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${vehicule_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateVehiculeLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateVehiculeLine called')
      queryClient.setQueryData(['VehiculeList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetVehiculeQuery(newprod.id, queryClient);
      handleCloseVehicule();
      handleRowSelectionVehiculesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteVehiculeLine = (queryClient, handleCloseVehicule) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${vehicule_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['VehiculeList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetVehiculeQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseVehicule();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetVehiculesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['VehiculeList'] });
}


const VehiculesList = forwardRef((props, ref) => {
  //function VehiculesList({ removeWidget, isModal }) {
  const { handleGetid_vehicule, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openVehiculeList, setOpenVehiculeList] = useState(false);
  const [openVehicule, setOpenVehicule] = useState(false);

  const [actionNameVehicule, setActionNameVehicule] = useState("")
  const [selectVehiculesList, setSelectionVehiculesList] = useState([]);

  const handleRowSelectionVehiculesList = (e) => {
    if (e != selectVehiculesList[0]) {
      setSelectionVehiculesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenVehicule = () => {
    setOpenVehicule(true);

    //BasicModalRef.current.open();
  }
  const handleCloseVehicule = () => {
    setOpenVehicule(false);
    //BasicModalRef.current.close();
    cleanGetVehiculeEmptyQuery(queryClient);
    cleanGetVehiculeQuery(selectVehiculesList[0], queryClient);
  }



  const [status_vehicule, execute_vehicule, resolve_vehicule, reject_vehicule, reset_vehicule] = useAwaitableComponent();
  const [asyncConfirmDialogInfoVehicule, setConfirmDialogInfoVehicule] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogVehiculesList = status_vehicule === "awaiting";

  const handleAwaitModalVehiculesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoVehicule({ titre: mtitre, msg: mmsg });
      await execute_vehicule();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_vehicule();
    }
  };


  customConsoleLog('VehiculesList rendered')



  const { data: data_vehiculeslist = [], error: error_vehiculeslist,
    refetch: refecth_vehiculeslist, isLoading: isLoading_vehiculeslist, failureCount: failureCount_vehiculeslist } =
    GetVehiculesListQuery();






  const handleFiltrerVehiculesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauVehiculesList = () => {

    clearToasts();
    setActionNameVehicule(btnActionsName.nouveau);
    setSelectionVehiculesList([])
    handleOpenVehicule();

  }

  const handleConsulterVehiculesList = () => {
    if (selectVehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameVehicule(btnActionsName.consulter);

    handleOpenVehicule();
    customConsoleLog(selectVehiculesList[0]);
  }

  const handleDupliquerVehiculesList = () => {
    if (selectVehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameVehicule(btnActionsName.dupliquer);
    handleOpenVehicule();
    customConsoleLog(selectVehiculesList[0]);
  }

  const handleModifierVehiculesList = () => {
    if (selectVehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameVehicule(btnActionsName.modifier);
    handleOpenVehicule();
    customConsoleLog(selectVehiculesList[0]);
  }


  const handleSupprimerVehiculesList = async () => {
    if (selectVehiculesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectVehiculesList[0])
    if (await handleAwaitModalVehiculesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameVehicule(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteVehiculeLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenVehiculesList = (itemID) => {
    setOpenVehiculeList(true);


    if(itemID) {
      handleRowSelectionVehiculesList([itemID]);
    }
  }


  const handleFermerVehiculesList = () => {
    if (selectVehiculesList[0]) {
      if (handleGetid_vehicule) {

        handleGetid_vehicule(selectVehiculesList[0]);
      }
    }
    cleanGetVehiculesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetVehiculesListQuery(queryClient);
      cleanGetVehiculeEmptyQuery(queryClient);
      cleanGetVehiculeQuery(selectVehiculesList[0], queryClient);
      setOpenVehiculeList(false);
    }
    else{

      setOpenVehiculeList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_vehiculeslist.filter(item => item.id == id)[0];
  }



  const addVehiculeLine = useAddVehiculeLine(queryClient, handleCloseVehicule, handleRowSelectionVehiculesList);


  const updateVehiculeLine = useUpdateVehiculeLine(queryClient, handleCloseVehicule, handleRowSelectionVehiculesList);

  const deleteVehiculeLine = useDeleteVehiculeLine(queryClient, handleCloseVehicule);






  const DataTableVehiculesListRef = React.useRef(null);

  const handlePrintVehiculesList = () => {
    if (DataTableVehiculesListRef.current) {
      DataTableVehiculesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenVehiculesList(itemID);
    },
    handleClose() {
      handleFermerVehiculesList();
    }
  }));

  // if (isLoading_vehiculeslist || error_vehiculeslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerVehiculesList}
  //         refetch={refecth_vehiculeslist}
  //         failureCount={failureCount_vehiculeslist}
  //         error={error_vehiculeslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openVehiculeList} handleClose={handleFermerVehiculesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_vehiculeslist || error_vehiculeslist
            ? <BasicLoading
              handleClose={handleFermerVehiculesList}
              refetch={refecth_vehiculeslist}
              failureCount={failureCount_vehiculeslist}
              error={error_vehiculeslist} />
            : <>
              {
                showAsyncConfirmDialogVehiculesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogVehiculesList}
                  onSubmit={resolve_vehicule}
                  onCancel={reject_vehicule}
                  info={asyncConfirmDialogInfoVehicule}
                />
              }


              {
                openVehicule &&
                <BasicModal open={openVehicule} isModal={true}>
                  <Vehicule
                    actionName={actionNameVehicule}
                    selectedItem={getSelecteditemByID(selectVehiculesList[0])}
                    handleClose={handleCloseVehicule}
                    addVehiculeLine={addVehiculeLine}
                    updateVehiculeLine={updateVehiculeLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Vehicules</h2>}

              <RedDataTableBasic
                ref={DataTableVehiculesListRef}
                rows={data_vehiculeslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionVehiculesList} 
                select={selectVehiculesList}
                onRowDoubleClick={handleConsulterVehiculesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerVehiculesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauVehiculesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterVehiculesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerVehiculesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierVehiculesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintVehiculesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerVehiculesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerVehiculesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default VehiculesList



const VehiculesListwidgetKey = 'Vehiculeswidget'
export const VehiculesListwidget = { i: VehiculesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: VehiculesList, titre: 'Vehicules', id_fenetre: 805 }
