import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, defaultSelectChargement, lignesreglement_list_api, ping_api, reglement_info_api, reglement_update_api, typeMvt, typeTiers } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ClientOrFournisseur, clearToasts, formatDate, formatDateTime, formatDecimalNumber, getOnlyUpdatedValues, getTitreMvt, showToast, strIsNullOrEmpty } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import BasicDateInput from '../../components/BasicDateInput';
import BasicSelect from '../../components/BasicSelect';
import FournisseursList, { GetFournisseursListQuery } from '../fournisseur/FournisseursList';
import ClientsList, { GetClientsListQuery } from '../client/ClientsList';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import Mode_ReglementsList, { GetMode_ReglementsListQuery } from '../mode_reglement/Mode_ReglementsList';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import BasicNumberInput from '../../components/BasicNumberInput';
import RedEditDataTableBasic from '../../components/RedEditDataTableBasic';
import BasicContainer from '../../components/BasicContainer';
import BasicRadio from '../../components/BasicRadio';
import BasicCheckBox from '../../components/BasicCheckBox';
import ReglementsMvtsList from './ReglementsMvtsList';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useLoginStore } from '../../stores/globalStore';
import dayjs from 'dayjs';
import { customConsoleLog } from '../../global/Tools';





const GetReglementEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetReglementEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetReglementQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetReglementQuery', itemID],
    queryFn: async () => await callAxiosApi(`${reglement_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddReglementQuery = (handleClose, addReglementLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${reglement_update_api}${0}`, vals, true, 'post');
    },
    mutationKey: ['AddReglementQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Reglement ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addReglementLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateReglementQuery = (itemID, updateReglementLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {

      customConsoleLog('useUpdateReglementQuery data', vals);
      return await callAxiosApi(`${reglement_update_api}${vals.id}`, vals, true, 'post');
    }
    ,
    mutationKey: ['UpdateReglementQuery', itemID],
    onSuccess: () => {
      const msg = `Reglement mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateReglementQuery called');

        updateReglementLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetReglementEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetReglementEmptyQuery'], exact: true });
}

export const cleanGetReglementQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanReglementGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetReglementQuery', itemID], exact: true });
}

export const GetLignesReglementsListQuery = (formValues, canFetch = true) => {
  const params = formValues;
  customConsoleLog('params', params);

  return useQuery({
    queryKey: ['LignesReglementsList', params?.id_reglement],
    queryFn: async () => {
      customConsoleLog('LignesReglementsList called');
      const tmp = await callAxiosApi(`${lignesreglement_list_api}`, { params }, true);


      const convertFormart = tmp.map((ligne) => {
        return {
          id: ligne.id,
          id_mvt: ligne.id_mvt,
          ref_mvt: ligne.ref_mvt,
          date_mvt: ligne.date_mvt,
          montant_mvt: ligne.montant_mvt,
          montant: ligne.montant,
          type_mvt: ligne.type_mvt,
        }
      });

      return convertFormart;
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

export const cleanGetLignesReglementsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['LignesReglementsList'] });
}

function CustomDecimalEditComponent(props) {
  const { id, value, field, hasFocus, decimalPlaces, sameValue } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const maxFractionDigits = decimalPlaces ? decimalPlaces : 2;
  const minFractionDigits = decimalPlaces ? decimalPlaces : 2;

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (sameValue) {
      sameValue.forEach((childfield) => {
        apiRef.current.setEditCellValue({ id, field: childfield, value: newValue });
      });
    }
  };

  return (
    <BasicDecimalInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange}
      maxFractionDigits={maxFractionDigits} minFractionDigits={minFractionDigits} />
  )
}


const LignesReglementcolumns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'id_mvt', headerName: 'ID Mvt', width: 100 },
  { field: 'ref_mvt', headerName: 'N° Fac/BL', width: 100 },
  { field: 'date_mvt', headerName: 'Date Fac/BL', type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  {
    field: 'montant_mvt', headerName: 'Mont Fac/BL', width: 100, editable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
    renderEditCell: (params) => <CustomDecimalEditComponent {...params} decimalPlaces={2} />,
  },
  {
    field: 'montant', headerName: 'Montant Réglé', width: 100, editable: true,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
    renderEditCell: (params) => <CustomDecimalEditComponent {...params} decimalPlaces={2} />,
  },
];

const LignesReglementhideColumns = {
  id: false,
  id_mvt: false,
}


export const LignesReglement = (props) => {
  const { id_reglement, lignesreglementList, setLignesreglementList, isHt, height, width, columns, columnVisibilityModel,  ...others } = props;
  const queryClient = useQueryClient();
  const [selectedLigneReglement, setSelectedLigneReglement] = React.useState([]);

  const DataTableLignesReglementRef = React.useRef(null);
  

  const { data = [], error, isError, isLoading, failureCount, refetch } = GetLignesReglementsListQuery({ id_reglement: id_reglement });

  const handleRowSelectionLignesReglement = (e) => {
    if (e != selectedLigneReglement[0]) {
      setSelectedLigneReglement(e);
      customConsoleLog(e);
    }
  }

  React.useEffect(() => {
    if (data && !isLoading) {
      setLignesreglementList(data);
    }
  }, [isLoading]);

  customConsoleLog('LignesReglement compo rendered');
  customConsoleLog('isReadOnly', others.isReadOnly);


  return (
    <>
      <div className='w-full'>
        <div>

          <RedEditDataTableBasic
            nofilter={true}
            ref={DataTableLignesReglementRef}
            rows={lignesreglementList}
            setRows={setLignesreglementList}
            columns={columns || LignesReglementcolumns}
            columnVisibilityModel={columnVisibilityModel || LignesReglementhideColumns}
            handleRowSelection={handleRowSelectionLignesReglement}
            select={selectedLigneReglement}
            isReadOnly={others.isReadOnly}
            height={height || '140px'}
            width={width || '100%'}
            canDelete={true}
            pinnedRows={others.pinnedRows || []}
            noNewRow={others.noNewRow}
          />
        </div>
      </div>
    </>
  );
}






function Reglement(props) {

  const { actionName, selectedItem, handleClose, addReglementLine, updateReglementLine, type_mvt, ...others } = props;

  customConsoleLog('Reglement compo rendered');
  customConsoleLog('Reglement props', props);





  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  // useLoginStore
  const id_utilisateur = useLoginStore(state => state.loginDetails.id_utilisateur);


  customConsoleLog('id_utilisateur', id_utilisateur);



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetReglementEmptyQuery() : GetReglementQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addReglement = useAddReglementQuery(handleClose, addReglementLine)
  const updateReglement = useUpdateReglementQuery(itemID, updateReglementLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };

  const isCliOrFr = ClientOrFournisseur(type_mvt);

  const {
    data: data_tiers = defaultSelectChargement,
    isLoading: isLoading_tiers } = isCliOrFr == typeTiers.client ? GetClientsListQuery(canFetchOthers, 'nom') : GetFournisseursListQuery(canFetchOthers, 'nom');



  const { data: data_mode_reglement = [],
    isLoading: isLoading_mode_reglement } = GetMode_ReglementsListQuery(canFetchOthers, 'libelle');

  const [lignesreglementList, setLignesReglementList] = React.useState([]);


  const tiersNom = data_tiers.find(x => x.id == formValues.id_tiers)?.nom || '';

  const modeReglementLibelle = formValues.id_reglement ? data_mode_reglement?.find((mode) => mode.id == formValues.id_reglement)?.libelle : '';

  const FournisseursListRef = React.useRef();
  const ModeReglementListRef = React.useRef();
  const ReglementsMvtsListRef = React.useRef();


  const handleFournisseurClick = () => {
    customConsoleLog('handleFournisseurClick');
    FournisseursListRef.current.handleOpen(formValues.id_tiers);
  }

  const handleModeReglementClick = () => {
    customConsoleLog('handleModeReglementClick');
    ModeReglementListRef.current.handleOpen(formValues.id_reglement);
  }

  const handleGetid_mvt = (value) => {
    customConsoleLog('handleGetid_mvt', value);
    if (value && value.length > 0) {
      const tmp = [];
      const montant = formValues.montant || 0;
      let totalspent = 0 + lignesreglementList.reduce((acc, curr) => acc + curr.montant, 0);

      for (const mvt of value) {
        if (montant == 0) {
          const tmpmvt = {
            id: Math.random().toString(36).substring(7),
            id_reglement: formValues.id,
            id_mvt: mvt.id,
            ref_mvt: mvt.ref_mvt,
            date_mvt: mvt.date_mvt,
            montant_mvt: mvt.reste,
            montant: mvt.reste,
          }
          tmp.push(tmpmvt);
        }
        else {
          if (mvt.reste > 0) {
            const tmpmvt = {
              id: Math.random().toString(36).substring(7),
              id_reglement: formValues.id,
              id_mvt: mvt.id,
              ref_mvt: mvt.ref_mvt,
              date_mvt: mvt.date_mvt,
              montant_mvt: mvt.reste,
              montant: mvt.reste,
            }
            totalspent += mvt.reste;
            if (totalspent <= montant) {
              tmp.push(tmpmvt);
            }
            else {
              const diff = totalspent - montant;
              tmpmvt.montant = mvt.reste - diff;
              tmp.push(tmpmvt);
              break;
            }
          }

        }
      }

      // const tmp = value.map((mvt) => {

      //   return {
      //     id: Math.random().toString(36).substring(7),
      //     id_reglement: formValues.id,
      //     id_mvt: mvt.id,
      //     ref_mvt: mvt.ref_mvt,
      //     date_mvt: mvt.date_mvt,
      //     montant_mvt: mvt.total,
      //     montant: mvt.reste,
      //   }
      // });

      // const tmp = value.forEach((v) => {
      //   v['id_reglement'] = formValues.id;
      //   v['id_mvt'] = v['id'];
      //   v['montant_mvt'] = v['total'];
      //   v['montant'] = v['reste'];
      //   delete v['id'];
      //   delete v['total'];
      //   delete v['reste'];
      //   // generate random id for each ligne alphanum
      //   v['id'] = Math.random().toString(36).substring(7);
      // });



      setLignesReglementList((prev) => { return [...prev, ...tmp] });
    }
  }

  const handleRIcClick = () => {
    customConsoleLog('handleRIcClick');
  }

  const handleRIDClick = () => {
    customConsoleLog('handleRIDClick');
    // open ReglementsMvtsList
    ReglementsMvtsListRef.current.handleOpen(formValues.id);
  }

  React.useEffect(() => {
    if (actionName == btnActionsName.nouveau) {
      const tmp = {
        id: 0,
        ref_reglement: '',
        date: formatDate(dayjs(), true),
        id_tiers: '',
        id_reglement: '',
        libelle: '',
        montant: 0,
        etat: 0,
        date_depot: formatDate(dayjs(), true),
        situation_reg: 0,
        depose_le: null,
        n_piece: '',
        date_releve: null,
        pr: false,
        lettre: 'N',
        iddepots: others.id_etablissement,
        id_utilisateur: id_utilisateur,
        lignes: [],
      }

      setInitialData(tmp);
      setFormValues(tmp);
      customConsoleLog('useEffect called nouveau');
    }
    else {
      if (data) {
        setInitialData(data);
        setFormValues(data);
        customConsoleLog('useEffect called');
      }

    }
  }, [data]);




  const handleGetid_fournisseur = (value) => {
    if (value == formValues.id_tiers) return;
    customConsoleLog('handleGetid_fournisseur', value);
    setFormValues({
      ...formValues,
      'id_tiers': value
    });
  }

  const handleGetid_mode_reglement = (value) => {
    if (value == formValues.id_reglement) return;
    customConsoleLog('handleGetid_mode_reglement', value);
    setFormValues({
      ...formValues,
      'id_reglement': value
    });
  }




  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const handleChange = (e) => {
    const { name, value, type, checked, customCheckedValues } = e.target;

    customConsoleLog('handleChange ', value);
    customConsoleLog('handleChange ', name);

    customConsoleLog('customCheckedValues ', customCheckedValues);
    customConsoleLog('customCheckedValues ', e.target);


    if (name == 'id_reglement') {

      const tmp = data_mode_reglement.find((mode) => mode.id == value);

      setFormValues(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
        // change libelle conditionally if name equals id_reglement
        'libelle': tmp?.libelle || prevState.libelle,
        'etat': tmp?.code == 'ESP' ? 2 : 0,
        'date_releve': tmp?.code == 'ESP' ? formatDate(dayjs(), true) : null,
      }));

    }
    else if (name == 'etat') {
      setFormValues(prevState => ({
        ...prevState,
        etat: value,
        'date_releve': value == 2 ? formatDate(dayjs(), true) : null,
      }));
    }
    else {


      setFormValues(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));

    }


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkReglement = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {

      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');

        const tmplignesreglementList = lignesreglementList.map((ligne) => {
          const { id, date_mvt, id_mvt, id_reglement, montant, montant_mvt, ref_mvt, ...rest } = ligne;

          const tmpline = {
            montant: montant,
            id_utilisateur: id_utilisateur,
            saisie_le: formatDateTime(dayjs(), true),
            id_mvt: id_mvt,
            id_regle: id_reglement,
          }

          return tmpline;
        });

        const tmp = { ...formValues, lignes: tmplignesreglementList };
        addReglement.mutate(tmp);

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');

        const tmplignesreglementList = lignesreglementList.map((ligne) => {
          const { id, date_mvt, id_mvt, id_reglement, montant, montant_mvt, ref_mvt, ...rest } = ligne;

          const tmpline = {
            montant: montant,
            id_utilisateur: id_utilisateur,
            saisie_le: formatDateTime(dayjs(), false),
            id_mvt: id_mvt,
            id_regle: id_reglement,
          }

          return tmpline;
        });

        const tmp = { ...formValues, lignes: tmplignesreglementList };
        addReglement.mutate(tmp);

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');

        const tmplignesreglementList = lignesreglementList.map((ligne) => {
          const { id, date_mvt, id_mvt, id_reglement, montant, montant_mvt, ref_mvt, ...rest } = ligne;

          const tmpline = {
            montant: montant,
            id_utilisateur: id_utilisateur,
            saisie_le: formatDateTime(dayjs(), false),
            id_mvt: id_mvt,
            id_regle: id_reglement,
          }

          return tmpline;
        });

        const tmp = { ...getOnlyUpdatedValues(initialData, formValues), lignes: tmplignesreglementList };
        updateReglement.mutate(tmp);
        customConsoleLog('updateReglement called');
        customConsoleLog('lignesreglementList', lignesreglementList);

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseReglement = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }

  const ridParams = {
    type_mvt: type_mvt,
    id_tiers: formValues.id_tiers,
    iddepots: others.id_etablissement,
  }



  const RidBtnDisabled = strIsNullOrEmpty(formValues.id_tiers) || isReadOnly;



  const totalsum = lignesreglementList.reduce((acc, curr) => acc + curr.montant, 0);
  const tmpsumobj = [{
    id: 'totalsum',
    id_reglement: formValues.id,
    id_mvt: 'totalsum',
    ref_mvt: 'Total',
    date_mvt: '',
    montant_mvt: '',
    montant: totalsum,
  }];

  // const tmplignesreglementList = [...lignesreglementList, ...tmpsumobj];

  // const pinnedRows = {
  //   bottom: [
  //     { id: 'totalsum' },
  //   ],
  // };


  const BtnOkDisabled = strIsNullOrEmpty(formValues.ref_reglement) || strIsNullOrEmpty(formValues.id_tiers) || strIsNullOrEmpty(formValues.id_reglement) || Number(formValues.montant) == 0 || totalsum > Number(formValues.montant) || isReadOnly;

  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }
      {
        isCliOrFr == typeTiers.client
          ? <ClientsList ref={FournisseursListRef} handleGetid_client={handleGetid_fournisseur} isModal={true} />
          : <FournisseursList ref={FournisseursListRef} handleGetid_fournisseur={handleGetid_fournisseur} isModal={true} />
      }
      <Mode_ReglementsList
        ref={ModeReglementListRef}
        handleGetid_mode_reglement={handleGetid_mode_reglement}
        isModal={true}
      />
      <ReglementsMvtsList
        ref={ReglementsMvtsListRef}
        handleGetid_reglementmvt={handleGetid_mvt}
        ridParams={ridParams}
        isModal={true}
      />

      <div className="m-2 flex flex-row">
        <section className='w-[1000px]'>
          <h2 className="text-lg font-bold mb-4">Reglement</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-1 min-h-40 mt-8'>

              <div className='grid grid-cols-7'>
                <div className='col-span-2'>
                  <BasicLabel className='text-xs' titre="ref :">
                    <BasicInput id="ref_reglement" name="ref_reglement" className='text-xxs' value={formValues.ref_reglement || ''} onChange={handleChange} readOnly={isReadOnly} required />
                  </BasicLabel>
                </div>
                <div className='col-span-2'>
                  <BasicLabel className='text-xs' titre='Date : '>
                    <BasicDateInput id="date" name="date" value={formValues.date || ''} onChange={handleChange} readOnly={isReadOnly} />
                  </BasicLabel>
                </div>

              </div>

              <div className='grid grid-cols-7'>
                <div className='col-span-4'>
                  <BasicLabel className='text-xs w-[100px]' titre={`Tiers :`}>

                    <BasicSelect id="id_tiers" name="id_tiers" className={`text-xxs`}
                      listItems={data_tiers || []} value={formValues.id_tiers || ''}
                      textKey={'code'}
                      fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'nom', title: 'Nom' }]}
                      onChange={handleChange}
                      isNotClearable={true}
                      isDisabled={isReadOnly}
                      hasError={strIsNullOrEmpty(formValues.id_tiers)} >
                    </BasicSelect>

                    <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_tiers} onClick={handleFournisseurClick}>...</BasicButtonSmall>
                  </BasicLabel>
                </div>
                <div className='text-xs italic text-blue-600 col-span-2'>{`${tiersNom}`}</div>

              </div>





              <div className='grid grid-cols-7'>
                <div className='col-span-3 inline-flex'>

                  <BasicLabel className='text-xs' titre='Reglement : '>
                  </BasicLabel>
                  <BasicSelect id="id_reglement" name="id_reglement" className={`text-xxs`}
                    listItems={data_mode_reglement || []} value={formValues.id_reglement || ''}
                    textKey={'code'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                    onChange={handleChange}
                    isNotClearable={false}
                    isDisabled={isReadOnly}
                  hasError={strIsNullOrEmpty(formValues.id_reglement)} 
                  >
                  </BasicSelect>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_mode_reglement} onClick={handleModeReglementClick}>...</BasicButtonSmall>
                </div>
                <div className='text-xs italic text-blue-600 col-span-2'>{`${modeReglementLibelle}`}</div>
              </div>

              <div className='grid grid-cols-7'>
                <div className='col-span-5 inline-flex'>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_tiers} onClick={handleRIcClick}>RIc</BasicButtonSmall>
                  <BasicLabel className='text-xs' titre="Libelle :">
                  </BasicLabel>
                  <BasicInput id="libelle" name="libelle" className='text-xxs' value={formValues.libelle || ''} onChange={handleChange} readOnly={isReadOnly} required />

                </div>
              </div>


              <div className='grid grid-cols-7'>
                <div className='col-span-3 inline-flex'>
                  {
                    // disable RID button if id_tiers is empty or isReadOnly

                  }
                  <BasicButtonSmall type="button" disabled={RidBtnDisabled} loading={isLoading_tiers} onClick={handleRIDClick}>RID</BasicButtonSmall>
                  <BasicLabel className='text-xs' titre="Montant :">
                  </BasicLabel>
                  <BasicDecimalInput id="montant" name="montant" className='text-xs bg-yellow-300 border-black' value={formValues.montant || ''} onChange={handleChange} readOnly={isReadOnly} />

                </div>
                <BasicNumberInput id="unknown" name="unknown" className='text-xs bg-yellow-300 border-black' value={formValues.unknown || '0'} onChange={handleChange} readOnly={isReadOnly} />
                <div className='col-span-2'>
                  <BasicLabel className='text-xxs w-[150px]' titre='Date Depot : '>
                    <BasicDateInput id="date_depot" name="date_depot" value={formValues.date_depot || ''} onChange={handleChange} readOnly={isReadOnly} />
                  </BasicLabel>
                </div>
              </div>




              <BasicLabel className='text-xxs'>
              </BasicLabel>



              <div className='grid grid-cols-5 gap-1'>
                {!formValues.id && !isNew
                  ?
                  <div className='col-span-3'></div>
                  :
                  <div className='col-span-3'>
                    <LignesReglement
                      id_reglement={formValues.id}
                      lignesreglementList={lignesreglementList}
                      setLignesreglementList={setLignesReglementList}
                      isReadOnly={isReadOnly}
                      pinnedRows={tmpsumobj}
                      noNewRow={true}
                    />
                  </div>
                }

                <BasicContainer className="col-span-2 flex flex-col">

                  <BasicRadio type="radio" id="en_attente_depot" name="etat" value="0" titre="En attente Depot" selectedValue={formValues.etat} onChange={handleChange} className="text-xs"  readOnly={isReadOnly}/>
                  <div className='flex flex-row'>
                    <BasicRadio type="radio" id="depose_le_r" name="etat" value="1" titre="Deposé le" selectedValue={formValues.etat} onChange={handleChange} className="text-xs" readOnly={isReadOnly} />
                    <BasicDateInput id="depose_le" name="depose_le" value={formValues.depose_le || ''} onChange={handleChange} readOnly={isReadOnly} />
                  </div>
                  <div>
                    <BasicLabel className='text-xxs' titre="N° piece :">
                      <BasicInput id="n_piece" name="n_piece" className='text-xxs' value={formValues.n_piece || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </BasicLabel>
                  </div>
                  <div className='flex flex-row'>
                    <BasicRadio type="radio" id="paye_le_r" name="etat" value="2" titre="Paye le" selectedValue={formValues.etat} onChange={handleChange} className="text-xs" readOnly={isReadOnly} />
                    <BasicDateInput id="date_releve" name="date_releve" value={formValues.date_releve || ''} onChange={handleChange} readOnly={isReadOnly} />

                  </div>
                  <BasicRadio type="radio" id="retour_impaye" name="etat" value="3" titre="Retour impaye" selectedValue={formValues.etat} onChange={handleChange} className="text-xs" readOnly={isReadOnly} />
                  <BasicCheckBox id="probleme_resolu" name="pr" className="text-xs" titre="Probleme d`impaye résolu" checked={formValues.pr} onChange={handleChange} readOnly={isReadOnly} />

                  <BasicCheckBox id="lettre_oui_non" name="lettre" className="text-xs" titre="Lettré Oui/Non" checked={formValues.lettre} onChange={handleChange} readOnly={isReadOnly} customCheckedValues={['O', 'N']} />
                </BasicContainer>

              </div>

            </div>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkReglement}
                  loading={addReglement.isPending || updateReglement.isPending} disabled={BtnOkDisabled}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseReglement}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Reglement