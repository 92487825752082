
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { accesTypes, btnActionsName, defaultSelectChargement, reglement_delete_api, reglement_info_api, reglement_list_api, typeMvt, typeTiers } from '../../global/GlobalVars';
import Reglement, { LignesReglement, cleanGetLignesReglementsListQuery } from './Reglement';
import BasicButton from '../../components/BasicButton';
import { ClientOrFournisseur, DateFormat, checkPermission, clearToasts, formatDate, formatDecimalNumber, getTitreMvt, showToast, showToastAccesError, showToastError, strIsNullOrEmpty, validateValues } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetReglementEmptyQuery, cleanGetReglementQuery } from './Reglement';
import BasicSelect from '../../components/BasicSelect';
import dayjs from 'dayjs';
import BasicLabel from '../../components/BasicLabel';
import BasicDateInput from '../../components/BasicDateInput';
import BasicCheckBox from '../../components/BasicCheckBox';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import { GetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import { GetClientsListQuery } from '../client/ClientsList';
import { GetRepresentantsListQuery } from '../representant/RepresentantsList';
import { GetMode_ReglementsListQuery } from '../mode_reglement/Mode_ReglementsList';
import { GetUtilisateursListQuery } from '../utilisateur/UtilisateursList';
import { GetFournisseursListQuery } from '../fournisseur/FournisseursList';
import BasicInput from '../../components/BasicInput';
import { GetReglementsMvtsListQuery } from './ReglementsMvtsList';
import { customConsoleLog } from '../../global/Tools';
import { useLoginStore } from '../../stores/globalStore';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false }, // id_regle
  { field: 'etablissements_code', headerName: 'Depots', width: 50 },
  { field: 'date', headerName: 'Date', type: 'date', width: 100, valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'montant', headerName: 'Montant', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'modesreglementcode', headerName: 'Regl', width: 50 }, // id modereglement
  { field: 'libelle', headerName: 'Libelle', width: 200 },
  { field: 'etat', headerName: 'Etat', width: 50 },
  { field: 'lettre', headerName: 'Lettré', width: 50 },
  { field: 'tiers_nom', headerName: 'Nom', width: 200 },
  { field: 'date_depot', headerName: 'Date Depot', type: 'date', width: 100, valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'tiers_code', headerName: 'Code', width: 100 },
  { field: 'depose_le', headerName: 'Deposé le', type: 'date', width: 100, valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'piece_depot', headerName: 'Piece', width: 100 },
  { field: 'date_releve', headerName: 'Date releve', type: 'date', width: 100, valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'ref_reglement', headerName: 'Réf', width: 100 },
  { field: 'id_utilisateur', headerName: 'U', width: 50 },
  { field: 'pr', headerName: 'PR', type: 'boolean', width: 50 },
  { field: 'iddepots', headerName: 'ID Depots', type: 'number', width: 50 },
  { field: 'avoc', headerName: 'AVOC', width: 50 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetReglementsListQuery = (canFetch = true, formValues = {}) => {
  const params = formValues;

  return useQuery({
    queryKey: ['ReglementList'],
    queryFn: async () => await callAxiosApi(`${reglement_list_api}`, { params }, true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddReglementLine = (queryClient, handleCloseReglement, handleRowSelectionReglementsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${reglement_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['ReglementList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addReglementLine onSuccess');
      customConsoleLog(newprod);
      handleCloseReglement();
      handleRowSelectionReglementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateReglementLine = (queryClient, handleCloseReglement, handleRowSelectionReglementsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${reglement_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateReglementLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateReglementLine called')
      queryClient.setQueryData(['ReglementList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetReglementQuery(newprod.id, queryClient);
      handleCloseReglement();
      handleRowSelectionReglementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteReglementLine = (queryClient, handleCloseReglement) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${reglement_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['ReglementList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetReglementQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseReglement();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetReglementsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['ReglementList'] });
}


function getMvtListTypes(type_mvt) {

  if (ClientOrFournisseur(type_mvt) == typeTiers.client) {
    return [{ key: typeMvt.bonClient, value: getTitreMvt(typeMvt.bonClient) },
    { key: typeMvt.factureClient, value: getTitreMvt(typeMvt.factureClient) }];
  }
  else {
    //return [typeMvt.bonLivraisonFournisseur, typeMvt.factureFournisseur];
    return [{ key: typeMvt.bonLivraisonFournisseur, value: getTitreMvt(typeMvt.bonLivraisonFournisseur) },
    { key: typeMvt.factureFournisseur, value: getTitreMvt(typeMvt.factureFournisseur) }];
  }

}
// ref_reglement : ref_reglement
// date : date_debut au : date_fin
// tiers : id_tiers
// mode reglement : id_reglement
// libelle : libelle
// etat : 0, 1 , 2
// lettre : 0, 1
// payer enter le : date_releve_debut au : date_releve_fin
// depose enter le : date_depot_debut au : date_depot_fin
// utilisateur : id_utilisateur
// depot : iddepots
// client pertes uniquement : client_pertes // a voirs

const BasicFitlerModal = (props) => {
  const { openFilter, handleClose, defaultValues, values, setValues, handleRefetch, type_mvt, ...others } = props;

  const date_debut = formatDate(dayjs().startOf('year'), true);
  const date_debut_2001 = formatDate(dayjs('2001-01-01'), true);
  const date_fin = formatDate(dayjs(), true);
  // const date_debut = '22-01-2024';
  // const date_fin = '22-01-2024';

  const [filterFormValues, setFilterFormValues] = useState({
    ...defaultValues,
    date_debut: date_debut, date_fin: date_fin,
    date_releve_debut: date_debut_2001, date_releve_fin: date_fin,
    date_depot_debut: date_debut_2001, date_depot_fin: date_fin
  });

  customConsoleLog('filterFormValues', filterFormValues);

  const [canFetch, setCanFetch] = useState(false);

  const isCliOrFr = ClientOrFournisseur(type_mvt);

  const { loginPermission} = useLoginStore();



  const {
    data: data_etablissement = defaultSelectChargement,
    isLoading: isLoading_etablissement, error: error_etablissement, refetch: refetch_etablissement,
    failureCount: failureCount_etablissement } = GetEtablissementsListQuery(true);

  const {
    data: data_tiers = defaultSelectChargement,
    isLoading: isLoading_tiers } = isCliOrFr == typeTiers.client ? GetClientsListQuery(true, 'nom') : GetFournisseursListQuery(true, 'nom');


  const {
    data: data_mode_reglements = defaultSelectChargement,
    isLoading: isLoading_mode_reglements } = GetMode_ReglementsListQuery(true, 'code');

  const {
    data: data_utilisateur = defaultSelectChargement,
    isLoading: isLoading_utilisateur } = GetUtilisateursListQuery(true, 'id');

  const handleFilterSubmit = (e) => {
    e.preventDefault();

  }

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;

    // if (name.includes('date')) {
    //   //const formatedDate = formatDate(value, false);

    //   setFilterFormValues({ ...filterFormValues, [name]: formatedDate });
    // }
    // else {

    if (name === 'etat') {

      const ValuesOk = ['', '0', '1', '2', '3'];
      const maxChars = 1;
      if (validateValues(value, ValuesOk, maxChars)) {
        setFilterFormValues({ ...filterFormValues, [name]: value });
      }
    }
    else if (name === 'lettre') {

      const ValuesOk = ['', 'N', 'O'];
      const maxChars = 1;
      if (validateValues(value, ValuesOk, maxChars)) {
        setFilterFormValues({ ...filterFormValues, [name]: value });
      }
    }
    else {

      setFilterFormValues({ ...filterFormValues, [name]: type === 'checkbox' ? checked : value });
    }

    //}
  }


  const handleOkFilter = () => {

    if (!checkPermission(loginPermission, accesTypes.acces, filterFormValues.iddepots || 99, 0)) {
        showToastAccesError(5000, 'error');
        return;
    }

    setValues(filterFormValues);
    handleRefetch();
    handleFermerFilter();
  }

  const handleTousFilter = () => {

    if (!checkPermission(loginPermission, accesTypes.acces, 99, 0)) {
        showToastAccesError(5000, 'error');
        return;
    }
    setValues({ type_mvt: type_mvt });
    handleRefetch();
    handleFermerFilter();
  }

  const handleFermerFilter = () => {
    handleClose();
  }

  return (
    <>
      <BasicModal open={openFilter} isModal={true}>
        <section className="m-2">
          <form onSubmit={handleFilterSubmit}>

            <div className='flex flex-col gap-2 min-h-[250px] mt-8'>
              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Ref :">
                  <BasicInput className='text-xs' id="ref_reglement" name="ref_reglement" value={filterFormValues.ref_reglement || ''} onChange={handleFilterChange} />
                </BasicLabel>
                <div></div>
              </div>
              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Période du :">
                  <BasicDateInput isbig={true} id="date_debut" name="date_debut"
                    format={DateFormat} value={filterFormValues.date_debut || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs' titre="au :">
                  <BasicDateInput isbig={true} id="date_fin" name="date_fin"
                    format={DateFormat} value={filterFormValues.date_fin || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>

              </div>
              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Tiers :">
                  <BasicSelect id="id_tiers" name="id_tiers" className='text-xs'
                    listItems={data_tiers || []} value={filterFormValues.id_tiers || ''}
                    textKey={'nom'}
                    fieldsConfig={[{ key: 'nom', title: 'Nom' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>
              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Mode Reglement :">
                  <BasicSelect id="id_reglement" name="id_reglement"
                    className='text-xs'
                    listItems={data_mode_reglements || []} value={filterFormValues.id_reglement || ''}
                    textKey={'libelle'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>
              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Libelle :">
                  <BasicInput className='text-xs' id="libelle" name="libelle"
                    value={filterFormValues.libelle || ''} onChange={handleFilterChange} />
                </BasicLabel>
              </div>

              <div className='grid grid-cols-3'>
                <BasicLabel className='text-xs w-[280px]' titre="Etat :">
                  <BasicInput className='text-xs' id="etat" name="etat"
                    value={filterFormValues.etat || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs w-[200px] col-span-2' titre="Lettré :">
                  <BasicInput className='text-xs' id="lettre" name="lettre"
                    value={filterFormValues.lettre || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>
              </div>

              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Payer Entre :">
                  <BasicDateInput isbig={true} className='text-xs' id="date_releve_debut" name="date_releve_debut"
                    format={DateFormat} value={filterFormValues.date_releve_debut || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs' titre="au :">
                  <BasicDateInput isbig={true} id="date_releve_fin" name="date_releve_fin"
                    format={DateFormat} value={filterFormValues.date_releve_fin || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>

              </div>

              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[200px]' titre="Deposé Entre :">
                  <BasicDateInput isbig={true} className='text-xs' id="date_depot_debut" name="date_depot_debut"
                    format={DateFormat} value={filterFormValues.date_depot_debut || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>
                <BasicLabel className='text-xs' titre="au :">
                  <BasicDateInput isbig={true} id="date_depot_fin" name="date_depot_fin"
                    format={DateFormat} value={filterFormValues.date_depot_fin || ''}
                    onChange={handleFilterChange} />
                </BasicLabel>

              </div>

              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[150px]' titre="Utilisateur :">
                  <BasicSelect id="id_utilisateur" name="id_utilisateur"
                    className="text-xs"
                    listItems={data_utilisateur || []} value={filterFormValues.id_utilisateur || ''}
                    textKey={'username'}
                    fieldsConfig={[{ key: 'username', title: 'Utilisateur' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>
              <div className=''>
                <BasicLabel className='text-xs w-[150px]' titre="Depot :">
                  <BasicSelect id="iddepots" name="iddepots"
                    className="text-xs" listItems={data_etablissement || []}
                    value={filterFormValues.iddepots || ''}
                    textKey={'libelle'}
                    fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }]}
                    onChange={handleFilterChange}>
                  </BasicSelect>
                </BasicLabel>
              </div>

              <div className='grid grid-cols-1'>
                <BasicLabel className='text-xs w-[200px]' titre="Client pertes uniquement :">
                  <BasicCheckBox id="client_pertes" name="client_pertes"
                    checked={filterFormValues.client_pertes || ''} onChange={handleFilterChange} />
                </BasicLabel>
              </div>

            </div>

            <div className="grid grid-cols-7 gap-3 mt-2">
              <div className='col-span-4'></div>
              <BasicButton type="button" onClick={handleOkFilter}>Ok</BasicButton>
              <BasicButton type="button" onClick={handleFermerFilter}>Fermer</BasicButton>
              <BasicButton type="button" onClick={handleTousFilter}>Tous</BasicButton>
            </div>
          </form>
        </section>
      </BasicModal>
    </>

  )
}


const LignesReglementcolumns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'id_mvt', headerName: 'ID Mvt', width: 70, filterable: false  },
  { field: 'ref_mvt', headerName: 'N° Fac/BL', w0idth: 10 },
  { field: 'type_mvt', headerName: 'Type', width: 100 },
  { field: 'date_mvt', headerName: 'Date Fac/BL', width: 150, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  {
    field: 'montant_mvt', headerName: 'Mont Fac/BL', width: 200, editable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
  },
  {
    field: 'montant', headerName: 'Montant Réglé', width: 200, editable: true,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
  },
];




const ReglementsList = forwardRef((props, ref) => {
  //function ReglementsList({ removeWidget, isModal }) {
  customConsoleLog('ReglementsList rendered');
  const { handleGetid_reglement, isModal, removeWidget, id_etablissement, type_mvt_global, ...others } = props;



  const typesList = getMvtListTypes(type_mvt_global);

  const [type_mvt, setTypemvt] = React.useState(type_mvt_global);

  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(true);
  }

  const handleCloseFilter = () => {
    setOpenFilter(false);
  }

  const defaultfilterFormValues = {
    type_mvt: type_mvt,
    iddepots: id_etablissement,
    date_debut: formatDate(dayjs(), true),
    date_fin: formatDate(dayjs(), true),
  }


  const [filterFormValues, setFilterFormValues] = useState(defaultfilterFormValues);

  const queryClient = useQueryClient();
  const [openReglementList, setOpenReglementList] = useState(false);
  const [openReglement, setOpenReglement] = useState(false);

  const isCliOrFr = ClientOrFournisseur(type_mvt);

  const { loginPermission} = useLoginStore();

  const id_fenetre = isCliOrFr == typeTiers.client ? ReglementsListwidget.id_fenetre : ReglementsListwidget.id_fenetre_frs;

  customConsoleLog('id_etablissement', id_etablissement);
  customConsoleLog('isCliOrFr', isCliOrFr);

  const [actionNameReglement, setActionNameReglement] = useState("")
  const [selectReglementsList, setSelectionReglementsList] = useState([]);

  const [lignesreglementList, setLignesreglementList] = useState([]);

  const handleRefetchReglementsList = () => {
    customConsoleLog('handleRefetchReglementsList called');
    cleanGetReglementsListQuery(queryClient);
  }

  const handleRowSelectionReglementsList = (e) => {
    cleanGetLignesReglementsListQuery(queryClient);
    if (e != selectReglementsList[0]) {
      setSelectionReglementsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenReglement = () => {
    setOpenReglement(true);

    //BasicModalRef.current.open();
  }
  const handleCloseReglement = () => {
    setOpenReglement(false);
    //BasicModalRef.current.close();
    cleanGetReglementEmptyQuery(queryClient);
    cleanGetReglementQuery(selectReglementsList[0], queryClient);
  }



  const [status_reglement, execute_reglement, resolve_reglement, reject_reglement, reset_reglement] = useAwaitableComponent();
  const [asyncConfirmDialogInfoReglement, setConfirmDialogInfoReglement] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogReglementsList = status_reglement === "awaiting";

  const handleAwaitModalReglementsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoReglement({ titre: mtitre, msg: mmsg });
      await execute_reglement();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_reglement();
    }
  };


  customConsoleLog('ReglementsList rendered')

  const params = { iddepots: id_etablissement, type_mvt: type_mvt, tiers_type: isCliOrFr, ...filterFormValues };
  customConsoleLog('ReglementsList params', params);



  const { data: data_reglementslist = [], error: error_reglementslist,
    refetch: refecth_reglementslist, isLoading: isLoading_reglementslist, failureCount: failureCount_reglementslist } =
    GetReglementsListQuery(true, params);






  const handleFiltrerReglementsList = () => {
    customConsoleLog('filtrer')
    handleOpenFilter();
  }


  const handleNouveauReglementsList = () => {
    if(!checkPermission(loginPermission, accesTypes.ajouter, 0, id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }

    clearToasts();
    setActionNameReglement(btnActionsName.nouveau);
    setSelectionReglementsList([])
    handleOpenReglement();

  }

  const handleConsulterReglementsList = () => {
    if(!checkPermission(loginPermission, accesTypes.consulter, 0, id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameReglement(btnActionsName.consulter);

    handleOpenReglement();
    customConsoleLog(selectReglementsList[0]);
  }

  const handleDupliquerReglementsList = () => {
    if(!checkPermission(loginPermission, accesTypes.ajouter, 0, id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameReglement(btnActionsName.dupliquer);
    handleOpenReglement();
    customConsoleLog(selectReglementsList[0]);
  }

  const handleModifierReglementsList = () => {
    if(!checkPermission(loginPermission, accesTypes.modifier, 0, id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameReglement(btnActionsName.modifier);
    handleOpenReglement();
    customConsoleLog(selectReglementsList[0]);
  }


  const handleSupprimerReglementsList = async () => {
    if(!checkPermission(loginPermission, accesTypes.supprimer, 0, id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectReglementsList[0])
    if (await handleAwaitModalReglementsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameReglement(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteReglementLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenReglementsList = (itemID) => {
    setOpenReglementList(true);


    if (itemID) {
      handleRowSelectionReglementsList([itemID]);
    }
  }


  const handleFermerReglementsList = () => {
    if (selectReglementsList[0]) {
      if (handleGetid_reglement) {

        handleGetid_reglement(selectReglementsList[0]);
      }
    }
    cleanGetReglementsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetReglementsListQuery(queryClient);
      cleanGetReglementEmptyQuery(queryClient);
      cleanGetReglementQuery(selectReglementsList[0], queryClient);
      setOpenReglementList(false);
    }
    else {

      setOpenReglementList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_reglementslist.filter(item => item.id == id)[0];
  }



  const addReglementLine = useAddReglementLine(queryClient, handleCloseReglement, handleRowSelectionReglementsList);


  const updateReglementLine = useUpdateReglementLine(queryClient, handleCloseReglement, handleRowSelectionReglementsList);

  const deleteReglementLine = useDeleteReglementLine(queryClient, handleCloseReglement);






  const DataTableReglementsListRef = React.useRef(null);

  const handlePrintReglementsList = () => {
    if(!checkPermission(loginPermission, accesTypes.imprimer, 0, id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (DataTableReglementsListRef.current) {
      DataTableReglementsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenReglementsList(itemID);
    },
    handleClose() {
      handleFermerReglementsList();
    }
  }));

  const handleSelectChange = (e) => {
    setTypemvt(e.target.value);
    customConsoleLog('type_mvt', type_mvt);
    customConsoleLog('e.target.value', e);
  }

  customConsoleLog('type_mvt_global', type_mvt_global);
  customConsoleLog('typesList', typesList);
  customConsoleLog('type_mvt', type_mvt);



  // if (isLoading_reglementslist || error_reglementslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerReglementsList}
  //         refetch={refecth_reglementslist}
  //         failureCount={failureCount_reglementslist}
  //         error={error_reglementslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openReglementList} handleClose={handleFermerReglementsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_reglementslist || error_reglementslist
            ? <BasicLoading
              handleClose={handleFermerReglementsList}
              refetch={refecth_reglementslist}
              failureCount={failureCount_reglementslist}
              error={error_reglementslist} />
            : <>
              {
                showAsyncConfirmDialogReglementsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogReglementsList}
                  onSubmit={resolve_reglement}
                  onCancel={reject_reglement}
                  info={asyncConfirmDialogInfoReglement}
                />
              }


              {
                openReglement &&
                <BasicModal open={openReglement} isModal={true}>
                  <Reglement
                    actionName={actionNameReglement}
                    selectedItem={getSelecteditemByID(selectReglementsList[0])}
                    handleClose={handleCloseReglement}
                    addReglementLine={addReglementLine}
                    updateReglementLine={updateReglementLine}
                    type_mvt={type_mvt}
                    id_etablissement={id_etablissement}
                  />
                </BasicModal>
              }

              {
                openFilter &&
                <>
                  <BasicFitlerModal openFilter={openFilter} handleClose={handleCloseFilter}
                    defaultValues={defaultfilterFormValues}
                    values={filterFormValues} setValues={setFilterFormValues}
                    handleRefetch={handleRefetchReglementsList}
                    type_mvt={type_mvt} />
                </>
              }

              {isModal && <h2 className="text-lg font-bold mb-4">Reglements</h2>}

              <RedDataTableBasic
                ref={DataTableReglementsListRef}
                rows={data_reglementslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionReglementsList}
                select={selectReglementsList}
                onRowDoubleClick={handleConsulterReglementsList}
                height={selectReglementsList.length > 0 && selectReglementsList[0] ? '300px' : null}
                hideFooter={true}
              />
              <div className='p-1'></div>
              {
                // id_reglement, lignesreglementList, setLignesreglementList, isHt, ...others
                selectReglementsList.length > 0 && selectReglementsList[0]
                  ?
                  <div className='w-full'>
                    <div className='w-[80%] mx-auto'>
                    <LignesReglement
                      id_reglement={selectReglementsList[0]}
                      lignesreglementList={lignesreglementList}
                      setLignesreglementList={setLignesreglementList}
                      isHt={false}
                      isReadOnly={true}
                      height={'100px'}
                      columns={LignesReglementcolumns}
                      columnVisibilityModel={{ id: false, id_mvt: false }}
                      
                    />
                      
                    </div>

                  </div>
                  : <></>
              }

              {/* Buttons */}
              <div className='grid grid-cols-10'>
                <div></div>
                <div></div>
                <div className='col-span-2'>

                  <BasicSelect id="id_tiers" name="id_tiers" className={`text-xxs`}
                    listItems={typesList || []} value={type_mvt}
                    textKey={'value'}
                    valueKey={'key'}
                    fieldsConfig={[{ key: 'value', title: 'Type' }]}
                    onChange={handleSelectChange}
                    isNotClearable={true}
                    isDisabled={false}>
                  </BasicSelect>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerReglementsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauReglementsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterReglementsList} id="consulter" name="consulter">Consulter</BasicButton>
                {/* <BasicButton onClick={handleDupliquerReglementsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton> */}
                <div></div>
                <div></div>
                <BasicButton onClick={handleModifierReglementsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintReglementsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerReglementsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerReglementsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default ReglementsList



const ReglementsListwidgetKey = 'Reglementswidget'
export const ReglementsListwidget = { i: ReglementsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: (props) => <ReglementsList {...props} />, titre: 'Reglements', id_fenetre: 304, id_fenetre_frs:303 }
