
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, type_depense_delete_api, type_depense_info_api, type_depense_list_api } from '../../global/GlobalVars';
import Type_Depense from './Type_Depense';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetType_DepenseEmptyQuery, cleanGetType_DepenseQuery } from './Type_Depense';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 200 },
  { field: 'type', headerName: 'Libelle', width: 200 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetType_DepensesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['Type_DepenseList'],
    queryFn: async () => await callAxiosApi(`${type_depense_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddType_DepenseLine = (queryClient, handleCloseType_Depense, handleRowSelectionType_DepensesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${type_depense_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['Type_DepenseList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addType_DepenseLine onSuccess');
      customConsoleLog(newprod);
      handleCloseType_Depense();
      handleRowSelectionType_DepensesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateType_DepenseLine = (queryClient, handleCloseType_Depense, handleRowSelectionType_DepensesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${type_depense_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateType_DepenseLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateType_DepenseLine called')
      queryClient.setQueryData(['Type_DepenseList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetType_DepenseQuery(newprod.id, queryClient);
      handleCloseType_Depense();
      handleRowSelectionType_DepensesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteType_DepenseLine = (queryClient, handleCloseType_Depense) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${type_depense_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['Type_DepenseList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetType_DepenseQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseType_Depense();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetType_DepensesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['Type_DepenseList'] });
}


const Type_DepensesList = forwardRef((props, ref) => {
  //function Type_DepensesList({ removeWidget, isModal }) {
  const { handleGetid_type_depense, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openType_DepenseList, setOpenType_DepenseList] = useState(false);
  const [openType_Depense, setOpenType_Depense] = useState(false);

  const [actionNameType_Depense, setActionNameType_Depense] = useState("")
  const [selectType_DepensesList, setSelectionType_DepensesList] = useState([]);

  const handleRowSelectionType_DepensesList = (e) => {
    if (e != selectType_DepensesList[0]) {
      setSelectionType_DepensesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenType_Depense = () => {
    setOpenType_Depense(true);

    //BasicModalRef.current.open();
  }
  const handleCloseType_Depense = () => {
    setOpenType_Depense(false);
    //BasicModalRef.current.close();
    cleanGetType_DepenseEmptyQuery(queryClient);
    cleanGetType_DepenseQuery(selectType_DepensesList[0], queryClient);
  }



  const [status_type_depense, execute_type_depense, resolve_type_depense, reject_type_depense, reset_type_depense] = useAwaitableComponent();
  const [asyncConfirmDialogInfoType_Depense, setConfirmDialogInfoType_Depense] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogType_DepensesList = status_type_depense === "awaiting";

  const handleAwaitModalType_DepensesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoType_Depense({ titre: mtitre, msg: mmsg });
      await execute_type_depense();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_type_depense();
    }
  };


  customConsoleLog('Type_DepensesList rendered')



  const { data: data_type_depenseslist = [], error: error_type_depenseslist,
    refetch: refecth_type_depenseslist, isLoading: isLoading_type_depenseslist, failureCount: failureCount_type_depenseslist } =
    GetType_DepensesListQuery();






  const handleFiltrerType_DepensesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauType_DepensesList = () => {

    clearToasts();
    setActionNameType_Depense(btnActionsName.nouveau);
    setSelectionType_DepensesList([])
    handleOpenType_Depense();

  }

  const handleConsulterType_DepensesList = () => {
    if (selectType_DepensesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameType_Depense(btnActionsName.consulter);

    handleOpenType_Depense();
    customConsoleLog(selectType_DepensesList[0]);
  }

  const handleDupliquerType_DepensesList = () => {
    if (selectType_DepensesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameType_Depense(btnActionsName.dupliquer);
    handleOpenType_Depense();
    customConsoleLog(selectType_DepensesList[0]);
  }

  const handleModifierType_DepensesList = () => {
    if (selectType_DepensesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameType_Depense(btnActionsName.modifier);
    handleOpenType_Depense();
    customConsoleLog(selectType_DepensesList[0]);
  }


  const handleSupprimerType_DepensesList = async () => {
    if (selectType_DepensesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectType_DepensesList[0])
    if (await handleAwaitModalType_DepensesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameType_Depense(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteType_DepenseLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenType_DepensesList = (itemID) => {
    setOpenType_DepenseList(true);


    if(itemID) {
      handleRowSelectionType_DepensesList([itemID]);
    }
  }


  const handleFermerType_DepensesList = () => {
    if (selectType_DepensesList[0]) {
      if (handleGetid_type_depense) {

        handleGetid_type_depense(selectType_DepensesList[0]);
      }
    }
    cleanGetType_DepensesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetType_DepensesListQuery(queryClient);
      cleanGetType_DepenseEmptyQuery(queryClient);
      cleanGetType_DepenseQuery(selectType_DepensesList[0], queryClient);
      setOpenType_DepenseList(false);
    }
    else{

      setOpenType_DepenseList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_type_depenseslist.filter(item => item.id == id)[0];
  }



  const addType_DepenseLine = useAddType_DepenseLine(queryClient, handleCloseType_Depense, handleRowSelectionType_DepensesList);


  const updateType_DepenseLine = useUpdateType_DepenseLine(queryClient, handleCloseType_Depense, handleRowSelectionType_DepensesList);

  const deleteType_DepenseLine = useDeleteType_DepenseLine(queryClient, handleCloseType_Depense);






  const DataTableType_DepensesListRef = React.useRef(null);

  const handlePrintType_DepensesList = () => {
    if (DataTableType_DepensesListRef.current) {
      DataTableType_DepensesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenType_DepensesList(itemID);
    },
    handleClose() {
      handleFermerType_DepensesList();
    }
  }));

  // if (isLoading_type_depenseslist || error_type_depenseslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerType_DepensesList}
  //         refetch={refecth_type_depenseslist}
  //         failureCount={failureCount_type_depenseslist}
  //         error={error_type_depenseslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openType_DepenseList} handleClose={handleFermerType_DepensesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_type_depenseslist || error_type_depenseslist
            ? <BasicLoading
              handleClose={handleFermerType_DepensesList}
              refetch={refecth_type_depenseslist}
              failureCount={failureCount_type_depenseslist}
              error={error_type_depenseslist} />
            : <>
              {
                showAsyncConfirmDialogType_DepensesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogType_DepensesList}
                  onSubmit={resolve_type_depense}
                  onCancel={reject_type_depense}
                  info={asyncConfirmDialogInfoType_Depense}
                />
              }


              {
                openType_Depense &&
                <BasicModal open={openType_Depense} isModal={true}>
                  <Type_Depense
                    actionName={actionNameType_Depense}
                    selectedItem={getSelecteditemByID(selectType_DepensesList[0])}
                    handleClose={handleCloseType_Depense}
                    addType_DepenseLine={addType_DepenseLine}
                    updateType_DepenseLine={updateType_DepenseLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Type Depenses</h2>}

              <RedDataTableBasic
                ref={DataTableType_DepensesListRef}
                rows={data_type_depenseslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionType_DepensesList} 
                select={selectType_DepensesList}
                onRowDoubleClick={handleConsulterType_DepensesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerType_DepensesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauType_DepensesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterType_DepensesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerType_DepensesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierType_DepensesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintType_DepensesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerType_DepensesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerType_DepensesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default Type_DepensesList



const Type_DepensesListwidgetKey = 'Type_Depenseswidget'
export const Type_DepensesListwidget = { i: Type_DepensesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: Type_DepensesList, titre: 'Type Depenses', id_fenetre: 732 }
