import React from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { customConsoleLog } from '../global/Tools';

const GridTemplate = ({ layout, setLayouts, windowWidth }) => {

  const [layoutPosition, setLayoutPosition] = React.useState([]);

  customConsoleLog('GridTemplate Rendred')
  customConsoleLog(layout);

  const handleClick = (id) => {

    customConsoleLog('handleClick');
    customConsoleLog(id);
    customConsoleLog(layoutPosition);

    const clickedLayoutIndex = layout.findIndex((item) => item.i === id);
    const tmpLayouts = [...layout];
    try
    {

      for (let i = 0; i < layout.length; i++) {
        tmpLayouts[i].x = layoutPosition.find(item => item.i === layout[i].i).x;
         tmpLayouts[i].y = layoutPosition.find(item => item.i === layout[i].i).y;
     }
    }
    catch(e)
    {
      customConsoleLog(e);
    }

    const clickedLayout = tmpLayouts[clickedLayoutIndex];

    customConsoleLog('clickedLayout', clickedLayout);

    if (clickedLayoutIndex === layout.length - 1) {
      return;
    }

    const updatedLayout = [...tmpLayouts];
    updatedLayout.splice(clickedLayoutIndex, 1);
    updatedLayout.push(clickedLayout);

    customConsoleLog('updatedLayout', updatedLayout);

    setLayouts(updatedLayout);
  };

  const handleOnDrag = (layout) => {
    customConsoleLog('handleOnDrag');
    customConsoleLog(layout);
    setLayoutPosition(layout);
  }

  

  const removeWidget = (id) => {
    setLayouts((prevLayout) => prevLayout.filter((item) => item.i !== id));
    setLayoutPosition((prevLayout) => prevLayout.filter((item) => item.i !== id));
  };

  

  return (
    <>

      <GridLayout
        className=''
        layout={layout}
        cols={12}
        rowHeight={30}
        width={1200}
        allowOverlap={true}
        isResizable={false}
        draggableHandle=".drag-handle"
        onDragStop={handleOnDrag}>
        {layout.map((item) => (


          <div
            key={item.i}
            className='bg-gray-200 m-2 drop-shadow-xl border-2 border-gray-700'
            style={{ overflow: 'auto', position: 'absolute', top: item.y, left: item.x }}
          >
            <div className="w-full flex flex-row">
              <div className='drag-handle bg-gradient-to-r from-cyan-700 to-blue-500 basis-full text-white text-sm p-2'
                onClick={() => handleClick(item.i)}
                >
                {item.titre}
              </div>
              {/* <button onClick={() => removeWidget(item.i)}
                className="basis-12 bg-red-500 text-gray-700">X</button> */}
            </div>
            <div 
            // onClick={() => handleClick(item.i)} 
            className=''>
              {React.createElement(item.component, {...item.component.props, removeWidget: () => removeWidget(item.i), isModal: false })}

            </div>
          </div>
        ))}
      </GridLayout>
    </>
  );
};

export default GridTemplate;