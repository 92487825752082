import React from 'react'
import { checkString, cn } from '../global/Tools';
import BasicLoadingAnim from './BasicLoadingAnim';
import { customConsoleLog } from '../global/Tools';

const BtnSmallClass = "border-[1px] bg-gray-50 border-gray-400 border rounded py-1 px-1 text-gray-700 hover:bg-blue-300 hover:text-black hover:border-black active:border-blue-300 active:bg-white active:text-blue-300 "
const BtnSmallReadonlyClass = "border-[1px] border-gray-400 text-xs  bg-gray-100 rounded py-1 px-1 text-gray-700 "


function BasicButtonSmall(props) {
  const { children, titre, className, disabled, loading, ...others } = props;
  const customClass = cn(disabled || loading ? `${BtnSmallReadonlyClass} ${checkString(className)}` : `${BtnSmallClass} ${checkString(className)}`);

   
  return (
    <>
    <button className={`${customClass}`} disabled={disabled || loading} type='button' {...others}>
    {
        loading 
        ? <>
        <BasicLoadingAnim width={16} height={16}/>
        </>
        : <>{children}</>
      }
    </button>
    </>
  )
}

export default BasicButtonSmall