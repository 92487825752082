import React from 'react'
import { checkString, cn } from '../global/Tools';
import BasicLoadingAnim from './BasicLoadingAnim';
import { customConsoleLog } from '../global/Tools';

const BtnClass = "text-xs min-w-[80px]  bg-gray-50 border-gray-400 border rounded py-2 px-3 text-gray-700 hover:bg-blue-300 hover:text-black hover:border-black active:border-blue-300 active:bg-white active:text-blue-300 "
const BtnReadonlyClass = "text-xs  bg-gray-300 border border-gray-400 rounded py-2 px-1 text-gray-700 "

function BasicButton(props) {
    const { children, titre, className, disabled, loading, ...others } = props;
    const customClass = cn(disabled || loading ? `${BtnReadonlyClass} ${checkString(className)}` : `${BtnClass} ${checkString(className)}`);

  return (
    <>
    <button className={`${customClass}`} disabled={disabled || loading} type='button' {...others}>
      {
        loading 
        ? <>
        <BasicLoadingAnim width={16} height={16}/>
        </>
        : <>{children}</>
      }
        
    </button>
    </>
  )
}

export default BasicButton