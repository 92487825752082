
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, cuve_delete_api, cuve_info_api, cuve_list_api } from '../../global/GlobalVars';
import Cuve from './Cuve';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDate, formatDateTime, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetCuveEmptyQuery, cleanGetCuveQuery } from './Cuve';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'code', headerName: 'Code', width: 150 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
  { field: 'capacitetot', headerName: 'CapaciteTOT', width: 100 },
  { field: 'capaciteact', headerName: 'CapaciteACT', width: 100 },
  { field: 'localite', headerName: 'Localite', width: 200 },
  { field: 'dteaff', headerName: 'DTEAFF', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'dernierent', headerName: 'DernierENT', width: 100 },
  { field: 'derneirsor', headerName: 'DerneirSOR', width: 100 },
  { field: 'id_utilisateur', headerName: 'ID U', width: 100 },
  { field: 'saisie_le', headerName: 'Saisie le', width: 200, type: 'date', valueFormatter: (params) => formatDateTime(params.value, false, true) },
  { field: 'id', headerName: 'ID CUVES', width: 70},
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = {}


export const GetCuvesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['CuveList'],
    queryFn: async () => await callAxiosApi(`${cuve_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddCuveLine = (queryClient, handleCloseCuve, handleRowSelectionCuvesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${cuve_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['CuveList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addCuveLine onSuccess');
      customConsoleLog(newprod);
      handleCloseCuve();
      handleRowSelectionCuvesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateCuveLine = (queryClient, handleCloseCuve, handleRowSelectionCuvesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${cuve_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateCuveLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateCuveLine called')
      queryClient.setQueryData(['CuveList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetCuveQuery(newprod.id, queryClient);
      handleCloseCuve();
      handleRowSelectionCuvesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteCuveLine = (queryClient, handleCloseCuve) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${cuve_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['CuveList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetCuveQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseCuve();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetCuvesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['CuveList'] });
}


const CuvesList = forwardRef((props, ref) => {
  //function CuvesList({ removeWidget, isModal }) {
  const { handleGetid_cuve, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openCuveList, setOpenCuveList] = useState(false);
  const [openCuve, setOpenCuve] = useState(false);

  const [actionNameCuve, setActionNameCuve] = useState("")
  const [selectCuvesList, setSelectionCuvesList] = useState([]);

  const handleRowSelectionCuvesList = (e) => {
    if (e != selectCuvesList[0]) {
      setSelectionCuvesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenCuve = () => {
    setOpenCuve(true);

    //BasicModalRef.current.open();
  }
  const handleCloseCuve = () => {
    setOpenCuve(false);
    //BasicModalRef.current.close();
    cleanGetCuveEmptyQuery(queryClient);
    cleanGetCuveQuery(selectCuvesList[0], queryClient);
  }



  const [status_cuve, execute_cuve, resolve_cuve, reject_cuve, reset_cuve] = useAwaitableComponent();
  const [asyncConfirmDialogInfoCuve, setConfirmDialogInfoCuve] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogCuvesList = status_cuve === "awaiting";

  const handleAwaitModalCuvesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoCuve({ titre: mtitre, msg: mmsg });
      await execute_cuve();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_cuve();
    }
  };


  customConsoleLog('CuvesList rendered')



  const { data: data_cuveslist = [], error: error_cuveslist,
    refetch: refecth_cuveslist, isLoading: isLoading_cuveslist, failureCount: failureCount_cuveslist } =
    GetCuvesListQuery();






  const handleFiltrerCuvesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauCuvesList = () => {

    clearToasts();
    setActionNameCuve(btnActionsName.nouveau);
    setSelectionCuvesList([])
    handleOpenCuve();

  }

  const handleConsulterCuvesList = () => {
    if (selectCuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameCuve(btnActionsName.consulter);

    handleOpenCuve();
    customConsoleLog(selectCuvesList[0]);
  }

  const handleDupliquerCuvesList = () => {
    if (selectCuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameCuve(btnActionsName.dupliquer);
    handleOpenCuve();
    customConsoleLog(selectCuvesList[0]);
  }

  const handleModifierCuvesList = () => {
    if (selectCuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameCuve(btnActionsName.modifier);
    handleOpenCuve();
    customConsoleLog(selectCuvesList[0]);
  }


  const handleSupprimerCuvesList = async () => {
    if (selectCuvesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectCuvesList[0])
    if (await handleAwaitModalCuvesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameCuve(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteCuveLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenCuvesList = (itemID) => {
    setOpenCuveList(true);


    if(itemID) {
      handleRowSelectionCuvesList([itemID]);
    }
  }


  const handleFermerCuvesList = () => {
    if (selectCuvesList[0]) {
      if (handleGetid_cuve) {

        handleGetid_cuve(selectCuvesList[0]);
      }
    }
    cleanGetCuvesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetCuvesListQuery(queryClient);
      cleanGetCuveEmptyQuery(queryClient);
      cleanGetCuveQuery(selectCuvesList[0], queryClient);
      setOpenCuveList(false);
    }
    else{

      setOpenCuveList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_cuveslist.filter(item => item.id == id)[0];
  }



  const addCuveLine = useAddCuveLine(queryClient, handleCloseCuve, handleRowSelectionCuvesList);


  const updateCuveLine = useUpdateCuveLine(queryClient, handleCloseCuve, handleRowSelectionCuvesList);

  const deleteCuveLine = useDeleteCuveLine(queryClient, handleCloseCuve);






  const DataTableCuvesListRef = React.useRef(null);

  const handlePrintCuvesList = () => {
    if (DataTableCuvesListRef.current) {
      DataTableCuvesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenCuvesList(itemID);
    },
    handleClose() {
      handleFermerCuvesList();
    }
  }));

  const handleKeyDownClick = (params, event) => {
    // if esacpe key is pressed close the modal
    // if (event.key === 'Escape') {
    //   handleFermerCuvesList();
    // }
  }


  // if (isLoading_cuveslist || error_cuveslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerCuvesList}
  //         refetch={refecth_cuveslist}
  //         failureCount={failureCount_cuveslist}
  //         error={error_cuveslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openCuveList} handleClose={handleFermerCuvesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_cuveslist || error_cuveslist
            ? <BasicLoading
              handleClose={handleFermerCuvesList}
              refetch={refecth_cuveslist}
              failureCount={failureCount_cuveslist}
              error={error_cuveslist} />
            : <>
              {
                showAsyncConfirmDialogCuvesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogCuvesList}
                  onSubmit={resolve_cuve}
                  onCancel={reject_cuve}
                  info={asyncConfirmDialogInfoCuve}
                />
              }


              {
                openCuve &&
                <BasicModal open={openCuve} isModal={true}>
                  <Cuve
                    actionName={actionNameCuve}
                    selectedItem={getSelecteditemByID(selectCuvesList[0])}
                    handleClose={handleCloseCuve}
                    addCuveLine={addCuveLine}
                    updateCuveLine={updateCuveLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Cuves</h2>}

              <RedDataTableBasic
                ref={DataTableCuvesListRef}
                rows={data_cuveslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionCuvesList} 
                select={selectCuvesList}
                onRowDoubleClick={handleConsulterCuvesList}
                handleKeyDownClick={handleKeyDownClick}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerCuvesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauCuvesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterCuvesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerCuvesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierCuvesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintCuvesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerCuvesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerCuvesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default CuvesList



const CuvesListwidgetKey = 'Cuveswidget'
export const CuvesListwidget = { i: CuvesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: CuvesList, titre: 'Cuves', id_fenetre:801 }
