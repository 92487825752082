import React, { useRef } from 'react'
import { checkString, cn  } from '../global/Tools';
import { customConsoleLog } from '../global/Tools';


const spanLabelClass = "whitespace-nowrap inline bg-gray-100 py-1 px-1 text-gray-600"
const inputClass = "bg-transparent border-transparent w-full py-1  px-1  text-gray-600 focus:outline-blue-200"
const inputReadOnlyClass = "bg-gray-200 border-transparent w-full py-1  px-1  text-gray-600"


function BasicInput(props) {
  const { titre, className, readOnly, titreClassname, focus, ...others } = props;

  const customTitreClass = cn(titreClassname ? `${spanLabelClass} ${checkString(titreClassname)}` :  `${spanLabelClass}`)
  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);

  const inputRef = useRef(null);

  React.useEffect(() => {
    if(focus){
      if(inputRef.current){
        inputRef.current.focus();
      }
    }
  }, [focus])

  return (
    <>
    <div className="inline-flex bg-white rounded border-[1px] border-gray-300 text-xxs w-full">
    {titre && <span className={customTitreClass}>{titre}</span>}
    <input type="text" {...others} readOnly={readOnly} 
      className={`${customClass}`} ref={inputRef}  />
    {/* {
      readOnly
      ? <><div className={`${customClass}`}  >{others.value || '\u00A0'}</div></>
      : <><input type="text" {...others} readOnly={readOnly} 
      className={`${customClass}`}  /></>
    } */}
    
    </div>
    </>
  )
}

export default BasicInput