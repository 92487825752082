import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, defaultSelectChargement, ping_api, article_fardeau_info_api, article_fardeau_update_api } from '../../global/GlobalVars';
import BasicSelect from '../../components/BasicSelect';
import BasicCheckBox from '../../components/BasicCheckBox';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import BasicButton from '../../components/BasicButton';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { calculateHT, calculateTTC, clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import ImageUploader from '../ImageUploader';
import BasicModal from '../../components/BasicModal';
//import DepartementsList, { GetDepartementsListQuery } from '../departement/DepartementsList';
//import SousFamillesList, { GetSousFamillesListQuery } from '../sousfamille/SousFamillesList';
//import FamillesList, { GetFamillesListQuery } from '../famille/FamillesList';
import TvasList, { GetTvasListQuery } from '../tva/TvasList';
// import FournisseursList, { GetFournisseursListQuery } from '../fournisseur/FournisseursList';
import BasicNumberInput from '../../components/BasicNumberInput';
import BasicLabel from '../../components/BasicLabel';
import BasicDateInput from '../../components/BasicDateInput';
import BasicTextarea from '../../components/BasicTextarea';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import EtablissementsList, { GetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import ArticlesList, { GetArticlesListQuery } from '../article/ArticlesList';
import ClientsList, { GetClientsListQuery } from '../client/ClientsList';
import BasicTabs, { BasicTab, BasicTabPanel, BasicTabsList } from '../../components/BasicTabs';
import { customConsoleLog } from '../../global/Tools';





const GetArticle_FardeauEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetArticle_FardeauEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetArticle_FardeauQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetArticle_FardeauQuery', itemID],
    queryFn: async () => await callAxiosApi(`${article_fardeau_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const AddArticle_FardeauQuery = (handleClose, addArticle_FardeauLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${article_fardeau_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddArticle_FardeauQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Article_Fardeau ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addArticle_FardeauLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const UpdateArticle_FardeauQuery = (itemID, updateArticle_FardeauLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${article_fardeau_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateArticle_FardeauQuery', itemID],
    onSuccess: () => {
      const msg = `Article_Fardeau mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateArticle_FardeauQuery called');

        updateArticle_FardeauLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetArticle_FardeauEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetArticle_FardeauEmptyQuery'], exact: true });
}

export const cleanGetArticle_FardeauQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanArticle_FardeauGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetArticle_FardeauQuery', itemID], exact: true });
}

export const cleanArticle_FardeauImagesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['Article_FardeauImagesList'], exact: true });
}



function Article_Fardeau({ actionName, selectedItem, handleClose, addArticle_FardeauLine, updateArticle_FardeauLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Article_Fardeau compo rendered');

  const DepartementListRef = React.useRef();

  const handleDepartementClick = () => {
    customConsoleLog('handleDepartementClick depot etablissement') // depot etablissement
    DepartementListRef.current.handleOpen(formValues.iddepart);// depot etablissement
  };

  const FamilleListRef = React.useRef();

  const handleFamilleClick = () => {
    customConsoleLog('handleFamilleClick article')
    FamilleListRef.current.handleOpen(formValues.id_famille); // article
  };


  // const SousFamilleListRef = React.useRef();

  // const handleSousFamilleClick = () => {
  //   customConsoleLog('handleSousFamilleClick')
  //   SousFamilleListRef.current.handleOpen(formValues.idsfam);
  // };


  const TvaListRef = React.useRef();

  const handleTvaClick = () => {
    customConsoleLog('handleTvaClick')
    TvaListRef.current.handleOpen(formValues.id_tva);
  };

  const FournisseursListRef = React.useRef();

  const handleFournisseurClick = () => {
    customConsoleLog('handleFournisseurClick');
    FournisseursListRef.current.handleOpen(formValues.idfrs);
  }


  // const [imgDialogOpen, setImgDialogOpen] = React.useState(false);
  // const handleImgDialogOpen = () => setImgDialogOpen(true);
  // const handleImgDialogClose = () => {
  //   setImgDialogOpen(false);

  //   cleanArticle_FardeauImagesListQuery(queryClient);

  // }


  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetArticle_FardeauEmptyQuery() : GetArticle_FardeauQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)
  customConsoleLog('data', data);

  

  const {
    data: data_depart = defaultSelectChargement,
    isLoading: isLoading_depart } = GetEtablissementsListQuery(canFetchOthers);

  const {
    data: data_famille = defaultSelectChargement,
    isLoading: isLoading_famille } = GetArticlesListQuery(canFetchOthers);

  // const {
  //   data: data_sousfamille = defaultSelectChargement,
  //   isLoading: isLoading_sousfamille } = GetSousFamillesListQuery(canFetchOthers);

  const {
    data: data_tva = defaultSelectChargement,
    isLoading: isLoading_tva } = GetTvasListQuery(canFetchOthers);

  const {
    data: data_fournisseur = defaultSelectChargement,
    isLoading: isLoading_fournisseur } = GetClientsListQuery(canFetchOthers);

  const addArticle_Fardeau = AddArticle_FardeauQuery(handleClose, addArticle_FardeauLine)
  const updateArticle_Fardeau = UpdateArticle_FardeauQuery(itemID, updateArticle_FardeauLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  // const [status: status_img, execute, resolve, reject, reset] = useAwaitableComponent();


  React.useEffect(() => {
    // Update the state when data changes
    if (data) {
      if(isNew){
        setInitialData(data);
        setFormValues({...data, 'ordonnancier':0});
      }
      else{
        setInitialData(data);
        setFormValues(data);
      }
      customConsoleLog('useEffect called');
    }
  }, [data]);




  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  // const handleGetImg_Lien = (value) => {
  //   setFormValues({
  //     ...formValues,
  //     'img_lien': value
  //   });
  //   handleImgDialogClose();
  // }


  const handleGetiddepart = (value) => {
    if (value == formValues.iddepart) return;
    customConsoleLog('handleGetiddepart', value);
    setFormValues({
      ...formValues,
      'iddepart': value
    });
  }
  const handleGetid_famille = (value) => {
    if (value == formValues.id_famille) return;
    customConsoleLog('handleGetid_famille', value);
    setFormValues({
      ...formValues,
      'id_famille': value
    });
  }

  // const handleGetidsfam = (value) => {
  //   if (value == formValues.idsfam) return;
  //   customConsoleLog('handleGetidsfam', value);
  //   setFormValues({
  //     ...formValues,
  //     'idsfam': value
  //   });
  // }


  const handleGetid_tva = (value) => {
    if (value == formValues.id_tva) return;
    customConsoleLog('handleGetid_tva', value);
    setFormValues({
      ...formValues,
      'id_tva': value
    });
  }

  const handleGetid_fournisseur = (value) => {
    if (value == formValues.idfrs) return;
    customConsoleLog('handleGetid_fournisseur', value);
    setFormValues({
      ...formValues,
      'idfrs': value
    });
  }

  const ttcPrices = ['pp', 'pp2', 'pp3', 'pp4', 'pp5'];
  const htPrices = ['pp_ht', 'pp2_ht', 'pp3_ht', 'pp4_ht', 'pp5_ht'];

  React.useEffect(() => {
    customConsoleLog('useEffect id_tva called', formValues.id_tva, data_tva)

    if(!formValues.id_tva && data_tva.length > 1){
      customConsoleLog('useEffect id_tva called not valid');
      setFormValues(prevState => ({
        ...prevState,
        [`id_tva`]: 1
      }));
    }


    ttcPrices.forEach((keyname) => {
      const name = keyname;
      const value = formValues[keyname];
      const target = { name, value };
      const e = { target };
      customConsoleLog(e)
      handleTTCChange(e);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.id_tva, data_tva])



  const getTvaTaux = () => {
    try {

      return data_tva.find(prev => prev.id == formValues.id_tva)?.taux || 0;
    }
    catch (e) {
      return 0;
    }
  }

  const handleTTCChange = (e) => {

    const { name, value } = e.target;

    if (ttcPrices.includes(name)) {
      customConsoleLog('handleTTCChange');
      customConsoleLog(formValues.id_tva);
      const tva = getTvaTaux();
      const htValue = calculateHT(value, tva);

      setFormValues(prevState => ({
        ...prevState,
        //[`${name}`]: value,
        [`${name}_ht`]: htValue
      }));
    }
  };



  const handleHTChange = (e) => {


    const { name, value } = e.target;

    if (htPrices.includes(name)) {
      customConsoleLog('handleHTChange');
      customConsoleLog(formValues.id_tva);
      const tva = getTvaTaux();
      const ttcValue = calculateTTC(value, tva);

      // Update the corresponding price field
      const priceFieldName = name.replace('_ht', '');
      setFormValues(prevState => ({
        ...prevState,
        [priceFieldName]: ttcValue
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)
    customConsoleLog('handleChange ', type)
    customConsoleLog('handleChange ', name)
    customConsoleLog('handleChange ', checked)

    // if (name == 'libelle') {

    //   setFormValues(prevState => ({
    //     ...prevState,
    //     [name]: value,
    //     'libellea': value
    //   }));
    // }
    // else {

      setFormValues(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));

      handleTTCChange(e);
    // }




  };

  React.useEffect(() => {

    const libelle = data_famille.find(prev => prev.id == formValues.id_famille)?.libelle || '';
    const unite = data_famille.find(prev => prev.id == formValues.id_famille)?.unite || '';
    const prv1 = data_famille.find(prev => prev.id == formValues.id_famille)?.pp || 0;
    const prv2 = data_famille.find(prev => prev.id == formValues.id_famille)?.pp2 || 0;
    const prv3 = data_famille.find(prev => prev.id == formValues.id_famille)?.pp3 || 0;
    const prv4 = data_famille.find(prev => prev.id == formValues.id_famille)?.pp4 || 0;
    const prv5 = data_famille.find(prev => prev.id == formValues.id_famille)?.pp5 || 0;

    customConsoleLog('useEffect id_famille called', libelle)
    customConsoleLog('useEffect id_famille called', unite)

    setFormValues(prevState => ({
      ...prevState,
      'libelle': libelle,
      'libellea': libelle,
      'unite': unite,
      'prv1': prv1,
      'prv2': prv2,
      'prv3': prv3,
      'prv4': prv4,
      'prv5': prv5,
    }));

  }, [formValues.id_famille, isLoading_famille]);

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkArticle_Fardeau = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        customConsoleLog('formValues', formValues);
        addArticle_Fardeau.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addArticle_Fardeau.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateArticle_Fardeau.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseArticle_Fardeau = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const fields = Array.from(document.querySelectorAll('input')) || []
      const position = fields.indexOf(e.target)
      fields[position + 1] && fields[position + 1].focus()
    }
  }

  // const handleOui = (e) => {
  //   customConsoleLog('okButton : ', okButton);
  //   customConsoleLog('oui')
  //   setOpenConfirm(false);
  //   if(okButton){

  //   }
  //   else{
  //   }
  // }

  // const handleNON = (e) => {
  //   customConsoleLog('okButton : ', okButton);
  //   customConsoleLog('non')
  //   setOpenConfirm(false);

  // }

  const tabContentClass = "h-[160px] border rounded-sm border-gray-400 border-t-0 p-1  bg-slate-100";




  // if (error) {
  //   return (
  //     <>
  //       <div className='flex flex-col p-2'>
  //         <h1 className='ml-3 text-red-600'>Erreur : {error.message}</h1>
  //         <BasicButton type="button" onClick={refetch}>Ressayer</BasicButton>
  //         <BasicButton type="button" onClick={handleClose}>Fermer</BasicButton>
  //       </div>
  //     </>
  //   )
  // }


  

 

  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }

  const handletestbtn = () => {
    customConsoleLog('formValues', formValues)
  }



  return (
    <>
      <EtablissementsList ref={DepartementListRef} handleGetid_etablissement={handleGetiddepart} isModal={true} />
      <ArticlesList ref={FamilleListRef} handleGetid_article={handleGetid_famille} isModal={true} />
      {/* <SousFamillesList ref={SousFamilleListRef} handleGetid_sousfamille={handleGetidsfam} isModal={true} /> */}
      <TvasList ref={TvaListRef} handleGetid_tva={handleGetid_tva} isModal={true} />
      <ClientsList ref={FournisseursListRef} handleGetid_client={handleGetid_fournisseur} isModal={true} />
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      {/* {
        imgDialogOpen &&
        <BasicModal open={imgDialogOpen} handleClose={handleImgDialogClose} isModal={true}>
          <ImageUploader handleDialogClose={handleImgDialogClose} handleGetImg_Lien={handleGetImg_Lien}

          />
        </BasicModal>
      } */}

      <div className="p-2 flex flex-row">
        <section className='w-[750px]'>
          <h2 className="text-lg font-bold mb-4">Article Fardeau</h2>
            {/* <BasicButton onClick={handletestbtn}>Test btn</BasicButton> */}
          <form onSubmit={handleSubmit}>



            <div className='grid grid-cols-5 gap-2'>
              <div className='col-span-5 p-1 border-[1px] border-gray-400 bg-slate-100 flex flex-col gap-2'>

                <BasicLabel className='text-xxs' titre="Depot :">
                  <BasicSelect id="iddepart" name="iddepart" listItems={data_depart || []} value={formValues.iddepart || ''}
                   textKey={'libelle'}
                   fieldsConfig={[{key:'code', title: 'Code'},{key:'libelle', title:'Libelle'}]}
                    onChange={handleChange} readOnly={isReadOnly}>
                    <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_depart} onClick={handleDepartementClick}>...</BasicButtonSmall>
                  </BasicSelect>
                </BasicLabel>

                <BasicLabel className='text-xxs' titre="N° de Fardeau :">
                  <BasicInput id="code" name="code" value={formValues.code || ''} onChange={handleChange} readOnly={isReadOnly} required />
                </BasicLabel>

                <BasicLabel className='text-xxs' titre="Code article :">
                  <BasicSelect id="id_famille" name="id_famille" listItems={data_famille || []} value={formValues.id_famille || ''} 
                  textKey={'code'}
                  fieldsConfig={[{key:'code', title: 'Code'},{key:'libelle', title:'Libelle'}]}
                   onChange={handleChange} readOnly={isReadOnly}>
                    <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_famille} onClick={handleFamilleClick}>...</BasicButtonSmall>
                  </BasicSelect>
                </BasicLabel>

                {/* <BasicLabel className='text-xxs' titre="sousfamille :">
                  <BasicSelect id="idsfam" name="idsfam" listItems={data_sousfamille || []} value={formValues.idsfam || ''} textKey={'libelle'} onChange={handleChange} readOnly={isReadOnly}>
                    <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_sousfamille} onClick={handleSousFamilleClick}>...</BasicButtonSmall>
                  </BasicSelect>
                </BasicLabel> */}

                <BasicLabel className='text-xxs' titre="Designation :">
                  <BasicInput id="libelle" name="libelle" className='bg-yellow-300 text-gray-500' value={formValues.libelle || ''} onChange={handleChange} readOnly={true} required />
                </BasicLabel>

                {/* <BasicLabel className='text-xxs' titre="DesignationA :">
                  <BasicInput id="libellea" name="libellea" value={formValues.libellea || ''} onChange={handleChange} readOnly={isReadOnly} required />
                </BasicLabel> */}

                <BasicLabel className='text-xxs' titre="TVA :">
                  <BasicSelect id="id_tva" name="id_tva" listItems={data_tva || []} value={formValues.id_tva || ''} 
                  textKey={'libelle'}
                   fieldsConfig={[{key:'code', title: 'Code'},{key:'libelle', title:'Libelle'}, {key:'taux', title:'Taux'}]}
                    onChange={handleChange} readOnly={isReadOnly}>
                    <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_tva} onClick={handleTvaClick}>...</BasicButtonSmall>
                    <div className='text-ms flex items-center px-1'>{getTvaTaux()}%</div>
                  </BasicSelect>
                </BasicLabel>
                {/* <div>TVA : {formValues.id_tva}%</div> */}
                <div className='grid grid-cols-2 gap-4'>

                  <BasicLabel className='text-xxs' titre="Unite :">
                    <BasicInput id="unite" name="unite" type="text" className='bg-yellow-300 text-gray-500' value={formValues.unite || ''} onChange={handleChange} readOnly={true} />
                  </BasicLabel>


                  <BasicLabel className='text-xxs' titre="Emplacement :">
                    <BasicInput id="emplacement" name="emplacement" type="text" value={formValues.emplacement || ''} onChange={handleChange} readOnly={isReadOnly} />
                  </BasicLabel>
                </div>


                <BasicLabel className='text-xxs' titre="Stk minimum :">
                  <div className='inline-flex gap-2'>
                    <BasicDecimalInput id="stk_minimum" name="stk_minimum" value={formValues.stk_minimum || ''} onChange={handleChange} readOnly={isReadOnly} />
                    <BasicCheckBox id="service" name="service" titre="Service" checked={formValues.service || ''} onChange={handleChange} readOnly={isReadOnly} />

                    <BasicNumberInput id="ordonnancier" name="ordonnancier" titre="En Stock/Vendu/Détaillé/Nouveau" value={formValues.ordonnancier || ''} onChange={handleChange} readOnly={isReadOnly} />
                  </div>
                </BasicLabel>

                <BasicLabel className='text-xxs' titre="FRS :">
                  <BasicSelect id="idfrs" name="idfrs" listItems={data_fournisseur || []} value={formValues.idfrs || ''}
                   textKey={'nom'}
                   fieldsConfig={[{key:'code', title: 'Code'},{key:'nom', title:'Nom'}]}
                    onChange={handleChange} readOnly={isReadOnly}>
                    <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_fournisseur} onClick={handleFournisseurClick}>...</BasicButtonSmall>
                  </BasicSelect>
                </BasicLabel>


              </div>

              {/* <div className='col-span-2 p-1 border-[1px] border-gray-400  bg-slate-100'>
                <BasicButtonSmall type="button" className="w-full" onClick={() => handleImgDialogOpen()} disabled={isReadOnly}>Changer Image</BasicButtonSmall>
                <input id="img_lien" name="img_lien" type="hidden" value={formValues.img_lien || ''} onChange={handleChange} readOnly={isReadOnly} />
                <img alt={formValues.img_lien || ''} src={`${article_fardeaus_image_dir}${formValues.img_lien || ''}`} className='max-h-[130px] m-2' />
              </div> */}

            </div>

            <div>

              <BasicTabs defaultValue={4}>
                <BasicTabsList className='grid grid-cols-4'>
                  <BasicTab value={1} className='pt-1' >Tarif</BasicTab>
                  <BasicTab value={2} >Description</BasicTab>
                  <BasicTab value={3} >Long</BasicTab>
                  <BasicTab value={4} >Prix Detail</BasicTab>
                </BasicTabsList>
                <BasicTabPanel value={1} className={tabContentClass}>

                  <div className='grid grid-cols-5 gap-3'>
                    <div>
                      <span className='text-xxs'>STOCK INITIAL</span>
                      <BasicDecimalInput id="qti" name="qti" value={formValues.qti || ''} readOnly={true} />
                      <span className='text-xxs'>STOCK ACTUEL</span>
                      <BasicDecimalInput id="stk" name="stk" className='bg-yellow-300 text-gray-500' value={formValues.stk || ''} readOnly={true} />
                      <span className='text-xxs'>PAM</span>
                      <BasicDecimalInput id="pam" name="pam" className='bg-yellow-300 text-gray-500' value={formValues.pam || ''} readOnly={true} />
                    </div>
                    <div className='col-span-4'>
                      <div className='grid grid-cols-6 gap-1'>
                        <div className='grid grid-rows-6'>
                          <span></span>
                          <span className='text-xxs'>PV1</span>
                          <span className='text-xxs'>PV2</span>
                          <span className='text-xxs'>PV3</span>
                          <span className='text-xxs'>PV4</span>
                          <span className='text-xxs'>PV5</span>
                        </div>

                        <div className='grid grid-rows-6'>
                          <span className='text-xxs'>PV HT</span>
                          <BasicDecimalInput id="pp_ht" name="pp_ht" value={formValues.pp_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} onKeyDown={handleKeyDown}/>
                          <BasicDecimalInput id="pp2_ht" name="pp2_ht" value={formValues.pp2_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pp3_ht" name="pp3_ht" value={formValues.pp3_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pp4_ht" name="pp4_ht" value={formValues.pp4_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pp5_ht" name="pp5_ht" value={formValues.pp5_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                        </div>

                        <div className='grid grid-rows-6'>

                          <span className='text-xxs'>PV TTC</span>
                          <BasicDecimalInput id="pp" name="pp" value={formValues.pp || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pp2" name="pp2" value={formValues.pp2 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pp3" name="pp3" value={formValues.pp3 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pp4" name="pp4" value={formValues.pp4 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pp5" name="pp5" value={formValues.pp5 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />

                        </div>

                        <div className='grid grid-rows-6'>

                          <span className='text-xxs'>DPA TTC</span>
                          <BasicDecimalInput id="pa" name="pa" value={formValues.pa || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pa2" name="pa2" value={formValues.pa2 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pa3" name="pa3" value={formValues.pa3 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pa4" name="pa4" value={formValues.pa4 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicDecimalInput id="pa5" name="pa5" value={formValues.pa5 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />

                        </div>

                        <div className='grid grid-rows-6'>

                          <span className='text-xxs'>STOCK</span>
                          <BasicNumberInput id="qt1" name="qt1" className='bg-green-500 text-white border-black' value={formValues.qt1 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicNumberInput id="qt2" name="qt2" className='bg-green-500 text-white border-black' value={formValues.qt2 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicNumberInput id="qt3" name="qt3" className='bg-green-500 text-white border-black' value={formValues.qt3 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicNumberInput id="qt4" name="qt4" className='bg-green-500 text-white border-black' value={formValues.qt4 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />
                          <BasicNumberInput id="qt5" name="qt5" className='bg-green-500 text-white border-black' value={formValues.qt5 || ''} onChange={handleChange} readOnly={isReadOnly} onKeyDown={handleKeyDown} />

                        </div>

                        <div className='grid grid-rows-6'>
                          <span className='text-xxs'></span>
                          <BasicDateInput id="dt1" name="dt1" value={formValues.dt1 || ''} onChange={handleChange} readOnly={isReadOnly} />
                          <BasicDateInput id="dt2" name="dt2" value={formValues.dt2 || ''} onChange={handleChange} readOnly={isReadOnly} />
                          <BasicDateInput id="dt3" name="dt3" value={formValues.dt3 || ''} onChange={handleChange} readOnly={isReadOnly} />
                          <BasicDateInput id="dt4" name="dt4" value={formValues.dt4 || ''} onChange={handleChange} readOnly={isReadOnly} />
                          <BasicDateInput id="dt5" name="dt5" value={formValues.dt5 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-span-4'>
                      <div className='grid grid-cols-6 gap-1 mb-1'>
                        <span></span>
                        <span className='text-xxs'>PV HT</span>
                        <span className='text-xxs'>PV TTC</span>
                        <span className='text-xxs'>DPA TTC</span>
                        <span className='text-xxs'>STOCK</span>
                        <span className='text-xxs'></span>
                      </div>

                      <div className='grid grid-cols-6 gap-1 mb-1'>
                        <span className='text-xxs'>PV1</span>
                        <BasicDecimalInput id="pp_ht" name="pp_ht" value={formValues.pp_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pp" name="pp" value={formValues.pp || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pa" name="pa" value={formValues.pa || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicNumberInput id="qt1" name="qt1" className='bg-green-500 text-white border-black' value={formValues.qt1 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDateInput id="dt1" name="dt1" value={formValues.dt1 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </div>

                      <div className='grid grid-cols-6 gap-1 mb-1'>
                        <span className='text-xxs'>PV2</span>
                        <BasicDecimalInput id="pp2_ht" name="pp2_ht" value={formValues.pp2_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pp2" name="pp2" value={formValues.pp2 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pa2" name="pa2" value={formValues.pa2 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicNumberInput id="qt2" name="qt2" className='bg-green-500 text-white border-black' value={formValues.qt2 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDateInput id="dt2" name="dt2" value={formValues.dt2 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </div>

                      <div className='grid grid-cols-6 gap-1 mb-1'>
                        <span className='text-xxs'>PV3</span>
                        <BasicDecimalInput id="pp3_ht" name="pp3_ht" value={formValues.pp3_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pp3" name="pp3" value={formValues.pp3 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pa3" name="pa3" value={formValues.pa3 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicNumberInput id="qt3" name="qt3" className='bg-green-500 text-white border-black' value={formValues.qt3 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDateInput id="dt3" name="dt3" value={formValues.dt3 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </div>

                      <div className='grid grid-cols-6 gap-1 mb-1'>
                        <span className='text-xxs'>PV4</span>
                        <BasicDecimalInput id="pp4_ht" name="pp4_ht" value={formValues.pp4_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pp4" name="pp4" value={formValues.pp4 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pa4" name="pa4" value={formValues.pa4 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicNumberInput id="qt4" name="qt4" className='bg-green-500 text-white border-black' value={formValues.qt4 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDateInput id="dt4" name="dt4" value={formValues.dt4 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </div>

                      <div className='grid grid-cols-6 gap-1 mb-1'>
                        <span className='text-xxs'>PV5</span>
                        <BasicDecimalInput id="pp5_ht" name="pp5_ht" value={formValues.pp5_ht || ''} onChange={handleHTChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pp5" name="pp5" value={formValues.pp5 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="pa5" name="pa5" value={formValues.pa5 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicNumberInput id="qt5" name="qt5" className='bg-green-500 text-white border-black' value={formValues.qt5 || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDateInput id="dt5" name="dt5" value={formValues.dt5 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </div>
                    </div> */}


                  </div>




                </BasicTabPanel>
                <BasicTabPanel value={2} className={tabContentClass}>
                  <div className='grid grid-cols-2 gap-2'>
                    <div className='grid grid-cols-2 gap-2'>
                      <div className='flex flex-col gap-4'>
                        <BasicNumberInput id="xcoef" name="xcoef" titre={'Nb Piece'} value={formValues.xcoef || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </div>
                      <div className='flex flex-col gap-4'>
                        <BasicDecimalInput id="xlong" name="xlong" className='bg-yellow-300 border-black' titre={'Longeur'} value={formValues.xlong || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="xlarg" name="xlarg" className='bg-yellow-300 border-black' titre={'Largeur'} value={formValues.xlarg || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="xepai" name="xepai" className='bg-yellow-300 border-black' titre={'Epaisseur'} value={formValues.xepai || ''} onChange={handleChange} readOnly={isReadOnly} />
                        <BasicDecimalInput id="xsect" name="xsect" titre={'Qté/Section'} maxFractionDigits={4} value={formValues.xsect || ''} onChange={handleChange} readOnly={isReadOnly} />
                      </div>

                    </div>
                    <div>
                      <BasicTextarea id="caracteristiques" name="caracteristiques" value={formValues.caracteristiques || ''} onChange={handleChange} readOnly={isReadOnly} />

                    </div>

                  </div>
                </BasicTabPanel>
                <BasicTabPanel value={3} className={tabContentClass}>
                  <div className='grid grid-cols-3 gap-4'>
                    <div>
                      <BasicNumberInput id="nbre_composants" name="nbre_composants" titre={'Nbre Composants'} value={formValues.nbre_composants || ''} onChange={handleChange} readOnly={true} />
                    </div>
                    <div className='col-span-2 flex flex-col gap-3 h-full w-24'>
                      <BasicDecimalInput id="rem3" name="rem3" className='bg-yellow-300 border-black' titre={'Lg1'} value={formValues.rem3 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      <BasicDecimalInput id="rem4" name="rem4" className='bg-yellow-300 border-black' titre={'Lg2'} value={formValues.rem4 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      <BasicDecimalInput id="rem5" name="rem5" className='bg-yellow-300 border-black' titre={'Lg3'} value={formValues.rem5 || ''} onChange={handleChange} readOnly={isReadOnly} />
                      <BasicDecimalInput id="remise_sys" name="remise_sys" className='bg-yellow-300 border-black' titre={'Lg4'} value={formValues.remise_sys || ''} onChange={handleChange} readOnly={isReadOnly} />
                    </div>

                  </div>

                </BasicTabPanel>
                <BasicTabPanel value={4} className={tabContentClass}>
                  <div className='grid grid-cols-4 gap-4 mr-16'>
                    <div className='col-span-3'>
                    </div>
                    <div className='flex flex-col gap-1 p-2 h-full'>
                      <BasicDecimalInput id="prv1" name="prv1" titre={'Prv1'} value={formValues.prv1 || ''} onChange={handleChange} readOnly={true} />
                      <BasicDecimalInput id="prv2" name="prv2" titre={'Prv2'} value={formValues.prv2 || ''} onChange={handleChange} readOnly={true} />
                      <BasicDecimalInput id="prv3" name="prv3" titre={'Prv3'} value={formValues.prv3 || ''} onChange={handleChange} readOnly={true} />
                      <BasicDecimalInput id="prv4" name="prv4" titre={'Prv4'} value={formValues.prv4 || ''} onChange={handleChange} readOnly={true} />
                      <BasicDecimalInput id="prv5" name="prv5" titre={'Prv5'} value={formValues.prv5 || ''} onChange={handleChange} readOnly={true} />
                    </div>

                  </div>
                </BasicTabPanel>
              </BasicTabs>

            </div>
            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkArticle_Fardeau}
                  loading={addArticle_Fardeau.isPending || updateArticle_Fardeau.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseArticle_Fardeau}>Fermer</BasicButton>
            </div>

          </form>

        </section>



        {/* <section className='flex flex-col max-w-[150px] mx-4'>
          <div className='my-auto'>

            <BasicButton type="button" className="mb-5 w-full">Encour de Commande</BasicButton>
            <BasicButton type="button" className="mb-1 w-full" >Vente</BasicButton>
            <BasicButton type="button" className="mb-3 w-full" >Achat</BasicButton>
            <BasicButton type="button" className="mb-1 w-full" >Cde client</BasicButton>
            <BasicButton type="button" className="mb-1 w-full" >Cde Fournisseur</BasicButton>
          </div>
        </section> */}
      </div >
    </>
  )
}

export default Article_Fardeau