
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, pays_delete_api, pays_info_api, pays_list_api } from '../../global/GlobalVars';
import Pays from './Pays';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetPaysEmptyQuery, cleanGetPaysQuery } from './Pays';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'pays', headerName: 'Pays', width: 200 },
  { field: 'devise', headerName: 'Devise', width: 200 },
  { field: 'taux', headerName: 'TAUX', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetPayssListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['PaysList'],
    queryFn: async () => await callAxiosApi(`${pays_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddPaysLine = (queryClient, handleClosePays, handleRowSelectionPayssList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${pays_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['PaysList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addPaysLine onSuccess');
      customConsoleLog(newprod);
      handleClosePays();
      handleRowSelectionPayssList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdatePaysLine = (queryClient, handleClosePays, handleRowSelectionPayssList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${pays_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updatePaysLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updatePaysLine called')
      queryClient.setQueryData(['PaysList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetPaysQuery(newprod.id, queryClient);
      handleClosePays();
      handleRowSelectionPayssList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeletePaysLine = (queryClient, handleClosePays) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${pays_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['PaysList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetPaysQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleClosePays();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetPayssListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['PaysList'] });
}


const PayssList = forwardRef((props, ref) => {
  //function PayssList({ removeWidget, isModal }) {
  const { handleGetid_pays, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openPaysList, setOpenPaysList] = useState(false);
  const [openPays, setOpenPays] = useState(false);

  const [actionNamePays, setActionNamePays] = useState("")
  const [selectPayssList, setSelectionPayssList] = useState([]);

  const handleRowSelectionPayssList = (e) => {
    if (e != selectPayssList[0]) {
      setSelectionPayssList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenPays = () => {
    setOpenPays(true);

    //BasicModalRef.current.open();
  }
  const handleClosePays = () => {
    setOpenPays(false);
    //BasicModalRef.current.close();
    cleanGetPaysEmptyQuery(queryClient);
    cleanGetPaysQuery(selectPayssList[0], queryClient);
  }



  const [status_pays, execute_pays, resolve_pays, reject_pays, reset_pays] = useAwaitableComponent();
  const [asyncConfirmDialogInfoPays, setConfirmDialogInfoPays] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogPayssList = status_pays === "awaiting";

  const handleAwaitModalPayssList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoPays({ titre: mtitre, msg: mmsg });
      await execute_pays();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_pays();
    }
  };


  customConsoleLog('PayssList rendered')



  const { data: data_paysslist = [], error: error_paysslist,
    refetch: refecth_paysslist, isLoading: isLoading_paysslist, failureCount: failureCount_paysslist } =
    GetPayssListQuery();






  const handleFiltrerPayssList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauPayssList = () => {

    clearToasts();
    setActionNamePays(btnActionsName.nouveau);
    setSelectionPayssList([])
    handleOpenPays();

  }

  const handleConsulterPayssList = () => {
    if (selectPayssList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNamePays(btnActionsName.consulter);

    handleOpenPays();
    customConsoleLog(selectPayssList[0]);
  }

  const handleDupliquerPayssList = () => {
    if (selectPayssList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNamePays(btnActionsName.dupliquer);
    handleOpenPays();
    customConsoleLog(selectPayssList[0]);
  }

  const handleModifierPayssList = () => {
    if (selectPayssList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNamePays(btnActionsName.modifier);
    handleOpenPays();
    customConsoleLog(selectPayssList[0]);
  }


  const handleSupprimerPayssList = async () => {
    if (selectPayssList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectPayssList[0])
    if (await handleAwaitModalPayssList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNamePays(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deletePaysLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenPayssList = (itemID) => {
    setOpenPaysList(true);


    if(itemID) {
      handleRowSelectionPayssList([itemID]);
    }
  }


  const handleFermerPayssList = () => {
    if (selectPayssList[0]) {
      if (handleGetid_pays) {

        handleGetid_pays(selectPayssList[0]);
      }
    }
    cleanGetPayssListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetPayssListQuery(queryClient);
      cleanGetPaysEmptyQuery(queryClient);
      cleanGetPaysQuery(selectPayssList[0], queryClient);
      setOpenPaysList(false);
    }
    else{

      setOpenPaysList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_paysslist.filter(item => item.id == id)[0];
  }



  const addPaysLine = useAddPaysLine(queryClient, handleClosePays, handleRowSelectionPayssList);


  const updatePaysLine = useUpdatePaysLine(queryClient, handleClosePays, handleRowSelectionPayssList);

  const deletePaysLine = useDeletePaysLine(queryClient, handleClosePays);






  const DataTablePayssListRef = React.useRef(null);

  const handlePrintPayssList = () => {
    if (DataTablePayssListRef.current) {
      DataTablePayssListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenPayssList(itemID);
    },
    handleClose() {
      handleFermerPayssList();
    }
  }));

  // if (isLoading_paysslist || error_paysslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerPayssList}
  //         refetch={refecth_paysslist}
  //         failureCount={failureCount_paysslist}
  //         error={error_paysslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openPaysList} handleClose={handleFermerPayssList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_paysslist || error_paysslist
            ? <BasicLoading
              handleClose={handleFermerPayssList}
              refetch={refecth_paysslist}
              failureCount={failureCount_paysslist}
              error={error_paysslist} />
            : <>
              {
                showAsyncConfirmDialogPayssList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogPayssList}
                  onSubmit={resolve_pays}
                  onCancel={reject_pays}
                  info={asyncConfirmDialogInfoPays}
                />
              }


              {
                openPays &&
                <BasicModal open={openPays} isModal={true}>
                  <Pays
                    actionName={actionNamePays}
                    selectedItem={getSelecteditemByID(selectPayssList[0])}
                    handleClose={handleClosePays}
                    addPaysLine={addPaysLine}
                    updatePaysLine={updatePaysLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Pays</h2>}

              <RedDataTableBasic
                ref={DataTablePayssListRef}
                rows={data_paysslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionPayssList}
                select={selectPayssList}
                onRowDoubleClick={handleConsulterPayssList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerPayssList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauPayssList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterPayssList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerPayssList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierPayssList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintPayssList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerPayssList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerPayssList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default PayssList



const PayssListwidgetKey = 'Paysswidget'
export const PayssListwidget = { i: PayssListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: PayssList, titre: 'Pays', id_fenetre: 701  }
