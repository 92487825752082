import React, { useRef } from 'react'

import { InputNumber } from 'primereact/inputnumber';
import { checkString, cn } from '../global/Tools';
import { customConsoleLog } from '../global/Tools';

const spanLabelClass = "whitespace-nowrap inline bg-gray-100 py-1 px-1 text-gray-600"
const inputClass = " border-transparent w-full py-1  px-1  text-gray-600 focus:outline-blue-200 "
const inputReadOnlyClass = "bg-gray-200 border-transparent w-full py-1  px-1  text-gray-600"

//React.forwardRef((props, ref)
function BasicDecimalInput(props) {
//const BasicDecimalInput =  React.forwardRef((props, ref) => {
  const { value, titre, onChange, className, readOnly, maxFractionDigits, minFractionDigits, focus, isNotEditable, ...others } = props;
  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);
  
  // Create a ref to the input field

  const inputRef = useRef(null);

  const handleFocus = () => {
    inputRef.current.select();
  };


  React.useEffect(() => {
    if(focus){
      if(inputRef.current){
        inputRef.current.focus();
      }
    }
  }, [focus])

  


  return (
    <>
      <div className="inline-flex bg-white rounded border-[1px] border-gray-300 text-xxs w-full">
        {titre && <span className={spanLabelClass}>{titre}</span>}
        <InputNumber {...others}
          mode={'decimal'}
          useGrouping={false}
          value={value ? value : '0.00'}
          onChange={(e) => {
            // if(isNotEditable){
            //   customConsoleLog('isNotEditable', isNotEditable)
            //   customConsoleLog('onChange e', e)
            //   //onChange(null);
            //   return onChange(null);
            // }
            return onChange(e.originalEvent);
          }}
          readOnly={readOnly}
          pt={{
            root: 'w-full inline-flex ',
            input: {
              root: ({ props }) => ({
                className: `${customClass}`
              })
            },

          }} 
          minFractionDigits={minFractionDigits ? minFractionDigits : 2} 
          maxFractionDigits={maxFractionDigits ? maxFractionDigits : 2}
          onFocus={handleFocus} // Call handleFocus when the input receives focus
          inputRef={inputRef} // Assign the ref to the input field
          />

        {/* {
          readOnly
            ? <><div className={`${customClass} p-inputnumber-input`}  >{value ? value : '0.00'}</div></>
            : <>
              <InputNumber {...others}
                value={value ? value : '0.00'}
                onValueChange={onChange}
                readOnly={readOnly}
                pt={{
                  root: 'w-full inline-flex ',
                  input: {
                    root: ({ props }) => ({
                      className: `${customClass}`
                    })
                  },

                }} minFractionDigits={2} maxFractionDigits={2} />
            </>
        } */}

      </div>
    </>
  )
}

export default BasicDecimalInput