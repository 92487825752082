
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, acces_depot_delete_api, acces_depot_info_api, acces_depot_list_api } from '../../global/GlobalVars';
import Acces_Depot from './Acces_Depot';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetAcces_DepotEmptyQuery, cleanGetAcces_DepotQuery } from './Acces_Depot';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'id_utilisateur_nom', headerName: 'Utilisateur', width: 200 },
  { field: 'iddepot_code', headerName: 'DEPOT', width: 100 },
  { field: 'iddepot_libelle', headerName: 'NOM DEPOTS', width: 250 },
  { field: 'id_utilisateur', headerName: 'ID Utilisateur', width: 100 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetAcces_DepotsListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['Acces_DepotList'],
    queryFn: async () => await callAxiosApi(`${acces_depot_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddAcces_DepotLine = (queryClient, handleCloseAcces_Depot, handleRowSelectionAcces_DepotsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${acces_depot_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['Acces_DepotList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addAcces_DepotLine onSuccess');
      customConsoleLog(newprod);
      handleCloseAcces_Depot();
      handleRowSelectionAcces_DepotsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateAcces_DepotLine = (queryClient, handleCloseAcces_Depot, handleRowSelectionAcces_DepotsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${acces_depot_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateAcces_DepotLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateAcces_DepotLine called')
      queryClient.setQueryData(['Acces_DepotList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetAcces_DepotQuery(newprod.id, queryClient);
      handleCloseAcces_Depot();
      handleRowSelectionAcces_DepotsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteAcces_DepotLine = (queryClient, handleCloseAcces_Depot) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${acces_depot_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['Acces_DepotList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetAcces_DepotQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseAcces_Depot();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetAcces_DepotsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['Acces_DepotList'] });
}


const Acces_DepotsList = forwardRef((props, ref) => {
  //function Acces_DepotsList({ removeWidget, isModal }) {
  const { handleGetid_acces_depot, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openAcces_DepotList, setOpenAcces_DepotList] = useState(false);
  const [openAcces_Depot, setOpenAcces_Depot] = useState(false);

  const [actionNameAcces_Depot, setActionNameAcces_Depot] = useState("")
  const [selectAcces_DepotsList, setSelectionAcces_DepotsList] = useState([]);

  const handleRowSelectionAcces_DepotsList = (e) => {
    if (e != selectAcces_DepotsList[0]) {
      setSelectionAcces_DepotsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenAcces_Depot = () => {
    setOpenAcces_Depot(true);

    //BasicModalRef.current.open();
  }
  const handleCloseAcces_Depot = () => {
    setOpenAcces_Depot(false);
    //BasicModalRef.current.close();
    cleanGetAcces_DepotEmptyQuery(queryClient);
    cleanGetAcces_DepotQuery(selectAcces_DepotsList[0], queryClient);
  }



  const [status_acces_depot, execute_acces_depot, resolve_acces_depot, reject_acces_depot, reset_acces_depot] = useAwaitableComponent();
  const [asyncConfirmDialogInfoAcces_Depot, setConfirmDialogInfoAcces_Depot] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogAcces_DepotsList = status_acces_depot === "awaiting";

  const handleAwaitModalAcces_DepotsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoAcces_Depot({ titre: mtitre, msg: mmsg });
      await execute_acces_depot();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_acces_depot();
    }
  };


  customConsoleLog('Acces_DepotsList rendered')



  const { data: data_acces_depotslist = [], error: error_acces_depotslist,
    refetch: refecth_acces_depotslist, isLoading: isLoading_acces_depotslist, failureCount: failureCount_acces_depotslist } =
    GetAcces_DepotsListQuery();






  const handleFiltrerAcces_DepotsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauAcces_DepotsList = () => {

    clearToasts();
    setActionNameAcces_Depot(btnActionsName.nouveau);
    setSelectionAcces_DepotsList([])
    handleOpenAcces_Depot();

  }

  const handleConsulterAcces_DepotsList = () => {
    if (selectAcces_DepotsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Depot(btnActionsName.consulter);

    handleOpenAcces_Depot();
    customConsoleLog(selectAcces_DepotsList[0]);
  }

  const handleDupliquerAcces_DepotsList = () => {
    if (selectAcces_DepotsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Depot(btnActionsName.dupliquer);
    handleOpenAcces_Depot();
    customConsoleLog(selectAcces_DepotsList[0]);
  }

  const handleModifierAcces_DepotsList = () => {
    if (selectAcces_DepotsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameAcces_Depot(btnActionsName.modifier);
    handleOpenAcces_Depot();
    customConsoleLog(selectAcces_DepotsList[0]);
  }


  const handleSupprimerAcces_DepotsList = async () => {
    if (selectAcces_DepotsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectAcces_DepotsList[0])
    if (await handleAwaitModalAcces_DepotsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameAcces_Depot(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteAcces_DepotLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenAcces_DepotsList = (itemID) => {
    setOpenAcces_DepotList(true);


    if(itemID) {
      handleRowSelectionAcces_DepotsList([itemID]);
    }
  }


  const handleFermerAcces_DepotsList = () => {
    if (selectAcces_DepotsList[0]) {
      if (handleGetid_acces_depot) {

        handleGetid_acces_depot(selectAcces_DepotsList[0]);
      }
    }
    cleanGetAcces_DepotsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetAcces_DepotsListQuery(queryClient);
      cleanGetAcces_DepotEmptyQuery(queryClient);
      cleanGetAcces_DepotQuery(selectAcces_DepotsList[0], queryClient);
      setOpenAcces_DepotList(false);
    }
    else{

      setOpenAcces_DepotList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_acces_depotslist.filter(item => item.id == id)[0];
  }



  const addAcces_DepotLine = useAddAcces_DepotLine(queryClient, handleCloseAcces_Depot, handleRowSelectionAcces_DepotsList);


  const updateAcces_DepotLine = useUpdateAcces_DepotLine(queryClient, handleCloseAcces_Depot, handleRowSelectionAcces_DepotsList);

  const deleteAcces_DepotLine = useDeleteAcces_DepotLine(queryClient, handleCloseAcces_Depot);






  const DataTableAcces_DepotsListRef = React.useRef(null);

  const handlePrintAcces_DepotsList = () => {
    if (DataTableAcces_DepotsListRef.current) {
      DataTableAcces_DepotsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenAcces_DepotsList(itemID);
    },
    handleClose() {
      handleFermerAcces_DepotsList();
    }
  }));

  // if (isLoading_acces_depotslist || error_acces_depotslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerAcces_DepotsList}
  //         refetch={refecth_acces_depotslist}
  //         failureCount={failureCount_acces_depotslist}
  //         error={error_acces_depotslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openAcces_DepotList} handleClose={handleFermerAcces_DepotsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_acces_depotslist || error_acces_depotslist
            ? <BasicLoading
              handleClose={handleFermerAcces_DepotsList}
              refetch={refecth_acces_depotslist}
              failureCount={failureCount_acces_depotslist}
              error={error_acces_depotslist} />
            : <>
              {
                showAsyncConfirmDialogAcces_DepotsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogAcces_DepotsList}
                  onSubmit={resolve_acces_depot}
                  onCancel={reject_acces_depot}
                  info={asyncConfirmDialogInfoAcces_Depot}
                />
              }


              {
                openAcces_Depot &&
                <BasicModal open={openAcces_Depot} isModal={true}>
                  <Acces_Depot
                    actionName={actionNameAcces_Depot}
                    selectedItem={getSelecteditemByID(selectAcces_DepotsList[0])}
                    handleClose={handleCloseAcces_Depot}
                    addAcces_DepotLine={addAcces_DepotLine}
                    updateAcces_DepotLine={updateAcces_DepotLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Acces_Depots</h2>}

              <RedDataTableBasic
                ref={DataTableAcces_DepotsListRef}
                rows={data_acces_depotslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionAcces_DepotsList} 
                select={selectAcces_DepotsList}
                onRowDoubleClick={handleConsulterAcces_DepotsList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerAcces_DepotsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauAcces_DepotsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterAcces_DepotsList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerAcces_DepotsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierAcces_DepotsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintAcces_DepotsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerAcces_DepotsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerAcces_DepotsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default Acces_DepotsList



const Acces_DepotsListwidgetKey = 'Acces_Depotswidget'
export const Acces_DepotsListwidget = { i: Acces_DepotsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: Acces_DepotsList, titre: 'Acces_Depots', id_fenetre: 901 }
