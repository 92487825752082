import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { lignesmvt_list_api } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  calculateHT, calculateQES, calculateReduction, calculateTTC, clearToasts, formatDate, formatDateTime,
  formatDecimalNumber, getPrixArticle, getPrixFromLivraisonType, useGetFetchQuery
} from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import RedEditDataTableBasic from '../../components/RedEditDataTableBasic';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import BasicSelect from '../../components/BasicSelect';
import ArticlesList from '../article/ArticlesList';
import BasicDecimalInput from '../../components/BasicDecimalInput';
import BasicNumberInput from '../../components/BasicNumberInput';
import BasicCheckBox from '../../components/BasicCheckBox';
import { useLoginStore, useMvtInfoStore } from '../../stores/globalStore';
import dayjs from 'dayjs';
import { customConsoleLog } from '../../global/Tools';
import Article_FardeausList from '../article_fardeau/Article_FardeausList';


export const GetLignesMvtsListQuery = (formValues, canFetch = true) => {
  const params = formValues;
  customConsoleLog('params', params);

  return useQuery({
    queryKey: ['LignesMvtsList', params?.id_mvt],
    queryFn: async () => {
      customConsoleLog('LignesMvtsList called');
      return await callAxiosApi(`${lignesmvt_list_api}`, { params }, true);
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

export const cleanGetLignesMvtsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['LignesMvtsList'] });
}

/*
 
 id_mvt = models.ForeignKey(Mvts, on_delete=models.CASCADE, related_name='lignesmvt', db_column='ID mvt', blank=True, null=True)   
    id_article = models.ForeignKey(Articles, on_delete=models.RESTRICT, related_name='lignesmvt', db_column='ID article', blank=True, null=True)   
    qte = models.FloatField(db_column='Qte', blank=True, null=True)  
    pu = models.FloatField(db_column='PU', blank=True, null=True)  
    composant = models.CharField(db_column='Composant', max_length=1, blank=True, null=True)  
    pa = models.FloatField(db_column='PA', blank=True, null=True)  
    pp = models.FloatField(db_column='PP', blank=True, null=True)  
    notes = models.TextField(db_column='Notes', blank=True, null=True)  
    num_systeme = models.IntegerField(db_column='Num systeme', blank=True, null=True)   
    qes = models.FloatField(db_column='QES', blank=True, null=True)  
    taux = models.FloatField(db_column='Taux', blank=True, null=True)  
    ref_mvt = models.CharField(db_column='Ref mvt', max_length=17, blank=True, null=True)
    saisie_le = models.DateTimeField(db_column='Saisie le', blank=True, null=True)   
    perso = models.CharField(db_column='Perso', max_length=12, blank=True, null=True)  
    puht = models.FloatField(db_column='PUHT', blank=True, null=True)  
    ppttc = models.FloatField(db_column='PPTTC', blank=True, null=True)  
    rms = models.FloatField(db_column='RMS', blank=True, null=True)  
    numstk = models.CharField(db_column='NumStk', max_length=1, blank=True, null=True)  
    nbp = models.IntegerField(db_column='NBP', blank=True, null=True)  
*/


function CustomSelectArticleEditComponent(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  customConsoleLog('props.row.id_article', props.row.id_article);

  const { g_CliOrFr, g_Cat, g_mvtType, g_livraisonType } = useMvtInfoStore();

  const [isArticle, setIsArticle] = React.useState(true);
  const [isArticle_Fardeau, setIsArticle_Fardeau] = React.useState(true);



  const isReadOnly = false;
  // const { data: data_articleslist = [], error: error_articleslist,
  //   refetch: refecth_articleslist, isLoading: isLoading_articleslist, failureCount: failureCount_articleslist } =
  //   GetArticlesListQuery();

  const data_articleslist = useGetFetchQuery(['ArticleList']);
  const data_articlesFardeaulist = useGetFetchQuery(['Article_FardeauList']);



  const handleListClick = () => {
    handleArticleClick();
  }

  const ArticleListRef = React.useRef();
  const ArticleFardeauListRef = React.useRef();

  const handleArticleClick = () => {
    customConsoleLog('handleArticleClick article')
    ArticleListRef.current.handleOpen(value); // article
    ArticleListRef.current.handleRefresh();
  };

  const handleArticleFardeauClick = () => {
    customConsoleLog('handleArticleFardeauClick article')
    ArticleFardeauListRef.current.handleOpen(value); // article
    ArticleFardeauListRef.current.handleRefresh();
  };

  const handleGetid_article = (value) => {
    handleValueChange({ target: { value: value } });
  }

  const handleGetid_article_fardeau = (value) => {
    customConsoleLog('handleGetid_article_fardeau value', value);
    const articleF = data_articlesFardeaulist?.find((article) => article.id == value);
    customConsoleLog('handleGetid_article_fardeau articleF', articleF);
    const article = data_articleslist?.find((article) => article.id == articleF.id_famille);
    customConsoleLog('handleGetid_article_fardeau article', articleF);


    handleValueChange({ target: { value: article.id, artf: articleF } });
  }


  const handleValueChange = (event) => {
    customConsoleLog('CustomSelectEditComponent handleValueChange article');
    //if(value == event.target.value) return;
    const newValue = event.target.value;

    const artfID = event.target?.artf?.id || false;

    customConsoleLog('isArticle artf', artfID);


    const article = data_articleslist?.find((article) => article.id == newValue);
    const articleF = data_articlesFardeaulist?.find((article) => article.id == artfID);
    customConsoleLog('article', article);

    if (isArticle) {
      customConsoleLog('isArticle', isArticle);
    }
    else if (isArticle_Fardeau) {
      customConsoleLog('isArticle_Fardeau', isArticle_Fardeau);

    }
    const articleGot = isArticle ? article : articleF;
    customConsoleLog('isArticle newValue', newValue);
    customConsoleLog('isArticle articleGot', isArticle, isArticle_Fardeau, articleGot);

    // if article.unite = M2, UN, KG field 'qte' should be read only

    const isReadOnly = articleGot?.unite == 'M2' || articleGot?.unite == 'UN' || articleGot?.unite == 'KG';
    customConsoleLog('isReadOnly', isReadOnly);


    const prixArt = isArticle ? getPrixArticle(articleGot, g_CliOrFr, g_Cat) : getPrixFromLivraisonType(article, g_CliOrFr, g_livraisonType);
    const prixHt = calculateHT(prixArt, article?.tva_taux);

    const defaultqte = articleGot?.xsect || 0;
    const finalValue = isArticle_Fardeau ? defaultqte : 0;

    apiRef.current.setEditCellValue({ id, field: 'id_article', value: newValue });
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.setEditCellValue({ id, field: 'nbp', value: isArticle_Fardeau ? articleF?.xcoef : 0 });
    apiRef.current.setEditCellValue({ id, field: 'qte', value: finalValue });
    apiRef.current.setEditCellValue({ id, field: 'qes', value: isArticle_Fardeau ? calculateQES(g_mvtType, finalValue) : 0 });
    apiRef.current.setEditCellValue({ id, field: 'ppttc', value: prixArt });
    apiRef.current.setEditCellValue({ id, field: 'pu', value: prixArt });
    apiRef.current.setEditCellValue({ id, field: 'puht', value: prixHt });
    apiRef.current.setEditCellValue({ id, field: 'pp', value: prixHt });
    //apiRef.current.setEditCellValue({ id, field: 'ppttc', value: 0 });
    apiRef.current.setEditCellValue({ id, field: 'defaultqte', value: defaultqte });
    apiRef.current.setEditCellValue({ id, field: 'taux', value: article?.tva_taux });
    apiRef.current.setEditCellValue({ id, field: 'pa', value: articleGot?.pa });
    apiRef.current.setEditCellValue({ id, field: 'numstk', value: 1 }); // a revoir
    apiRef.current.setEditCellValue({ id, field: 'rms', value: 0 });
    apiRef.current.setEditCellValue({ id, field: 'saisie_le', value: formatDateTime(dayjs(), true) });
    apiRef.current.setEditCellValue({ id, field: 'notes', value: '' });
    apiRef.current.setEditCellValue({ id, field: 'isNotEditable', value: isReadOnly });
    apiRef.current.setEditCellValue({ id, field: 'perso', value: isArticle_Fardeau ? articleF?.code : '' })

  };

  const onKeyUp = (e) => {
    if (field == 'code_article') {
      const key = e.key;
      customConsoleLog('onKeyUp edit Articlet', key);
      if (key == 'F9') {
        //Articles fardeau
        setIsArticle(false);
        setIsArticle_Fardeau(true);
        handleArticleFardeauClick();
      }
      else if (key == 'F4') {
        // Articles
        setIsArticle(true);
        setIsArticle_Fardeau(false);
        handleArticleClick();
      }
    }
  };



  return (
    <>
      {
        hasFocus && isArticle ?
          <ArticlesList ref={ArticleListRef} handleGetid_article={handleGetid_article} isModal={true} cleanData={true} />
          :
          <></>
      }

      {
        hasFocus && isArticle_Fardeau ?
          <Article_FardeausList ref={ArticleFardeauListRef} handleGetid_article_fardeau={handleGetid_article_fardeau} isModal={true} cleanData={true} />
          :
          <></>
      }


      <div className='w-full' tabIndex={0} onKeyUp={onKeyUp} style={{ outline: 'none' }} >
        <BasicSelect listItems={data_articleslist || []}
          portalClassName='min-w-[700px]'
          className='text-xs'
          inputClassName='text-xs text-blue-600'
          value={value || ''}
          focus={hasFocus}
          valueKey={'id'}
          textKey={'code'}
          fieldsConfig={[{ key: 'code', title: 'Code' }, { key: 'libelle', title: 'Libelle' }, { key: 'xsect', title: 'Section' }]}
          onChange={handleValueChange}
          //onKeyUp={onKeyUp}
          readOnly={isReadOnly}
          isNotClearable={true}>
          <BasicButtonSmall type="button" disabled={isReadOnly} loading={false} onClick={handleListClick}>...</BasicButtonSmall>
        </BasicSelect>
      </div>
    </>
  );


}

function UpdateTotal(id, nbp, qte, pu, taux) {

  //const ppttc = nbp * qte * pu * (1 + taux / 100);
  const ppttc = qte * pu;
  return ppttc;
}

function CustomNBPEditComponent(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();
  const { g_mvtType } = useMvtInfoStore();

  const data_articleslist = useGetFetchQuery(['ArticleList']);
  const data_articlesFardeaulist = useGetFetchQuery(['Article_FardeauList']);


  const handleValueChange = (event) => {
    const newValue = event.target.value;

    const id_article = apiRef.current.getCellValue(id, 'id_article') || 0;
    const code_fardeau = apiRef.current.getCellValue(id, 'perso') || '';

    const isArticle_Fardeau = code_fardeau ? true : false;

    const article = data_articleslist?.find((article) => article.id == id_article);
    const articleF = data_articlesFardeaulist?.find((article) => article.code == code_fardeau);

    const articleGot = isArticle_Fardeau ? articleF : article;

    const defaultqte = articleGot?.xsect || 0;
    const finalValueF = isArticle_Fardeau ? defaultqte : 0;

    const finalValueA = defaultqte == 0 ? newValue : defaultqte * newValue;

    const finalValue = isArticle_Fardeau ? finalValueF : finalValueA;


    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.setEditCellValue({ id, field: 'defaultqte', value: defaultqte });
    apiRef.current.setEditCellValue({ id, field: 'qte', value: finalValue });
    apiRef.current.setEditCellValue({ id, field: 'qes', value: calculateQES(g_mvtType, finalValue) });
  };

  return (
    <BasicNumberInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange} />
  )
}


function CustomNumberEditComponent(props) {
  const { id, value, field, hasFocus, sameValue } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();


  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (sameValue) {
      sameValue.forEach((childfield) => {
        apiRef.current.setEditCellValue({ id, field: childfield, value: newValue });
      });
    }
  };

  return (
    <BasicNumberInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange} />
  )
}


function CustomDecimalEditComponent(props) {
  const { id, value, field, hasFocus, decimalPlaces, sameValue } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const maxFractionDigits = decimalPlaces ? decimalPlaces : 2;
  const minFractionDigits = decimalPlaces ? decimalPlaces : 2;

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (sameValue) {
      sameValue.forEach((childfield) => {
        apiRef.current.setEditCellValue({ id, field: childfield, value: newValue });
      });
    }
  };

  return (
    <BasicDecimalInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange}
      maxFractionDigits={maxFractionDigits} minFractionDigits={minFractionDigits} />
  )
}

function CustomQteEditComponent(props) {
  const { id, value, field, hasFocus, decimalPlaces, ...others } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();
  const { g_mvtType } = useMvtInfoStore();

  const isNotEditable = apiRef.current.getCellValue(id, 'isNotEditable') || false;

  const maxFractionDigits = decimalPlaces ? decimalPlaces : 2;
  const minFractionDigits = decimalPlaces ? decimalPlaces : 2;

  const data_articleslist = useGetFetchQuery(['ArticleList']);

  // if (isNotEditable) 
  //   {
  //     apiRef.current.stopCellEditMode({ id:id, field:field, ignoreModifications: true, cellToFocusAfter: 'right'});

  //   }

  const handleValueChange = (event) => {
    customConsoleLog('isReadOnly others.isNotEditable', isNotEditable);
    customConsoleLog('isReadOnly event', event);

    if (isNotEditable) {
      return;
      const oldValue = apiRef.current.getCellValue(id, 'nbp');
      customConsoleLog('isReadOnly oldValue', oldValue);

      const id_article = apiRef.current.getCellValue(id, 'id_article');

      const article = data_articleslist?.find((article) => article.id == id_article);

      const defaultqte = article?.xsect || 0;
      const finalValue = defaultqte == 0 ? oldValue : defaultqte * oldValue;

      apiRef.current.setEditCellValue({ id, field: 'qte', value: finalValue });
      apiRef.current.setEditCellValue({ id, field: 'qes', value: calculateQES(g_mvtType, finalValue) });
    }
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.setEditCellValue({ id, field: 'qes', value: calculateQES(g_mvtType, newValue) });

  };



  if (isNotEditable) {
    return (

      <BasicDecimalInput focus={hasFocus} className="text-xs text-blue-600"
        value={value || ''} onChange={handleValueChange}
        maxFractionDigits={maxFractionDigits} minFractionDigits={minFractionDigits} isNotEditable={true} />
    )
  }
  else {
    return (

      <BasicDecimalInput focus={hasFocus} className="text-xs text-blue-600"
        value={value || ''} onChange={handleValueChange}
        maxFractionDigits={maxFractionDigits} minFractionDigits={minFractionDigits} />
    )
  }
}

function CustomTTCEditComponent(props) {
  const { id, value, field, hasFocus, decimalPlaces, htField } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const maxFractionDigits = decimalPlaces ? decimalPlaces : 2;
  const minFractionDigits = decimalPlaces ? decimalPlaces : 2;

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    customConsoleLog('newValue', newValue);
    customConsoleLog('field', field);
    customConsoleLog('htField', htField);
    if (htField) {
      const taux = apiRef.current.getCellValue(id, 'taux') || 0;
      const ht = calculateHT(newValue, taux);
      htField.forEach((childfield) => {
        customConsoleLog('childfield', childfield);
        customConsoleLog('ht', ht);
        apiRef.current.setEditCellValue({ id, field: childfield, value: ht });
      });
    }
  };

  return (
    <BasicDecimalInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange}
      maxFractionDigits={maxFractionDigits} minFractionDigits={minFractionDigits} />
  )
}


function CustomHTEditComponent(props) {
  const { id, value, field, hasFocus, decimalPlaces, ttcField } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const maxFractionDigits = decimalPlaces ? decimalPlaces : 2;
  const minFractionDigits = decimalPlaces ? decimalPlaces : 2;

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    customConsoleLog('newValue', newValue);
    customConsoleLog('field', field);
    customConsoleLog('ttcField', ttcField);
    if (ttcField) {
      const taux = apiRef.current.getCellValue(id, 'taux') || 0;
      const ttc = calculateTTC(newValue, taux);
      customConsoleLog('ttc', ttc);
      ttcField.forEach((childfield) => {
        customConsoleLog('setEditCellValue childfield', childfield);
        apiRef.current.setEditCellValue({ id, field: childfield, value: ttc });
      });
    }
  };

  return (
    <BasicDecimalInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange}
      maxFractionDigits={maxFractionDigits} minFractionDigits={minFractionDigits} />
  )
}


function CustomTauxEditComponent(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();


  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });

    const pu = apiRef.current.getCellValue(id, 'pu') || 0;
    const ppttc = apiRef.current.getCellValue(id, 'ppttc') || 0;

    const puht = calculateHT(pu, newValue);
    const pp = calculateHT(ppttc, newValue);

    apiRef.current.setEditCellValue({ id, field: 'puht', value: puht });
    apiRef.current.setEditCellValue({ id, field: 'pp', value: pp });
  };

  return (
    <BasicNumberInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange} />
  )
}

function CustomTextEditComponent(props) {
  const { id, value, field, hasFocus, sameValue } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();


  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (sameValue) {
      sameValue.forEach((childfield) => {
        apiRef.current.setEditCellValue({ id, field: childfield, value: newValue });
      });
    }
  };

  return (
    <BasicInput focus={hasFocus} className="text-xs text-blue-600"
      value={value || ''} onChange={handleValueChange} />
  )
}

function CustomShowArticletextComponent(props) {
  const { id, value, field, hasFocus, defaultkey } = props;
  const apiRef = useGridApiContext();

  const val = useGetArticleById(value, defaultkey);




  // const onKeyUp = (e) => {
  //   if (field == 'code_article') {
  //     customConsoleLog('onKeyUp Articlet', e);
  //   }
  // };

  // const onKeyDown = (e) => {
  //   if (field == 'code_article') {
  //     //customConsoleLog('onKeyDown Articlet', e);
  //   }
  // };

  // const handleKeyUp = (e) => {
  //   if (field === 'code_article') {
  //     customConsoleLog('handleKeyUp props',props);
  //     if (e.key === 'F9') {
  //       customConsoleLog('handleKeyUp', e.key);
  //       triggerEnterKey(id, field, apiRef, 'F9', 120);
  //     }
  //     else if (e.key === 'F4') {
  //       customConsoleLog('handleKeyUp', e.key);
  //       triggerEnterKey(id, field, apiRef, 'F4', 115);
  //     }
  //   }
  // };

  // const triggerEnterKey = (_id, field, api, keyCode, which) => {
  //   customConsoleLog('handleKeyUp triggerEnterKey', 'Enter', which, _id);
  //   apiRef.current.startRowEditMode(_id, 'edit');
  //   // const event = new KeyboardEvent('keydown', { key: 'Enter', code: 'Enter', which: which });
  //   // document.dispatchEvent(event); 

  //   setTimeout(() => {
  //     triggerF9Key(keyCode, which);
  //   }, 200);
  // };

  // const triggerF9Key = (keyCode, which) => {
  //   customConsoleLog('handleKeyUp triggerF9Key', keyCode, which);
  //   const event = new KeyboardEvent('keydown', { key: keyCode, code: keyCode, which: which });
  //   document.dispatchEvent(event); // Dispatch the 'F9' key event to open the modal
  // };

  const onKeyUp = (e) => {
    if (field === 'code_article') {
      customConsoleLog('handleKeyUp', e.key);
  
      if (e.key === 'F9') {
        handleKeyPress('F9', e.target);
      } else if (e.key === 'F4') {
        handleKeyPress('F4', e.target);
      }
    }
  };
  
  const handleKeyPress = (key, target) => {
    // Ensure the target is focused
    if (target) {
      target.focus();
    }
  
    // Trigger 'Enter' key event first to enter edit mode or perform other actions
    triggerEnterKey(id, field, apiRef, key, target);
  };
  
  const triggerEnterKey = (_id, field, api, key, target) => {
    customConsoleLog('handleKeyUp triggerEnterKey', 'Enter', key, _id);
  
    // Simulate pressing the 'Enter' key on the target element
    const eventEnter = new KeyboardEvent('keydown', { key: 'Enter', code: 'Enter', keyCode: 13 });
    target.dispatchEvent(eventEnter);
  
    setTimeout(() => {
      triggerKey(key, target);
    }, 200); // Delay to ensure 'Enter' is processed before the key
  };
  
  const triggerKey = (key, target) => {
    customConsoleLog('handleKeyUp triggerKey', key);
  
    // Simulate pressing the given key ('F9' or 'F4') on the target element
    const eventKey = new KeyboardEvent('keydown', { key, code: key, keyCode: key === 'F9' ? 120 : 115 });
    target.dispatchEvent(eventKey);
  };

  return (
    <div tabIndex={0} onKeyUp={onKeyUp} style={{ outline: 'none' }} id={`id-${id}`}>{val}</div>
  );
}


function useGetArticleById(idArticle, defaultkey) {
  const data = useGetFetchQuery(['ArticleList']);

  const article = data?.find((article) => article.id == idArticle);

  if (article) {
    return article[defaultkey];
  }
  else {
    return '';
  }

}

function randomId() {
  return 'rand' + Math.floor(Math.random() * 1000000);
}


// puht = pu - tva
// pp = ppttc - tva (it's ppht)
// ppttc = original price
// pu = chosen price
// rms = percentage reduction of pu compared to ppttc
// montant_ttc = qte * pu (not saved in the database)

const isCellEditable = (params) => {
  // Access the row data
  const row = params.row;
  // Check the 'isNotEditable' value
  return !row.isNotEditable; // Editable if 'isNotEditable' is false
};

const LignesMvtcolumns = [
  {
    field: 'id', headerName: 'id', filterable: false, sortable: false,
    valueGetter({ row }) {
      return row.id ? row.id : randomId();
    },
  },
  { field: 'id_article', headerName: 'Id article', editable: true, sortable: false },
  {
    field: 'code_article', headerName: 'Code article', width: 160, editable: true, sortable: false,
    renderCell: (params) => <CustomShowArticletextComponent {...params} defaultkey={'code'} />,
    renderEditCell: (params) => <CustomSelectArticleEditComponent {...params} />,
    valueGetter({ row }) {
      return row.id_article
    },

  },
  {
    field: 'designation', headerName: 'Designation', width: 200, editable: false, sortable: false,
    renderCell: (params) => <CustomShowArticletextComponent {...params} defaultkey={'libelle'} />,
    valueGetter({ row }) {
      return row.id_article
    },
    cellClassName: 'bg-yellow-400',

  },
  {
    field: 'un', headerName: 'UN', width: 50, editable: false, sortable: false,
    renderCell: (params) => {
      return <CustomShowArticletextComponent {...params} defaultkey={'unite'} />
    },
    valueGetter({ row }) {
      return row.id_article
    },
    cellClassName: 'bg-yellow-400',

  },
  {

    field: 'nbp', headerName: 'NBP', width: 50, editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 0),
    renderEditCell: (params) => <CustomNBPEditComponent {...params} />,
  },
  {
    field: 'qte', headerName: 'qte', width: 100, editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 5),
    renderEditCell: (params) => {
      // if (params.row.isNotEditable) {
      //   params.cellMode = "view";
      //   params.hasFocus = false;
      //   params.editable = false;
      //   return <div>{params.value}</div>;
      // } else {
      //customConsoleLog('isReadOnly params qte', params);
      // if(params.row.isNotEditable){
      //     params.cellMode = "view";
      //     params.hasFocus = false;
      //     params.editable = false;
      //     params.isEditable = false;
      //   }
      //   else{
      //       params.cellMode = "edit";
      //       params.editable = true;
      //       params.isEditable = true;
      //     }
      return <CustomQteEditComponent {...params} decimalPlaces={5} sameValue={['qes']} />;
      // return <></>;
      //}
    },
    // preProcessEditCellProps: (params) => ({
    //   ...params.props,
    //   editable: isCellEditable(params),
    // }),
  },
  {
    field: 'pp', headerName: 'PP HT', editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
    renderEditCell: (params) => <CustomHTEditComponent {...params} decimalPlaces={2} ttcField={['ppttc']} />,
  },
  {
    field: 'ppttc', headerName: 'PP TTC', width: 100, editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value),
    renderEditCell: (params) => <CustomTTCEditComponent {...params} decimalPlaces={2} htField={['pp']} />,
  },
  {
    field: 'puht', headerName: 'Prix HT', editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
    renderEditCell: (params) => <CustomHTEditComponent {...params} decimalPlaces={2} ttcField={['pu']} />,
  },
  {
    field: 'pu', headerName: 'Prix TTC', width: 100, editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value),
    renderEditCell: (params) => <CustomTTCEditComponent {...params} decimalPlaces={2} htField={['puht']} />,
  },
  {
    field: 'montant_ht', headerName: 'Montant HT', width: 100, editable: false, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value),
    cellClassName: 'bg-yellow-400',
    valueGetter({ row }) {
      return UpdateTotal(row.id, row.nbp, row.qte, row.puht, row.taux);
    },
  },
  {
    field: 'montant_ttc', headerName: 'Montant TTC', width: 100, editable: false, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value),
    cellClassName: 'bg-yellow-400',
    valueGetter({ row }) {
      return UpdateTotal(row.id, row.nbp, row.qte, row.pu, row.taux);
    },
  },
  {
    field: 'taux', headerName: 'TVA', width: 50, editable: true, sortable: false,
    type: 'number',
    valueFormatter: ({ value }) => formatDecimalNumber(value, 0),
    renderEditCell: (params) => <CustomTauxEditComponent {...params} />,
  },
  {
    field: 'notes', headerName: 'notes', editable: true, sortable: false,
    renderEditCell: (params) => <CustomTextEditComponent {...params} />,
  },
  {
    field: 'defaultqte', headerName: 'defaultqte', editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 5),
  },
  {
    field: 'qes', headerName: 'qes', editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 5),
  },
  {
    field: 'pa', headerName: 'pa', editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
  },
  {
    field: 'rms', headerName: 'rms', editable: true, sortable: false,
    type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 2),
    valueGetter({ row }) {
      return calculateReduction(row.ppttc, row.pu);
    }
  },
  {
    field: 'saisie_le', headerName: 'saisie_le', editable: true, sortable: false,
    type: 'dateTime', valueFormatter: ({ value }) => formatDateTime(value),
  },
  {
    field: 'numstk', headerName: 'numstk', editable: true, sortable: false,
    type: 'number',
  },
  {
    field: 'isNotEditable', headerName: 'isNotEditable', editable: true, sortable: false,
    type: 'boolean',
  },
  {
    field: 'perso', headerName: 'Fardeau', editable: true, sortable: false,
  }
];






const LignesMvthideColumns = {
  id: false,
  id_article: false,
  defaultqte: false,
  qes: false,
  puht: false,
  ppttc: false,
  pp: false,
  montant_ht: false,
  pa: false,
  rms: false,
  numstk: false,
  saisie_le: false,
};

const LignesMvthideColumnsHt = {
  id: false,
  id_article: false,
  defaultqte: false,
  qes: false,
  pu: false,
  ppttc: false,
  pp: false,
  montant_ttc: false,
  pa: false,
  rms: false,
  numstk: false,
  saisie_le: false,
}

const getStock_By_Depot = (tmp_art, tmp_depot) => {

  let stock = 0;

  switch (tmp_depot) {
    case 1:
      stock = tmp_art.qt1;
      break;
    case 2:
      stock = tmp_art.qt2;
      break;
    case 3:
      stock = tmp_art.qt3;
      break;
    case 4:
      stock = tmp_art.qt4;
      break;
    case 5:
      stock = tmp_art.qt5;
      break;
    default:
      stock = tmp_art.qti;
      break;
  }

  return stock;
}




const checkOutStock = (tmp_articlelist, tmp_lignesmvtList, tmp_mvtType, tmp_depot, tmp_articleFardeaulist) => {

  let mylist = []
  //let isOutOfStock = false;
  //let myvar = { isOutOfStock: false, ligneIndex: mylist}

  for (var i = 0; i < tmp_lignesmvtList.length; i++) {
    if (tmp_lignesmvtList[i]?.code_article) {

      const tmp_ligne = tmp_lignesmvtList[i];

      const isArticle_Fardeau = tmp_ligne.perso ? true : false;

      customConsoleLog('getStock_By_Depot tmp_ligne?.id_article', tmp_ligne?.id_article);

      const tmp_article = isArticle_Fardeau
        ? tmp_articleFardeaulist.find((article) => article.code == tmp_ligne?.perso)
        : tmp_articlelist.find((article) => article.id == tmp_ligne?.id_article);

      customConsoleLog(tmp_articlelist);
      customConsoleLog('getStock_By_Depot tmp_article', tmp_article);

      //customConsoleLog('getStock_By_Depot id_article', tmp_ligne?.id_article);

      customConsoleLog('getStock_By_Depot isArticle_Fardeau', isArticle_Fardeau);

      /////
      customConsoleLog('getStock_By_Depot tmp_article, tmp_depot', tmp_article, tmp_depot)
      const tmp_stk = isArticle_Fardeau ? tmp_article.xsect : getStock_By_Depot(tmp_article, tmp_depot);
      const tmp_val = calculateQES(tmp_mvtType, tmp_ligne.qte);

      const tmp_needCheck = calculateQES(tmp_mvtType, 1) < 0 ? true : false;

      customConsoleLog('lignesmvtList tmp_stk + tmp_val', (tmp_stk + tmp_val));
      if (((tmp_stk + tmp_val) < 0) && tmp_needCheck) {
        // BAHBI 0.8 43
        customConsoleLog('lignesmvtList out of stock');
        //isOutOfStock = true;
        mylist.push({ ligneIndex: i, isOutOfStock: true });
      }
      else {
        mylist.push({ ligneIndex: i, isOutOfStock: false })
      }

    }
  }

  return mylist;
  //return { isOutOfStock: isOutOfStock, ligneIndex: mylist};
}

export const LignesMvt = (props) => {
  const { id_mvt, lignesmvtList, setLignesmvtList, isHt, columns, columnVisibilityModel, canDelete, height, width,
    setIsOutOfStock, ...others } = props;
  const queryClient = useQueryClient();
  const [selectedLigneMvt, setSelectedLigneMvt] = React.useState([]);

  const { g_mvtType, g_CliOrFr, g_depot, g_Cat, g_livraisonType } = useMvtInfoStore();

  const { data = [], error, isError, isLoading, failureCount, refetch } = GetLignesMvtsListQuery({ id_mvt: id_mvt }, id_mvt ? true : false);

  const data_articleslist = useGetFetchQuery(['ArticleList']);
  const data_articleFardeaulist = useGetFetchQuery(['Article_FardeauList']);



  const handleRowSelectionLignesMvt = (e) => {
    if (e != selectedLigneMvt[0]) {
      setSelectedLigneMvt(e);
      customConsoleLog(e);
    }
  }

  React.useEffect(() => {
    if (data) {
      setLignesmvtList(data);
    }
  }, [isLoading]);


  React.useEffect(() => {
    if (lignesmvtList && !others.isReadOnly && data_articleslist && data_articleFardeaulist) {
      customConsoleLog('lignesmvtList ', lignesmvtList);

      customConsoleLog('lignesmvtList data_articleslist', data_articleslist);
      customConsoleLog('lignesmvtList data_articleFardeaulist', data_articleFardeaulist);

      const mylist = checkOutStock(data_articleslist, lignesmvtList, g_mvtType, g_depot, data_articleFardeaulist);

      let tester = false;

      if (mylist && DataTableLignesMvtRef) {
        for (let index = 0; index < mylist.length; index++) {
          const element = mylist[index].ligneIndex;
          const isOutOfStock = mylist[index].isOutOfStock;

          if (isOutOfStock) {
            tester = true;
          }

          handleChangeBgColor(element, isOutOfStock);
        }
      }

      setIsOutOfStock(tester);
    }
  }, [lignesmvtList])


  const DataTableLignesMvtRef = React.useRef(null);

  const handlePrintLignesMvt = () => {
    if (DataTableLignesMvtRef.current) {
      DataTableLignesMvtRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  }

  const handleChangeBgColor = (ligne_id, is_error) => {
    const mcolor = is_error ? 'red' : 'default';
    if (DataTableLignesMvtRef.current) {
      DataTableLignesMvtRef.current.handleLigneColor(ligne_id, mcolor);
    }
  }

  const resVisibliityModel = isHt ? LignesMvthideColumnsHt : LignesMvthideColumns;


  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }

  return (
    <>
      <div className='w-full'>
        <div>

          <RedEditDataTableBasic
            nofilter={true}
            ref={DataTableLignesMvtRef}
            rows={lignesmvtList}
            setRows={setLignesmvtList}
            columns={columns || LignesMvtcolumns}
            columnVisibilityModel={columnVisibilityModel || resVisibliityModel}
            handleRowSelection={handleRowSelectionLignesMvt}
            select={selectedLigneMvt}
            isReadOnly={others.isReadOnly}
            height={height || '140px'}
            width={width}
            canDelete={canDelete || true}
          />
          {/* <BasicButtonSmall className='text-xxs' onClick={() => { customConsoleLog(lignesmvtList) }}>Show</BasicButtonSmall>
          <BasicButtonSmall className='text-xxs' onClick={() => { 
            customConsoleLog('g_mvtType', g_mvtType); 
            customConsoleLog('g_CliOrFr', g_CliOrFr); 
            customConsoleLog('g_depot', g_depot);
            customConsoleLog('g_Cat', g_Cat);
            }}>Show mvt info</BasicButtonSmall> */}
        </div>
      </div>
    </>
  );


}

export default LignesMvt;