
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, article_fardeau_delete_api, article_fardeau_info_api, article_fardeau_list_api } from '../../global/GlobalVars';
import Article_Fardeau from './Article_Fardeau';
import BasicButton from '../../components/BasicButton';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetArticle_FardeauEmptyQuery, cleanGetArticle_FardeauQuery } from './Article_Fardeau';
import { cleanGetEtablissementsListQuery } from '../etablissement/EtablissementsList';
import { cleanGetArticlesListQuery } from '../article/ArticlesList';
import { cleanGetTvasListQuery } from '../tva/TvasList';
import { cleanGetClientsListQuery } from '../client/ClientsList';
import { customConsoleLog } from '../../global/Tools';



const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'etablissements_code', headerName: 'DEP', width: 50 },
  { field: 'code', headerName: 'Fardeau', width: 100 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
  { field: 'unite', headerName: 'Unite', width: 50 },
  { field: 'xcoef', headerName: 'PIECE', width: 100, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'xsect', headerName: 'SECTION', width: 100, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value, 4) },
  { field: 'etat', headerName: 'ETAT', width: 50 },
  { field: 'code_article', headerName: 'Code article', width: 150 },
  { field: 'pp', headerName: 'PRIX VTE', width: 150, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'usage', headerName: 'Usage', width: 50 },
  { field: 'blk', headerName: 'BLK', width: 50 },
  { field: 'rem3', headerName: 'Rem3', width: 50, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'rem4', headerName: 'Rem4', width: 50, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'rem5', headerName: 'Rem5', width: 50, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value) },
  { field: 'rem6', headerName: 'Rem6', width: 50, type: 'number', valueFormatter: ({ value }) => formatDecimalNumber(value) },
];


const hideColumns = { id: false }


export const GetArticle_FardeausListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['Article_FardeauList'],
    queryFn: async () => await callAxiosApi(`${article_fardeau_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
    enabled: canFetch,
  })
}

const useAddArticle_FardeauLine = (queryClient, handleCloseArticle_Fardeau, handleRowSelectionArticle_FardeausList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${article_fardeau_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['Article_FardeauList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addArticle_FardeauLine onSuccess');
      customConsoleLog(newprod);
      handleCloseArticle_Fardeau();
      handleRowSelectionArticle_FardeausList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateArticle_FardeauLine = (queryClient, handleCloseArticle_Fardeau, handleRowSelectionArticle_FardeausList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${article_fardeau_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateArticle_FardeauLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateArticle_FardeauLine called')
      queryClient.setQueryData(['Article_FardeauList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetArticle_FardeauQuery(newprod.id, queryClient);
      handleCloseArticle_Fardeau();
      handleRowSelectionArticle_FardeausList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteArticle_FardeauLine = (queryClient, handleCloseArticle_Fardeau) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${article_fardeau_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['Article_FardeauList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetArticle_FardeauQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseArticle_Fardeau();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetArticle_FardeausListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['Article_FardeauList'] });
}

export const invalidateGetArticle_FardeausListQuery = (queryClient) => {
  queryClient.invalidateQueries({ queryKey: ['Article_FardeauList'] });
}
 
const Article_FardeausList = forwardRef((props, ref) => {
  //function Article_FardeausList({ removeWidget, isModal }) {
  const { handleGetid_article_fardeau, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openArticle_FardeauList, setOpenArticle_FardeauList] = useState(false);
  const [openArticle_Fardeau, setOpenArticle_Fardeau] = useState(false);

  const [actionNameArticle_Fardeau, setActionNameArticle_Fardeau] = useState("")
  const [selectArticle_FardeausList, setSelectionArticle_FardeausList] = useState([]);

  const handleCleanData = () => {
    invalidateGetArticle_FardeausListQuery(queryClient);
  }

  const handleRowSelectionArticle_FardeausList = (e) => {
    if (e != selectArticle_FardeausList[0]) {
      setSelectionArticle_FardeausList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenArticle_Fardeau = () => {
    setOpenArticle_Fardeau(true);

    //BasicModalRef.current.open();
  }
  const handleCloseArticle_Fardeau = () => {
    setOpenArticle_Fardeau(false);
    //BasicModalRef.current.close();
    cleanGetArticle_FardeauEmptyQuery(queryClient);
    cleanGetArticle_FardeauQuery(selectArticle_FardeausList[0], queryClient);

    cleanGetEtablissementsListQuery(queryClient);
    cleanGetArticlesListQuery(queryClient);
    cleanGetTvasListQuery(queryClient);
    cleanGetClientsListQuery(queryClient);
  }



  const [status_article_fardeau, execute_article_fardeau, resolve_article_fardeau, reject_article_fardeau, reset_article_fardeau] = useAwaitableComponent();
  const [asyncConfirmDialogInfoArticle_Fardeau, setConfirmDialogInfoArticle_Fardeau] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogArticle_FardeausList = status_article_fardeau === "awaiting";

  const handleAwaitModalArticle_FardeausList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoArticle_Fardeau({ titre: mtitre, msg: mmsg });
      await execute_article_fardeau();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_article_fardeau();
    }
  };


  customConsoleLog('Article_FardeausList rendered')



  const { data: data_article_fardeauslist = [], error: error_article_fardeauslist,
    refetch: refecth_article_fardeauslist, isLoading: isLoading_article_fardeauslist, failureCount: failureCount_article_fardeauslist } =
    GetArticle_FardeausListQuery();






  const handleFiltrerArticle_FardeausList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauArticle_FardeausList = () => {

    clearToasts();
    setActionNameArticle_Fardeau(btnActionsName.nouveau);
    setSelectionArticle_FardeausList([])
    handleOpenArticle_Fardeau();

  }

  const handleConsulterArticle_FardeausList = () => {
    if (selectArticle_FardeausList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameArticle_Fardeau(btnActionsName.consulter);

    handleOpenArticle_Fardeau();
    customConsoleLog(selectArticle_FardeausList[0]);
  }

  const handleDupliquerArticle_FardeausList = () => {
    if (selectArticle_FardeausList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameArticle_Fardeau(btnActionsName.dupliquer);
    handleOpenArticle_Fardeau();
    customConsoleLog(selectArticle_FardeausList[0]);
  }

  const handleModifierArticle_FardeausList = () => {
    if (selectArticle_FardeausList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameArticle_Fardeau(btnActionsName.modifier);
    handleOpenArticle_Fardeau();
    customConsoleLog(selectArticle_FardeausList[0]);
  }


  const handleSupprimerArticle_FardeausList = async () => {
    if (selectArticle_FardeausList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectArticle_FardeausList[0])
    if (await handleAwaitModalArticle_FardeausList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameArticle_Fardeau(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteArticle_FardeauLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenArticle_FardeausList = (itemID) => {
    setOpenArticle_FardeauList(true);


    if(itemID) {
      handleRowSelectionArticle_FardeausList([itemID]);
    }
  }


  const handleFermerArticle_FardeausList = () => {
    if (selectArticle_FardeausList[0]) {
      if (handleGetid_article_fardeau) {

        handleGetid_article_fardeau(selectArticle_FardeausList[0]);
      }
    }
    cleanGetArticle_FardeausListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetArticle_FardeausListQuery(queryClient);
      cleanGetArticle_FardeauEmptyQuery(queryClient);
      cleanGetArticle_FardeauQuery(selectArticle_FardeausList[0], queryClient);
      setOpenArticle_FardeauList(false);
    }
    else{

      setOpenArticle_FardeauList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_article_fardeauslist.filter(item => item.id == id)[0];
  }



  const addArticle_FardeauLine = useAddArticle_FardeauLine(queryClient, handleCloseArticle_Fardeau, handleRowSelectionArticle_FardeausList);


  const updateArticle_FardeauLine = useUpdateArticle_FardeauLine(queryClient, handleCloseArticle_Fardeau, handleRowSelectionArticle_FardeausList);

  const deleteArticle_FardeauLine = useDeleteArticle_FardeauLine(queryClient, handleCloseArticle_Fardeau);






  const DataTableArticle_FardeausListRef = React.useRef(null);

  const handlePrintArticle_FardeausList = () => {
    if (DataTableArticle_FardeausListRef.current) {
      DataTableArticle_FardeausListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenArticle_FardeausList(itemID);
    },
    handleClose() {
      handleFermerArticle_FardeausList();
    },
    handleRefresh() {
      handleCleanData();
    },
  }));

  // if (isLoading_article_fardeauslist || error_article_fardeauslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerArticle_FardeausList}
  //         refetch={refecth_article_fardeauslist}
  //         failureCount={failureCount_article_fardeauslist}
  //         error={error_article_fardeauslist} />

  //     </>
  //   )
  // }

  const mybtnStyle = 'text-mxs w-full';


  return (
    <>
      <BasicModal open={openArticle_FardeauList} handleClose={handleFermerArticle_FardeausList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_article_fardeauslist || error_article_fardeauslist
            ? <BasicLoading
              handleClose={handleFermerArticle_FardeausList}
              refetch={refecth_article_fardeauslist}
              failureCount={failureCount_article_fardeauslist}
              error={error_article_fardeauslist} />
            : <>
              {
                showAsyncConfirmDialogArticle_FardeausList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogArticle_FardeausList}
                  onSubmit={resolve_article_fardeau}
                  onCancel={reject_article_fardeau}
                  info={asyncConfirmDialogInfoArticle_Fardeau}
                />
              }


              {
                openArticle_Fardeau &&
                <BasicModal open={openArticle_Fardeau} isModal={true}>
                  <Article_Fardeau
                    actionName={actionNameArticle_Fardeau}
                    selectedItem={getSelecteditemByID(selectArticle_FardeausList[0])}
                    handleClose={handleCloseArticle_Fardeau}
                    addArticle_FardeauLine={addArticle_FardeauLine}
                    updateArticle_FardeauLine={updateArticle_FardeauLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Article Fardeaux</h2>}

              <RedDataTableBasic
                ref={DataTableArticle_FardeausListRef}
                rows={data_article_fardeauslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionArticle_FardeausList} 
                select={selectArticle_FardeausList}
                onRowDoubleClick={handleConsulterArticle_FardeausList}
              />

              {/* Buttons */}
              <div className='grid grid-cols-11 gap-2 m-1'>

                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={handleFiltrerArticle_FardeausList}>Filter</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>MAJ Inventaire</BasicButtonSmall>
                </div>
                <div className='grid grid-cols-2 gap-1'>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Code A Bar</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Corr. Stock</BasicButtonSmall>
                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={handleConsulterArticle_FardeausList}>Consulter</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>BLOCKER</BasicButtonSmall>
                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={handleNouveauArticle_FardeausList}>Ajouter</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Prix</BasicButtonSmall>
                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={handleModifierArticle_FardeausList}>Modifier</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={handleDupliquerArticle_FardeausList}>Dupliquer</BasicButtonSmall>
                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={handleSupprimerArticle_FardeausList}>Supprimer</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Prix 4</BasicButtonSmall>
                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Detailler</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Repris</BasicButtonSmall>
                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Les Ventes</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Les Achats</BasicButtonSmall>
                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Liste</BasicButtonSmall>
                  <BasicButtonSmall className={mybtnStyle} onClick={() => { }}>Inventaire</BasicButtonSmall>
                </div>
                <div>

                </div>
                <div className='grid grid-rows-2 gap-2'>
                  <BasicButtonSmall className={mybtnStyle} onClick={handleFermerArticle_FardeausList}>Fermer</BasicButtonSmall>
                  <div></div>
                </div>

              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default Article_FardeausList



const Article_FardeausListwidgetKey = 'Article_Fardeauswidget'
export const Article_FardeausListwidget = { i: Article_FardeausListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: (props) => <Article_FardeausList {...props}/>, titre: 'Article Fardeaux', id_fenetre: 531 }
