import * as React from 'react'

import BasicInput from '../../components/BasicInput'
import { btnActionsName, ping_api, entretien_info_api, entretien_update_api, defaultSelectChargement } from '../../global/GlobalVars';
import BasicButton from '../../components/BasicButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearToasts, getOnlyUpdatedValues, showToast } from '../../global/Tools';
import BasicLoading from '../../components/BasicLoading';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import useAwaitableComponent from 'use-awaitable-component';
import { callAxiosApi } from '../../interceptors/axios';
import BasicLabel from '../../components/BasicLabel';
import BasicRadio from '../../components/BasicRadio';
import BasicContainer from '../../components/BasicContainer';
import BasicButtonSmall from '../../components/BasicButtonSmall';
import BasicSelect from '../../components/BasicSelect';
import VehiculesList, { GetVehiculesListQuery } from '../vehicule/VehiculesList';
import Type_DepensesList, { GetType_DepensesListQuery } from '../type_depense/Type_DepensesList';
import BasicNumberInput from '../../components/BasicNumberInput';
import BasicDateInput from '../../components/BasicDateInput';
import { customConsoleLog } from '../../global/Tools';





const GetEntretienEmptyQuery = () => {
  return useQuery({
    queryKey: ['GetEntretienEmptyQuery'],
    queryFn: async () => await callAxiosApi(`${ping_api}`), // Return an empty object
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const GetEntretienQuery = (itemID) => {
  return useQuery({
    queryKey: ['GetEntretienQuery', itemID],
    queryFn: async () => await callAxiosApi(`${entretien_info_api}${itemID}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
  })
}

const useAddEntretienQuery = (handleClose, addEntretienLine) => {

  return useMutation({
    mutationFn: async (vals) => {
      if (vals?.id) {
        delete vals.id;
      }

      return await callAxiosApi(`${entretien_update_api}${0}`, JSON.stringify(vals), true, 'post');
    },
    mutationKey: ['AddEntretienQuery', 0],
    onSuccess: (newprod) => {
      const msg = `Entretien ajouter avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('newprod.id', newprod.id)
        addEntretienLine.mutate(newprod.id)
      }
      catch (e) {
        customConsoleLog('error', e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })

}

const useUpdateEntretienQuery = (itemID, updateEntretienLine, handleClose, itemName) => {
  return useMutation({
    mutationFn: async (vals) => {
      return await callAxiosApi(`${entretien_update_api}${vals.id}`, JSON.stringify(vals), true, 'post');
    }
    ,
    mutationKey: ['UpdateEntretienQuery', itemID],
    onSuccess: () => {
      const msg = `Entretien mise a jours avec succes`
      showToast(msg, 5000, 'succes');
      try {
        customConsoleLog('UpdateEntretienQuery called');

        updateEntretienLine.mutate(itemID)
      }
      catch (e) {
        customConsoleLog(e)
      }
      handleClose();
    },
    onError: (error, variables, context) => {


    },

  })
}

export const cleanGetEntretienEmptyQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['GetEntretienEmptyQuery'], exact: true });
}

export const cleanGetEntretienQuery = async (itemID, queryClient) => {
  customConsoleLog('cleanEntretienGetQuery', itemID);
  queryClient.removeQueries({ queryKey: ['GetEntretienQuery', itemID], exact: true });
}




function Entretien({ actionName, selectedItem, handleClose, addEntretienLine, updateEntretienLine }) {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = React.useState({});
  const [initialData, setInitialData] = React.useState({});
  const itemID = actionName != btnActionsName.nouveau ? selectedItem?.id : 0;
  const isNew = itemID ? false : true;
  const isReadOnly = actionName == btnActionsName.consulter ? true : false;
  customConsoleLog('Entretien compo rendered');



  const {
    data,
    error,
    isError,
    isLoading,
    failureCount,
    refetch } = isNew ? GetEntretienEmptyQuery() : GetEntretienQuery(itemID);

  const canFetchOthers = !isLoading && !isError;
  customConsoleLog('canFetchOthers', canFetchOthers)

  const addEntretien = useAddEntretienQuery(handleClose, addEntretienLine)
  const updateEntretien = useUpdateEntretienQuery(itemID, updateEntretienLine, handleClose, data?.libelle)


  const [status_confirm, execute_confirm, resolve_confirm, reject_confirm, reset_confirm] = useAwaitableComponent();
  const [asyncConfirmDialogInfo, setConfirmDialogInfo] = React.useState({ titre: '', msg: '' });

  const showAsyncConfirmDialog = status_confirm === "awaiting";

  const handleAwaitModal = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfo({ titre: mtitre, msg: mmsg });
      await execute_confirm();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_confirm();
    }
  };



  React.useEffect(() => {
    if (data) {
      setInitialData(data);
      setFormValues(data);
      customConsoleLog('useEffect called');
    }
  }, [data]);


  const {
    data: data_vehicule = defaultSelectChargement,
    isLoading: isLoading_vehicule } = GetVehiculesListQuery(canFetchOthers);

  const VehiculeListRef = React.useRef();

  const handleVehiculeClick = () => {

    VehiculeListRef.current?.handleOpen(formValues.idcompte);
  };

  const handleGetid_vehicule = (value) => {
    if (value == formValues.idcompte) return;
    customConsoleLog('handleGetid_vehicule', value);
    setFormValues({
      ...formValues,
      'idcompte': value
    });
  }

  const {
    data: data_type_depense = defaultSelectChargement,
    isLoading: isLoading_type_depense } = GetType_DepensesListQuery(canFetchOthers);

  const Type_DepenseListRef = React.useRef();

  const handleType_DepenseClick = () => {
    const typedepid = data_type_depense?.find(x => x.code == formValues.typedep)?.id;

    Type_DepenseListRef.current?.handleOpen(typedepid);
  };

  const handleGetid_type_depense = (value) => {
    const typedepcode = data_type_depense?.find(x => x.id == value)?.code;
    if (typedepcode == formValues.typedep) return;

    customConsoleLog('handleGetid_type_depense', typedepcode);

    setFormValues(prevState => ({
      ...prevState,
      'typedep': typedepcode,
    }));
  }

  React.useEffect(() => {
    const typedepcode = formValues.typedep;
    const typedepLibelle = data_type_depense?.find(x => x.code == typedepcode)?.type;
    customConsoleLog('typedepcode', typedepcode);
    customConsoleLog('typedepLibelle', typedepLibelle);

    setFormValues(prevState => ({
      ...prevState,
      'typedepLibelle': typedepLibelle,
    }));

  }, [formValues.typedep, data_type_depense]);



  const getButtonName = () => {
    switch (actionName) {
      case btnActionsName.consulter:
        return "";

      default:
        return "ok";
    }


  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    customConsoleLog('handleChange ', value)


    setFormValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customConsoleLog('SubmitEvent')
  };

  const handleOkEntretien = async (e) => {



    clearToasts();

    if (actionName == btnActionsName.nouveau) {
      if (await handleAwaitModal('Ajouter!!', 'Etes vous sur?!')) {
        customConsoleLog('nouveau');
        addEntretien.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else if (actionName == btnActionsName.dupliquer) {


      if (await handleAwaitModal('Dupliquer!!', 'Etes vous sur?!')) {
        customConsoleLog('dupliquer');
        addEntretien.mutate(formValues)

      }
      else {
        customConsoleLog('nouveau annuler');
      }
    }
    else {
      if (await handleAwaitModal('Enregistrer!!', 'Etes vous sur?!')) {
        customConsoleLog('modifier');
        updateEntretien.mutate(getOnlyUpdatedValues(initialData, formValues))

      }
      else {
        customConsoleLog('modifier annuler');
      }
    }




  }

  const handleCloseEntretien = async (e) => {
    clearToasts();
    if (actionName == btnActionsName.consulter) {
      handleClose();
    }
    else {

      if (await handleAwaitModal('Fermer!!', 'Etes vous sur?!')) {
        handleClose();
      }
      else {
        customConsoleLog('annuler');
      }
    }
  }




  if (isLoading || error) {
    return (
      <>
        <div className='flex flex-col p-2'>

          <BasicLoading
            handleClose={handleClose}
            refetch={refetch}
            failureCount={failureCount}
            error={error} />
        </div>
      </>
    )
  }



  return (
    <>
      {
        showAsyncConfirmDialog &&
        <BasicAsyncConfirmDialog
          visible={showAsyncConfirmDialog}
          onSubmit={resolve_confirm}
          onCancel={reject_confirm}
          info={asyncConfirmDialogInfo}
        />
      }

      <VehiculesList ref={VehiculeListRef} handleGetid_vehicule={handleGetid_vehicule} isModal={true} />
      <Type_DepensesList ref={Type_DepenseListRef} handleGetid_type_depense={handleGetid_type_depense} isModal={true} />
      <div className="m-2 flex flex-row">
        <section className='w-[700px]'>
          <h2 className="text-lg font-bold mb-4">Entretien</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-3 min-h-40 mt-8'>

              <div className='inline-flex items-center'>
                <div className='text-blue-600 italic w-[200px] text-right mr-2'>Type :</div>
                <BasicContainer className="p-2">
                  <div className='grid grid-cols-2'>
                    <BasicRadio type="radio" id="compteur_heures" name="pr" value="K" titre="Compteur Heures" selectedValue={formValues.pr} onChange={handleChange} className="text-xs" />
                    <BasicRadio type="radio" id="date_nbre_mois" name="pr" value="M" titre="Date Nbre Mois" selectedValue={formValues.pr} onChange={handleChange} className="text-xs" />
                  </div>
                </BasicContainer>
              </div>

              <BasicLabel className='text-xs w-[300px]' titre="Vehicules :">
                <BasicSelect id="idcompte" name="idcompte" className="text-xs" listItems={data_vehicule || []} value={formValues.idcompte || ''} 
                textKey={'code'} 
                fieldsConfig={[{key:'code', title: 'Immatriculation'},{key:'marque', title:'Marque'}]} 
                onChange={handleChange} readOnly={isReadOnly}>
                  <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_vehicule} onClick={handleVehiculeClick}>...</BasicButtonSmall>
                </BasicSelect>
              </BasicLabel>

                <BasicLabel className='text-xs w-[300px]' titre="Type :">
                  <BasicSelect id="typedep" name="typedep" className="text-xs" listItems={data_type_depense || []} 
                  value={formValues.typedep || ''} valueKey={'code'} textKey={'code'} 
                  fieldsConfig={[{key:'code', title: 'Code'},{key:'type', title:'Type'}]} 
                  onChange={handleChange} readOnly={isReadOnly}>
                    <BasicButtonSmall type="button" disabled={isReadOnly} loading={isLoading_type_depense} onClick={handleType_DepenseClick}>...</BasicButtonSmall>
                  </BasicSelect>
                </BasicLabel>
                <div className='text-blue-600 italic ml-[300px]'>{formValues.typedepLibelle}</div>

              <BasicLabel className='text-xs w-[50px]'>
                <BasicInput id="libelle" name="libelle" className='text-xs' value={formValues.libelle || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <BasicLabel className='text-xs w-[300px]' titre="A FAIR TOUS LES MOIS/KM/HR :">
                <BasicNumberInput id="mtdep" name="mtdep" className='text-xs bg-yellow-300' value={formValues.mtdep || ''} onChange={handleChange} readOnly={isReadOnly} />
              </BasicLabel>

              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[300px]' titre="Derniere Fait :">
                  <BasicDateInput id="dtefai" name="dtefai" className='text-xs' isbig={true} value={formValues.dtefai || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>
                <BasicLabel className='text-xs w-[300px]' titre="Dernier KM/NBR :">
                  <BasicNumberInput id="afftou" name="afftou" className='text-xs bg-yellow-300' value={formValues.afftou || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>
              </div>

              <div className='grid grid-cols-2'>
                <BasicLabel className='text-xs w-[300px]' titre="Derniere Prochain :">
                  <BasicDateInput id="dtepro" name="dtepro" className='text-xs' isbig={true} value={formValues.dtepro || ''} onChange={handleChange} readOnly={isReadOnly} />
                </BasicLabel>
                <div></div>
              </div>
            </div>

            <div className="grid grid-cols-7 gap-2 mt-2">
              <div className='col-span-5'></div>
              {actionName != btnActionsName.consulter
                ? <BasicButton type="button" onClick={handleOkEntretien}
                  loading={addEntretien.isPending || updateEntretien.isPending}>{getButtonName(actionName)}</BasicButton>
                : <div></div>
              }
              <BasicButton type="button" onClick={handleCloseEntretien}>Fermer</BasicButton>
            </div>

          </form>

        </section>
      </div>
    </>
  )
}

export default Entretien