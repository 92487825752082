
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, etablissement_delete_api, etablissement_info_api, etablissement_list_api } from '../../global/GlobalVars';
import Etablissement from './Etablissement';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetEtablissementEmptyQuery, cleanGetEtablissementQuery } from './Etablissement';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code établissement', width: 200 },
  { field: 'libelle', headerName: 'Nom établissement', width: 200 },
  { field: 'region', headerName: 'Région', width: 200 },
  { field: 'ex', headerName: 'Plafond', width: 200 },
  { field: 'date_arr', headerName: 'DATE_ARR', width: 200 },
];
const hideColumns = { id: false }


export const GetEtablissementsListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['EtablissementList'],
    queryFn: async () =>{ 
      customConsoleLog('GetEtablissementsListQuery called')
      return await callAxiosApi(`${etablissement_list_api}`, '', true)
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 1000 * 60 * 5,
    enabled: canFetch,
  })
}

const useAddEtablissementLine = (queryClient, handleCloseEtablissement, handleRowSelectionEtablissementsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${etablissement_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['EtablissementList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addEtablissementLine onSuccess');
      customConsoleLog(newprod);
      handleCloseEtablissement();
      handleRowSelectionEtablissementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateEtablissementLine = (queryClient, handleCloseEtablissement, handleRowSelectionEtablissementsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${etablissement_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateEtablissementLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateEtablissementLine called')
      queryClient.setQueryData(['EtablissementList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetEtablissementQuery(newprod.id, queryClient);
      handleCloseEtablissement();
      handleRowSelectionEtablissementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteEtablissementLine = (queryClient, handleCloseEtablissement) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${etablissement_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['EtablissementList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetEtablissementQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseEtablissement();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetEtablissementsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['EtablissementList'] });
}


const EtablissementsList = forwardRef((props, ref) => {
  //function EtablissementsList({ removeWidget, isModal }) {
  const { handleGetid_etablissement, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openEtablissementList, setOpenEtablissementList] = useState(false);
  const [openEtablissement, setOpenEtablissement] = useState(false);

  const [actionNameEtablissement, setActionNameEtablissement] = useState("")
  const [selectEtablissementsList, setSelectionEtablissementsList] = useState([]);

  const handleRowSelectionEtablissementsList = (e) => {
    if (e != selectEtablissementsList[0]) {
      setSelectionEtablissementsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenEtablissement = () => {
    setOpenEtablissement(true);

    //BasicModalRef.current.open();
  }
  const handleCloseEtablissement = () => {
    setOpenEtablissement(false);
    //BasicModalRef.current.close();
    cleanGetEtablissementEmptyQuery(queryClient);
    cleanGetEtablissementQuery(selectEtablissementsList[0], queryClient);
  }



  const [status_etablissement, execute_etablissement, resolve_etablissement, reject_etablissement, reset_etablissement] = useAwaitableComponent();
  const [asyncConfirmDialogInfoEtablissement, setConfirmDialogInfoEtablissement] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogEtablissementsList = status_etablissement === "awaiting";

  const handleAwaitModalEtablissementsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoEtablissement({ titre: mtitre, msg: mmsg });
      await execute_etablissement();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_etablissement();
    }
  };


  customConsoleLog('EtablissementsList rendered')



  const { data: data_etablissementslist = [], error: error_etablissementslist,
    refetch: refecth_etablissementslist, isLoading: isLoading_etablissementslist, failureCount: failureCount_etablissementslist } =
    GetEtablissementsListQuery();






  const handleFiltrerEtablissementsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauEtablissementsList = () => {

    clearToasts();
    setActionNameEtablissement(btnActionsName.nouveau);
    setSelectionEtablissementsList([])
    handleOpenEtablissement();

  }

  const handleConsulterEtablissementsList = () => {
    if (selectEtablissementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameEtablissement(btnActionsName.consulter);

    handleOpenEtablissement();
    customConsoleLog(selectEtablissementsList[0]);
  }

  const handleDupliquerEtablissementsList = () => {
    if (selectEtablissementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameEtablissement(btnActionsName.dupliquer);
    handleOpenEtablissement();
    customConsoleLog(selectEtablissementsList[0]);
  }

  const handleModifierEtablissementsList = () => {
    if (selectEtablissementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameEtablissement(btnActionsName.modifier);
    handleOpenEtablissement();
    customConsoleLog(selectEtablissementsList[0]);
  }


  const handleSupprimerEtablissementsList = async () => {
    if (selectEtablissementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectEtablissementsList[0])
    if (await handleAwaitModalEtablissementsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameEtablissement(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteEtablissementLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenEtablissementsList = (itemID) => {
    setOpenEtablissementList(true);


    if(itemID) {
      handleRowSelectionEtablissementsList([itemID]);
    }
  }


  const handleFermerEtablissementsList = () => {
    if (selectEtablissementsList[0]) {
      if (handleGetid_etablissement) {

        handleGetid_etablissement(selectEtablissementsList[0]);
      }
    }
    cleanGetEtablissementsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetEtablissementsListQuery(queryClient);
      cleanGetEtablissementEmptyQuery(queryClient);
      cleanGetEtablissementQuery(selectEtablissementsList[0], queryClient);
      setOpenEtablissementList(false);
    }
    else{

      setOpenEtablissementList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_etablissementslist.filter(item => item.id == id)[0];
  }



  const addEtablissementLine = useAddEtablissementLine(queryClient, handleCloseEtablissement, handleRowSelectionEtablissementsList);


  const updateEtablissementLine = useUpdateEtablissementLine(queryClient, handleCloseEtablissement, handleRowSelectionEtablissementsList);

  const deleteEtablissementLine = useDeleteEtablissementLine(queryClient, handleCloseEtablissement);






  const DataTableEtablissementsListRef = React.useRef(null);

  const handlePrintEtablissementsList = () => {
    if (DataTableEtablissementsListRef.current) {
      DataTableEtablissementsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenEtablissementsList(itemID);
    },
    handleClose() {
      handleFermerEtablissementsList();
    }
  }));

  // if (isLoading_etablissementslist || error_etablissementslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerEtablissementsList}
  //         refetch={refecth_etablissementslist}
  //         failureCount={failureCount_etablissementslist}
  //         error={error_etablissementslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openEtablissementList} handleClose={handleFermerEtablissementsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_etablissementslist || error_etablissementslist
            ? <BasicLoading
              handleClose={handleFermerEtablissementsList}
              refetch={refecth_etablissementslist}
              failureCount={failureCount_etablissementslist}
              error={error_etablissementslist} />
            : <>
              {
                showAsyncConfirmDialogEtablissementsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogEtablissementsList}
                  onSubmit={resolve_etablissement}
                  onCancel={reject_etablissement}
                  info={asyncConfirmDialogInfoEtablissement}
                />
              }


              {
                openEtablissement &&
                <BasicModal open={openEtablissement} isModal={true}>
                  <Etablissement
                    actionName={actionNameEtablissement}
                    selectedItem={getSelecteditemByID(selectEtablissementsList[0])}
                    handleClose={handleCloseEtablissement}
                    addEtablissementLine={addEtablissementLine}
                    updateEtablissementLine={updateEtablissementLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Etablissements</h2>}

              <RedDataTableBasic
                ref={DataTableEtablissementsListRef}
                rows={data_etablissementslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionEtablissementsList} 
                select={selectEtablissementsList}
                onRowDoubleClick={handleConsulterEtablissementsList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerEtablissementsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauEtablissementsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterEtablissementsList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerEtablissementsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierEtablissementsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintEtablissementsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerEtablissementsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerEtablissementsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default EtablissementsList



const EtablissementsListwidgetKey = 'Etablissementswidget'
export const EtablissementsListwidget = { i: EtablissementsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: EtablissementsList, titre: 'Etablissements', id_fenetre: 505 }
