import React from 'react'
import { checkString, cn  } from '../global/Tools';
import { customConsoleLog } from '../global/Tools';

const divClassName = 'p-1 border-[1px] border-gray-400 bg-slate-100'

function BasicContainer(props) {
    const { children, className} = props;
    const customClassdiv = cn(`${divClassName} ${checkString(className)}`);
  return (
    <div className={customClassdiv}>
        {children}
    </div>
  )
}

export default BasicContainer