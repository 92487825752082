import React, { useState } from 'react'
import { ArticlesListwidget } from '../pages/article/ArticlesList';
import { DepartementsListwidget } from '../pages/departement/DepartementsList';
import { FamillesListwidget } from '../pages/famille/FamillesList';
import { SousFamillesListwidget } from '../pages/sousfamille/SousFamillesList';
import { TvasListwidget } from '../pages/tva/TvasList';
import { Article_FardeausListwidget } from '../pages/article_fardeau/Article_FardeausList';
import { ClientsListwidget } from '../pages/client/ClientsList';
import { FournisseursListwidget } from '../pages/fournisseur/FournisseursList';
import { RepresentantsListwidget } from '../pages/representant/RepresentantsList';
// import { Stock_DepotsListwidget } from '../pages/stock_depot/Stock_DepotsList';
// import { Fardeau_DetailesListwidget } from '../pages/fardeau_detaile/Fardeau_DetailesList';
// import { DepensesListwidget } from '../pages/depense/DepensesList';
// import { Chiffre_Affaire_JourneesListwidget } from '../pages/chiffre_affaire_journee/Chiffre_Affaire_JourneesList';
// import { DepotsListwidget } from '../pages/depot/DepotsList';
import { PayssListwidget } from '../pages/pays/PayssList';
import { RegionsListwidget } from '../pages/region/RegionsList';
import { CategoriesListwidget } from '../pages/categorie/CategoriesList';
import { EtablissementsListwidget } from '../pages/etablissement/EtablissementsList';
import { Mode_ReglementsListwidget } from '../pages/mode_reglement/Mode_ReglementsList';
import { Type_DepensesListwidget } from '../pages/type_depense/Type_DepensesList';
import { CuvesListwidget } from '../pages/cuve/CuvesList';
import { MarquesListwidget } from '../pages/marque/MarquesList';
import { UsagesListwidget } from '../pages/usage/UsagesList';
import { ChauffeursListwidget } from '../pages/chauffeur/ChauffeursList';
import { VehiculesListwidget } from '../pages/vehicule/VehiculesList';
import { EntretiensListwidget } from '../pages/entretien/EntretiensList';
import { ReservationsListwidget } from '../pages/reservation/ReservationsList';
import { UtilisateursListwidget } from '../pages/utilisateur/UtilisateursList';
import { Acces_DepotsListwidget } from '../pages/acces_depot/Acces_DepotsList';
import { Acces_CuvesListwidget } from '../pages/acces_cuve/Acces_CuvesList';
import { Acces_VehiculesListwidget } from '../pages/acces_vehicule/Acces_VehiculesList';
import { Parametrewidget } from '../pages/parametre/Parametre';
import ChoisirDepot from '../pages/choisir_depot/choisir_depot';
import { fenetreType, livraisonsType, typeMvt } from '../global/GlobalVars';
import { MvtsListwidget } from '../pages/mvt/MvtsList';
import { ReglementsListwidget } from '../pages/reglement/ReglementsList';
//import { ParametresListwidget } from '../pages/parametre/ParametresList';
import { customConsoleLog } from '../global/Tools';
import { ParametreAutoIncrementwidget } from '../pages/parametre/ParametreAutoIncrement';

function Accordion(props) {
    return (
        <div className="">
            <button
                className="w-full p-2 text-left bg-gray-300  text-xs
                           hover:bg-gray-400 transition duration-300 border border-b-1 border-b-gray-400"
                onClick={props.toggleAccordion}
            >
                {props.titre}
                <span className={`float-right transform ${props.isOpen ?
                    'rotate-180' : 'rotate-0'}  
                                 transition-transform duration-300`}>
                    &#9660;
                </span>
            </button>
            {props.isOpen && (
                <div className="bg-white max-h-96 overflow-scroll">
                    {props.submenu.map((submenu) => {
                        return <button key={submenu.id} 
                        onClick={() => props.addLayout(submenu)} 
                        className={`${submenu.link === '#' ? 'text-gray-400' : ''} block text-left p-1 text-xs w-full border border-b-gray-300 hover:bg-gray-100`}>
                              {submenu.titre}
                            </button>
                    })}
                </div>
            )}
        </div>
    );
};

export default function BasicLeftMenu(props) {

    const [accordions, setAccordion] = useState([
        {
            id: 'tresorerie',
            titre: 'Tresorerie',
            submenu: [
                {
                    id: ReglementsListwidget.id_fenetre_frs,
                    titre: 'Reglement Frs',
                    link: <ChoisirDepot titre={'Reglement Frs'} compo={ReglementsListwidget} type_mvt={typeMvt.bonLivraisonFournisseur} open={true} isModal={true}  />,//ReglementsListwidget,
                    typefenetre: fenetreType.modal ,//fenetreType.widget,
                },
                {
                    id: ReglementsListwidget.id_fenetre,
                    titre: 'Reglement Clt',
                    link: <ChoisirDepot titre={'Reglement Clt'} compo={ReglementsListwidget} type_mvt={typeMvt.bonClient} open={true} isModal={true}  />,//ReglementsListwidget,
                    typefenetre: fenetreType.modal ,//fenetreType.widget,
                }
            ]

        }
        ,
        {
            id: 'gestion',
            titre: 'Gestion',
            submenu: [
                {
                    id: ArticlesListwidget.id_fenetre,
                    titre: 'Articles',
                    link: ArticlesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: Article_FardeausListwidget.id_fenetre,
                    titre: 'Articles Fardeau',
                    link: Article_FardeausListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: ClientsListwidget.id_fenetre,
                    titre: 'Clients',
                    link: ClientsListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: FournisseursListwidget.id_fenetre,
                    titre: 'Fournisseurs',
                    link: FournisseursListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: RepresentantsListwidget.id_fenetre,
                    titre: 'Representants',
                    link: RepresentantsListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: MvtsListwidget.id_fenetre_reception,
                    titre: 'Receptions',
                    link: <ChoisirDepot titre={'Receptions'} compo={MvtsListwidget} type_mvt={typeMvt.bonLivraisonFournisseur} open={true} isModal={true}  />, //'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: MvtsListwidget.id_fenetre_livraison,
                    titre: 'Livraisons',
                    link: <ChoisirDepot titre={'Bon de Livraisons'} compo={MvtsListwidget} type_mvt={typeMvt.bonLivraisonClient} open={true} isModal={true} livraisonType={livraisonsType.livraisons}  />,//'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: MvtsListwidget.id_fenetre_livraisonC,
                    titre: 'LivraisonsC',
                    link: <ChoisirDepot titre={'Bon de LivraisonsC'} compo={MvtsListwidget} type_mvt={typeMvt.bonLivraisonClient} open={true} autoOpen={true} isModal={true} livraisonType={livraisonsType.livraisonsC}     />,
                    typefenetre: fenetreType.modal,
                },
                {
                    id: 'livraisonsCr',
                    titre: 'LivraisonsCr',
                    link: <ChoisirDepot titre={'Bon de LivraisonsCr'} compo={MvtsListwidget} type_mvt={typeMvt.bonLivraisonClient} open={true} autoOpen={true} isModal={true} livraisonType={livraisonsType.livraisonsCr}     />,
                    typefenetre: fenetreType.modal,
                },
                {
                    id: MvtsListwidget.id_fenetre_bonClient,
                    titre: 'Bon',
                    link:  <ChoisirDepot titre={'Bon client'} compo={MvtsListwidget} type_mvt={typeMvt.bonClient} open={true} isModal={true}  />,//'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: MvtsListwidget.id_fenetre_stockSF,
                    titre: 'Stocks',
                    link: <ChoisirDepot titre={'Stocks'} compo={MvtsListwidget} type_mvt={typeMvt.stockSF} open={true} isModal={true}  />,//'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: 'sorties_M_P_',
                    titre: 'Sorties M.P.',
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: MvtsListwidget.id_fenetre_encoursPF,
                    titre: 'Encours P.F.',
                    link: <ChoisirDepot titre={'Encours P.F.'} compo={MvtsListwidget} type_mvt={typeMvt.encoursPF} open={true} isModal={true}  />, //'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: MvtsListwidget.id_fenetre_entreesPF,
                    titre: 'Entrées P.F.',
                    link: <ChoisirDepot titre={'Entrées P.F.'} compo={MvtsListwidget} type_mvt={typeMvt.entreesPF} open={true} isModal={true}  />,//'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: MvtsListwidget.id_fenetre_transfertDepot,
                    titre: 'Transfert entre Depots',
                    link: <ChoisirDepot titre={'Transfert entre Depots'} compo={MvtsListwidget} type_mvt={typeMvt.transfertEntreDepot} type_mvt_global={typeMvt.transfertEntreDepot} open={true} isModal={true}  />,//'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: MvtsListwidget.id_fenetre_factureClt,
                    titre: 'Facturation',
                    link: <ChoisirDepot titre={'Factures clients'} compo={MvtsListwidget} type_mvt={typeMvt.factureClient} type_mvt_global={typeMvt.factureClient} open={true} isModal={true}  />,//'#',
                    typefenetre: fenetreType.modal,
                },
                {
                    id: 'stocks_depots',
                    titre: 'Stocks Dépots',
                    link: '#', //Stock_DepotsListwidget,
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'fardeau_detailes',
                    titre: 'Fardeau Détailes',
                    link: '#', //Fardeau_DetailesListwidget,
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'depenses',
                    titre: 'Depenses',
                    link: '#', //DepensesListwidget,
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'inventaires_depots',
                    titre: 'Inventaires Dépots', // etat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'inventaires_ste',
                    titre: 'Inventaires Sté', // etat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'situation_du_jour',
                    titre: 'Situation du Jour',
                    link: '#', //Chiffre_Affaire_JourneesListwidget, // requette ??
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'inventaires_depots_fam',
                    titre: 'Inventaires Dépots Fam', // etat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'inventaires_ste_fam',
                    titre: 'Inventaires Sté Fam', //etat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
            ],
            isOpen: true
        },
        {
            id: 'transport',
            titre: 'Transport',
            submenu: [
                {
                    id: CuvesListwidget.id_fenetre,
                    titre: 'Cuves',
                    link: CuvesListwidget, //'#',
                    typefenetre: fenetreType.widget,
                },
                {
                    id: MarquesListwidget.id_fenetre,
                    titre: 'Marques', // 02_Marques
                    link: MarquesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: UsagesListwidget.id_fenetre,
                    titre: 'Usages', // 01_Usages
                    link: UsagesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: ChauffeursListwidget.id_fenetre,
                    titre: 'Chauffeurs', // 02_Genres
                    link: ChauffeursListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: VehiculesListwidget.id_fenetre,
                    titre: 'Vehicules', // 01_Vehicules
                    link: VehiculesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: EntretiensListwidget.id_fenetre,
                    titre: 'Entretiens', // MBentretients
                    link: EntretiensListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: ReservationsListwidget.id_fenetre,
                    titre: 'Reservations', // MBreservations
                    link: ReservationsListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: 'achcuves',
                    titre: 'AchCuves', //mbAchat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'affcuves',
                    titre: 'AffCuves', //Mbaff
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'trscuves',
                    titre: 'TrsCuves', //mbtrans
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'pleins_frais',
                    titre: 'Pleins/Frais', //mbpleins
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'consommations',
                    titre: 'Consommations', // ???
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'rapports',
                    titre: 'Rapports', // etat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'situation_cuves',
                    titre: 'Situation Cuves', // etat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                {
                    id: 'situation_vehicules',
                    titre: 'situation Vehicules', // etat
                    link: '#',
                    typefenetre: fenetreType.link,
                },
            ],
            isOpen: false
        },
        {
            id: 'tables',
            titre: 'Tables',
            link: '#',
            submenu: [
                {

                    id: DepartementsListwidget.id_fenetre,
                    titre: 'Départements',
                    link: DepartementsListwidget,
                    typefenetre: fenetreType.widget,
                },
                {

                    id: FamillesListwidget.id_fenetre,
                    titre: 'Familles',
                    link: FamillesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {

                    id: SousFamillesListwidget.id_fenetre,
                    titre: 'sFamilles',
                    link: SousFamillesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {

                    id: EtablissementsListwidget.id_fenetre,
                    titre: 'Depots',
                    link: EtablissementsListwidget,
                    typefenetre: fenetreType.widget,
                },
                {

                    id: PayssListwidget.id_fenetre,
                    titre: 'Pays',
                    link: PayssListwidget,
                    typefenetre: fenetreType.widget,
                },
                {

                    id: RegionsListwidget.id_fenetre,
                    titre: 'Régions',
                    link: RegionsListwidget,
                    typefenetre: fenetreType.widget,
                },
                // {

                //     id: 'phrasier',
                //     titre: 'Phrasier',
                //     link: PhrasiersListwidget,
                  //  typefenetre: fenetreType.widget,
                // },
                // {

                //     id: 'sections',
                //     titre: 'Sections',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                {

                    id: TvasListwidget.id_fenetre,
                    titre: 'Type Tva',
                    link: TvasListwidget,
                    typefenetre: fenetreType.widget,
                },
                {

                    id: CategoriesListwidget.id_fenetre,
                    titre: 'Type Tiers',
                    link: CategoriesListwidget,
                    typefenetre: fenetreType.widget,
                },
                // {

                //     id: 'type_personnel',
                //     titre: 'Type Personnel',
                //     link: '#',
                //   typefenetre: fenetreType.link,
                // },
                // {

                //     id: 'type_prestations',
                //     titre: 'Type Prestations',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                {

                    id: Mode_ReglementsListwidget.id_fenetre,
                    titre: 'Type Réglements',
                    link: Mode_ReglementsListwidget,
                    typefenetre: fenetreType.widget,
                },
                {

                    id: Type_DepensesListwidget.id_fenetre,
                    titre: 'Type Depenses',
                    link: Type_DepensesListwidget,
                    typefenetre: fenetreType.widget,
                },
                // {

                //     id: 'natures_tva',
                //     titre: 'Natures Tva',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                // {

                //     id: 'table_anciennete',
                //     titre: 'Table Ancienneté',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                // {

                //     id: 'rubriques_paie',
                //     titre: 'Rubriques Paie',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                // {

                //     id: 'rubriques_fixes',
                //     titre: 'Rubriques Fixes',
                //     link: '#',
                //   typefenetre: fenetreType.link,
                // },
                // {

                //     id: 'fonctions',
                //     titre: 'Fonctions',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
            ],
            isOpen: false

        },
        {
            id: 'outils',
            titre: 'Outils',
            link: '#',
            submenu: [
                {
                    id: 'arrete_de_stocks',
                    titre: 'Arreté de Stocks', // formule
                    link: '#',
                    typefenetre: fenetreType.link,
                },
                // {
                //     id: 'cloture_mensuel_paie',
                //     titre: 'Cloture Mensuel Paie',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                // {
                //     id: 'cloture_annuelle_compta',
                //     titre: 'Cloture annuelle Compta',
                //     link: '#',
                //   typefenetre: fenetreType.link,
                // },
                // {
                //     id: 'importer_balance_ouverture',
                //     titre: 'Importer Balance Ouverture',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                // {
                //     id: 'exporter_la_paie_vers_compta',
                //     titre: 'Exporter la Paie Vers Compta',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
                // {
                //     id: 'exporter_factures',
                //     titre: 'Exporter Factures',
                //     link: '#',
                //    typefenetre: fenetreType.link,
                // },
            ],
            isOpen: false
        },
        {
            id: 'autres',
            titre: 'Autres',
            link: '#',
            submenu: [
                {
                    id: Parametrewidget.id_fenetre,
                    titre: 'Parametres', // Parametres
                    link: Parametrewidget, //'#',// ParametresListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: UtilisateursListwidget.id_fenetre,
                    titre: 'Utilisateurs Options',
                    link: UtilisateursListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: Acces_DepotsListwidget.id_fenetre,
                    titre: 'Acces aux Depots',
                    link: Acces_DepotsListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: Acces_CuvesListwidget.id_fenetre,
                    titre: 'Acces aux Cuves',
                    link: Acces_CuvesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: Acces_VehiculesListwidget.id_fenetre,
                    titre: 'Acces aux Vehicules',
                    link: Acces_VehiculesListwidget,
                    typefenetre: fenetreType.widget,
                },
                {
                    id: ParametreAutoIncrementwidget.id_fenetre,
                    titre: 'Parametres Auto', // Parametres Auto
                    link: ParametreAutoIncrementwidget, //'#',// ParametreAutoIncrementwidget,
                    typefenetre: fenetreType.widget,
                },
            ],
            isOpen: false
        },
    ]);

    const toggleAccordion = (accordionkey) => {
        const updatedAccordions = accordions.map((accord) => {
            if (accord.id === accordionkey) {
                return { ...accord, isOpen: !accord.isOpen };
            } else {
                return { ...accord, isOpen: false };
            }
        });

        setAccordion(updatedAccordions);
    };
    

    for(const accordion of accordions){
        for(const submenu of accordion.submenu){
            if(typeof submenu.id == 'number' && submenu.id > 1000){

                const formatedText = (`Menu : ${accordion.titre}, id : ${submenu.id}, Titre : ${submenu.titre}`)

                customConsoleLog(formatedText);
            }
        }
    }

    return (
        <>
            <div className="">
                {accordions.map((accordion) => (
                    <Accordion
                        key={accordion.id}
                        addLayout={props.addLayout}
                        id={accordion.id}
                        titre={accordion.titre}
                        submenu={accordion.submenu}
                        isOpen={accordion.isOpen}
                        toggleAccordion={() => toggleAccordion(accordion.id)}
                    />
                ))}
            </div>
        </>
    )
}



