
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, usage_delete_api, usage_info_api, usage_list_api } from '../../global/GlobalVars';
import Usage from './Usage';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDateTime, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetUsageEmptyQuery, cleanGetUsageQuery } from './Usage';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 200 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
  { field: 'saisie_le', headerName: 'Saisie le', width: 200, type: 'date', valueFormatter: (params) => formatDateTime(params.value, false, true) },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetUsagesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['UsageList'],
    queryFn: async () => await callAxiosApi(`${usage_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddUsageLine = (queryClient, handleCloseUsage, handleRowSelectionUsagesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${usage_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['UsageList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addUsageLine onSuccess');
      customConsoleLog(newprod);
      handleCloseUsage();
      handleRowSelectionUsagesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateUsageLine = (queryClient, handleCloseUsage, handleRowSelectionUsagesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${usage_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateUsageLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateUsageLine called')
      queryClient.setQueryData(['UsageList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetUsageQuery(newprod.id, queryClient);
      handleCloseUsage();
      handleRowSelectionUsagesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteUsageLine = (queryClient, handleCloseUsage) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${usage_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['UsageList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetUsageQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseUsage();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetUsagesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['UsageList'] });
}


const UsagesList = forwardRef((props, ref) => {
  //function UsagesList({ removeWidget, isModal }) {
  const { handleGetid_usage, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openUsageList, setOpenUsageList] = useState(false);
  const [openUsage, setOpenUsage] = useState(false);

  const [actionNameUsage, setActionNameUsage] = useState("")
  const [selectUsagesList, setSelectionUsagesList] = useState([]);

  const handleRowSelectionUsagesList = (e) => {
    if (e != selectUsagesList[0]) {
      setSelectionUsagesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenUsage = () => {
    setOpenUsage(true);

    //BasicModalRef.current.open();
  }
  const handleCloseUsage = () => {
    setOpenUsage(false);
    //BasicModalRef.current.close();
    cleanGetUsageEmptyQuery(queryClient);
    cleanGetUsageQuery(selectUsagesList[0], queryClient);
  }



  const [status_usage, execute_usage, resolve_usage, reject_usage, reset_usage] = useAwaitableComponent();
  const [asyncConfirmDialogInfoUsage, setConfirmDialogInfoUsage] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogUsagesList = status_usage === "awaiting";

  const handleAwaitModalUsagesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoUsage({ titre: mtitre, msg: mmsg });
      await execute_usage();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_usage();
    }
  };


  customConsoleLog('UsagesList rendered')



  const { data: data_usageslist = [], error: error_usageslist,
    refetch: refecth_usageslist, isLoading: isLoading_usageslist, failureCount: failureCount_usageslist } =
    GetUsagesListQuery();






  const handleFiltrerUsagesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauUsagesList = () => {

    clearToasts();
    setActionNameUsage(btnActionsName.nouveau);
    setSelectionUsagesList([])
    handleOpenUsage();

  }

  const handleConsulterUsagesList = () => {
    if (selectUsagesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameUsage(btnActionsName.consulter);

    handleOpenUsage();
    customConsoleLog(selectUsagesList[0]);
  }

  const handleDupliquerUsagesList = () => {
    if (selectUsagesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameUsage(btnActionsName.dupliquer);
    handleOpenUsage();
    customConsoleLog(selectUsagesList[0]);
  }

  const handleModifierUsagesList = () => {
    if (selectUsagesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameUsage(btnActionsName.modifier);
    handleOpenUsage();
    customConsoleLog(selectUsagesList[0]);
  }


  const handleSupprimerUsagesList = async () => {
    if (selectUsagesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectUsagesList[0])
    if (await handleAwaitModalUsagesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameUsage(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteUsageLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenUsagesList = (itemID) => {
    setOpenUsageList(true);


    if(itemID) {
      handleRowSelectionUsagesList([itemID]);
    }
  }


  const handleFermerUsagesList = () => {
    if (selectUsagesList[0]) {
      if (handleGetid_usage) {

        handleGetid_usage(selectUsagesList[0]);
      }
    }
    cleanGetUsagesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetUsagesListQuery(queryClient);
      cleanGetUsageEmptyQuery(queryClient);
      cleanGetUsageQuery(selectUsagesList[0], queryClient);
      setOpenUsageList(false);
    }
    else{

      setOpenUsageList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_usageslist.filter(item => item.id == id)[0];
  }



  const addUsageLine = useAddUsageLine(queryClient, handleCloseUsage, handleRowSelectionUsagesList);


  const updateUsageLine = useUpdateUsageLine(queryClient, handleCloseUsage, handleRowSelectionUsagesList);

  const deleteUsageLine = useDeleteUsageLine(queryClient, handleCloseUsage);






  const DataTableUsagesListRef = React.useRef(null);

  const handlePrintUsagesList = () => {
    if (DataTableUsagesListRef.current) {
      DataTableUsagesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenUsagesList(itemID);
    },
    handleClose() {
      handleFermerUsagesList();
    }
  }));

  // if (isLoading_usageslist || error_usageslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerUsagesList}
  //         refetch={refecth_usageslist}
  //         failureCount={failureCount_usageslist}
  //         error={error_usageslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openUsageList} handleClose={handleFermerUsagesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_usageslist || error_usageslist
            ? <BasicLoading
              handleClose={handleFermerUsagesList}
              refetch={refecth_usageslist}
              failureCount={failureCount_usageslist}
              error={error_usageslist} />
            : <>
              {
                showAsyncConfirmDialogUsagesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogUsagesList}
                  onSubmit={resolve_usage}
                  onCancel={reject_usage}
                  info={asyncConfirmDialogInfoUsage}
                />
              }


              {
                openUsage &&
                <BasicModal open={openUsage} isModal={true}>
                  <Usage
                    actionName={actionNameUsage}
                    selectedItem={getSelecteditemByID(selectUsagesList[0])}
                    handleClose={handleCloseUsage}
                    addUsageLine={addUsageLine}
                    updateUsageLine={updateUsageLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Usages</h2>}

              <RedDataTableBasic
                ref={DataTableUsagesListRef}
                rows={data_usageslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionUsagesList} 
                elect={selectUsagesList}
                onRowDoubleClick={handleConsulterUsagesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerUsagesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauUsagesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterUsagesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerUsagesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierUsagesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintUsagesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerUsagesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerUsagesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default UsagesList



const UsagesListwidgetKey = 'Usageswidget'
export const UsagesListwidget = { i: UsagesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: UsagesList, titre: 'Usages', id_fenetre: 803 }
