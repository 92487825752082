
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, famille_delete_api, famille_info_api, famille_list_api } from '../../global/GlobalVars';
import Famille from './Famille';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetFamilleEmptyQuery, cleanGetFamilleQuery } from './Famille';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 100 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
];
const hideColumns = { id: false }


export const GetFamillesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['FamilleList'],
    queryFn: async () => await callAxiosApi(`${famille_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddFamilleLine = (queryClient, handleCloseFamille, handleRowSelectionFamillesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${famille_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['FamilleList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addFamilleLine onSuccess');
      customConsoleLog(newprod);
      handleCloseFamille();
      handleRowSelectionFamillesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateFamilleLine = (queryClient, handleCloseFamille, handleRowSelectionFamillesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${famille_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateFamilleLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateFamilleLine called')
      queryClient.setQueryData(['FamilleList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetFamilleQuery(newprod.id, queryClient);
      handleCloseFamille();
      handleRowSelectionFamillesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteFamilleLine = (queryClient, handleCloseFamille) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${famille_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['FamilleList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetFamilleQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseFamille();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetFamillesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['FamilleList'] });
}


const FamillesList = forwardRef((props, ref) => {
  //function FamillesList({ removeWidget, isModal }) {
  const { handleGetid_famille, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openFamilleList, setOpenFamilleList] = useState(false);
  const [openFamille, setOpenFamille] = useState(false);

  const [actionNameFamille, setActionNameFamille] = useState("")
  const [selectFamillesList, setSelectionFamillesList] = useState([]);

  const handleRowSelectionFamillesList = (e) => {
    if (e != selectFamillesList[0]) {
      setSelectionFamillesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenFamille = () => {
    setOpenFamille(true);

    //BasicModalRef.current.open();
  }
  const handleCloseFamille = () => {
    setOpenFamille(false);
    //BasicModalRef.current.close();
    cleanGetFamilleEmptyQuery(queryClient);
    cleanGetFamilleQuery(selectFamillesList[0], queryClient);
  }



  const [status_famille, execute_famille, resolve_famille, reject_famille, reset_famille] = useAwaitableComponent();
  const [asyncConfirmDialogInfoFamille, setConfirmDialogInfoFamille] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogFamillesList = status_famille === "awaiting";

  const handleAwaitModalFamillesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoFamille({ titre: mtitre, msg: mmsg });
      await execute_famille();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_famille();
    }
  };


  customConsoleLog('FamillesList rendered')



  const { data: data_familleslist = [], error: error_familleslist,
    refetch: refecth_familleslist, isLoading: isLoading_familleslist, failureCount: failureCount_familleslist } =
    GetFamillesListQuery();






  const handleFiltrerFamillesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauFamillesList = () => {

    clearToasts();
    setActionNameFamille(btnActionsName.nouveau);
    setSelectionFamillesList([])
    handleOpenFamille();

  }

  const handleConsulterFamillesList = () => {
    if (selectFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameFamille(btnActionsName.consulter);

    handleOpenFamille();
    customConsoleLog(selectFamillesList[0]);
  }

  const handleDupliquerFamillesList = () => {
    if (selectFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameFamille(btnActionsName.dupliquer);
    handleOpenFamille();
    customConsoleLog(selectFamillesList[0]);
  }

  const handleModifierFamillesList = () => {
    if (selectFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameFamille(btnActionsName.modifier);
    handleOpenFamille();
    customConsoleLog(selectFamillesList[0]);
  }


  const handleSupprimerFamillesList = async () => {
    if (selectFamillesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectFamillesList[0])
    if (await handleAwaitModalFamillesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameFamille(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteFamilleLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenFamillesList = (itemID) => {
    setOpenFamilleList(true);


    if(itemID) {
      handleRowSelectionFamillesList([itemID]);
    }
  }


  const handleFermerFamillesList = () => {
    if (selectFamillesList[0]) {
      if (handleGetid_famille) {

        handleGetid_famille(selectFamillesList[0]);
      }
    }
    cleanGetFamillesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetFamillesListQuery(queryClient);
      cleanGetFamilleEmptyQuery(queryClient);
      cleanGetFamilleQuery(selectFamillesList[0], queryClient);
      setOpenFamilleList(false);
    }
    else{

    setOpenFamilleList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_familleslist.filter(item => item.id == id)[0];
  }



  const addFamilleLine = useAddFamilleLine(queryClient, handleCloseFamille, handleRowSelectionFamillesList);


  const updateFamilleLine = useUpdateFamilleLine(queryClient, handleCloseFamille, handleRowSelectionFamillesList);

  const deleteFamilleLine = useDeleteFamilleLine(queryClient, handleCloseFamille);






  const DataTableFamillesListRef = React.useRef(null);

  const handlePrintFamillesList = () => {
    if (DataTableFamillesListRef.current) {
      DataTableFamillesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenFamillesList(itemID);
    },
    handleClose() {
      handleFermerFamillesList();
    }
  }));

  // if (isLoading_familleslist || error_familleslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerFamillesList}
  //         refetch={refecth_familleslist}
  //         failureCount={failureCount_familleslist}
  //         error={error_familleslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openFamilleList} handleClose={handleFermerFamillesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_familleslist || error_familleslist
            ? <BasicLoading
              handleClose={handleFermerFamillesList}
              refetch={refecth_familleslist}
              failureCount={failureCount_familleslist}
              error={error_familleslist} />
            : <>
              {
                showAsyncConfirmDialogFamillesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogFamillesList}
                  onSubmit={resolve_famille}
                  onCancel={reject_famille}
                  info={asyncConfirmDialogInfoFamille}
                />
              }


              {
                openFamille &&
                <BasicModal open={openFamille} isModal={true}>
                  <Famille
                    actionName={actionNameFamille}
                    selectedItem={getSelecteditemByID(selectFamillesList[0])}
                    handleClose={handleCloseFamille}
                    addFamilleLine={addFamilleLine}
                    updateFamilleLine={updateFamilleLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Familles</h2>}

              <RedDataTableBasic
                ref={DataTableFamillesListRef}
                rows={data_familleslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionFamillesList} 
                select={selectFamillesList}
                onRowDoubleClick={handleConsulterFamillesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerFamillesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauFamillesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterFamillesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerFamillesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierFamillesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintFamillesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerFamillesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerFamillesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }

      </BasicModal>


    </>

  );
  //}
});





export default FamillesList



const FamillesListwidgetKey = 'Familleswidget'
export const FamillesListwidget = { i: FamillesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: FamillesList, titre: 'Familles', id_fenetre: 502 }
