
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { accesTypes, btnActionsName, permission_info_api, utilisateur_delete_api, utilisateur_info_api, utilisateur_list_api } from '../../global/GlobalVars';
import Utilisateur, { cleanGetUtilisateurPermissionQuery, titletmpUtilisateurPermission } from './Utilisateur';
import BasicButton from '../../components/BasicButton';
import { checkPermission, clearToasts, formatDecimalNumber, showToast, showToastAccesError, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetUtilisateurEmptyQuery, cleanGetUtilisateurQuery } from './Utilisateur';
import { customConsoleLog } from '../../global/Tools';
import { useLoginStore } from '../../stores/globalStore';






const columns = [
  { field: 'id', headerName: 'ID Utilisateur', width: 100, filterable: false },
  { field: 'username', headerName: 'Utilisateur', width: 200 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = {  }




export const GetUtilisateursListQuery = (canFetch = true, order_by='code', ...data) => {
  return useQuery({
    queryKey: ['UtilisateurList'],
    queryFn: async () => {
      const params = {order_by: order_by, ...data};
      return await callAxiosApi(`${utilisateur_list_api}`, '', true)
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddUtilisateurLine = (queryClient, handleCloseUtilisateur, handleRowSelectionUtilisateursList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${utilisateur_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['UtilisateurList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addUtilisateurLine onSuccess');
      customConsoleLog(newprod);
      handleCloseUtilisateur();
      handleRowSelectionUtilisateursList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateUtilisateurLine = (queryClient, handleCloseUtilisateur, handleRowSelectionUtilisateursList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${utilisateur_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateUtilisateurLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateUtilisateurLine called')
      queryClient.setQueryData(['UtilisateurList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetUtilisateurQuery(newprod.id, queryClient);
      handleCloseUtilisateur();
      handleRowSelectionUtilisateursList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteUtilisateurLine = (queryClient, handleCloseUtilisateur) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${utilisateur_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['UtilisateurList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetUtilisateurQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseUtilisateur();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetUtilisateursListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['UtilisateurList'] });
}


const UtilisateursList = forwardRef((props, ref) => {
  //function UtilisateursList({ removeWidget, isModal }) {
    
  const { handleGetid_utilisateur, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openUtilisateurList, setOpenUtilisateurList] = useState(false);
  const [openUtilisateur, setOpenUtilisateur] = useState(false);

  const [actionNameUtilisateur, setActionNameUtilisateur] = useState("")
  const [selectUtilisateursList, setSelectionUtilisateursList] = useState([]);

  const { loginPermission} = useLoginStore();

  const handleRowSelectionUtilisateursList = (e) => {
    if (e != selectUtilisateursList[0]) {
      setSelectionUtilisateursList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenUtilisateur = () => {
    setOpenUtilisateur(true);

    //BasicModalRef.current.open();
  }
  const handleCloseUtilisateur = () => {
    setOpenUtilisateur(false);
    //BasicModalRef.current.close();
    cleanGetUtilisateurEmptyQuery(queryClient);
    cleanGetUtilisateurQuery(selectUtilisateursList[0], queryClient);

    cleanGetUtilisateurPermissionQuery(queryClient, titletmpUtilisateurPermission);
  }



  const [status_utilisateur, execute_utilisateur, resolve_utilisateur, reject_utilisateur, reset_utilisateur] = useAwaitableComponent();
  const [asyncConfirmDialogInfoUtilisateur, setConfirmDialogInfoUtilisateur] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogUtilisateursList = status_utilisateur === "awaiting";

  const handleAwaitModalUtilisateursList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoUtilisateur({ titre: mtitre, msg: mmsg });
      await execute_utilisateur();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_utilisateur();
    }
  };


  customConsoleLog('UtilisateursList rendered')



  const { data: data_utilisateurslist = [], error: error_utilisateurslist,
    refetch: refecth_utilisateurslist, isLoading: isLoading_utilisateurslist, failureCount: failureCount_utilisateurslist } =
    GetUtilisateursListQuery();






    // const { data: data_utilisateurpermission = [] } = GetUtilisateurPermissionQuery(selectUtilisateursList[0] ? true : false, 
    //                                                   selectUtilisateursList[0], 0, UtilisateursListFenetreInfo.id_fenetre, 'Filtrer');
  const handleFiltrerUtilisateursList = () => {
    customConsoleLog('filtrer');

    //customConsoleLog(data_utilisateurpermission);
  }


  const handleNouveauUtilisateursList = () => {
    if(!checkPermission(loginPermission, accesTypes.ajouter, 0, UtilisateursListwidget.id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    clearToasts();
    setActionNameUtilisateur(btnActionsName.nouveau);
    setSelectionUtilisateursList([])
    handleOpenUtilisateur();

  }

  const handleConsulterUtilisateursList = () => {

    if(!checkPermission(loginPermission, accesTypes.consulter, 0, UtilisateursListwidget.id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectUtilisateursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameUtilisateur(btnActionsName.consulter);

    handleOpenUtilisateur();
    customConsoleLog(selectUtilisateursList[0]);
  }

  const handleDupliquerUtilisateursList = () => {
    if(!checkPermission(loginPermission, accesTypes.ajouter, 0, UtilisateursListwidget.id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectUtilisateursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameUtilisateur(btnActionsName.dupliquer);
    handleOpenUtilisateur();
    customConsoleLog(selectUtilisateursList[0]);
  }

  const handleModifierUtilisateursList = () => {

    if(!checkPermission(loginPermission, accesTypes.modifier, 0, UtilisateursListwidget.id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectUtilisateursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameUtilisateur(btnActionsName.modifier);
    handleOpenUtilisateur();
    customConsoleLog(selectUtilisateursList[0]);
  }


  const handleSupprimerUtilisateursList = async () => {

    if(!checkPermission(loginPermission, accesTypes.supprimer, 0, UtilisateursListwidget.id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (selectUtilisateursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectUtilisateursList[0])
    if (await handleAwaitModalUtilisateursList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameUtilisateur(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteUtilisateurLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenUtilisateursList = (itemID) => {

    setOpenUtilisateurList(true);


    if(itemID) {
      handleRowSelectionUtilisateursList([itemID]);
    }
  }


  const handleFermerUtilisateursList = () => {
    if (selectUtilisateursList[0]) {
      if (handleGetid_utilisateur) {

        handleGetid_utilisateur(selectUtilisateursList[0]);
      }
    }
    cleanGetUtilisateursListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetUtilisateursListQuery(queryClient);
      cleanGetUtilisateurEmptyQuery(queryClient);
      cleanGetUtilisateurQuery(selectUtilisateursList[0], queryClient);
      setOpenUtilisateurList(false);
    }
    else{

      setOpenUtilisateurList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_utilisateurslist.filter(item => item.id == id)[0];
  }



  const addUtilisateurLine = useAddUtilisateurLine(queryClient, handleCloseUtilisateur, handleRowSelectionUtilisateursList);


  const updateUtilisateurLine = useUpdateUtilisateurLine(queryClient, handleCloseUtilisateur, handleRowSelectionUtilisateursList);

  const deleteUtilisateurLine = useDeleteUtilisateurLine(queryClient, handleCloseUtilisateur);






  const DataTableUtilisateursListRef = React.useRef(null);

  const handlePrintUtilisateursList = () => {

    if(!checkPermission(loginPermission, accesTypes.imprimer, 0, UtilisateursListwidget.id_fenetre)){
      showToastAccesError(5000, 'error');
      return;
    }
    if (DataTableUtilisateursListRef.current) {
      DataTableUtilisateursListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenUtilisateursList(itemID);
    },
    handleClose() {
      handleFermerUtilisateursList();
    }
  }));

  // if (isLoading_utilisateurslist || error_utilisateurslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerUtilisateursList}
  //         refetch={refecth_utilisateurslist}
  //         failureCount={failureCount_utilisateurslist}
  //         error={error_utilisateurslist} />

  //     </>
  //   )
  // }





  return (
    <>
      <BasicModal open={openUtilisateurList} handleClose={handleFermerUtilisateursList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_utilisateurslist || error_utilisateurslist
            ? <BasicLoading
              handleClose={handleFermerUtilisateursList}
              refetch={refecth_utilisateurslist}
              failureCount={failureCount_utilisateurslist}
              error={error_utilisateurslist} />
            : <>
              {
                showAsyncConfirmDialogUtilisateursList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogUtilisateursList}
                  onSubmit={resolve_utilisateur}
                  onCancel={reject_utilisateur}
                  info={asyncConfirmDialogInfoUtilisateur}
                />
              }


              {
                openUtilisateur &&
                <BasicModal open={openUtilisateur} isModal={true}>
                  <Utilisateur
                    actionName={actionNameUtilisateur}
                    selectedItem={getSelecteditemByID(selectUtilisateursList[0])}
                    handleClose={handleCloseUtilisateur}
                    addUtilisateurLine={addUtilisateurLine}
                    updateUtilisateurLine={updateUtilisateurLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Utilisateurs</h2>}

              <RedDataTableBasic
                ref={DataTableUtilisateursListRef}
                rows={data_utilisateurslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionUtilisateursList} 
                select={selectUtilisateursList}
                onRowDoubleClick={handleConsulterUtilisateursList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerUtilisateursList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauUtilisateursList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterUtilisateursList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerUtilisateursList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierUtilisateursList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintUtilisateursList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerUtilisateursList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerUtilisateursList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default UtilisateursList


const UtilisateursListwidgetKey = 'Utilisateurswidget'
export const UtilisateursListwidget = { i: UtilisateursListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: UtilisateursList, titre: 'Utilisateurs', id_fenetre: 730 }
