
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, reservation_delete_api, reservation_info_api, reservation_list_api } from '../../global/GlobalVars';
import Reservation from './Reservation';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDate, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetReservationEmptyQuery, cleanGetReservationQuery } from './Reservation';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'date', headerName: 'Date', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'dtedeb', headerName: 'DATEDEB', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'dtefin', headerName: 'DATEFIN', width: 100, type: 'date', valueFormatter: (params) => formatDate(params.value, false, true) },
  { field: 'mtdep', headerName: 'NBC', width: 50, type: 'number' },
  { field: 'id_utilisateur', headerName: 'U', width: 50, type: 'number' },
  { field: 'typedep', headerName: 'ETA', width: 80 },
  { field: 'pr', headerName: 'PR', width: 80 },
  { field: 'iddestination', headerName: 'IDDESTINATION', width: 100, type: 'number' },
  { field: 'iddepot', headerName: 'IDDEPOT', width: 100, type: 'number' },
  { field: 'idcompte_code', headerName: 'Camion', width: 150 },
  { field: 'iddepot_code', headerName: 'DEP', width: 50, type: 'number' },
  //{ field: 'libelle', headerName: 'Libelle', width: 200 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetReservationsListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['ReservationList'],
    queryFn: async () => await callAxiosApi(`${reservation_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddReservationLine = (queryClient, handleCloseReservation, handleRowSelectionReservationsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${reservation_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['ReservationList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addReservationLine onSuccess');
      customConsoleLog(newprod);
      handleCloseReservation();
      handleRowSelectionReservationsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateReservationLine = (queryClient, handleCloseReservation, handleRowSelectionReservationsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${reservation_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateReservationLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateReservationLine called')
      queryClient.setQueryData(['ReservationList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetReservationQuery(newprod.id, queryClient);
      handleCloseReservation();
      handleRowSelectionReservationsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteReservationLine = (queryClient, handleCloseReservation) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${reservation_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['ReservationList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetReservationQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseReservation();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetReservationsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['ReservationList'] });
}


const ReservationsList = forwardRef((props, ref) => {
  //function ReservationsList({ removeWidget, isModal }) {
  const { handleGetid_reservation, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openReservationList, setOpenReservationList] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);

  const [actionNameReservation, setActionNameReservation] = useState("")
  const [selectReservationsList, setSelectionReservationsList] = useState([]);

  const handleRowSelectionReservationsList = (e) => {
    if (e != selectReservationsList[0]) {
      setSelectionReservationsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenReservation = () => {
    setOpenReservation(true);

    //BasicModalRef.current.open();
  }
  const handleCloseReservation = () => {
    setOpenReservation(false);
    //BasicModalRef.current.close();
    cleanGetReservationEmptyQuery(queryClient);
    cleanGetReservationQuery(selectReservationsList[0], queryClient);
  }



  const [status_reservation, execute_reservation, resolve_reservation, reject_reservation, reset_reservation] = useAwaitableComponent();
  const [asyncConfirmDialogInfoReservation, setConfirmDialogInfoReservation] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogReservationsList = status_reservation === "awaiting";

  const handleAwaitModalReservationsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoReservation({ titre: mtitre, msg: mmsg });
      await execute_reservation();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_reservation();
    }
  };


  customConsoleLog('ReservationsList rendered')



  const { data: data_reservationslist = [], error: error_reservationslist,
    refetch: refecth_reservationslist, isLoading: isLoading_reservationslist, failureCount: failureCount_reservationslist } =
    GetReservationsListQuery();






  const handleFiltrerReservationsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauReservationsList = () => {

    clearToasts();
    setActionNameReservation(btnActionsName.nouveau);
    setSelectionReservationsList([])
    handleOpenReservation();

  }

  const handleConsulterReservationsList = () => {
    if (selectReservationsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameReservation(btnActionsName.consulter);

    handleOpenReservation();
    customConsoleLog(selectReservationsList[0]);
  }

  const handleDupliquerReservationsList = () => {
    if (selectReservationsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameReservation(btnActionsName.dupliquer);
    handleOpenReservation();
    customConsoleLog(selectReservationsList[0]);
  }

  const handleModifierReservationsList = () => {
    if (selectReservationsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameReservation(btnActionsName.modifier);
    handleOpenReservation();
    customConsoleLog(selectReservationsList[0]);
  }


  const handleSupprimerReservationsList = async () => {
    if (selectReservationsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectReservationsList[0])
    if (await handleAwaitModalReservationsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameReservation(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteReservationLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenReservationsList = (itemID) => {
    setOpenReservationList(true);


    if(itemID) {
      handleRowSelectionReservationsList([itemID]);
    }
  }


  const handleFermerReservationsList = () => {
    if (selectReservationsList[0]) {
      if (handleGetid_reservation) {

        handleGetid_reservation(selectReservationsList[0]);
      }
    }
    cleanGetReservationsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetReservationsListQuery(queryClient);
      cleanGetReservationEmptyQuery(queryClient);
      cleanGetReservationQuery(selectReservationsList[0], queryClient);
      setOpenReservationList(false);
    }
    else{

      setOpenReservationList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_reservationslist.filter(item => item.id == id)[0];
  }



  const addReservationLine = useAddReservationLine(queryClient, handleCloseReservation, handleRowSelectionReservationsList);


  const updateReservationLine = useUpdateReservationLine(queryClient, handleCloseReservation, handleRowSelectionReservationsList);

  const deleteReservationLine = useDeleteReservationLine(queryClient, handleCloseReservation);






  const DataTableReservationsListRef = React.useRef(null);

  const handlePrintReservationsList = () => {
    if (DataTableReservationsListRef.current) {
      DataTableReservationsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenReservationsList(itemID);
    },
    handleClose() {
      handleFermerReservationsList();
    }
  }));

  // if (isLoading_reservationslist || error_reservationslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerReservationsList}
  //         refetch={refecth_reservationslist}
  //         failureCount={failureCount_reservationslist}
  //         error={error_reservationslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openReservationList} handleClose={handleFermerReservationsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_reservationslist || error_reservationslist
            ? <BasicLoading
              handleClose={handleFermerReservationsList}
              refetch={refecth_reservationslist}
              failureCount={failureCount_reservationslist}
              error={error_reservationslist} />
            : <>
              {
                showAsyncConfirmDialogReservationsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogReservationsList}
                  onSubmit={resolve_reservation}
                  onCancel={reject_reservation}
                  info={asyncConfirmDialogInfoReservation}
                />
              }


              {
                openReservation &&
                <BasicModal open={openReservation} isModal={true}>
                  <Reservation
                    actionName={actionNameReservation}
                    selectedItem={getSelecteditemByID(selectReservationsList[0])}
                    handleClose={handleCloseReservation}
                    addReservationLine={addReservationLine}
                    updateReservationLine={updateReservationLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Reservations</h2>}

              <RedDataTableBasic
                ref={DataTableReservationsListRef}
                rows={data_reservationslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionReservationsList} 
                select={selectReservationsList}
                onRowDoubleClick={handleConsulterReservationsList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerReservationsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauReservationsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterReservationsList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerReservationsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierReservationsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintReservationsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerReservationsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerReservationsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default ReservationsList



const ReservationsListwidgetKey = 'Reservationswidget'
export const ReservationsListwidget = { i: ReservationsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: ReservationsList, titre: 'Reservations', id_fenetre: 817 }
