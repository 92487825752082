
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, departement_delete_api, departement_info_api, departement_list_api } from '../../global/GlobalVars';
import Departement from './Departement';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetDepartementEmptyQuery, cleanGetDepartementQuery } from './Departement';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 100 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
];
const hideColumns = { id: false }


export const GetDepartementsListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['DepartementList'],
    queryFn: async () => await callAxiosApi(`${departement_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddDepartementLine = (queryClient, handleCloseDepartement, handleRowSelectionDepartementsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${departement_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['DepartementList'], (previous) => {
        customConsoleLog('addDepartementLine previous', previous);
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addDepartementLine onSuccess');
      customConsoleLog(newprod);
      handleCloseDepartement();
      handleRowSelectionDepartementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateDepartementLine = (queryClient, handleCloseDepartement, handleRowSelectionDepartementsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${departement_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateDepartementLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateDepartementLine called')
      queryClient.setQueryData(['DepartementList'], (previous) => {
        customConsoleLog('updateDepartementLine previous', previous);
        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetDepartementQuery(newprod.id, queryClient);
      handleCloseDepartement();
      handleRowSelectionDepartementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteDepartementLine = (queryClient, handleCloseDepartement) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${departement_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['DepartementList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetDepartementQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseDepartement();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetDepartementsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['DepartementList'] });
}


const DepartementsList = forwardRef((props, ref) => {
  //function DepartementsList({ removeWidget, isModal }) {
  const { handleGetid_departement, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openDepartementList, setOpenDepartementList] = useState(false);
  const [openDepartement, setOpenDepartement] = useState(false);

  const [actionNameDepartement, setActionNameDepartement] = useState("")
  const [selectDepartementsList, setSelectionDepartementsList] = useState([]);

  const handleRowSelectionDepartementsList = (e) => {
    if (e != selectDepartementsList[0]) {
      setSelectionDepartementsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenDepartement = () => {
    setOpenDepartement(true);

    //BasicModalRef.current.open();
  }
  const handleCloseDepartement = () => {
    setOpenDepartement(false);
    //BasicModalRef.current.close();
    cleanGetDepartementEmptyQuery(queryClient);
    cleanGetDepartementQuery(selectDepartementsList[0], queryClient);
  }



  const [status_departement, execute_departement, resolve_departement, reject_departement, reset_departement] = useAwaitableComponent();
  const [asyncConfirmDialogInfoDepartement, setConfirmDialogInfoDepartement] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogDepartementsList = status_departement === "awaiting";

  const handleAwaitModalDepartementsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoDepartement({ titre: mtitre, msg: mmsg });
      await execute_departement();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_departement();
    }
  };


  customConsoleLog('DepartementsList rendered')



  const { data: data_departementslist = [], error: error_departementslist,
    refetch: refecth_departementslist, isLoading: isLoading_departementslist, failureCount: failureCount_departementslist } =
    GetDepartementsListQuery();






  const handleFiltrerDepartementsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauDepartementsList = () => {

    clearToasts();
    setActionNameDepartement(btnActionsName.nouveau);
    setSelectionDepartementsList([])
    handleOpenDepartement();

  }

  const handleConsulterDepartementsList = () => {
    if (selectDepartementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameDepartement(btnActionsName.consulter);

    handleOpenDepartement();
    customConsoleLog(selectDepartementsList[0]);
  }

  const handleDupliquerDepartementsList = () => {
    if (selectDepartementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameDepartement(btnActionsName.dupliquer);
    handleOpenDepartement();
    customConsoleLog(selectDepartementsList[0]);
  }

  const handleModifierDepartementsList = () => {
    if (selectDepartementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameDepartement(btnActionsName.modifier);
    handleOpenDepartement();
    customConsoleLog(selectDepartementsList[0]);
  }


  const handleSupprimerDepartementsList = async () => {
    if (selectDepartementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectDepartementsList[0])
    if (await handleAwaitModalDepartementsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameDepartement(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteDepartementLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenDepartementsList = (itemID) => {
    setOpenDepartementList(true);


    if(itemID) {
      handleRowSelectionDepartementsList([itemID]);
    }
  }


  const handleFermerDepartementsList = () => {
    if (selectDepartementsList[0]) {
      if (handleGetid_departement) {

        handleGetid_departement(selectDepartementsList[0]);
      }
    }
    cleanGetDepartementsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetDepartementsListQuery(queryClient);
      cleanGetDepartementEmptyQuery(queryClient);
      cleanGetDepartementQuery(selectDepartementsList[0], queryClient);
      setOpenDepartementList(false);
      
    }
    else{

    setOpenDepartementList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_departementslist.filter(item => item.id == id)[0];
  }



  const addDepartementLine = useAddDepartementLine(queryClient, handleCloseDepartement, handleRowSelectionDepartementsList);


  const updateDepartementLine = useUpdateDepartementLine(queryClient, handleCloseDepartement, handleRowSelectionDepartementsList);

  const deleteDepartementLine = useDeleteDepartementLine(queryClient, handleCloseDepartement);






  const DataTableDepartementsListRef = React.useRef(null);

  const handlePrintDepartementsList = () => {
    if (DataTableDepartementsListRef.current) {
      DataTableDepartementsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };



  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenDepartementsList(itemID);
    },
    handleClose() {
      handleFermerDepartementsList();
    }
  }));


  return (
    <>
      <BasicModal open={openDepartementList} handleClose={handleFermerDepartementsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_departementslist || error_departementslist
            ? <BasicLoading
              handleClose={handleFermerDepartementsList}
              refetch={refecth_departementslist}
              failureCount={failureCount_departementslist}
              error={error_departementslist} />
            : <>
              {
                showAsyncConfirmDialogDepartementsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogDepartementsList}
                  onSubmit={resolve_departement}
                  onCancel={reject_departement}
                  info={asyncConfirmDialogInfoDepartement}
                />
              }


              {
                openDepartement &&
                <BasicModal open={openDepartement} isModal={true}>
                  <Departement
                    actionName={actionNameDepartement}
                    selectedItem={getSelecteditemByID(selectDepartementsList[0])}
                    handleClose={handleCloseDepartement}
                    addDepartementLine={addDepartementLine}
                    updateDepartementLine={updateDepartementLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Departements</h2>}

              <RedDataTableBasic
                ref={DataTableDepartementsListRef}
                rows={data_departementslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionDepartementsList} 
                select={selectDepartementsList}
                onRowDoubleClick={handleConsulterDepartementsList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerDepartementsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauDepartementsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterDepartementsList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerDepartementsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierDepartementsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintDepartementsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerDepartementsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerDepartementsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }

      </BasicModal>


    </>

  );
  //}
});





export default DepartementsList



const DepartementsListwidgetKey = 'Departementswidget'
export const DepartementsListwidget = { i: DepartementsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: DepartementsList, titre: 'Departements', id_fenetre: 501 }
