import React from 'react'
import { checkString, cn  } from '../global/Tools';


const spanLabelClass = "whitespace-nowrap inline bg-gray-100 py-1 px-1 text-gray-600"
const inputClass = "bg-transparent border-transparent w-full h-full py-1  px-1  text-gray-600 focus:outline-blue-200"
const inputReadOnlyClass = "bg-gray-200 border-transparent w-full py-1  px-1  text-gray-600"


function BasicTextarea(props) {
  const { titre, className, readOnly, titreClassname, value, ...others } = props;

  const customTitreClass = cn(titreClassname ? `${spanLabelClass} ${checkString(titreClassname)}` :  `${spanLabelClass}`)
  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);

  const divClassname = `inline-flex bg-white rounded border-[1px] border-gray-300 text-xxs w-full` ;

  const customDivClass = cn(divClassname,others.rows ? '' : 'h-full');

  return (
    <>
    <div className={customDivClass}>
    {titre && <span className={customTitreClass}>{titre}</span>}
    <textarea {...others} readOnly={readOnly} 
      className={`${customClass}`} value={value} />
    
    </div>
    </>
  )
}

export default BasicTextarea