import axios from "axios";
import { ping_api } from "../global/GlobalVars";
import { customConsoleLog, errorStr, getCookie, showToastError } from "../global/Tools";
import { ProblemDeConnectionMSG } from "../global/GlobalText";



export function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.withCredentials = true;


export const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Authorization': `Basic ${getCookie('X-CSRFTOKEN')}`,
    'X-CSRFTOKEN': `${getCookie('X-CSRFTOKEN')}`,
  },
  
});
axiosClient.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.timeout = 30000;
      //config.params = { ...config.params, timestamp: Date.now() };
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

export const callAxiosApi = async (link, data='', showError=false, method='get', headers={}) => {

    if(method == 'get'){
  
      try {

        const response = await axiosClient.get(`${link}`, data , {
          withCredentials: true, headers
        });
        if(link == ping_api){
          return {};
        }
        return response.data;
      } catch (error) {
    
        const errorMsg = errorStr(error.response?.data || ProblemDeConnectionMSG.localerreur.info[0]);
        if(showError) showToastError(errorMsg, 5000, 'error');
        throw new Error(errorMsg);
      }
    }
    else
    {
      try {
        const response = await axiosClient.post(
          `${link}`, data ,
          { withCredentials: true, headers
           }
        );
        return response.data;
      } catch (error) {
  
        const errorMsg = errorStr(error.response?.data || ProblemDeConnectionMSG.localerreur.info[0]);
        if(showError) showToastError(errorMsg, 5000, 'error');
        throw new Error(errorMsg);
      }
  
    }
  }


// axios.interceptors.request.use(config => {
//     if (config.method.toUpperCase() === 'POST' || config.method.toUpperCase() === 'PATCH' || config.method.toUpperCase() === 'PUT' || config.method.toUpperCase() === 'OPTIONS')
//       config.headers['Content-Type'] = 'application/json;charset=utf-8';
  
//     const accessToken = getCookie('X-CSRFTOKEN');
//     if (accessToken) config.headers.Authorization = 'Bearer ' + accessToken;
  
//     return config;
//   });
// axios.interceptors.response.use(resp => resp, async error => {
//     if (error.response.status === 401 && !refresh) {
//         refresh = true;

//         customConsoleLog(localStorage.getItem('refresh_token'))
//         const response = await axios.post(loging_refresh_token_api, {
//             refresh:localStorage.getItem('refresh_token')
//         }, {
//             headers: {
//               'Content-Type': 'application/json',
//             }
//           },{withCredentials: true});

//         if (response.status === 200) {
//             axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;
//             localStorage.setItem('access_token', response.data.access);
//             localStorage.setItem('refresh_token', response.data.refresh);

//             return axios(error.config);
//         }
//     }
//     refresh = false;
//     return error;
// });