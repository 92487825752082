
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, mode_reglement_delete_api, mode_reglement_info_api, mode_reglement_list_api } from '../../global/GlobalVars';
import Mode_Reglement from './Mode_Reglement';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetMode_ReglementEmptyQuery, cleanGetMode_ReglementQuery } from './Mode_Reglement';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 100 },
  { field: 'libelle', headerName: 'Libelle Réglements', width: 300 },
  { field: 'nbre_jours', headerName: 'Nbre jours', width: 80 },
];
const hideColumns = { id: false }


export const GetMode_ReglementsListQuery = (canFetch = true, order_by='id', ...data) => {
  return useQuery({
    queryKey: ['Mode_ReglementList'],
    queryFn: async () => {
      const params = {order_by: order_by, ...data};
      return await callAxiosApi(`${mode_reglement_list_api}`, {params}, true)
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddMode_ReglementLine = (queryClient, handleCloseMode_Reglement, handleRowSelectionMode_ReglementsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${mode_reglement_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['Mode_ReglementList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addMode_ReglementLine onSuccess');
      customConsoleLog(newprod);
      handleCloseMode_Reglement();
      handleRowSelectionMode_ReglementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateMode_ReglementLine = (queryClient, handleCloseMode_Reglement, handleRowSelectionMode_ReglementsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${mode_reglement_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateMode_ReglementLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateMode_ReglementLine called')
      queryClient.setQueryData(['Mode_ReglementList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetMode_ReglementQuery(newprod.id, queryClient);
      handleCloseMode_Reglement();
      handleRowSelectionMode_ReglementsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteMode_ReglementLine = (queryClient, handleCloseMode_Reglement) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${mode_reglement_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['Mode_ReglementList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetMode_ReglementQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseMode_Reglement();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetMode_ReglementsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['Mode_ReglementList'] });
}


const Mode_ReglementsList = forwardRef((props, ref) => {
  //function Mode_ReglementsList({ removeWidget, isModal }) {
  const { handleGetid_mode_reglement, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openMode_ReglementList, setOpenMode_ReglementList] = useState(false);
  const [openMode_Reglement, setOpenMode_Reglement] = useState(false);

  const [actionNameMode_Reglement, setActionNameMode_Reglement] = useState("")
  const [selectMode_ReglementsList, setSelectionMode_ReglementsList] = useState([]);

  const handleRowSelectionMode_ReglementsList = (e) => {
    if (e != selectMode_ReglementsList[0]) {
      setSelectionMode_ReglementsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenMode_Reglement = () => {
    setOpenMode_Reglement(true);

    //BasicModalRef.current.open();
  }
  const handleCloseMode_Reglement = () => {
    setOpenMode_Reglement(false);
    //BasicModalRef.current.close();
    cleanGetMode_ReglementEmptyQuery(queryClient);
    cleanGetMode_ReglementQuery(selectMode_ReglementsList[0], queryClient);
  }



  const [status_mode_reglement, execute_mode_reglement, resolve_mode_reglement, reject_mode_reglement, reset_mode_reglement] = useAwaitableComponent();
  const [asyncConfirmDialogInfoMode_Reglement, setConfirmDialogInfoMode_Reglement] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogMode_ReglementsList = status_mode_reglement === "awaiting";

  const handleAwaitModalMode_ReglementsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoMode_Reglement({ titre: mtitre, msg: mmsg });
      await execute_mode_reglement();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_mode_reglement();
    }
  };


  customConsoleLog('Mode_ReglementsList rendered')



  const { data: data_mode_reglementslist = [], error: error_mode_reglementslist,
    refetch: refecth_mode_reglementslist, isLoading: isLoading_mode_reglementslist, failureCount: failureCount_mode_reglementslist } =
    GetMode_ReglementsListQuery();






  const handleFiltrerMode_ReglementsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauMode_ReglementsList = () => {

    clearToasts();
    setActionNameMode_Reglement(btnActionsName.nouveau);
    setSelectionMode_ReglementsList([])
    handleOpenMode_Reglement();

  }

  const handleConsulterMode_ReglementsList = () => {
    if (selectMode_ReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMode_Reglement(btnActionsName.consulter);

    handleOpenMode_Reglement();
    customConsoleLog(selectMode_ReglementsList[0]);
  }

  const handleDupliquerMode_ReglementsList = () => {
    if (selectMode_ReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMode_Reglement(btnActionsName.dupliquer);
    handleOpenMode_Reglement();
    customConsoleLog(selectMode_ReglementsList[0]);
  }

  const handleModifierMode_ReglementsList = () => {
    if (selectMode_ReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameMode_Reglement(btnActionsName.modifier);
    handleOpenMode_Reglement();
    customConsoleLog(selectMode_ReglementsList[0]);
  }


  const handleSupprimerMode_ReglementsList = async () => {
    if (selectMode_ReglementsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectMode_ReglementsList[0])
    if (await handleAwaitModalMode_ReglementsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameMode_Reglement(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteMode_ReglementLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenMode_ReglementsList = (itemID) => {
    setOpenMode_ReglementList(true);


    if(itemID) {
      handleRowSelectionMode_ReglementsList([itemID]);
    }
  }


  const handleFermerMode_ReglementsList = () => {
    if (selectMode_ReglementsList[0]) {
      if (handleGetid_mode_reglement) {

        handleGetid_mode_reglement(selectMode_ReglementsList[0]);
      }
    }
    cleanGetMode_ReglementsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetMode_ReglementsListQuery(queryClient);
      cleanGetMode_ReglementEmptyQuery(queryClient);
      cleanGetMode_ReglementQuery(selectMode_ReglementsList[0], queryClient);
      setOpenMode_ReglementList(false);
    }
    else{

      setOpenMode_ReglementList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_mode_reglementslist.filter(item => item.id == id)[0];
  }



  const addMode_ReglementLine = useAddMode_ReglementLine(queryClient, handleCloseMode_Reglement, handleRowSelectionMode_ReglementsList);


  const updateMode_ReglementLine = useUpdateMode_ReglementLine(queryClient, handleCloseMode_Reglement, handleRowSelectionMode_ReglementsList);

  const deleteMode_ReglementLine = useDeleteMode_ReglementLine(queryClient, handleCloseMode_Reglement);






  const DataTableMode_ReglementsListRef = React.useRef(null);

  const handlePrintMode_ReglementsList = () => {
    if (DataTableMode_ReglementsListRef.current) {
      DataTableMode_ReglementsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenMode_ReglementsList(itemID);
    },
    handleClose() {
      handleFermerMode_ReglementsList();
    }
  }));

  // if (isLoading_mode_reglementslist || error_mode_reglementslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerMode_ReglementsList}
  //         refetch={refecth_mode_reglementslist}
  //         failureCount={failureCount_mode_reglementslist}
  //         error={error_mode_reglementslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openMode_ReglementList} handleClose={handleFermerMode_ReglementsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_mode_reglementslist || error_mode_reglementslist
            ? <BasicLoading
              handleClose={handleFermerMode_ReglementsList}
              refetch={refecth_mode_reglementslist}
              failureCount={failureCount_mode_reglementslist}
              error={error_mode_reglementslist} />
            : <>
              {
                showAsyncConfirmDialogMode_ReglementsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogMode_ReglementsList}
                  onSubmit={resolve_mode_reglement}
                  onCancel={reject_mode_reglement}
                  info={asyncConfirmDialogInfoMode_Reglement}
                />
              }


              {
                openMode_Reglement &&
                <BasicModal open={openMode_Reglement} isModal={true}>
                  <Mode_Reglement
                    actionName={actionNameMode_Reglement}
                    selectedItem={getSelecteditemByID(selectMode_ReglementsList[0])}
                    handleClose={handleCloseMode_Reglement}
                    addMode_ReglementLine={addMode_ReglementLine}
                    updateMode_ReglementLine={updateMode_ReglementLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Modes Reglements</h2>}

              <RedDataTableBasic
                ref={DataTableMode_ReglementsListRef}
                rows={data_mode_reglementslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionMode_ReglementsList} 
                select={selectMode_ReglementsList}
                onRowDoubleClick={handleConsulterMode_ReglementsList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerMode_ReglementsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauMode_ReglementsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterMode_ReglementsList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerMode_ReglementsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierMode_ReglementsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintMode_ReglementsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerMode_ReglementsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerMode_ReglementsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default Mode_ReglementsList



const Mode_ReglementsListwidgetKey = 'Mode_Reglementswidget'
export const Mode_ReglementsListwidget = { i: Mode_ReglementsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: Mode_ReglementsList, titre: 'Modes Reglements', id_fenetre: 709 }
