
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, tva_delete_api, tva_info_api, tva_list_api } from '../../global/GlobalVars';
import Tva from './Tva';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetTvaEmptyQuery, cleanGetTvaQuery } from './Tva';
import BasicDateInput from '../../components/BasicDateInput';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 100 },
  { field: 'libelle', headerName: 'Libelle', width: 200 },
  { field: 'taux', headerName: 'Taux', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetTvasListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['TvaList'],
    queryFn: async () => await callAxiosApi(`${tva_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddTvaLine = (queryClient, handleCloseTva, handleRowSelectionTvasList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${tva_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['TvaList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addTvaLine onSuccess');
      customConsoleLog(newprod);
      handleCloseTva();
      handleRowSelectionTvasList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateTvaLine = (queryClient, handleCloseTva, handleRowSelectionTvasList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${tva_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateTvaLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateTvaLine called')
      queryClient.setQueryData(['TvaList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetTvaQuery(newprod.id, queryClient);
      handleCloseTva();
      handleRowSelectionTvasList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteTvaLine = (queryClient, handleCloseTva) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${tva_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['TvaList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetTvaQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseTva();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetTvasListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['TvaList'] });
}


const TvasList = forwardRef((props, ref) => {
  //function TvasList({ removeWidget, isModal }) {
  const { handleGetid_tva, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openTvaList, setOpenTvaList] = useState(false);
  const [openTva, setOpenTva] = useState(false);

  const [actionNameTva, setActionNameTva] = useState("")
  const [selectTvasList, setSelectionTvasList] = useState([]);

  const handleRowSelectionTvasList = (e) => {
    if (e != selectTvasList[0]) {
      setSelectionTvasList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenTva = () => {
    setOpenTva(true);

    //BasicModalRef.current.open();
  }
  const handleCloseTva = () => {
    setOpenTva(false);
    //BasicModalRef.current.close();
    cleanGetTvaEmptyQuery(queryClient);
    cleanGetTvaQuery(selectTvasList[0], queryClient);
  }



  const [status_tva, execute_tva, resolve_tva, reject_tva, reset_tva] = useAwaitableComponent();
  const [asyncConfirmDialogInfoTva, setConfirmDialogInfoTva] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogTvasList = status_tva === "awaiting";

  const handleAwaitModalTvasList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoTva({ titre: mtitre, msg: mmsg });
      await execute_tva();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_tva();
    }
  };


  customConsoleLog('TvasList rendered')



  const { data: data_tvaslist = [], error: error_tvaslist,
    refetch: refecth_tvaslist, isLoading: isLoading_tvaslist, failureCount: failureCount_tvaslist } =
    GetTvasListQuery();






  const handleFiltrerTvasList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauTvasList = () => {

    clearToasts();
    setActionNameTva(btnActionsName.nouveau);
    setSelectionTvasList([])
    handleOpenTva();

  }

  const handleConsulterTvasList = () => {
    if (selectTvasList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameTva(btnActionsName.consulter);

    handleOpenTva();
    customConsoleLog(selectTvasList[0]);
  }

  const handleDupliquerTvasList = () => {
    if (selectTvasList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameTva(btnActionsName.dupliquer);
    handleOpenTva();
    customConsoleLog(selectTvasList[0]);
  }

  const handleModifierTvasList = () => {
    if (selectTvasList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameTva(btnActionsName.modifier);
    handleOpenTva();
    customConsoleLog(selectTvasList[0]);
  }


  const handleSupprimerTvasList = async () => {
    if (selectTvasList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectTvasList[0])
    if (await handleAwaitModalTvasList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameTva(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteTvaLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenTvasList = (itemID) => {
    setOpenTvaList(true);


    if(itemID) {
      handleRowSelectionTvasList([itemID]);
    }
  }


  const handleFermerTvasList = () => {
    if (selectTvasList[0]) {
      if (handleGetid_tva) {

        handleGetid_tva(selectTvasList[0]);
      }
    }
    cleanGetTvasListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetTvasListQuery(queryClient);
      cleanGetTvaEmptyQuery(queryClient);
      cleanGetTvaQuery(selectTvasList[0], queryClient);
      setOpenTvaList(false);
    }
    else {
      setOpenTvaList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_tvaslist.filter(item => item.id == id)[0];
  }



  const addTvaLine = useAddTvaLine(queryClient, handleCloseTva, handleRowSelectionTvasList);


  const updateTvaLine = useUpdateTvaLine(queryClient, handleCloseTva, handleRowSelectionTvasList);

  const deleteTvaLine = useDeleteTvaLine(queryClient, handleCloseTva);






  const DataTableTvasListRef = React.useRef(null);

  const handlePrintTvasList = () => {
    if (DataTableTvasListRef.current) {
      DataTableTvasListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenTvasList(itemID);
    },
    handleClose() {
      handleFermerTvasList();
    }
  }));

  // if (isLoading_tvaslist || error_tvaslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerTvasList}
  //         refetch={refecth_tvaslist}
  //         failureCount={failureCount_tvaslist}
  //         error={error_tvaslist} />

  //     </>
  //   )
  // }



  return (
    <>
      <BasicModal open={openTvaList} handleClose={handleFermerTvasList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_tvaslist || error_tvaslist
            ? <BasicLoading
              handleClose={handleFermerTvasList}
              refetch={refecth_tvaslist}
              failureCount={failureCount_tvaslist}
              error={error_tvaslist} />
            : <>
              {
                showAsyncConfirmDialogTvasList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogTvasList}
                  onSubmit={resolve_tva}
                  onCancel={reject_tva}
                  info={asyncConfirmDialogInfoTva}
                />
              }


              {
                openTva &&
                <BasicModal open={openTva} isModal={true}>
                  <Tva
                    actionName={actionNameTva}
                    selectedItem={getSelecteditemByID(selectTvasList[0])}
                    handleClose={handleCloseTva}
                    addTvaLine={addTvaLine}
                    updateTvaLine={updateTvaLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Tvas</h2>}
              <RedDataTableBasic
                ref={DataTableTvasListRef}
                rows={data_tvaslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionTvasList} 
                select={selectTvasList}
                onRowDoubleClick={handleConsulterTvasList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerTvasList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauTvasList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterTvasList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerTvasList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierTvasList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintTvasList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerTvasList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerTvasList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }

      </BasicModal>


    </>

  );
  //}
});





export default TvasList



const TvasListwidgetKey = 'Tvaswidget'
export const TvasListwidget = { i: TvasListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: TvasList, titre: 'Tvas', id_fenetre: 705 }
