
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, representant_delete_api, representant_info_api, representant_list_api } from '../../global/GlobalVars';
import Representant from './Representant';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetRepresentantEmptyQuery, cleanGetRepresentantQuery } from './Representant';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 200 },
  { field: 'nom', headerName: 'Nom', width: 200 },
  { field: 'region', headerName: 'Region', width: 200 },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetRepresentantsListQuery = (canFetch = true, order_by='code', ...data) => {
  return useQuery({
    queryKey: ['RepresentantList'],
    queryFn: async () => {
      const params = {order_by: order_by, ...data};
      return await callAxiosApi(`${representant_list_api}`, {params}, true)
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddRepresentantLine = (queryClient, handleCloseRepresentant, handleRowSelectionRepresentantsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${representant_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['RepresentantList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addRepresentantLine onSuccess');
      customConsoleLog(newprod);
      handleCloseRepresentant();
      handleRowSelectionRepresentantsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateRepresentantLine = (queryClient, handleCloseRepresentant, handleRowSelectionRepresentantsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${representant_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateRepresentantLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateRepresentantLine called')
      queryClient.setQueryData(['RepresentantList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetRepresentantQuery(newprod.id, queryClient);
      handleCloseRepresentant();
      handleRowSelectionRepresentantsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteRepresentantLine = (queryClient, handleCloseRepresentant) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${representant_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['RepresentantList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetRepresentantQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseRepresentant();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetRepresentantsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['RepresentantList'] });
}


const RepresentantsList = forwardRef((props, ref) => {
  //function RepresentantsList({ removeWidget, isModal }) {
  const { handleGetid_representant, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openRepresentantList, setOpenRepresentantList] = useState(false);
  const [openRepresentant, setOpenRepresentant] = useState(false);

  const [actionNameRepresentant, setActionNameRepresentant] = useState("")
  const [selectRepresentantsList, setSelectionRepresentantsList] = useState([]);

  const handleRowSelectionRepresentantsList = (e) => {
    if (e != selectRepresentantsList[0]) {
      setSelectionRepresentantsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenRepresentant = () => {
    setOpenRepresentant(true);

    //BasicModalRef.current.open();
  }
  const handleCloseRepresentant = () => {
    setOpenRepresentant(false);
    //BasicModalRef.current.close();
    cleanGetRepresentantEmptyQuery(queryClient);
    cleanGetRepresentantQuery(selectRepresentantsList[0], queryClient);
  }



  const [status_representant, execute_representant, resolve_representant, reject_representant, reset_representant] = useAwaitableComponent();
  const [asyncConfirmDialogInfoRepresentant, setConfirmDialogInfoRepresentant] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogRepresentantsList = status_representant === "awaiting";

  const handleAwaitModalRepresentantsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoRepresentant({ titre: mtitre, msg: mmsg });
      await execute_representant();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_representant();
    }
  };


  customConsoleLog('RepresentantsList rendered')



  const { data: data_representantslist = [], error: error_representantslist,
    refetch: refecth_representantslist, isLoading: isLoading_representantslist, failureCount: failureCount_representantslist } =
    GetRepresentantsListQuery();






  const handleFiltrerRepresentantsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauRepresentantsList = () => {

    clearToasts();
    setActionNameRepresentant(btnActionsName.nouveau);
    setSelectionRepresentantsList([])
    handleOpenRepresentant();

  }

  const handleConsulterRepresentantsList = () => {
    if (selectRepresentantsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameRepresentant(btnActionsName.consulter);

    handleOpenRepresentant();
    customConsoleLog(selectRepresentantsList[0]);
  }

  const handleDupliquerRepresentantsList = () => {
    if (selectRepresentantsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameRepresentant(btnActionsName.dupliquer);
    handleOpenRepresentant();
    customConsoleLog(selectRepresentantsList[0]);
  }

  const handleModifierRepresentantsList = () => {
    if (selectRepresentantsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameRepresentant(btnActionsName.modifier);
    handleOpenRepresentant();
    customConsoleLog(selectRepresentantsList[0]);
  }


  const handleSupprimerRepresentantsList = async () => {
    if (selectRepresentantsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectRepresentantsList[0])
    if (await handleAwaitModalRepresentantsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameRepresentant(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteRepresentantLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenRepresentantsList = (itemID) => {
    setOpenRepresentantList(true);


    if(itemID) {
      handleRowSelectionRepresentantsList([itemID]);
    }
  }


  const handleFermerRepresentantsList = () => {
    if (selectRepresentantsList[0]) {
      if (handleGetid_representant) {

        handleGetid_representant(selectRepresentantsList[0]);
      }
    }
    cleanGetRepresentantsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetRepresentantsListQuery(queryClient);
      cleanGetRepresentantEmptyQuery(queryClient);
      cleanGetRepresentantQuery(selectRepresentantsList[0], queryClient);
      setOpenRepresentantList(false);
    }
    else{

      setOpenRepresentantList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_representantslist.filter(item => item.id == id)[0];
  }



  const addRepresentantLine = useAddRepresentantLine(queryClient, handleCloseRepresentant, handleRowSelectionRepresentantsList);


  const updateRepresentantLine = useUpdateRepresentantLine(queryClient, handleCloseRepresentant, handleRowSelectionRepresentantsList);

  const deleteRepresentantLine = useDeleteRepresentantLine(queryClient, handleCloseRepresentant);






  const DataTableRepresentantsListRef = React.useRef(null);

  const handlePrintRepresentantsList = () => {
    if (DataTableRepresentantsListRef.current) {
      DataTableRepresentantsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenRepresentantsList(itemID);
    },
    handleClose() {
      handleFermerRepresentantsList();
    }
  }));

  // if (isLoading_representantslist || error_representantslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerRepresentantsList}
  //         refetch={refecth_representantslist}
  //         failureCount={failureCount_representantslist}
  //         error={error_representantslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openRepresentantList} handleClose={handleFermerRepresentantsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_representantslist || error_representantslist
            ? <BasicLoading
              handleClose={handleFermerRepresentantsList}
              refetch={refecth_representantslist}
              failureCount={failureCount_representantslist}
              error={error_representantslist} />
            : <>
              {
                showAsyncConfirmDialogRepresentantsList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogRepresentantsList}
                  onSubmit={resolve_representant}
                  onCancel={reject_representant}
                  info={asyncConfirmDialogInfoRepresentant}
                />
              }


              {
                openRepresentant &&
                <BasicModal open={openRepresentant} isModal={true}>
                  <Representant
                    actionName={actionNameRepresentant}
                    selectedItem={getSelecteditemByID(selectRepresentantsList[0])}
                    handleClose={handleCloseRepresentant}
                    addRepresentantLine={addRepresentantLine}
                    updateRepresentantLine={updateRepresentantLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Representants</h2>}

              <RedDataTableBasic
                ref={DataTableRepresentantsListRef}
                rows={data_representantslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionRepresentantsList} 
                select={selectRepresentantsList}
                onRowDoubleClick={handleConsulterRepresentantsList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerRepresentantsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauRepresentantsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterRepresentantsList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerRepresentantsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierRepresentantsList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintRepresentantsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerRepresentantsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerRepresentantsList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default RepresentantsList



const RepresentantsListwidgetKey = 'Representantswidget'
export const RepresentantsListwidget = { i: RepresentantsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: (props) => <RepresentantsList {...props}/>, titre: 'Representants', id_fenetre: 508 }
