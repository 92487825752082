import React from 'react'
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tab } from '@mui/base/Tab';
import { checkString, cn  } from '../global/Tools';



function BasicTabs(props) {
  const tabClass = "bg-gray-50 text-xs mt-1"
  const tabReadOnlyClass = "bg-gray-50 text-xs mt-1"

  const { children, defaultValue, readOnly, className, ...others } = props;

  const customClass = cn(readOnly ? `${tabReadOnlyClass} ${checkString(className)}` : `${tabClass} ${checkString(className)}`);
  return (
    <Tabs defaultValue={defaultValue} className={customClass} {...others}>
      {children}
    </Tabs>
  )
}

export default BasicTabs

export function BasicTabsList(props) {
  const tabClass = ""
  const tabReadOnlyClass = ""

  const { children, readOnly, className, ...others } = props;

  const customClass = cn(readOnly ? `${tabReadOnlyClass} ${checkString(className)}` : `${tabClass} ${checkString(className)}`);
  return (
    <TabsList className={customClass} {...others}>
      {children}
    </TabsList>
  )
}

export function BasicTab(props) {
  const tabClass = ""
  const tabReadOnlyClass = ""

  const { children, value, readOnly, className, ...others } = props;

  const customClass = cn(readOnly ? `${tabReadOnlyClass} ${checkString(className)}` : `${tabClass} ${checkString(className)}`);
  return (
    <Tab className={customClass} 
    value={value}
    slotProps={{
      root: ({ selected, disabled }) => ({
        className: `font-sans ${selected
          ? 'text-black bg-slate-100 border border-gray-400 border-b-0 p-1'
          : 'text-black bg-gray-300 hover:bg-gray-200 border border-gray-500 border-b-0 p-1'
          }`
      }),
    }} 
    {...others}>
      {children}
    </Tab>
  )
}

export function BasicTabPanel(props) {
  const tabClass = ""
  const tabReadOnlyClass = ""

  const { children, value, readOnly, className, ...others } = props;

  const customClass = cn(readOnly ? `${tabReadOnlyClass} ${checkString(className)}` : `${tabClass} ${checkString(className)}`);
  return (
    <TabPanel className={customClass} 
    value={value}
    {...others}>
      {children}
    </TabPanel>
  )
}