
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, client_delete_api, client_info_api, client_list_api } from '../../global/GlobalVars';
import Client from './Client';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetClientEmptyQuery, cleanGetClientQuery } from './Client';
// import { cleanGetCategorieEmptyQuery, cleanGetCategorieQuery } from '../categorie/Categorie';
import { cleanGetRegionsListQuery } from '../region/RegionsList';
import { cleanGetCategoriesListQuery } from '../categorie/CategoriesList';
import { cleanGetPayssListQuery } from '../pays/PayssList';
import { cleanGetRepresentantsListQuery } from '../representant/RepresentantsList';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 100 },
  { field: 'nom', headerName: 'Nom', width: 200 },
  { field: 'ville', headerName: 'ICE', width: 200 },
  { field: 'tel_1', headerName: 'Telephone', width: 100 },
  { field: 'gsm', headerName: 'GSM', width: 100 },
  { field: 'cat', headerName: 'CAT', width: 50 },
  { field: 'devises', headerName: 'DEV', width: 50 },
  { field: 'depotac', headerName: 'Depot', width: 50 },
];
const hideColumns = { id: false, depotac: false, }


export const GetClientsListQuery = (canFetch = true, order_by='code', id_etablissement = null) => {
  return useQuery({
    queryKey: ['ClientList'],
    queryFn: async () => {
      const params = {order_by: order_by, id_etablissement};
      return await callAxiosApi(`${client_list_api}`, {params}, true);
    }
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddClientLine = (queryClient, handleCloseClient, handleRowSelectionClientsList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${client_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['ClientList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addClientLine onSuccess');
      customConsoleLog(newprod);
      handleCloseClient();
      handleRowSelectionClientsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateClientLine = (queryClient, handleCloseClient, handleRowSelectionClientsList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${client_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateClientLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateClientLine called')
      queryClient.setQueryData(['ClientList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetClientQuery(newprod.id, queryClient);
      handleCloseClient();
      handleRowSelectionClientsList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteClientLine = (queryClient, handleCloseClient) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${client_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['ClientList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetClientQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseClient();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetClientsListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['ClientList'] });
}


const ClientsList = forwardRef((props, ref) => {
  //function ClientsList({ removeWidget, isModal }) {
  const { handleGetid_client, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openClientList, setOpenClientList] = useState(false);
  const [openClient, setOpenClient] = useState(false);

  const [actionNameClient, setActionNameClient] = useState("")
  const [selectClientsList, setSelectionClientsList] = useState([]);

  const handleRowSelectionClientsList = (e) => {
    if (e != selectClientsList[0]) {
      setSelectionClientsList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenClient = () => {
    setOpenClient(true);

    //BasicModalRef.current.open();
  }
  const handleCloseClient = () => {
    setOpenClient(false);
    //BasicModalRef.current.close();
    cleanGetClientEmptyQuery(queryClient);
    cleanGetClientQuery(selectClientsList[0], queryClient);
  }



  const [status_client, execute_client, resolve_client, reject_client, reset_client] = useAwaitableComponent();
  const [asyncConfirmDialogInfoClient, setConfirmDialogInfoClient] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogClientsList = status_client === "awaiting";

  const handleAwaitModalClientsList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoClient({ titre: mtitre, msg: mmsg });
      await execute_client();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_client();
    }
  };


  customConsoleLog('ClientsList rendered')



  const { data: data_clientslist = [], error: error_clientslist,
    refetch: refecth_clientslist, isLoading: isLoading_clientslist, failureCount: failureCount_clientslist } =
    GetClientsListQuery();






  const handleFiltrerClientsList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauClientsList = () => {

    clearToasts();
    setActionNameClient(btnActionsName.nouveau);
    setSelectionClientsList([])
    handleOpenClient();

  }

  const handleConsulterClientsList = () => {
    if (selectClientsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameClient(btnActionsName.consulter);

    handleOpenClient();
    customConsoleLog(selectClientsList[0]);
  }

  const handleDupliquerClientsList = () => {
    if (selectClientsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameClient(btnActionsName.dupliquer);
    handleOpenClient();
    customConsoleLog(selectClientsList[0]);
  }

  const handleModifierClientsList = () => {
    if (selectClientsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameClient(btnActionsName.modifier);
    handleOpenClient();
    customConsoleLog(selectClientsList[0]);
  }


  const handleSupprimerClientsList = async () => {
    if (selectClientsList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectClientsList[0])
    if (await handleAwaitModalClientsList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameClient(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteClientLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenClientsList = (itemID) => {
    setOpenClientList(true);


    if(itemID) {
      handleRowSelectionClientsList([itemID]);
    }
  }


  const handleFermerClientsList = () => {
    if (selectClientsList[0]) {
      if (handleGetid_client) {

        handleGetid_client(selectClientsList[0]);
      }
    }
    cleanGetClientsListQuery(queryClient);
    cleanGetRegionsListQuery(queryClient);
    cleanGetCategoriesListQuery(queryClient);
    cleanGetPayssListQuery(queryClient);
    cleanGetRepresentantsListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetClientsListQuery(queryClient);
      cleanGetClientEmptyQuery(queryClient);
      cleanGetClientQuery(selectClientsList[0], queryClient);
      setOpenClientList(false);
    }
    else{

      setOpenClientList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_clientslist.filter(item => item.id == id)[0];
  }



  const addClientLine = useAddClientLine(queryClient, handleCloseClient, handleRowSelectionClientsList);


  const updateClientLine = useUpdateClientLine(queryClient, handleCloseClient, handleRowSelectionClientsList);

  const deleteClientLine = useDeleteClientLine(queryClient, handleCloseClient);






  const DataTableClientsListRef = React.useRef(null);

  const handlePrintClientsList = () => {
    if (DataTableClientsListRef.current) {
      DataTableClientsListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenClientsList(itemID);
    },
    handleClose() {
      handleFermerClientsList();
    }
  }));

  // if (isLoading_clientslist || error_clientslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerClientsList}
  //         refetch={refecth_clientslist}
  //         failureCount={failureCount_clientslist}
  //         error={error_clientslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openClientList} handleClose={handleFermerClientsList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_clientslist || error_clientslist
          ? <BasicLoading
          handleClose={handleFermerClientsList}
          refetch={refecth_clientslist}
          failureCount={failureCount_clientslist}
          error={error_clientslist} />
          : <>
          {
          showAsyncConfirmDialogClientsList &&
          <BasicAsyncConfirmDialog
            visible={showAsyncConfirmDialogClientsList}
            onSubmit={resolve_client}
            onCancel={reject_client}
            info={asyncConfirmDialogInfoClient}
          />
        }


        {
          openClient &&
          <BasicModal open={openClient} isModal={true}>
            <Client
              actionName={actionNameClient}
              selectedItem={getSelecteditemByID(selectClientsList[0])}
              handleClose={handleCloseClient}
              addClientLine={addClientLine}
              updateClientLine={updateClientLine}
            />
          </BasicModal>
        }
        {isModal && <h2 className="text-lg font-bold mb-4">Clients</h2>}

        <RedDataTableBasic
          ref={DataTableClientsListRef}
          rows={data_clientslist}
          columns={columns}
          columnVisibilityModel={hideColumns}
          handleRowSelection={handleRowSelectionClientsList} 
          select={selectClientsList}
          onRowDoubleClick={handleConsulterClientsList}
        />

        {/* Buttons */}
        <div className="mt-2 grid grid-cols-10 gap-2 p-2">
          <BasicButton onClick={handleFiltrerClientsList} id="filtrer" name="filtrer">Filtrer</BasicButton>
          <div></div>
          <BasicButton onClick={handleNouveauClientsList} id="nouveau" name="nouveau">Nouveau</BasicButton>
          <BasicButton onClick={handleConsulterClientsList} id="consulter" name="consulter">Consulter</BasicButton>
          <BasicButton onClick={handleDupliquerClientsList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
          <div></div>
          <BasicButton onClick={handleModifierClientsList} id="modifier" name="modifier">Modifier</BasicButton>
          <BasicButton onClick={handlePrintClientsList} id="imprimer" name="imprimer">Imprimer</BasicButton>
          <BasicButton onClick={handleSupprimerClientsList} id="supprimer" name="supprimer">Supprimer</BasicButton>
          <BasicButton onClick={handleFermerClientsList} id="fermer" name="fermer">Fermer</BasicButton>
        </div>
          </>
        }
        
      </BasicModal>


    </>

  );
  //}
});





export default ClientsList



const ClientsListwidgetKey = 'Clientswidget'
export const ClientsListwidget = { i: ClientsListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: ClientsList, titre: 'Clients', id_fenetre: 506 }
