import React from 'react';
import { alpha, styled } from '@mui/material/styles';
//import { DataGrid, gridClasses, gridExpandedSortedRowIdsSelector, gridVisibleColumnDefinitionsSelector } from '@mui/x-data-grid';
// import { DataGridPro, 
//     gridClasses, 
//     gridExpandedSortedRowIdsSelector, 
//     gridVisibleColumnDefinitionsSelector } from '@mui/x-data-grid-pro';
import {
    DataGridPro,
    gridClasses,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarExport,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import BasicInput from '../components/BasicInput';
import { customConsoleLog } from '../global/Tools';



const ODD_OPACITY = 0.2;
// need to add borders to the cells and make it collapse to avoid double borders


const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {

    return (
        <div className='grid grid-cols-10 gap-2 p-2'>
            <div className='col-span-6'>
                <BasicInput
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={'rechercher...'}
                    className={'text-xs p-1'}
                    titre={'Recherche : '}
                    titreClassname={'text-xs p-1'}
                />
            </div>
            <div className='col-span-4 text-right'>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
            </div>
        </div>
    );
}

function getRowId(row) {
    return row.id;
}




//function RedDataTableBasic(props) {
const RedDataTableBasic = React.forwardRef((props, ref) => {

    const { rows, columns, handleRowSelection, columnVisibilityModel, select, nofilter, checkboxSelection, 
        aggregation, handleKeyDownClick, height, width, handleContextMenu, ...others } = props

    const [searchText, setSearchText] = React.useState('');
    const [rowsFinal, setRowsFinal] = React.useState(rows);
    const apiRef = useGridApiRef();



    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        if (rows) {
            try {
                const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
                const filteredRows = rows.filter((row) => {
                    return Object.keys(row).some((field) => {
                        // Check if the field value is not null or undefined
                        if (row[field] !== null && row[field] !== undefined) {
                            // Convert the field value to a string before applying the regular expression
                            return searchRegex.test(row[field].toString());
                        }
                        return false;
                    });
                });
                setRowsFinal(filteredRows);
            } catch (e) {
                customConsoleLog(e);
            }
        }
    };


    React.useEffect(() => {
        customConsoleLog('rows changed')
        setRowsFinal(rows)
    }, [rows]);


    React.useEffect(() => {
        customConsoleLog('selectedRowIds changed')
        handleRowSelectionChange(select)
    }, [select])

    const handlePrint = (options) => {
        apiRef.current.exportDataAsPrint(options);
    };

    React.useImperativeHandle(ref, () => ({
        handlePrint: handlePrint,
    }));

    const handleRowSelectionChange = (newSelection) => {
        if (!handleRowSelection) return;
        if (!newSelection) return;
        handleRowSelection(newSelection);

        customConsoleLog('newSelection : ', newSelection)
        const selectedRowIndex = rowsFinal.findIndex((row) => row.id === newSelection[0]);
        try {
            setTimeout(() => {
                apiRef.current.scrollToIndexes({ rowIndex: selectedRowIndex, colIndex: 0 });

            }, 200);

        }
        catch (e) {
            customConsoleLog(e)
        }
    };

    const handleKeyDownClickChange = (params, event) => {
        if(!handleKeyDownClick) return;
        handleKeyDownClick(params, event)
    }

    const slotProps = {
        ...(nofilter
          ? {}
          : {
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }),
        ...(handleContextMenu
          ? {
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: 'context-menu' },
              },
            }
          : {}),
      };
      


    return (
        <>

            <div className='bg-white' style={!nofilter ? { height: height || 440, width: width || '100%' } : { height: height || '140px', width:  width || '100%'}}>

                <StripedDataGrid
                    getRowId={getRowId}
                    apiRef={apiRef}
                    rows={rowsFinal}
                    columns={columns}
                    rowHeight={25}
                    columnHeaderHeight={25}
                    disableMultipleRowSelection={true}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    initialState={{
                        pagination: {
                        },
                    }}
                    pageSizeOptions={[]}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    rowSelectionModel={select}
                    columnVisibilityModel={columnVisibilityModel}
                    ignoreDiacritics={true}
                    hideFooter={nofilter}
                    showColumnVerticalBorder={true}
                    showCellVerticalBorder={true}
                    slots={!nofilter ? { toolbar: QuickSearchToolbar } : {}}
                    slotProps={slotProps}
                    // add checkboxSelection if not undefined or false
                    checkboxSelection={checkboxSelection ? checkboxSelection : false}
                    onCellKeyDown={handleKeyDownClickChange}
                    {...others}
                    
                />
            </div>

        </>
    )
    //}
});

export default RedDataTableBasic