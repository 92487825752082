
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, chauffeur_delete_api, chauffeur_info_api, chauffeur_list_api } from '../../global/GlobalVars';
import Chauffeur from './Chauffeur';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDateTime, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetChauffeurEmptyQuery, cleanGetChauffeurQuery } from './Chauffeur';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID', width: 70, filterable: false },
  { field: 'code', headerName: 'Code', width: 200 },
  { field: 'libelle', headerName: 'Nom', width: 200 },
  { field: 'saisie_le', headerName: 'Saisie le', width: 200, type: 'date', valueFormatter: (params) => formatDateTime(params.value, false, true) },
  //{ field: 'pp', headerName: 'PRIX VTE', type: 'number', width: 100, valueFormatter: ({ value }) => formatDecimalNumber(value) },
];
const hideColumns = { id: false }


export const GetChauffeursListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['ChauffeurList'],
    queryFn: async () => await callAxiosApi(`${chauffeur_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddChauffeurLine = (queryClient, handleCloseChauffeur, handleRowSelectionChauffeursList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${chauffeur_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['ChauffeurList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addChauffeurLine onSuccess');
      customConsoleLog(newprod);
      handleCloseChauffeur();
      handleRowSelectionChauffeursList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateChauffeurLine = (queryClient, handleCloseChauffeur, handleRowSelectionChauffeursList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${chauffeur_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateChauffeurLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateChauffeurLine called')
      queryClient.setQueryData(['ChauffeurList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetChauffeurQuery(newprod.id, queryClient);
      handleCloseChauffeur();
      handleRowSelectionChauffeursList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteChauffeurLine = (queryClient, handleCloseChauffeur) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${chauffeur_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['ChauffeurList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetChauffeurQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseChauffeur();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetChauffeursListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['ChauffeurList'] });
}


const ChauffeursList = forwardRef((props, ref) => {
  //function ChauffeursList({ removeWidget, isModal }) {
  const { handleGetid_chauffeur, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openChauffeurList, setOpenChauffeurList] = useState(false);
  const [openChauffeur, setOpenChauffeur] = useState(false);

  const [actionNameChauffeur, setActionNameChauffeur] = useState("")
  const [selectChauffeursList, setSelectionChauffeursList] = useState([]);

  const handleRowSelectionChauffeursList = (e) => {
    if (e != selectChauffeursList[0]) {
      setSelectionChauffeursList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenChauffeur = () => {
    setOpenChauffeur(true);

    //BasicModalRef.current.open();
  }
  const handleCloseChauffeur = () => {
    setOpenChauffeur(false);
    //BasicModalRef.current.close();
    cleanGetChauffeurEmptyQuery(queryClient);
    cleanGetChauffeurQuery(selectChauffeursList[0], queryClient);
  }



  const [status_chauffeur, execute_chauffeur, resolve_chauffeur, reject_chauffeur, reset_chauffeur] = useAwaitableComponent();
  const [asyncConfirmDialogInfoChauffeur, setConfirmDialogInfoChauffeur] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogChauffeursList = status_chauffeur === "awaiting";

  const handleAwaitModalChauffeursList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoChauffeur({ titre: mtitre, msg: mmsg });
      await execute_chauffeur();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_chauffeur();
    }
  };


  customConsoleLog('ChauffeursList rendered')



  const { data: data_chauffeurslist = [], error: error_chauffeurslist,
    refetch: refecth_chauffeurslist, isLoading: isLoading_chauffeurslist, failureCount: failureCount_chauffeurslist } =
    GetChauffeursListQuery();






  const handleFiltrerChauffeursList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauChauffeursList = () => {

    clearToasts();
    setActionNameChauffeur(btnActionsName.nouveau);
    setSelectionChauffeursList([])
    handleOpenChauffeur();

  }

  const handleConsulterChauffeursList = () => {
    if (selectChauffeursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameChauffeur(btnActionsName.consulter);

    handleOpenChauffeur();
    customConsoleLog(selectChauffeursList[0]);
  }

  const handleDupliquerChauffeursList = () => {
    if (selectChauffeursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameChauffeur(btnActionsName.dupliquer);
    handleOpenChauffeur();
    customConsoleLog(selectChauffeursList[0]);
  }

  const handleModifierChauffeursList = () => {
    if (selectChauffeursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameChauffeur(btnActionsName.modifier);
    handleOpenChauffeur();
    customConsoleLog(selectChauffeursList[0]);
  }


  const handleSupprimerChauffeursList = async () => {
    if (selectChauffeursList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectChauffeursList[0])
    if (await handleAwaitModalChauffeursList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameChauffeur(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteChauffeurLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenChauffeursList = (itemID) => {
    setOpenChauffeurList(true);


    if(itemID) {
      handleRowSelectionChauffeursList([itemID]);
    }
  }


  const handleFermerChauffeursList = () => {
    if (selectChauffeursList[0]) {
      if (handleGetid_chauffeur) {

        handleGetid_chauffeur(selectChauffeursList[0]);
      }
    }
    cleanGetChauffeursListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      cleanGetChauffeursListQuery(queryClient);
      cleanGetChauffeurEmptyQuery(queryClient);
      cleanGetChauffeurQuery(selectChauffeursList[0], queryClient);
      setOpenChauffeurList(false);
    }
    else{

      setOpenChauffeurList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_chauffeurslist.filter(item => item.id == id)[0];
  }



  const addChauffeurLine = useAddChauffeurLine(queryClient, handleCloseChauffeur, handleRowSelectionChauffeursList);


  const updateChauffeurLine = useUpdateChauffeurLine(queryClient, handleCloseChauffeur, handleRowSelectionChauffeursList);

  const deleteChauffeurLine = useDeleteChauffeurLine(queryClient, handleCloseChauffeur);






  const DataTableChauffeursListRef = React.useRef(null);

  const handlePrintChauffeursList = () => {
    if (DataTableChauffeursListRef.current) {
      DataTableChauffeursListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenChauffeursList(itemID);
    },
    handleClose() {
      handleFermerChauffeursList();
    }
  }));

  // if (isLoading_chauffeurslist || error_chauffeurslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerChauffeursList}
  //         refetch={refecth_chauffeurslist}
  //         failureCount={failureCount_chauffeurslist}
  //         error={error_chauffeurslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openChauffeurList} handleClose={handleFermerChauffeursList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_chauffeurslist || error_chauffeurslist
            ? <BasicLoading
              handleClose={handleFermerChauffeursList}
              refetch={refecth_chauffeurslist}
              failureCount={failureCount_chauffeurslist}
              error={error_chauffeurslist} />
            : <>
              {
                showAsyncConfirmDialogChauffeursList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogChauffeursList}
                  onSubmit={resolve_chauffeur}
                  onCancel={reject_chauffeur}
                  info={asyncConfirmDialogInfoChauffeur}
                />
              }


              {
                openChauffeur &&
                <BasicModal open={openChauffeur} isModal={true}>
                  <Chauffeur
                    actionName={actionNameChauffeur}
                    selectedItem={getSelecteditemByID(selectChauffeursList[0])}
                    handleClose={handleCloseChauffeur}
                    addChauffeurLine={addChauffeurLine}
                    updateChauffeurLine={updateChauffeurLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Chauffeurs</h2>}

              <RedDataTableBasic
                ref={DataTableChauffeursListRef}
                rows={data_chauffeurslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionChauffeursList} 
                select={selectChauffeursList}
                onRowDoubleClick={handleConsulterChauffeursList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerChauffeursList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauChauffeursList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterChauffeursList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerChauffeursList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierChauffeursList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintChauffeursList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerChauffeursList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerChauffeursList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default ChauffeursList



const ChauffeursListwidgetKey = 'Chauffeurswidget'
export const ChauffeursListwidget = { i: ChauffeursListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: ChauffeursList, titre: 'Chauffeurs', id_fenetre: 816 }
