
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import BasicModal from '../../components/BasicModal';
import RedDataTableBasic from '../../components/RedDataTableBasic';
import { btnActionsName, categorie_delete_api, categorie_info_api, categorie_list_api } from '../../global/GlobalVars';
import Categorie from './Categorie';
import BasicButton from '../../components/BasicButton';
import { clearToasts, formatDecimalNumber, showToast, showToastError } from '../../global/Tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BasicLoading from '../../components/BasicLoading';
import useAwaitableComponent from 'use-awaitable-component';
import BasicAsyncConfirmDialog from '../../components/BasicAsyncConfirmDialog';
import { callAxiosApi } from '../../interceptors/axios';
import { selectionERREUR } from '../../global/GlobalText';
import { cleanGetCategorieEmptyQuery, cleanGetCategorieQuery } from './Categorie';
import { customConsoleLog } from '../../global/Tools';






const columns = [
  { field: 'id', headerName: 'ID Catégorie', width: 120, filterable: false },
  { field: 'libelle', headerName: 'Catégorie', width: 200 },
  { field: 'tarif', headerName: 'Tarif', width: 100 },
];
const hideColumns = { }


export const GetCategoriesListQuery = (canFetch = true) => {
  return useQuery({
    queryKey: ['CategorieList'],
    queryFn: async () => await callAxiosApi(`${categorie_list_api}`, '', true)
    ,
    refetchOnWindowFocus: false,
    retry: 2,
    cacheTime: 0,
    enabled: canFetch,
  })
}

const useAddCategorieLine = (queryClient, handleCloseCategorie, handleRowSelectionCategoriesList) => {
  return useMutation({
    mutationFn: async (itemID) => {
      const response = await callAxiosApi(`${categorie_info_api}${itemID}`, '', true);

      return response;
    }
    ,
    onSuccess: (newprod) => {
      queryClient.setQueryData(['CategorieList'], (previous) => {
        if (Array.isArray(previous)) {
          return [...previous, newprod]
        }
        else {
          customConsoleLog('its not an array')
        }
      })

      customConsoleLog('addCategorieLine onSuccess');
      customConsoleLog(newprod);
      handleCloseCategorie();
      handleRowSelectionCategoriesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },

  })
}

const useUpdateCategorieLine = (queryClient, handleCloseCategorie, handleRowSelectionCategoriesList) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${categorie_info_api}${itemID}`, '', true)
    ,
    mutationKey: ['updateCategorieLine', 0],
    onSuccess: (newprod) => {
      customConsoleLog('updateCategorieLine called')
      queryClient.setQueryData(['CategorieList'], (previous) => {

        if (Array.isArray(previous)) { // Check if previous is an array
          return previous.map((prod) => (prod.id === newprod.id ? newprod : prod));
        } else {
          return previous;
        }
      })
      cleanGetCategorieQuery(newprod.id, queryClient);
      handleCloseCategorie();
      handleRowSelectionCategoriesList([newprod.id]);
    },
    onError: (error, variables, context) => {


    },
  })
}

const useDeleteCategorieLine = (queryClient, handleCloseCategorie) => {
  return useMutation({
    mutationFn: async (itemID) => await callAxiosApi(`${categorie_delete_api}${itemID}`, '', true)
    ,
    onSuccess: (delprod) => {
      queryClient.setQueryData(['CategorieList'], (previous) => {
        if (Array.isArray(previous)) {

          return previous.filter((prod) => prod.id !== delprod.id);
        }

      }
      );
      cleanGetCategorieQuery(delprod.id, queryClient);
      showToast(`${delprod.success}`, 5000, 'succes');
      handleCloseCategorie();
    },
    onError: (error, variables, context) => {


    },
  })
}



export const cleanGetCategoriesListQuery = (queryClient) => {
  queryClient.removeQueries({ queryKey: ['CategorieList'] });
}


const CategoriesList = forwardRef((props, ref) => {
  //function CategoriesList({ removeWidget, isModal }) {
  const { handleGetid_categorie, isModal, removeWidget, ...others } = props;
  const queryClient = useQueryClient();
  const [openCategorieList, setOpenCategorieList] = useState(false);
  const [openCategorie, setOpenCategorie] = useState(false);

  const [actionNameCategorie, setActionNameCategorie] = useState("")
  const [selectCategoriesList, setSelectionCategoriesList] = useState([]);

  const handleRowSelectionCategoriesList = (e) => {
    if (e != selectCategoriesList[0]) {
      setSelectionCategoriesList(e);
      customConsoleLog(e);
    }
  };

  const handleOpenCategorie = () => {
    setOpenCategorie(true);

    //BasicModalRef.current.open();
  }
  const handleCloseCategorie = () => {
    setOpenCategorie(false);
    //BasicModalRef.current.close();
    cleanGetCategorieEmptyQuery(queryClient);
    cleanGetCategorieQuery(selectCategoriesList[0], queryClient);
  }



  const [status_categorie, execute_categorie, resolve_categorie, reject_categorie, reset_categorie] = useAwaitableComponent();
  const [asyncConfirmDialogInfoCategorie, setConfirmDialogInfoCategorie] = useState({ titre: '', msg: '' });

  const showAsyncConfirmDialogCategoriesList = status_categorie === "awaiting";

  const handleAwaitModalCategoriesList = async (mtitre, mmsg) => {

    try {
      setConfirmDialogInfoCategorie({ titre: mtitre, msg: mmsg });
      await execute_categorie();
      return true;
    } catch (err) {
      return false;
    } finally {
      reset_categorie();
    }
  };


  customConsoleLog('CategoriesList rendered')



  const { data: data_categorieslist = [], error: error_categorieslist,
    refetch: refecth_categorieslist, isLoading: isLoading_categorieslist, failureCount: failureCount_categorieslist } =
    GetCategoriesListQuery();






  const handleFiltrerCategoriesList = () => {
    customConsoleLog('filtrer')
  }


  const handleNouveauCategoriesList = () => {

    clearToasts();
    setActionNameCategorie(btnActionsName.nouveau);
    setSelectionCategoriesList([])
    handleOpenCategorie();

  }

  const handleConsulterCategoriesList = () => {
    if (selectCategoriesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameCategorie(btnActionsName.consulter);

    handleOpenCategorie();
    customConsoleLog(selectCategoriesList[0]);
  }

  const handleDupliquerCategoriesList = () => {
    if (selectCategoriesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameCategorie(btnActionsName.dupliquer);
    handleOpenCategorie();
    customConsoleLog(selectCategoriesList[0]);
  }

  const handleModifierCategoriesList = () => {
    if (selectCategoriesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();
    setActionNameCategorie(btnActionsName.modifier);
    handleOpenCategorie();
    customConsoleLog(selectCategoriesList[0]);
  }


  const handleSupprimerCategoriesList = async () => {
    if (selectCategoriesList.length < 1) {
      showToastError(selectionERREUR, 5000, 'error');
      return;
    }
    clearToasts();

    const item = getSelecteditemByID(selectCategoriesList[0])
    if (await handleAwaitModalCategoriesList('Supprimer!!', `Supprimer [${item.code} - ${item.libelle} ] ?!`)) {

      setActionNameCategorie(btnActionsName.supprimer);
      customConsoleLog('supprimer : ', item.id);
      deleteCategorieLine.mutate(item.id);
    }
    else {
      customConsoleLog('annuler');
    }


  }


  const handleOpenCategoriesList = (itemID) => {
    setOpenCategorieList(true);


    if(itemID) {
      handleRowSelectionCategoriesList([itemID]);
    }
  }


  const handleFermerCategoriesList = () => {
    if (selectCategoriesList[0]) {
      if (handleGetid_categorie) {

        handleGetid_categorie(selectCategoriesList[0]);
      }
    }
    //cleanGetCategoriesListQuery(queryClient);

    if (removeWidget) {

      removeWidget();
      //cleanGetCategoriesListQuery(queryClient);
      cleanGetCategorieEmptyQuery(queryClient);
      cleanGetCategorieQuery(selectCategoriesList[0], queryClient);
      setOpenCategorieList(false);
    }
    else{

      setOpenCategorieList(false);
    }
  }



  const getSelecteditemByID = (id) => {
    return data_categorieslist.filter(item => item.id == id)[0];
  }



  const addCategorieLine = useAddCategorieLine(queryClient, handleCloseCategorie, handleRowSelectionCategoriesList);


  const updateCategorieLine = useUpdateCategorieLine(queryClient, handleCloseCategorie, handleRowSelectionCategoriesList);

  const deleteCategorieLine = useDeleteCategorieLine(queryClient, handleCloseCategorie);






  const DataTableCategoriesListRef = React.useRef(null);

  const handlePrintCategoriesList = () => {
    if (DataTableCategoriesListRef.current) {
      DataTableCategoriesListRef.current.handlePrint({ hideToolbar: true, hideFooter: true });
    }
  };


  useImperativeHandle(ref, () => ({
    handleOpen(itemID) {
      handleOpenCategoriesList(itemID);
    },
    handleClose() {
      handleFermerCategoriesList();
    }
  }));

  // if (isLoading_categorieslist || error_categorieslist) {
  //   return (
  //     <>
  //       <BasicLoading
  //         handleClose={handleFermerCategoriesList}
  //         refetch={refecth_categorieslist}
  //         failureCount={failureCount_categorieslist}
  //         error={error_categorieslist} />

  //     </>
  //   )
  // }


  return (
    <>
      <BasicModal open={openCategorieList} handleClose={handleFermerCategoriesList} isModal={isModal} >
        {/* Tables */}
        {
          isLoading_categorieslist || error_categorieslist
            ? <BasicLoading
              handleClose={handleFermerCategoriesList}
              refetch={refecth_categorieslist}
              failureCount={failureCount_categorieslist}
              error={error_categorieslist} />
            : <>
              {
                showAsyncConfirmDialogCategoriesList &&
                <BasicAsyncConfirmDialog
                  visible={showAsyncConfirmDialogCategoriesList}
                  onSubmit={resolve_categorie}
                  onCancel={reject_categorie}
                  info={asyncConfirmDialogInfoCategorie}
                />
              }


              {
                openCategorie &&
                <BasicModal open={openCategorie} isModal={true}>
                  <Categorie
                    actionName={actionNameCategorie}
                    selectedItem={getSelecteditemByID(selectCategoriesList[0])}
                    handleClose={handleCloseCategorie}
                    addCategorieLine={addCategorieLine}
                    updateCategorieLine={updateCategorieLine}
                  />
                </BasicModal>
              }
              {isModal && <h2 className="text-lg font-bold mb-4">Categories</h2>}

              <RedDataTableBasic
                ref={DataTableCategoriesListRef}
                rows={data_categorieslist}
                columns={columns}
                columnVisibilityModel={hideColumns}
                handleRowSelection={handleRowSelectionCategoriesList} 
                select={selectCategoriesList}
                onRowDoubleClick={handleConsulterCategoriesList}
              />

              {/* Buttons */}
              <div className="mt-2 grid grid-cols-10 gap-2 p-2">
                <BasicButton onClick={handleFiltrerCategoriesList} id="filtrer" name="filtrer">Filtrer</BasicButton>
                <div></div>
                <BasicButton onClick={handleNouveauCategoriesList} id="nouveau" name="nouveau">Nouveau</BasicButton>
                <BasicButton onClick={handleConsulterCategoriesList} id="consulter" name="consulter">Consulter</BasicButton>
                <BasicButton onClick={handleDupliquerCategoriesList} id="dupliquer" name="dupliquer">Dupliquer</BasicButton>
                <div></div>
                <BasicButton onClick={handleModifierCategoriesList} id="modifier" name="modifier">Modifier</BasicButton>
                <BasicButton onClick={handlePrintCategoriesList} id="imprimer" name="imprimer">Imprimer</BasicButton>
                <BasicButton onClick={handleSupprimerCategoriesList} id="supprimer" name="supprimer">Supprimer</BasicButton>
                <BasicButton onClick={handleFermerCategoriesList} id="fermer" name="fermer">Fermer</BasicButton>
              </div>
            </>
        }
      </BasicModal>


    </>

  );
  //}
});





export default CategoriesList



const CategoriesListwidgetKey = 'Categorieswidget'
export const CategoriesListwidget = { i: CategoriesListwidgetKey, x: 0, y: 0, w: 10, h: 15, component: CategoriesList, titre: 'Categories', id_fenetre:706 }
