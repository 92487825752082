import React from 'react'
import { DateFormat, DateFormatDb, checkString, cn, formatDate } from '../global/Tools';
//import { Calendar } from 'primereact/calendar';
import BasicInput from './BasicInput';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { alpha, styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { customConsoleLog } from '../global/Tools';

const spanLabelClass = "whitespace-nowrap inline bg-gray-100 py-1 px-1 text-gray-600"
const inputClass = "bg-transparent border-transparent w-full py-1  px-1  text-gray-600 focus:outline-blue-200"
const inputReadOnlyClass = "bg-gray-200 border-transparent w-full py-1  px-1  text-gray-600"

var customParseFormat = require('dayjs/plugin/customParseFormat')

const sxIsbig = {
  '& .MuiInputBase-root ': {
    backgroundColor: 'background.paper',
    borderRadius: 1,
    px: 0.2,
    py: 0.5,
    width: '100%',
    transition: (theme) => theme.transitions.create([
      'border-color',
    ]),
    '&:hover': {
      backgroundColor: 'background.paper'
    },
    '&.Mui-focused': {
      backgroundColor: 'background.paper',
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
      height: 'unset',
      lineHeight: 1.2,
      padding: 1,
    },
    '& .MuiIconButton-root': {
      padding: 0,
      marginRight: 0.5,
    },
    '& .MuiSvgIcon-root': {
      padding: 0,
    }
  }
}


const sxIsNotbig = {
  '& .MuiInputBase-root ': {
    backgroundColor: 'background.paper',
    borderRadius: 1,
    px: 0.2,
    py: 0.5,
    transition: (theme) => theme.transitions.create([
      'border-color',
    ]),
    '&:hover': {
      backgroundColor: 'background.paper'
    },
    '&.Mui-focused': {
      backgroundColor: 'background.paper',
    },
    '& .MuiInputBase-input': {
      fontSize: 10,
      height: 'unset',
      lineHeight: 1.2,
      padding: 0
    },
    '&.Mui-readOnly': {
      backgroundColor: 'action.disabledBackground',
      boxShadow: 'none',
      border: 'none',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      marginRight: 0.5,
    },
    '& .MuiSvgIcon-root': {
      padding: 0,
      height: 12,
      width: 12
    }
  }
}

function BasicDateInput(props) {
  const { titre, className, readOnly, value, onChange, isbig, ...others } = props;

  dayjs.extend(customParseFormat);

  const customClass = cn(readOnly ? `${inputReadOnlyClass} ${checkString(className)}` : `${inputClass} ${checkString(className)}`);
 
  const val = value ? dayjs(value, DateFormatDb) : '';

  // if (isbig) {
  //   return (
  //     <>
  //       <div className="inline-flex bg-white text-xxs w-full">
  //         {titre && <span className={spanLabelClass}>{titre}</span>}
  //         <LocalizationProvider dateAdapter={AdapterDayjs}>
  //           <DatePicker
  //             className='w-full'
  //             {...others}
  //             format={DateFormat}
  //             value={val}
  //             readOnly={readOnly}
  //             onChange={(e) => {
  //               let date = null
  //               try {

  //                 date = formatDate(e, true)
  //                 customConsoleLog('onChange date', date);
  //               }
  //               catch (e) {
  //                 customConsoleLog(e)
  //               }
  //               const event = { target: { value: date, name: others.name } };
  //               onChange(event)
  //             }}
  //             sx={sxIsbig}


  //           />
  //         </LocalizationProvider>

  //       </div>
  //     </>
  //   )
  // }

  return (
    <div className="inline-flex bg-white  w-full">
      {titre && <span className={spanLabelClass}>{titre}</span>}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className='w-full'
          format={DateFormat}
          {...others}
          value={val}
          readOnly={readOnly}
          onChange={(e) => {
            let date = null
            try {

              date = formatDate(e, true)
              customConsoleLog('onChange date', date);
            }
            catch (e) {
              customConsoleLog(e)
            }
            const event = { target: { value: date, name: others.name } };
            onChange(event)
          }}
          sx={isbig ? sxIsbig : sxIsNotbig}


        />
      </LocalizationProvider>

    </div>

  )
}

export default BasicDateInput